import React, { Component } from 'react';

import { getLoginUser } from 'services/Commonfunction';

import { isEmpty } from 'util/utils';

import { handleSendEmail, handleSendXRayEmail } from '../../DiamondList/DiamondListFunctions';
import OpenNotification from '../CommonButton/OpenNotification';
import CommonModal from '../CommonModal';
import InputBlock from '../InputBlock';

class SendEmailPopup extends Component {
  state = {
    subject: this.props.xRay ? 'X-Ray Detail' : 'Diamond Details',
    cc: '',
    email: '',
    message: this.props.xRay
      ? 'Dear Sir / Madam \nGreetings of the day from SL  Diamonds Team.\n Please have a look at below Xray detail.'
      : 'Dear Sir / Madam \nGreetings of the day from SL  Diamonds Team.\n Please have a look at below stock file.',
    subjectInvalid: false,
    emailInvalid: false,
    ccInvalid: false,
    isLoading: false,
  };

  componentDidMount() {
    const loginUser = getLoginUser();
    this.setState({ email: loginUser?.email ?? '' });
  }

  validateEmail = (value) => {
    const regEx = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    // return regEx.test(String(value).toLowerCase());

    return !isEmpty(value);
  };

  handleEmailChange = (e) => {
    this.setState({ email: e.target.value, emailInvalid: !this.validateEmail(e.target.value) });
  };

  handleSubjectChange = (e) => {
    this.setState({ subject: e.target.value, subjectInvalid: isEmpty(e.target.value.trim()) });
  };

  handleCCChange = (e) => {
    this.setState({ cc: e.target.value, ccInvalid: !this.validateEmail(e.target.value) });
  };

  sendEmail = () => {
    const obj = {
      email: {
        email: this.state.email,
        subject: this.state.subject,
        cc: this.state.cc,
        message: this.state.message,
      },
      sendEmail: true,
      orderDiamond: this?.props?.cnfrmPath ? this?.props?.cnfrmPath : undefined,
      id: this.props.ids,
      ...this.props.totalChecked,
    };

    if (window.location.pathname === '/account/memo-list') {
      obj.filter = { blockid: this.props.blockId };
      obj.excelType = 3;
    }

    if (window.location.pathname?.includes('predefine-match-pair')) {
      obj.isPairExcel = true;
    }

    if (!isEmpty(this.props.orderDiamond)) {
      obj.orderDiamond = this.props.orderDiamond;
    }

    for (const key in obj) {
      if (obj[key] === false) delete obj[key];
    }

    this.setState({ isLoading: true });

    this.props.xRay
      ? handleSendXRayEmail({ ...this.state }, this.props.path, (status) => {
          this.setState({ isLoading: true });

          if (status) {
            this.props.onDetailClose();
            this.props.onClose();
          }
        })
      : handleSendEmail(obj, (status) => {
          this.setState({ isLoading: true });
          this.props.onCancel();
          this.props.onClose();
        });
  };

  handleOk = () => {
    if (isEmpty(this.state.email.trim()))
      return this.setState({
        emailInvalid: true,
      });

    if (!this.state.subjectInvalid && this.props.ids.length >= 6) {
      this.props.onCancel();
      this.props.onClose();
      return OpenNotification({
        type: 'error',
        title: `Sorry, file size is greater than 25MB and can't send via email.`,
      });
    }

    this.sendEmail();
  };

  render() {
    return (
      <>
        <CommonModal
          visible={this.props.sendEmail}
          handleOk={() => this.handleOk()}
          handleCancel={() => this.props.onCancel()}
          title="Send Email"
          footerShow
          submitTitle="Send Email"
          cancelTitle="Cancel"
        >
          {/* <div className="searchPopupCommon"> */}
          <div className={`searchPopupCommon  ${this.state.isLoading ? 'loading-indicator' : ''} `}>
            <div className="searchPopupTwoValue">
              <InputBlock
                label="Subject *"
                placeholder="Subject"
                value={this.state.subject}
                //onChange={(e) => this.setState({ subject: e.target.value })}
                onChange={(e) => this.handleSubjectChange(e)}
                hasError={this.state.subjectInvalid ? true : false}
              />
              {this.state.subjectInvalid === true ? (
                <span className="required" style={{ color: 'red' }}>
                  Please enter email subject.
                </span>
              ) : (
                ''
              )}
            </div>
            <div className="searchPopupTwoValue">
              <InputBlock
                label="Email *"
                placeholder="Email ID"
                autoFocus
                value={this.state.email}
                onChange={(e) => this.handleEmailChange(e)}
                hasError={this.state.emailInvalid ? true : false}
              />
              {this.state.emailInvalid === true ? (
                <span className="required" style={{ color: 'red' }}>
                  Please enter valid email address.
                </span>
              ) : (
                ''
              )}
            </div>
            <div className="searchPopupTwoValue">
              <InputBlock
                label="Mail CC"
                placeholder="Mail CC"
                value={this.state.cc}
                onChange={(e) => this.handleCCChange(e)}
                hasError={this.state.ccInvalid ? true : false}
              />
              {this.state.ccInvalid === true ? (
                <span className="required" style={{ color: 'red' }}>
                  Please enter valid email address.
                </span>
              ) : (
                ''
              )}
            </div>
            <div className="searchPopupTwoValue">
              <InputBlock
                label="Message"
                placeholder="Message"
                value={this.state.message}
                onChange={(e) => this.setState({ message: e.target.value })}
              />
            </div>
          </div>
        </CommonModal>
      </>
    );
  }
}

export default SendEmailPopup;
