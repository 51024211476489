import React, { useEffect } from 'react';
import { HeadingCalc } from './ConfirmStonePopup';
import { ListingCards } from './MobileComfirmStone';
import Heading from '../Heading';
import useRowSelect from '../../../util/useRowSelect';

const WatchPopupMobile = (props) => {
  const { toggleRowSelection, isRowChecked } = useRowSelect(props.currentType);
  useEffect(() => {
    props.data.map((row) => {
      toggleRowSelection(row, true);
      return true;
    });
  }, []);

  return (
    <div className="offerWrapper">
      <div className="d-flex align-items-center justify-space-between offerTopBlock">
        <div className="d-flex align-items-center">
          <Heading className="popupInnerTitle" title="Watchlist"></Heading>
        </div>
        {HeadingCalc(props.data.filter((x) => isRowChecked(x.id)))}
      </div>
      <div className="searchInnerResult tabInnerTable">
        <div className="diamondListMobile mobileOfferScroll">
          <ListingCards watchpopup={props.parent.getBackSelect} data={props.data} currentType={props.currentType} />
        </div>
        <div className="d-flex justify-content-between offerBottomBox flex-wrap"></div>
      </div>
      {props.parent.btnAction()}
    </div>
  );
};

export default WatchPopupMobile;
