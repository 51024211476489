import axios from 'axios';
import FileType from 'file-type/browser';
import { kebabCase } from 'lodash';

import { asyncHandler } from 'util/utils';

export class MediaService {
  static async getHeaders(path) {
    return asyncHandler(async () => {
      const response = await axios.head(path, { responseType: 'blob' });
      return response?.headers;
    });
  }

  static async getBlob(path) {
    return asyncHandler(async () => {
      const response = await axios.get(path, { responseType: 'blob' });
      return response?.data;
    });
  }

  static async getCorrectExtension(blob) {
    const type = await FileType.fromBlob(blob);
    return type?.ext;
  }

  static async getCorrectFileName(blob, name = 'file') {
    const ext = await this.getCorrectExtension();
    return `${kebabCase(name).toLowerCase()}.${ext}`;
  }

  static viewFile(path) {
    return asyncHandler(async () => {
      const [error, blob] = await this.getBlob(path);
      if (error) throw error;
      const blobUrl = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      void ((anchor.style.display = 'none'), (anchor.href = blobUrl), (anchor.target = '_blank'));
      void (document.body.appendChild(anchor), anchor.click(), anchor.remove(), window.URL.revokeObjectURL(blobUrl));
    });
  }

  static downloadFile(path, name) {
    return asyncHandler(async () => {
      const [error, blob] = await this.getBlob(path);
      if (error) throw error;
      const fileName = await this.getCorrectFileName(blob, name ?? path.split('/').pop());
      const blobUrl = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      void ((anchor.style.display = 'none'), (anchor.href = blobUrl), (anchor.download = fileName));
      void (document.body.appendChild(anchor), anchor.click(), anchor.remove(), window.URL.revokeObjectURL(blobUrl));
    });
  }

  static printFile(path) {
    return asyncHandler(async () => {
      const [error, blob] = await this.getBlob(path);
      if (error) throw error;
      const blobUrl = window.URL.createObjectURL(blob);
      const iframe = document.querySelector('#PrintFrame') ?? document.createElement('iframe');
      if (iframe?.src) window.URL.revokeObjectURL(iframe.src);
      void ((iframe.style.display = 'none'), (iframe.src = blobUrl), (iframe.height = '100%'), (iframe.width = '100%'));
      void ((iframe.id = 'PrintFrame'), document.body.appendChild(iframe), iframe.contentWindow.print());
    });
  }

  static async checkIfSrcExists(url, tag = 'embed') {
    try {
      await new Promise((resolve, reject) => {
        const el = document.createElement(tag);
        el.addEventListener('load', () => void (el.remove(), resolve()));
        el.addEventListener('error', () => void (el.remove(), reject()));
        void ((el.src = url), (el.style.width = '1px'), (el.style.height = '1px'));
        document.body.appendChild(el);
        setTimeout(() => void (el.remove(), resolve()), 1000 * 60);
      });
      return true;
    } catch (error) {
      return false;
    }
  }
}

window.__MediaService = MediaService;
