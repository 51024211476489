import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import PageNotFound from 'components/PageNotFound';

import { AuthService } from 'services/Auth';
import { Storage } from 'services/Storage';

import { history } from 'util/history';
import { useIsMobile, usePathname, useQueryParams } from 'util/hooks';
import { loadable } from 'util/loadable';
import { isEmpty, logError } from 'util/utils';

import App from 'App';

const DiamondSearch = loadable(() => import('containers/DiamondSearch'));
const MatchPairDiamondSearch = loadable(() => import('containers/MatchPairDiamondSearch'));

const Account = loadable(() => import('components/Account'));
const ChangePassword = loadable(() => import('components/Account/ChangePassword'));
const CompanyInformation = loadable(() => import('components/Account/CompanyInformation'));
const DiamondColumnListing = loadable(() => import('components/Account/ManageDiamondSequence'));
const MobileAccount = loadable(() => import('components/Account/MobileAccount'));
const PersonalInformation = loadable(() => import('components/Account/PersonalInformation'));
const Dashboard = loadable(() => import('components/Dashboard'));
const Compare = loadable(() => import('components/DiamondCompare'));
const DiamondDetail = loadable(() => import('components/DiamondDetail'));
const DiamondLayout = loadable(() => import('components/DiamondLayout'));
const DiamondList = loadable(() => import('components/DiamondList'));
const BidList = loadable(() => import('components/DiamondList/BidList'));
const MyDemand = loadable(() => import('components/MyDemand/'));
const NewArrivalBidIt = loadable(() => import('components/NewArrivalBidIt'));
const SavedSearch = loadable(() => import('components/SavedSearch'));
const NotificationDropdown = loadable(() => import('components/common/Header/NotificationDropdown'));
const NotificationPage = loadable(() => import('components/common/Header/NotificationPage'));

function PermissionRoute(props) {
  const { moduleType, ...forwardProps } = props;

  const view = useSelector((store) => store.settings?.permissions?.[moduleType]?.view);

  if (!view) return <PageNotFound />;

  return <Route {...forwardProps} />;
}

function Routes() {
  const pathname = usePathname();
  const query = useQueryParams();

  const [isMobile] = useIsMobile();

  const token = useSelector((store) => store.auth?.token);
  const permissions = useSelector((store) => store.settings?.permissions);

  const redirectPage = () => {
    try {
      if (isEmpty(permissions)) AuthService.signOut(true);

      if (pathname === 'device' && !isEmpty(query.token)) Storage.set('token', query.token);

      if (isEmpty(token)) {
        const isTrusted = Storage.get('isTrusted', false) !== 'false';
        history.push(isTrusted ? 'signin' : 'verify-otp');
      }
    } catch (error) {
      logError(error);
    }
  };

  redirectPage();

  return (
    <App>
      <Switch>
        <PermissionRoute moduleType="CART" path="/account/cart" component={isMobile ? DiamondList : Account} exact />
        <PermissionRoute
          moduleType="CONFIRM_STONE"
          path="/account/confirm-list"
          component={isMobile ? DiamondList : Account}
          exact
        />
        <PermissionRoute
          moduleType="ENQUIRY"
          path="/account/enquiry"
          component={isMobile ? DiamondList : Account}
          exact
        />
        <PermissionRoute
          moduleType="DEMAND"
          path="/account/my-demand"
          component={isMobile ? MyDemand : Account}
          exact
        />
        <PermissionRoute
          moduleType="CONFIRM_STONE"
          path="/account/my-purchase"
          component={isMobile ? DiamondList : Account}
          exact
        />
        <PermissionRoute moduleType="NOTES" path="/account/notes" component={isMobile ? DiamondList : Account} exact />
        <PermissionRoute
          moduleType="MEMO"
          path="/account/memo-list"
          component={isMobile ? DiamondList : Account}
          exact
        />
        <PermissionRoute moduleType="REMINDER" path="/account/reminder" component={Account} exact />
        <PermissionRoute
          moduleType="SAVE_SEARCH"
          path="/account/saved-search"
          component={isMobile ? SavedSearch : Account}
          exact
        />
        <PermissionRoute
          moduleType="WATCHLIST"
          path="/account/watchlist"
          component={isMobile ? DiamondList : Account}
          exact
        />
        <PermissionRoute moduleType="SEARCH_LIST" path="/collection/:id?" component={DiamondList} exact />
        <PermissionRoute moduleType="COMPARE" path="/compare" component={Compare} exact />
        <PermissionRoute moduleType="DASHBOARD" path="/dashboard" component={Dashboard} exact />
        <PermissionRoute moduleType="DEMO" path="/demo/:id?" component={DiamondList} exact />
        <PermissionRoute moduleType="LAYOUT" path="/diamond-layout/:id?" component={DiamondLayout} exact />
        <PermissionRoute moduleType="SEARCH_LIST" path="/diamond-list/:id?" component={DiamondList} exact />
        <PermissionRoute moduleType="SEARCH_DIAMOND" path="/diamond-search/:id?" component={DiamondSearch} exact />
        <PermissionRoute moduleType="FEATURE_STONE" path="/feature-stone/:id?" component={DiamondList} exact />
        <PermissionRoute moduleType="LAYOUT" path="/layout/:id?" component={DiamondList} />
        <PermissionRoute moduleType="MY_BID" path="/my-bid/:id?" component={BidList} exact />
        <PermissionRoute moduleType="BID" path="/new-arrival-bid-it/:id?" component={NewArrivalBidIt} exact />
        <PermissionRoute moduleType="NEW_ARRIVAL" path="/new-arrival/:id?" component={DiamondList} exact />
        <PermissionRoute moduleType="NOTIFICATIONS" path="/notifications" component={NotificationPage} exact />
        <PermissionRoute moduleType="MATCH_PAIR" path="/predefine-match-pair/:id?" component={DiamondList} />
        <PermissionRoute moduleType="SEARCH_LAYOUT" path="/search-layout/:id?" component={DiamondLayout} exact />
        <PermissionRoute
          moduleType="SEARCH_MATCH_PAIR"
          path="/search-match-pair/:id?"
          component={MatchPairDiamondSearch}
          exact
        />
        <PermissionRoute moduleType="UPCOMING" path="/upcoming/:id?" component={DiamondList} exact />

        {isMobile && <PermissionRoute moduleType="DEMAND" path="/account/my-demand" component={MyDemand} exact />}
        {isMobile && (
          <PermissionRoute moduleType="SAVE_SEARCH" path="/account/saved-search" component={SavedSearch} exact />
        )}
        {isMobile && <Route path="/account/change-password" component={ChangePassword} exact />}
        {isMobile && <Route path="/account/company-profile" component={CompanyInformation} exact />}
        {isMobile && <Route path="/account/personal-information" component={PersonalInformation} exact />}
        {isMobile && <Route path="/notification" component={NotificationDropdown} exact />}

        <Route path="/account" component={isMobile ? MobileAccount : Account} exact />
        <Route path="/account/profile" component={Account} exact />
        <Route path="/account/profile/diamond-sequence" component={isMobile ? DiamondColumnListing : Account} exact />
        <Route path="/diamond-detail/:id" component={DiamondDetail} exact />

        <Route path="*" component={PageNotFound} />
      </Switch>
    </App>
  );
}

export default Routes;
