import React from 'react';

import { Select } from 'antd';

import { Field, Label, Error } from 'components/Input/Input';

import { isEmpty } from 'util/utils';

import './selectOption.less';

const SelectOption = (props) => {
  const { error, label: title, hasError: he, selectValue, ...rest } = props;

  const hasError = !isEmpty(error);

  return (
    <Field className="formSelect" hasErrors={hasError}>
      <Label>{title}</Label>
      <Select
        {...rest}
        dropdownClassName="selectDopdown"
        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      >
        {selectValue.map((option, index) => {
          const { id, name, ...rest } = option;
          return (
            <Select.Option key={index} {...rest} value={id}>
              {name}
            </Select.Option>
          );
        })}
      </Select>
      <Error>{error}</Error>
    </Field>
  );
};

export default SelectOption;
