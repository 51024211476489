import React, { memo, useEffect, useState } from 'react';

import { Drawer } from 'antd';
import find from 'lodash/find';

import { parseDecimal, isNumeric } from 'util/utils';

import { HeadingCalc } from './ConfirmStonePopup';
import SendEmailPopup from './SendEmail';

import IntlMessages from '../../../util/IntlMessages';
import useRowSelect from '../../../util/useRowSelect';
import MobileViewListCard from '../../DiamondList/MobileViewListCard';
import HeaderRightSidebar from '../HeaderRightSidebar';
import Heading from '../Heading';
/* eslint jsx-a11y/anchor-is-valid: 0 */

export const ListingCards = memo(
  ({
    parents,
    data,
    currentType,
    finalCalc = false,
    bidPopup = false,
    quote = false,
    quoteClick,
    checked = null,
    bid = false,
    note = false,
    watchpopup = false,
    quotePage = false,
  }) => {
    const { isRowChecked, toggleRowSelection } = useRowSelect(currentType);
    return (
      <>
        {data.map((x) => {
          const isChecked = !checked ? isRowChecked(x.id) : find(checked, { id: x.id }) ? true : false;
          const finalOfferOffer = isNumeric(x.finalquote) ? parseDecimal(x.finalquote) : 0;
          const newPricePerCaratOffer = isNumeric(x.newPricePerCarat) ? parseDecimal(x.newPricePerCarat) : 0;
          const calcDiscountOffer = Number(x.calcDiscount);
          const calcPricePerCaratOffer = Number(x.calcPricePerCarat);
          const calcAmountOffer = Number(x.calcAmount);
          return (
            <MobileViewListCard
              dataArray={data}
              {...parents}
              finalOfferOffer={finalOfferOffer}
              newPricePerCaratOffer={newPricePerCaratOffer}
              calcDiscountOffer={calcDiscountOffer}
              calcPricePerCaratOffer={calcPricePerCaratOffer}
              calcAmountOffer={calcAmountOffer}
              quote={quote}
              quotePage={quotePage}
              note={note}
              watchpopup={watchpopup}
              bidPopup={bidPopup}
              bid={bid}
              finalCalc={finalCalc}
              quoteClick={quoteClick}
              data={x}
              onClick={() => toggleRowSelection(x, !isChecked)}
              activeClass={isChecked ? 'active' : ''}
            />
          );
        })}
      </>
    );
  },
);

const MobileConfirmStone = (props) => {
  const { toggleRowSelection, isRowChecked } = useRowSelect(props.currentType);
  const [menu, showMenu, openMore] = useState(false);
  const [email, showEmail] = useState(false);
  useEffect(() => {
    props.data.map((row) => {
      toggleRowSelection(row, true);
      return true;
    });
  }, []);

  return (
    <>
      <div className="offerWrapper">
        <div className="d-flex align-items-center justify-space-between offerTopBlock">
          <div className="d-flex align-items-center">
            <Heading className="popupInnerTitle" title={<IntlMessages id="app.confirmStone" />}></Heading>
          </div>
          {HeadingCalc(props.data.filter((x) => isRowChecked(x.id)))}
        </div>
        <div className="searchInnerResult tabInnerTable">
          <div className="diamondListMobile mobileOfferScroll">
            <ListingCards data={props.data} currentType={props.currentType} />
          </div>
          <div className="d-flex justify-content-between offerBottomBox flex-wrap"></div>
        </div>

        <div className="sideBarPopupButton">
          <div className="mobileSearchBottom">
            <div className="mobileSearchActionItem">
              <a
                className="mobileSearchActionItem"
                onClick={() => {
                  if (props.parent.checkCheck()) showMenu(true);
                }}
              >
                <img src={require('../../../assets/svg/DiamondSearch/confirm.svg')} alt="" />

                <IntlMessages id="app.confirmStone" />
              </a>
            </div>
            <div className="mobileSearchActionItem">
              <a onClick={() => props.onClickExcel()}>
                <img src={require('../../../assets/svg/DiamondSearch/excel-download.svg')} alt="" />
                <IntlMessages id="app.ExcelExport" />
              </a>
            </div>
            <div className="mobileSearchActionItem">
              <a
                onClick={() => {
                  showEmail(true);
                }}
              >
                <img src={require('../../../assets/svg/DiamondSearch/emailExcel.svg')} alt="" />
                <IntlMessages id="app.EmailStock" />
              </a>
            </div>
            {/* <div className="mobileSearchActionItem">
              <a
              // onClick={() => this.props.handleRefresh()}
              >
                <img src={require('../../../assets/svg/DiamondSearch/email-pic.svg')} alt="" />
                <span>Email Pic</span>
              </a>
            </div> */}
          </div>
        </div>
      </div>
      {menu && (
        <Drawer
          title={false}
          onClose={() => showMenu(false)}
          visible={true}
          wrapClassName="stone-detail-sidebar smallSidebarMobilePopup"
        >
          <div>
            <div className="offerInputBox">
              {props.parent.getcompany()}
              {props.parent.gettextarea()}
            </div>
            <div className="sideBarPopupButton">
              <a className="commonButton" onClick={props.parent.submit}>
                Confirm
              </a>
              <a className="commonOutline" onClick={props.parent.cancel}>
                Cancel
              </a>
            </div>
          </div>
        </Drawer>
      )}
      <SendEmailPopup
        sendEmail={email}
        onClose={() => {
          props.parent.cancel();
          showEmail(false);
        }}
        onCancel={() => showEmail(false)}
        ids={props?.data?.map((x) => x.id)}
        img={email === 'img'}
        isStock={email === 'stock'}
      />
    </>
  );
};

export default memo(MobileConfirmStone);
