import React from 'react';
import { useSelector } from 'react-redux';

import { EMPTY_VALUES } from 'util/constants';

import { useAutoRef } from './useAutoRef';

export function useSelectedRows(currentType, options) {
  options = { mapFields: [], ...options };
  const optionsRef = useAutoRef(options);

  const rowList = useSelector((state) => state?.diamond?.selectedRows?.[currentType] ?? EMPTY_VALUES.ARRAY);

  const rowMap = React.useMemo(() => {
    const { mapFields } = optionsRef.current;
    return Object.fromEntries(mapFields.map((field) => [field, rowList.map((row) => row?.[field])]));
  }, [optionsRef, rowList]);

  React.useDebugValue({ rowList, rowMap });
  return [rowList, rowMap];
}
