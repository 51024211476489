import React, { Component } from 'react';

import axios from 'axios';

export default class IFrame extends Component {
  state = {
    src: this.props.src,
    error: false,
    loading: true,
  };
  // componentDidMount() {
  //   if (this.props.detail) {

  //     if (this.props.video) {
  //       this.setState({ error: this.props.error })

  //       if (!this.props.videoDone) {

  //         this.getData()
  //       }
  //     } else {
  //       this.setState({ error: this.props.errorCerti })
  //       if (!this.props.certiDone) {
  //         this.getData()
  //       }
  //     }
  //   } else {
  //     this.getData()

  //   }

  // }
  // getData = () => {
  //   this.setState({ loading: true }, () => {
  //     axios
  //       .get(this.props.src + "?" + new Date(), {
  //         responseType: "blob",
  //       })
  //       .then((resp) => {
  //         if (resp.status === 200 && resp.statusText === "OK") {
  //           this.setState({ error: false });
  //           if (this.props.detail) {
  //             if (this.props.video) {
  //               this.props.doneVideo();
  //               this.props.setError(false);
  //             } else {
  //               this.props.setErrorCerti(false);

  //               this.props.doneCerti();
  //             }
  //           }
  //         } else {
  //           if (this.props.detail) {
  //             if (this.props.video) {
  //               this.props.doneVideo();
  //               this.props.setError(true);
  //             } else {
  //               this.props.setErrorCerti(true);
  //               this.props.doneCerti();
  //             }
  //           }
  //           this.setState({ error: true });
  //         }
  //         this.setState({ loading: false });
  //       })
  //       .catch((error) => {
  //         if (this.props.detail) {
  //           if (this.props.video) {
  //             this.props.doneVideo();
  //             this.props.setError(true);
  //           } else {
  //             this.props.doneCerti();
  //             this.props.setErrorCerti(true);
  //           }
  //         }
  //         if (error) {
  //           this.setState({ error: true });
  //         }
  //         this.setState({ loading: false });
  //       });
  //   });
  // };
  removeOnLoad = () => {
    this.setState({ loading: false });
  };

  render() {
    const { error, loading } = this.state;
    return (
      <>
        {loading && <div className="loading-indicator"></div>}

        {error ? (
          this.props.detail ? (
            <div className="nodataFoundDetail">
              <div className="nodataFoundInner">
                <img src={this.props.tempSrc} />
                <span>No {this.props.video ? 'Video ' : 'Certificate '}Found</span>
              </div>
            </div>
          ) : (
            <div className="nodataFoundDetail">
              <div className="nodataFoundInner">
                <img src={this.props.tempSrc} />
                <span>No {this.props.video ? 'Video ' : 'Certificate '}Found</span>
              </div>
            </div>
          )
        ) : this.props.detail ? (
          <iframe
            ref={(this.myRef = React.createRef())}
            id="myFrameVideoSmall"
            src={this.state.src}
            onLoad={this.removeOnLoad}
          />
        ) : (
          <iframe
            ref={(this.myRef = React.createRef())}
            id="myFrame"
            src={this.state.src}
            height="100%"
            width="100%"
            onLoad={() => {
              this.setState({ loading: false });
            }}
          />
        )}
      </>
    );
  }
}
