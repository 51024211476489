import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import groupBy from 'lodash/groupBy';
import moment from 'moment';

import { Storage } from 'services/Storage';

import { usePathname } from 'util/hooks';
import { isNumber, parseDecimal } from 'util/utils';

import FinalCalcMobile from './FinalCalcMobile';
import { calculate, formatNumber } from './SelectStone';
import SendEmailPopup from './SendEmail';

import { LOCAL_STORAGE_VAR, DIAMOND_BID, PROJECT_SETTINGS_TYPE } from '../../../constants/Common';
import IntlMessages from '../../../util/IntlMessages';
import {
  handleDownloadExcel,
  getColumn,
  handleConfirmStone,
  getProjectSetting,
  isMobile,
  getPath,
  LISTINGPAGES,
} from '../../DiamondList/DiamondListFunctions';
import Table from '../../DiamondList/TableBack';
import { getBidType } from '../../NewArrivalBidIt';
import CheckBox from '../CheckBox';
import OpenNotification from '../CommonButton/OpenNotification';
import SelectOption from '../SelectOption';

const currentType = 'FinalCalculation';
const FMTERMS = [
  { id: 'COD', name: 'COD Fixed', charge: 1 },
  { id: 'THIRTY', name: '30 Days Fixed', charge: 1.01 },
  { id: 'SIXTY', name: '60 Days Fixed', charge: 1.02 },
];

export function getExtraPer() {
  const terms = Storage.get('terms');
  let extraPer = isNumber(Number(terms?.extraPer)) ? Number(terms?.extraPer) : 0;
  if (extraPer < 0) extraPer = extraPer * -1;
  return extraPer;
}

export function newDiamondPrice(diamond, qt = false, qChange = '') {
  if (diamond.isBid) diamond.fmCharge = 0;
  let extraPer = getExtraPer();
  const quotePer = qt === true ? diamond.finalquote * -1 : 0;
  const bidPer = (qt === 'bid' || diamond.isBid) && getBidType() === DIAMOND_BID.TYPE.BLIND ? 0.5 : 0;
  let pricePerCarat =
    qt === 'bid'
      ? diamond.bidPricePerCarat
      : qt === true
      ? parseDecimal(diamond.rap - (diamond.rap * quotePer) / 100)
      : diamond.ctPr;

  if (qChange === 'quote') pricePerCarat = parseDecimal((diamond.finalquote * diamond.rap) / 100 + diamond.rap);
  if (qChange === 'ctPr') pricePerCarat = parseDecimal(diamond.newPricePerCarat);
  if (qt && typeof qt === 'string' && qt.includes('charge') && diamond.fmCharge) {
    extraPer = 0;
    const selectedFM = qt.slice(6, qt.length);
    const newFMamt = pricePerCarat * diamond.crt + diamond.fmCharge;
    const FMcharge = newFMamt * find(FMTERMS, { id: selectedFM }).charge;
    pricePerCarat = parseDecimal(FMcharge / diamond.crt);
  }
  if (bidPer) pricePerCarat = parseDecimal(pricePerCarat - (pricePerCarat * bidPer) / 100);
  diamond.calcPricePerCarat = parseDecimal(pricePerCarat - (pricePerCarat * extraPer) / 100);
  //diamond.calcDiscount = (diamond.quote)
  diamond.calcDiscount =
    diamond.calcPricePerCarat && diamond.rap
      ? parseDecimal((1 - diamond.calcPricePerCarat / diamond.rap) * 100 * -1)
      : 0;

  diamond.calcAmount = parseDecimal(diamond.calcPricePerCarat * diamond.crt);
  return diamond;
}

const FinalCalculations = (props) => {
  const [columns, setColumns] = useState([]);
  const checked = useSelector((state) => state.diamond.selectedRows[currentType]) || [];
  const [cm, setCm] = useState(false);
  const [fm, setFm] = useState('COD');
  const [settings, setSettings] = useState([]);
  const [email, sendEmail] = useState('');

  const pathname = usePathname();

  const getFMCharge = ({ shpNm, crt, clrNm, colNm }) => {
    let charge = 0;
    if (settings.length) {
      const grp = groupBy(settings, 'shp');
      const shaped = shpNm === 'ROUND' ? grp['ROUND'] : grp['FANCY'];
      const carated = shaped.filter((el) => (el.from ? el.from <= crt : true) && (el.to ? el.to >= crt : true));
      if (carated.length) {
        let found = carated.filter((el) => !el.clr && !el.col);
        if (!found.length)
          found = carated.filter(
            (el) =>
              (el.col && el.col.split(',').includes(colNm) ? true : false) &&
              (el.clr && el.clr.split(',').includes(clrNm) ? true : false),
          );
        if (found.length) charge = found[0].charges;
      }
    }
    return charge;
  };

  let newdata = props.checked;
  newdata = newdata.map((n) => ({
    ...n,
    fmCharge: n.isFm === 'ELIG' ? getFMCharge(n) : 0,
    isBid: pathname === LISTINGPAGES.BID || pathname === LISTINGPAGES.MYBID,
  }));
  newdata = newdata.map((x) => newDiamondPrice(x, 'charge' + fm));
  const isCmVisible = newdata.filter((el) => el.isCm === 'ELIG').length;

  useEffect(() => {
    getProjectSetting(
      props.checked[0].isFm === 'ELIG' ? PROJECT_SETTINGS_TYPE.FM_CHARGES : PROJECT_SETTINGS_TYPE.CM_CHARGES,
      setSettings,
    );
  }, []);

  useEffect(() => {
    const Columns = getColumn();
    let index = findIndex(Columns, { id: 'rap' });
    if (!index) index = 1;
    const columns = [
      ...Columns.slice(0, index + 1).filter((el) => !['back', 'amt', 'ctPr'].includes(el.id)),
      find(Columns, { id: 'back' }),
      find(Columns, { id: 'ctPr' }),
      find(Columns, { id: 'amt' }),
      props.checked[0].isFm === 'ELIG' && !(pathname === LISTINGPAGES.BID || pathname === LISTINGPAGES.MYBID)
        ? {
            Header: 'FM Charges',
            accessor: 'fmCharge',
            id: 'fmCharge',
            Cell: ({ cell }) => <>{parseFloat(cell.value).toFixed(2)}</>,
          }
        : null,
      {
        Header:
          'Final Disc(' +
          parseFloat(getExtraPer()).toFixed(2) +
          ((pathname === LISTINGPAGES.BID || pathname === LISTINGPAGES.MYBID) && getBidType() === DIAMOND_BID.TYPE.BLIND
            ? '+0.5'
            : '') +
          '%)',
        accessor: 'fnBack',
        id: 'fnBack',
        Cell: ({ row }) => <>{parseFloat(row.original.calcDiscount).toFixed(2)}</>,
      },
      {
        Header: 'Final Rate',
        accessor: 'fnCtpr',
        id: 'fnCtpr',
        Cell: ({ row }) => <>{parseFloat(row.original.calcPricePerCarat).toFixed(2)}</>,
      },
      {
        Header: 'Final Value',
        accessor: 'fnAmt',
        id: 'fnAmt',
        Cell: ({ row }) => <>{parseFloat(row.original.calcAmount).toFixed(2)}</>,
      },
      ...Columns.slice(index + 1, Columns.length).filter((el) => !['back', 'amt', 'ctPr'].includes(el.id)),
    ];
    setColumns(columns.filter((el) => el && el));
  }, []);

  const getCmCharge = (arr) => {
    let charge = 0;
    arr.map((line) => {
      if (line.isCm && line.isCm === 'ELIG') {
        const found = settings.filter((el) => el.from <= line.crt && (el.to ? el.to >= line.crt : true));
        if (found.length) charge += found[0].fee;
      }
      return true;
    });
    return charge;
  };

  const newPricedRows = checked; //.map(x => newDiamondPrice(x, 'charge' + fm))
  const summation = calculate(newPricedRows);
  const totalCarat = parseFloat(summation.total_carat).toFixed(2);
  const netValue = '$' + formatNumber(parseFloat(summation.final_value).toFixed(2));
  const avgDisc = parseFloat(summation.final_discount).toFixed(2);
  const finalAvgDisc = parseFloat(summation.final_term_discount).toFixed(2);
  const finalNetRate = '$' + formatNumber(parseFloat(summation.final_rate).toFixed(2));
  const finalNetValue = '$' + formatNumber(parseFloat(summation.final_net_value).toFixed(2));
  const CmCharge = parseFloat(getCmCharge(newPricedRows)).toFixed(2);
  const VALUES = isMobile()
    ? {
        'CT.:': totalCarat,
        'Final Disc%:': finalAvgDisc,
        'Amount:': netValue,
        'Final Rate:': finalNetRate,
        'Disc%:': avgDisc,
        'Final Value:': finalNetValue,
      }
    : [
        { title: 'Total Carats', value: totalCarat },
        { title: 'Final Net Rate', value: finalNetRate },
        { title: 'Avg. Discount', value: avgDisc },
        { title: 'Final Avg Discount', value: finalAvgDisc },
        { title: 'Net Value', value: netValue },
        {
          title: 'Final Net Value',
          value: finalNetValue + (cm && CmCharge > 0 ? ' + $' + CmCharge + ' (CM Charges)' : ''),
        },
      ];
  const user = JSON.parse(window.atob(localStorage.getItem(`${LOCAL_STORAGE_VAR}-user`)));
  const checkCheck = () => {
    if (!checked.length) {
      OpenNotification({
        type: 'error',
        title: 'Please select stone(s) to confirm.',
      });
      return false;
    } else return true;
  };
  const submit = () => {
    if (!checkCheck()) return;
    handleConfirmStone(
      checked.map((x) => x.id),
      '',
      moment().toISOString(),
      user && user.account ? user.account?.companyName : '',
      undefined,
      (flag) => {
        props.onClose();
        props.clearAll();
        if (flag && props.fetch) props.fetch();
      },
    );
  };

  return isMobile() ? (
    <FinalCalcMobile
      data={newdata}
      VALUES={VALUES}
      currentType={currentType}
      parent={{ submit, checkCheck, onClose: props.onClose }}
    />
  ) : (
    <div>
      <h2 className="popupInnerTitle">
        <IntlMessages id="app.FinalCalculation" />
      </h2>
      <div className="searchPopupCommonTable">
        <div className="searchResultTable tabInnerTableScroll">
          <Table
            data={newdata}
            columns={columns}
            areAllChecked={true}
            nodots
            noGrp
            currentType={currentType}
            canSort={false}
          />
        </div>
        <div className="mt-10 finalCalDetail">
          {props.checked[0].isFm === 'ELIG' && !(pathname === LISTINGPAGES.BID || pathname === LISTINGPAGES.MYBID) ? (
            <SelectOption
              className="finalFMSelect"
              selectValue={FMTERMS}
              label="FM Terms*"
              value={fm}
              onChange={(e) => setFm(e)}
            />
          ) : isCmVisible ? (
            <CheckBox label="CM Charge" checked={cm} onChange={() => setCm(!cm)} />
          ) : null}
          <div className="DiamondDetailPopup mt-10">
            {VALUES.map((x) => (
              <div key={x.title} className="DiamondDetailPopupItem">
                <span>{x.title}:</span>
                <span>{x.value}</span>
              </div>
            ))}
          </div>
        </div>
        <div className="sideBarPopupButton">
          <a className="commonButton" onClick={submit}>
            <IntlMessages id="app.confirmStone" />
          </a>
          <a className="commonButton" onClick={props.onClose}>
            <IntlMessages id="app.CancelStone" />
          </a>
          <a
            className="commonButton"
            onClick={() =>
              handleDownloadExcel(
                {},
                checked.map((x) => x.id),
                () => {},
              )
            }
          >
            <IntlMessages id="app.ExcelExport" />
          </a>
          <a
            className="commonButton"
            onClick={() => {
              if (checked.length) sendEmail('stock');
              else
                OpenNotification({
                  type: 'error',
                  title: 'Please select any stone(s).',
                });
            }}
          >
            <IntlMessages id="app.EmailStock" />
          </a>
          <a
            className="commonButton"
            onClick={() => {
              if (checked.length) sendEmail('img');
              else
                OpenNotification({
                  type: 'error',
                  title: 'Please select any stone(s).',
                });
            }}
          >
            <IntlMessages id="app.EmailPic" />
          </a>
        </div>
      </div>
      {email !== '' && (
        <SendEmailPopup
          sendEmail={email}
          onCancel={() => sendEmail('')}
          ids={checked.map((x) => x.id)}
          img={email === 'img'}
          isStock={email === 'stock'}
        />
      )}
    </div>
  );
};

export default FinalCalculations;
