import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import invert from 'lodash/invert';
import size from 'lodash/size';
import { DateTime } from 'luxon';
import moment from 'moment';

import UtilService from 'services/util';

import IntlMessages from 'util/IntlMessages';
import { formatDecimal, isArray, isEmpty } from 'util/utils';

import { LOCAL_STORAGE_VAR } from 'constants/Common';
import { GET_USER_ADDRESS } from 'constants/apiConstant';

import MobileConfirmStone from './MobileComfirmStone';
import { formatNumber, calculate } from './SelectStone';
import SendEmailPopup from './SendEmail';

import { handleConfirmStone, handleDownloadExcel, isMobile } from '../../DiamondList/DiamondListFunctions';
import Table from '../../DiamondList/TableBack';
import OpenNotification from '../CommonButton/OpenNotification';
import Heading from '../Heading';
import InputBlock from '../InputBlock';
import SelectOption from '../SelectOption';
import TextArea from '../TextArea';

export const savedSearch = {
  saleman: [{ value: 'John Doe' }, { value: 'John Doe' }],
  invoicDate: [
    { id: '0', name: 'Today' },
    { id: '1', name: 'Tommorrow' },
    { id: '2', name: 'Later' },
  ],
  country: [{ value: 'India' }, { value: 'Hong Kong' }, { value: 'US' }],
  city: [{ value: 'Gujarat' }, { value: 'baroda' }, { value: 'Vapi' }],
  billType: [{ value: 'Dollar Bill' }],
  couriername: [{ value: 'Any' }, { value: 'Malca Amit4' }],
  sale: [{ value: 'Idex Ok' }, { value: 'Blue Nile Ok' }],
  terms: [{ value: '7 Days' }, { value: 'Advance' }, { value: 'COD' }],
  assi: [{ value: 'Vishal Virani' }, { value: 'Vishal Virani' }, { value: 'Vishal Virani' }],
};

export function HeadingCalc(checked, VALUES = null) {
  if (isMobile() && !checked.length && !VALUES) return null;
  let obj = {};

  if (VALUES) obj = VALUES;
  else {
    const sum = calculate(checked);

    obj = {
      'Pieces :': parseFloat(sum.total_pieces),
      'CT. :': parseFloat(sum.total_carat).toFixed(2),
      'Rap $Amount :': formatNumber(parseFloat(sum.final_rap_avg).toFixed(2)),
      'Rap$/Ct :': formatNumber(parseFloat(sum.final_rapaport).toFixed(2)),
      'Disc% :': parseFloat(sum.final_discount).toFixed(2),
      '$/Ct :': formatNumber(parseFloat(sum.final_price).toFixed(2)),
      '$Total :': formatNumber(parseFloat(sum.final_value).toFixed(2)),
    };
  }
  if (isMobile()) obj = invert(obj);
  if (size(obj) < 4) return null;
  return (
    <div className="DiamondDetailPopup">
      {Object.keys(obj).map((x) => {
        return (
          <div key={x} className="DiamondDetailPopupItem" style={VALUES ? checked : {}}>
            <span>{x}</span>
            <span>{isMobile() ? obj[x].slice(0, obj[x].length - 1) : obj[x]}</span>
          </div>
        );
      })}
    </div>
  );
}

const currentType = 'ConfirmStonePopup';
const restrictedStatus = ['H', 'M', 'U'];

const ConfirmStonePopup = (props) => {
  const user = JSON.parse(window.atob(localStorage.getItem(`${LOCAL_STORAGE_VAR}-user`)));
  const [comment, setComment] = useState('');
  const [company] = useState(user && user.account ? user.account?.companyName : '');
  const [date, setDate] = useState();
  const [address, setAddress] = useState([]);
  const [shippingAdd, setShippingAdd] = useState('');
  const [billingAdd, setBillingAdd] = useState('');
  const [email, sendEmail] = useState('');
  const [dayTermsId, setDayTermId] = useState(user?.accountTerm?.dayTermsId);
  const checked = useSelector((state) => state.diamond.selectedRows[currentType]) || [];

  const removeToday = React.useMemo(() => {
    const todayEnd = DateTime.local().startOf('day').plus({ hour: 16, minute: 59, second: 59 });
    const currentTime = DateTime.local();
    return currentTime > todayEnd;
  }, []);

  const InvoicDateList = React.useMemo(() => {
    const output = [...savedSearch.invoicDate];
    // if (removeToday) output[0].disabled = true; // disable
    if (removeToday) output.splice(0, 1); // remove
    return output;
  }, [removeToday]);

  const getcompany = () => {
    return (
      <IntlMessages id="app.CompanyName*">
        {(placeholder) => (
          <InputBlock
            label={<IntlMessages id="app.CompanyName*" />}
            placeholder={placeholder}
            value={company}
            disabled
          />
        )}
      </IntlMessages>
    );
  };

  const getinvoicedate = () => {
    return (
      <SelectOption
        selectValue={InvoicDateList}
        placeholder="Invoice Date"
        label="Select Invoice Date"
        value={date}
        onChange={setDate}
      />
    );
  };

  const checkCheck = () => {
    if (!checked.length) {
      OpenNotification({ type: 'error', title: 'Please select stone(s) to confirm.' });
      return false;
    } else return true;
  };

  const submit = () => {
    if (!company) {
      OpenNotification({ type: 'error', title: 'Please enter company name.' });
      return;
    }
    if (!checkCheck()) return;

    handleConfirmStone(
      checked.map((x) => x.id),
      comment,
      moment().add(parseInt(date), 'days').toISOString(),
      company,
      dayTermsId,
      (flag) => {
        props.onClose();
        if (props.clearAll) props.clearAll();
        if (flag && props.fetch) props.fetch();
      },
      shippingAdd,
      billingAdd,
      ['/account/offer-list'].includes(props.currentType),
    );
  };

  const cancel = () => {
    props.onClose();
    if (props.clearAll) props.clearAll();
  };

  useEffect(() => {
    (() => {
      const objData = {
        ...GET_USER_ADDRESS,
        request: {},
      };

      UtilService.callApi(objData, (err, data) => {
        if (err) throw err;
        else if (data && data.code === 'OK') {
          setAddress(data.data.list);
        }
      });
    })();
  }, []);

  const data = React.useMemo(() => {
    if (!isArray(props?.checked)) return;
    const data = props.checked.filter((x) => !restrictedStatus.includes(x.wSts));
    return data.map((stone) => ({ ...stone }));
  }, [props.checked]);

  const overrideColumns = React.useMemo(() => {
    if (!['/account/offer-list'].includes(props.currentType)) return {};

    return {
      back: function Cell({ row }) {
        return !isEmpty(row?.original?.bargainTrack) && row?.original?.hasCounterOffer
          ? `${formatDecimal(row?.original?.currentValidTrack?.trackDiscount ?? 0)}% (${formatDecimal(
              row?.original?.original.back ?? 0,
            )}%)`
          : `${formatDecimal(row?.original?.original?.back ?? row?.original?.back ?? 0)}%`;
      },
      ctPr: function Cell({ row }) {
        return !isEmpty(row?.original?.bargainTrack) && row?.original?.hasCounterOffer
          ? formatDecimal(row?.original?.currentValidTrack?.trackPricePerCarat ?? 0)
          : formatDecimal(row?.original?.original?.ctPr ?? row?.original?.ctPr ?? 0);
      },
      amt: function Cell({ row }) {
        return !isEmpty(row?.original?.bargainTrack) && row?.original?.hasCounterOffer
          ? formatDecimal(row?.original?.currentValidTrack?.trackAmount ?? 0)
          : formatDecimal(row?.original?.original?.amt ?? row?.original?.amt ?? 0);
      },
    };
  }, [props.currentType]);

  const restrictStoneList = React.useMemo(() => {
    return props?.checked?.filter?.((x) => restrictedStatus.includes(x.wSts))?.map?.((x) => x.vStnId) ?? [];
  }, [props?.checked]);

  const gettextarea = () => {
    return isMobile() ? (
      <IntlMessages id="app.comment">
        {(placeholder) => (
          <TextArea
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            label={<IntlMessages id="app.comment" />}
            placeholder={placeholder}
          />
        )}
      </IntlMessages>
    ) : (
      <textarea value={comment} onChange={(e) => setComment(e.target.value)} />
    );
  };

  if (isMobile())
    return (
      <MobileConfirmStone
        data={data}
        currentType={currentType}
        onClickExcel={() =>
          handleDownloadExcel(
            {},
            checked.map((x) => x.id),
            () => {},
          )
        }
        parent={{ getcompany, getinvoicedate, submit, cancel, gettextarea, checkCheck }}
      />
    );

  return (
    <div className="confirmStonePopUp">
      <div className="d-flex align-items-center offerTopBlock offerWrapper mb-20">
        <Heading className="popupInnerTitle mr-40" title={<IntlMessages id="app.confirmStone" />}></Heading>
        {restrictStoneList.length ? (
          <p style={{ color: 'red' }}>( The selected diamond(s) {restrictStoneList.join(', ')} is in Busy. )</p>
        ) : (
          ''
        )}
        {HeadingCalc(checked)}
      </div>
      <div className={`searchPopupCommonTable ${props.popupClass ?? ''}`} style={props.style}>
        <div className="searchResultTable tabInnerTableScroll" style={props.detail ? { height: 'auto' } : {}}>
          <Table
            data={data}
            overrideColumns={overrideColumns}
            currentType={currentType}
            canSort={false}
            areAllChecked
            nodots
            noGrp
          />
        </div>
        <div className="mt-10 finalCalDetail confirmListOption" style={props.detail ? { padding: '5px' } : {}}>
          <div className="DiamondDetailPopup width-100">
            <div className="commonTextArea from-group">
              <label>
                <IntlMessages id="app.comment" />
              </label>
              {gettextarea()}
            </div>
            {!props.detail && (
              <p className="offerNote">
                <b>
                  <IntlMessages id="app.Note" />:
                </b>
                <br />
                <IntlMessages id="app.ConfirmStoneNotenonnegotiable" />
                <br />
                <IntlMessages id="app.ConfirmStoneNoteAvailability" />
              </p>
            )}
          </div>
        </div>
        <div className="sideBarPopupButton">
          <a className="commonButton" onClick={submit}>
            <IntlMessages id="app.confirmStone" />
          </a>
          <a className="commonButton" onClick={cancel}>
            <IntlMessages id="app.Cancel" />
          </a>
          {!props.detail && (
            <>
              <a
                className="commonButton"
                onClick={() =>
                  handleDownloadExcel(
                    {},
                    checked.map((x) => x.id),
                    () => {},
                  )
                }
              >
                <IntlMessages id="app.ExcelExport" />
              </a>
              <a
                className="commonButton"
                onClick={() => {
                  checked.length
                    ? sendEmail('stock')
                    : OpenNotification({ type: 'error', title: 'Please select any stone(s).' });
                }}
              >
                <IntlMessages id="app.EmailStock" />
              </a>
              {/* <a
                className="commonButton"
                onClick={() => {
                  checked.length
                    ? sendEmail('img')
                    : OpenNotification({ type: 'error', title: 'Please select any stone(s).' });
                }}
              >
                <IntlMessages id="app.EmailPic" />
              </a> */}
            </>
          )}
        </div>
      </div>
      <SendEmailPopup
        sendEmail={email}
        onCancel={() => sendEmail('')}
        ids={checked.map((x) => x.id)}
        img={email === 'img'}
        isStock={email === 'stock'}
      />
    </div>
  );
};

export default ConfirmStonePopup;
