import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DateTime } from 'luxon';
import InputBlock from '../InputBlock';
import Heading from '../Heading';
import Table from '../../DiamondList/TableBack';
import SendEmailPopup from './SendEmail';
import MobileOfferPopup from './MobileOfferPopup';
import IntlMessages from 'util/IntlMessages';
import { LOCAL_STORAGE_VAR } from 'constants/Common';
import { every, find, invert } from 'lodash';
import {
  isEmpty,
  isNumber,
  isArray,
  parseDecimal,
  isNotEmpty,
  sortArrayByKey,
  isNumeric,
  formatDecimal,
  logInfo,
} from 'util/utils';
import OpenNotification from '../CommonButton/OpenNotification';
import { newDiamondPrice } from './FinalCalculations';
import { calculate, formatNumber } from './SelectStone';
import { getColumn, handleSaveQuote, handleDownloadExcel, isMobile } from 'components/DiamondList/DiamondListFunctions';

export const QUOTE = [0.5, 1];
export const HOURS = [24, 48, 72];

export const prepareStoneForBargain = (_stone, options) => {
  const { noModify, hours, isUpdate } = { noModify: true, noUpdate: true, ...options };

  const stone = { ...(_stone?.original ?? _stone) }; // get original value
  stone.original = { ...stone }; // preserve original values for recalculation

  stone.hours = isUpdate && isNumber(hours) && HOURS.includes(hours + 1) ? hours + 1 : HOURS[0];

  // cast to number or undefined
  stone.amt = isNumeric(stone.amt) ? parseDecimal(stone.amt) : undefined;
  stone.back = isNumeric(stone.back) ? parseDecimal(stone.back) : undefined;
  stone.ctPr = isNumeric(stone.ctPr) ? parseDecimal(stone.ctPr) : undefined;
  stone.rap = isNumeric(stone.rap) ? parseDecimal(stone.rap) : undefined;
  stone.crt = isNumeric(stone.crt) ? parseDecimal(stone.crt) : undefined;

  stone.newAmount = isNumeric(stone.newAmount) ? parseDecimal(stone.newAmount) : undefined;
  stone.newDiscount = isNumeric(stone.newDiscount) ? parseDecimal(stone.newDiscount) : undefined;
  stone.newPricePerCarat = isNumeric(stone.newPricePerCarat) ? parseDecimal(stone.newPricePerCarat) : undefined;

  stone.amt = stone.newAmount ?? stone.amt;
  stone.back = stone.newDiscount ?? stone.back;
  stone.ctPr = stone.newPricePerCarat ?? stone.ctPr;

  if (!noModify && !isUpdate) {
    stone.back = isNumber(stone.back) ? parseDecimal(stone.back - 1) : stone.back;
    stone.ctPr = parseDecimal((stone.back * stone.rap) / 100 + stone.rap);
    stone.amt = parseDecimal(stone.ctPr * stone.crt);
  }

  stone.calcAmount = stone.amt;
  stone.calcDiscount = stone.back;
  stone.calcPricePerCarat = stone.ctPr;

  stone.newAmount = stone.calcAmount;
  stone.newDiscount = stone.calcDiscount;
  stone.newPricePerCarat = stone.calcPricePerCarat;

  stone.finalquote = stone.back;

  stone.bargainTrack =
    isArray(stone.bargainTrack) && !isEmpty(stone.bargainTrack)
      ? stone.bargainTrack
          .map((track) => ({ ...track, timestamp: DateTime.fromISO(track.updatedAt).valueOf() }))
          .sort(sortArrayByKey('timestamp', true))
      : [];

  stone.defaultTrack = {
    userType: stone.coUserType,
    trackAmount: stone.amt,
    trackDiscount: stone.back,
    trackPricePerCarat: stone.ctPr,
  };

  stone.currentValidTrack = (() => {
    if ([1, 2, 8].includes(stone.coUserType)) return stone.defaultTrack;

    const latestAdminTrack = stone.bargainTrack.find((track) => [1, 2, 8].includes(track?.userType));
    if (!isEmpty(latestAdminTrack)) return latestAdminTrack;

    return undefined;
  })();

  stone.hasCounterOffer = [1, 2, 8].includes(stone.currentValidTrack?.userType);

  if (stone.offerStatus === 1 && !stone.confirmedStone) logInfo(stone, stone.currentValidTrack, stone.defaultTrack);

  return stone;
};

const currentType = 'QuotePopup';

class QuotePopup extends Component {
  constructor(props) {
    super(props);
    const user = JSON.parse(window.atob(localStorage.getItem(`${LOCAL_STORAGE_VAR}-user`)));
    this.state = {
      columns: [],
      data: [],
      comment: props?.editOffer?.[0]?.remarks ?? '',
      checked: [],
      company: user?.account?.companyName ?? '',
      email: '',
      restrictedStatus: ['H', 'M', 'U'],
    };
  }

  get isUpdate() {
    return isArray(this.props.editOffer) && !isEmpty(this.props.editOffer);
  }

  get list() {
    return isNotEmpty(this.props.checked)
      ? this.props.checked
      : isNotEmpty(this.props.editOffer)
      ? this.props.editOffer
      : [];
  }

  onQuoteChange = (value, row) => {
    const data = [...this.state.data];
    const index = data.findIndex((stone) => stone.stoneId === row.stoneId);
    data[index] = { ...data[index] };

    data[index].finalquote = value;
    data[index] = newDiamondPrice(data[index], 'quote', 'quote');
    data[index].newPricePerCarat = parseDecimal(data[index]['calcPricePerCarat']);

    this.setState({ data }, () => {
      const checked = this.state.data.filter((el) => find(this.state.checked, { id: el.id }));
      this.setState({ checked });
    });
  };

  handleQuoteBlur = (value, row) => {
    const data = [...this.state.data];
    const index = data.findIndex((stone) => stone.stoneId === row.stoneId);
    data[index] = { ...data[index] };

    data[index].finalquote = value;
    this.setState({ data });
  };

  handlePricePerCarat = (value, row) => {
    const data = [...this.state.data];
    const index = data.findIndex((stone) => stone.stoneId === row.stoneId);
    data[index] = { ...data[index] };

    data[index].finalquote = parseDecimal((data[index].quote || 0) * -1 + (data[index].back || 0));
    data[index].newPricePerCarat = value;
    data[index] = newDiamondPrice(data[index], 'quote', 'ctPr');
    data[index].finalquote = parseDecimal(data[index]['calcDiscount']);

    this.setState({ data }, () => {
      let checked = this.state.data.filter((el) => find(this.state.checked, { id: el.id }));
      this.setState({ checked });
    });
  };

  handleFloatInputBlur = (value, row) => {
    const data = [...this.state.data];
    const index = data.findIndex((stone) => stone.stoneId === row.stoneId);
    data[index] = { ...data[index] };

    data[index].newPricePerCarat = value;
    this.setState({ data: data });
  };

  componentDidMount() {
    const { editOffer } = this.props;
    if (!isArray(this.list)) return;

    const hours = (() => {
      const offer = editOffer?.[0];
      if (isEmpty(offer)) return undefined;
      const endDate = DateTime.fromISO(offer?.offerValidDate);
      const currDate = DateTime.local();
      return endDate.isValid ? Math.ceil(endDate.diff(currDate).as('hours')) : undefined;
    })();

    const data = this.list
      .map((stone) => prepareStoneForBargain(stone, { hours, isUpdate: this.isUpdate }))
      .map((stone) => newDiamondPrice(stone, 'quote'));

    const columns_ = getColumn();
    let columns = [
      { ...columns_.find((column) => column?.id === 'rap') },
      { ...columns_.find((column) => column?.id === 'back'), accessor: 'oldBack' },
      { ...columns_.find((column) => column?.id === 'ctPr'), accessor: 'oldCtPr' },
      { ...columns_.find((column) => column?.id === 'amt'), accessor: 'oldAmt' },
      {
        Header: 'Hours',
        accessor: 'validityHours',
        id: 'hours',
        Cell: ({ row, cell }) => (
          <span className="tableInput" disabled={cell.value}>
            <select
              value={cell.value}
              onChange={(e) => this.onHourChange(e.target.value, row.original)}
              disabled={cell.value ? true : false}
            >
              {HOURS.map((x) => (
                <option value={x}>{x}</option>
              ))}
            </select>
          </span>
        ),
      },
      {
        Header: 'Final Offer(%)',
        accessor: 'finalquote',
        id: 'finalquote',
        Cell: ({ row, cell }) => {
          const defaultValue = isNumeric(cell.value) ? parseDecimal(cell.value) : 0;

          return (
            <div className="tableInput">
              <input
                // defaultValue={defaultValue}
                value={cell.value}
                onChange={(e) => this.onQuoteChange(e.target.value, row.original)}
                onBlur={() => this.handleQuoteBlur(defaultValue, row.original)}
                disabled={row.original.isFcCol}
              />
            </div>
          );
        },
      },
      {
        Header: 'Final Price/Ct.',
        accessor: 'newPricePerCarat',
        id: '_newPricePerCarat',
        Cell: ({ row, cell }) => {
          const defaultValue = isNumeric(cell.value) ? parseDecimal(cell.value) : 0;

          return (
            <div className="tableInput">
              <input
                // defaultValue={defaultValue}
                value={cell.value}
                onChange={(e) => this.handlePricePerCarat(e.target.value, row.original)}
                onBlur={() => this.handleFloatInputBlur(defaultValue, row.original)}
                disabled={!row.original.isFcCol && every(this.state.data, { isFcCol: true })}
              />
            </div>
          );
        },
      },
      {
        Header: 'Final Disc',
        id: '_calcDiscount',
        accessor: 'calcDiscount',
        Cell({ row }) {
          const value = Number(row.original.calcDiscount);
          return <>{isNumber(value) ? formatDecimal(value) : 0}</>;
        },
      },
      {
        Header: 'Final Rate',
        id: '_calcPricePerCarat',
        accessor: 'calcPricePerCarat',
        Cell({ row }) {
          const value = Number(row.original.calcPricePerCarat);
          return <>{isNumber(value) ? formatDecimal(value) : 0}</>;
        },
      },
      {
        Header: 'Final Value',
        id: '_calcAmount',
        accessor: 'calcAmount',
        Cell({ row }) {
          const value = Number(row.original.calcAmount);
          return <>{isNumber(value) ? formatDecimal(value) : 0}</>;
        },
      },
    ].filter((el) => el && el);
    columns = [...columns, ...columns_.filter((el) => columns.findIndex((col) => el.id === col.id) < 0)];
    this.setState({ columns, data, checked: data });
  }

  onHourChange = (value, row) => {
    const data = [...this.state.data];
    const index = data.findIndex((stone) => stone.stoneId === row.stoneId);
    data[index] = { ...data[index] };
    data[index].hours = Number(value);
    this.setState({ data: data }, () => {
      let checked = this.state.data.filter((el) => find(this.state.checked, { id: el.id }));
      this.setState({ checked });
    });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.checkedData.length !== this.state.checked.length) {
      this.setState({ checked: nextProps.checkedData });
    }
  }

  offerHeading = () => {
    if (isMobile() && !this.state.checked.length) return null;
    let sum = calculate(this.state.checked);

    let obj = {
      'CT. :': parseFloat(sum.total_carat).toFixed(2),
      'Disc% :': parseFloat(sum.final_discount).toFixed(2),
      'Price/Ct :': formatNumber(parseFloat(sum.final_price).toFixed(2)),
      'Discount Difference :': parseFloat(sum.final_term_discount - sum.final_discount).toFixed(2),
      'Offer% :': parseFloat(sum.final_term_discount).toFixed(2),
      'Offer Value :': formatNumber(parseFloat(sum.final_net_value).toFixed(2)),
    };
    if (!isEmpty(find(this.state.checked, { isFcCol: true })))
      obj = {
        ...obj,
        'Offer Price/Ct. :': formatNumber(parseFloat(sum.fancy_price_per_carat).toFixed(2)),
      };
    if (isMobile()) obj = invert(obj);
    return (
      <div className="DiamondDetailPopup">
        {Object.keys(obj).map((key) => {
          return (
            <div key={key} className="DiamondDetailPopupItem">
              <span>{key}</span>
              <span>{isMobile() ? obj[key].slice(0, obj[key].length - 1) : obj[key]}</span>
            </div>
          );
        })}
      </div>
    );
  };

  getOfferInput = () => {
    return (
      <div className="offerInputBox">
        <IntlMessages id="app.CompanyName*">
          {(placeholder) => (
            <InputBlock label={placeholder} placeholder={placeholder} value={this.state.company} disabled />
          )}
        </IntlMessages>
        <IntlMessages id="app.comment">
          {(placeholder) => (
            <InputBlock
              value={this.state.comment}
              onChange={(e) => this.setState({ comment: e.target.value })}
              label={placeholder}
              placeholder={placeholder}
            />
          )}
        </IntlMessages>
      </div>
    );
  };

  checkSelectedRows = () => {
    if (!this.state.checked.length) {
      OpenNotification({ type: 'error', title: 'Please select stone(s) to apply offer.' });
      return false;
    }
    return true;
  };

  submit = () => {
    if (!this.checkSelectedRows()) return;
    if (!this.state.company.trim()) {
      OpenNotification({ type: 'error', title: 'Please enter company name.' });
      return;
    }

    handleSaveQuote(
      this.state.checked,
      this.state.comment,
      this.state.company,
      () => {
        this.props.onClose();
        this.props.clearAll();
      },
      this.isUpdate,
    );
  };

  render() {
    const list = this.state.data.filter((x) => !this.state.restrictedStatus.includes(x.sSts));
    const restrict = this.state.data
      ?.filter(({ wSts }) => this.state.restrictedStatus.includes(wSts))
      ?.map(({ vStnId }) => vStnId);
    return isMobile() ? (
      <MobileOfferPopup {...this.state} parent={this} />
    ) : (
      <div className="offerWrapper">
        <div className="d-flex align-items-center  offerTopBlock">
          <div className="d-flex align-items-center mr-40">
            <Heading className="popupInnerTitle" title={<IntlMessages id="app.ApplyQuote" />} />
          </div>
          {this.offerHeading()}
        </div>
        {restrict.length ? (
          <div>
            <p style={{ color: 'red', marginBottom: '20px' }}>
              ( The selected diamond(s) {restrict.join(', ')} is in Hold/Memo. )
            </p>
          </div>
        ) : (
          ''
        )}
        <div className="searchPopupCommonTable">
          <div className="searchResultTable tabInnerTableScroll">
            <Table
              data={list}
              columns={this.state.columns}
              areAllChecked={true}
              checked={this.state.checked}
              currentType={currentType}
              FilterOption={false}
              canSort={false}
              nodots
              noGrp
            />
          </div>
          <div className="d-flex justify-content-between offerBottomBox flex-wrap">
            {this.getOfferInput()}
            <p className="offerNote mt-20">
              <b>
                <IntlMessages id="app.Note" />:
              </b>{' '}
              <IntlMessages id="app.quoteNote" />
            </p>
          </div>
        </div>
        <div className="sideBarPopupButton">
          <a className="commonButton" onClick={this.submit}>
            <IntlMessages id="app.SaveQuote" />
          </a>
          <a className="commonButton" onClick={this.props.onClose}>
            <IntlMessages id="app.CancelQuote" />
          </a>
          <a
            className="commonButton"
            onClick={() =>
              handleDownloadExcel(
                {},
                this.state.checked.map((x) => x.id),
                () => {},
              )
            }
          >
            <IntlMessages id="app.ExcelExport" />
          </a>
          <a
            className="commonButton"
            onClick={() => {
              if (this.state.checked.length) this.setState({ email: 'stock' });
              else
                OpenNotification({
                  type: 'error',
                  title: 'Please select any stone(s).',
                });
            }}
          >
            <IntlMessages id="app.EmailStock" />
          </a>
          <a
            className="commonButton"
            onClick={() => {
              if (this.state.checked.length) this.setState({ email: 'img' });
              else
                OpenNotification({
                  type: 'error',
                  title: 'Please select any stone(s).',
                });
            }}
          >
            <IntlMessages id="app.EmailPic" />
          </a>
          <SendEmailPopup
            sendEmail={this.state.email}
            onCancel={() => this.setState({ email: '' })}
            ids={this.state.checked.map((x) => x.id)}
            img={this.state.email === 'img'}
            isStock={this.state.email === 'stock'}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ diamond }) => {
  return { checkedData: diamond.selectedRows[currentType] || [] };
};

export default connect(mapStateToProps, null)(QuotePopup);
