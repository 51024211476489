import React, { useEffect, useState, useReducer } from 'react';
import IntlMessages from '../../../util/IntlMessages';
import { GET_NOTIFICATIONS, MARK_AS_READ } from '../../../constants/apiConstant';
import UtilService from '../../../services/util';
import moment from 'moment';
import { LOCAL_STORAGE_VAR } from '../../../constants/Common';
import { EventManager } from '../../../services/eventBus';

const NotificationItem = (props) => {
  return (
    <div onClick={props.onClick} className={`notificationDropdownDetail ${props.read && 'readMessage'}`}>
      <div className={'norificationDropdownItem'}>
        <h2>{props.title}</h2>
        {props.pcv && <div className="d-flex flex-wrap basicDetail"></div>}
        <span>{props.time}</span>
      </div>
    </div>
  );
};

const NotificationDropdown = (props) => {
  const [notifyData, setNotifyData] = useState([]);
  const [unReadCount, setUnReadCount] = useState(0);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);

  const getNotifications = React.useCallback((pages) => {
    const obj = {
      ...GET_NOTIFICATIONS,
      request: { page: pages ? pages : page, limit: limit },
    };
    UtilService.callApi(obj, function (err, res) {
      if (!err && res && res.code === 'OK' && res.data) {
        setNotifyData([...res.data.list]);
        setTotal(res.data.count);
        setUnReadCount(res.data.unReadCount);
      } else {
        setNotifyData([]);
        setUnReadCount(0);
      }
    });
  }, []);

  // const getNotifications = () => {
  //   let obj = {
  //     ...GET_NOTIFICATIONS,
  //   };
  //   UtilService.callApi(obj, function (err, res) {
  //     if (err) {
  //       setNotifyData([]);
  //       setUnReadCount(0);
  //     } else if (res && res.code === "OK") {
  //       if (res.data) {
  //         setNotifyData([...res.data.list]);
  //         setTotal(res.data.count);
  //         setUnReadCount(res.data.unReadCount);
  //       }
  //     }
  //   });
  // };

  const MarkAsRead = React.useCallback(
    (id) => {
      const obj = { ...MARK_AS_READ, request: { id: id } };
      UtilService.callApi(obj, function (err, res) {
        if (!err && res && res.code === 'OK' && res.data) {
          getNotifications();
        }
      });
    },
    [notifyData],
  );

  useEffect(() => {
    getNotifications();
  }, [getNotifications, props.active]);

  useEffect(() => {
    EventManager.emit('@notifications/unReadCount', { unReadCount });
  }, [unReadCount]);
  return (
    <div className="notificationDropdown">
      <div className="notificationItem">
        <div className="notificationTitle">
          <IntlMessages id="app.New" />
        </div>
        <div className="notificationInner">
          {notifyData && notifyData.length > 0
            ? notifyData.map((e, i) => {
                let time = moment(e.updatedAt).fromNow();
                return (
                  <NotificationItem
                    key={i}
                    onClick={() => {
                      MarkAsRead(e.id);
                    }}
                    title={e.title}
                    time={time}
                    read={e.isRead}
                  />
                );
              })
            : 'No Notification Found'}
        </div>
        <div className="m-notifications-sticky">
          <a href="/notifications" className="viewLink">
            View All Notifications
          </a>
        </div>
      </div>
    </div>
  );
};
export default NotificationDropdown;
