import React, { Component } from 'react';

import { Drawer } from 'antd';

import IntlMessage from 'util/IntlMessages';

import { HeadingCalc } from './ConfirmStonePopup';

import Heading from '../Heading';
import MobileViewListCard from '../MobileDiamondList/MobileViewListCard';
import TextArea from '../TextArea';

class NotePopupMobile extends Component {
  render() {
    return (
      <div className="offerWrapper">
        <div className="d-flex align-items-center justify-space-between offerTopBlock">
          <div className="d-flex align-items-center">
            <Heading className="popupInnerTitle" title={<IntlMessage id="app.PlaceComment" />}></Heading>
          </div>
          {HeadingCalc(this.props.checked)}
        </div>
        <div className="searchInnerResult tabInnerTable">
          <div className="diamondListMobile mobileOfferScroll">
            <div className="offerInputBox width-100">
              <IntlMessage id="app.notes-comment">
                {(placeholder) => (
                  <TextArea
                    className="width-100"
                    label={placeholder}
                    placeholder={placeholder}
                    value={this.props.data[0] ? this.props.data[0].note : ''}
                    onChange={(e) => this.props.parent.ipValChange(e.target.value)}
                  />
                )}
              </IntlMessage>
            </div>
          </div>
        </div>
        <div className="sideBarPopupButton">
          <a className="commonButton" onClick={this.props.parent.submit}>
            <IntlMessage id="app.PlaceComment" />
          </a>
          <a className="commonOutline" onClick={this.props.onClose}>
            <IntlMessage id="app.Cancel" />
          </a>
        </div>
      </div>
    );
  }
}

export default NotePopupMobile;
