import React from 'react';

import { find, findIndex } from 'lodash';

import Table from 'components/DiamondList/TableBack';
import Heading from 'components/common/Heading';

import { getBidConfig } from 'services/util';

import IntlMessages from 'util/IntlMessages';
import { compose, withPathname } from 'util/hocs';
import { formatFloat, isNumber, isNumeric, reduceTotal } from 'util/utils';

import { SELECT_STONE_TERMS_NEW } from 'constants/Common';

import BIDStoneMobile from './BIDStoneMobile';
import { HeadingCalc } from './ConfirmStonePopup';
import { newDiamondPrice } from './FinalCalculations';

import { getColumn, getPath, handleBidUpsert, isMobile, LISTINGPAGES } from '../../DiamondList/DiamondListFunctions';
import OpenNotification from '../CommonButton/OpenNotification';

const currentType = 'bidStones';

class BidPopup extends React.Component {
  constructor() {
    super();

    this.state = {
      columns: [],
      checked: [],
      data: [],
      summ:
        this.props.pathname !== LISTINGPAGES.COMPARE
          ? localStorage.getItem('Terms') === 'true'
            ? localStorage.getItem('Termvalue')
              ? localStorage.getItem('Termvalue')
              : ''
            : localStorage.getItem('Terms')
          : '',
      summation: {},
      nextBidData: {},
      showModal: false,
      minCtPr: 0,
    };
  }

  componentDidUpdate(nextProps, nextState) {
    if (nextState.data != this.state.data) {
      const newData = [];
      this.state.data.forEach((d) => {
        this.state.checked.forEach((c) => {
          if (d.id === c.id) {
            newData.push(d);
          }
        });
      });
      this.handleCalculation(newData);
    }
  }

  checkCheck = () => {
    if (!this.state.checked.length) {
      OpenNotification({
        type: 'error',
        title: 'Please select stone(s) to bid.',
      });
      return false;
    } else return true;
  };

  submit = () => {
    let showModal = false;
    this.state.checked.forEach((x) => {
      if (Number(x.bidDiscount) === Number(x.back)) {
        showModal = true;
      }
    });
    if (showModal && this.state.nextBidData.sub_types.subTypeCode !== 'BIWI') {
      OpenNotification({
        type: 'error',
        title: 'Bid discount and discount can not be same for bid.',
      });
    } else {
      if (!this.checkCheck()) return;
      const newData = [];
      this.state.data.forEach((d) => {
        this.state.checked.forEach((c) => {
          if (d.id === c.id) {
            newData.push(d);
          }
        });
      });
      handleBidUpsert(
        newData,
        this.state.nextBidData.id,
        this.state.nextBidData.sub_types.subTypeCode,
        this.state.nextBidData.sub_types.result,
        (flag) => {
          this.props.onClose();
          this.props.clearAll();
          if (flag && this.props.fetch) this.props.fetch(this.state?.nextBidData);
        },
      );
    }
  };

  checkInterval = (value, oldValue) => {
    value = Number(value);
    oldValue = Number(oldValue);

    const diffInterval = this.state.nextBidData.sub_types.diffInterval;
    const v1 = parseInt(Math.round(value * 100));
    const v2 = parseInt(Math.round(oldValue * 100));
    const diff = Math.abs(v1 - v2);
    const inInterval = diff % (diffInterval * 100) === 0 || diff === 0;
    if (!inInterval) {
      OpenNotification({
        type: 'error',
        title: `You can only change discount in ${Math.abs(diffInterval)}% interval`,
      });
    }
    return inInterval;
  };

  handleDiscountChange = (value, row) => {
    const data = [...this.state.data];
    const index = findIndex(data, { stoneId: row.stoneId });
    data[index] = { ...data[index] };
    value = Number(value); // converting to number
    const isPositive = data[index].ctPr > data[index].rap;
    const back = isPositive ? Math.abs(data[index].back) : -1 * Math.abs(data[index].back);
    const calColumn = this.state.nextBidData.sub_types.calColumn;
    data[index].back = back;
    const subTypeCode = this.state.nextBidData.sub_types.subTypeCode;
    if (this.props.pathname === LISTINGPAGES.BID) {
      const oldValue = data[index].bidDiscount || back;
      const isInIntervalDiff = this.checkInterval(value, oldValue, back);
      const maxPricePerCarat = formatFloat(data[index].rap + data[index].rap * (value / 100));
      const upInterval = this.state.nextBidData.sub_types.upwardInterval;
      const downInterval = this.state.nextBidData.sub_types.downwardInterval * -1;
      const max = calColumn === 'ctPr' ? data[index].ctPr + data[index].ctPr * (upInterval / 100) : back + upInterval;
      const min =
        calColumn === 'ctPr' ? data[index].ctPr + data[index].ctPr * (downInterval / 100) : back + downInterval;
      const minDiscount = data[index].ctPr + data[index].ctPr * (downInterval / 100);
      if (calColumn !== 'ctPr') {
        if (value >= min && value <= max && isInIntervalDiff && value >= -100 && value <= 100) {
          data[index].bidDiscount = value;
          const bigPricePerCarat = formatFloat(data[index].rap + data[index].rap * (value / 100));
          data[index].bidPricePerCarat = bigPricePerCarat;
          data[index].bidAmount = bigPricePerCarat * data[index].crt;
        } else {
          data[index].bidDiscount = oldValue;
          data[index].bidPricePerCarat = formatFloat(data[index].rap + data[index].rap * (oldValue / 100));
          data[index].bidAmount = (data[index].bidPricePerCarat || 0) * data[index].crt;
          if (isInIntervalDiff) {
            if (!(value >= back)) {
              OpenNotification({
                type: 'error',
                title:
                  subTypeCode !== 'BIWI'
                    ? 'Your bidding value must be higher than base value.'
                    : `Your bidding amount can not be lower then ${Number(minDiscount * data[index].crt).toFixed(
                        2,
                      )}. Please, try again.`,
              });
            } else {
              OpenNotification({
                type: 'error',
                title:
                  subTypeCode !== 'BIWI'
                    ? 'Your bidding value must be higher than base value.'
                    : `Your bidding amount can not be lower then ${Number(minDiscount * data[index].crt).toFixed(
                        2,
                      )}. Please, try again.`,
              });
            }
          }
        }
      } else {
        if (
          Number(maxPricePerCarat) >= min &&
          Number(maxPricePerCarat) <= max &&
          isInIntervalDiff &&
          value >= -100 &&
          value <= 100
        ) {
          data[index].bidDiscount = value;
          const bigPricePerCarat = formatFloat(data[index].rap + data[index].rap * (value / 100));
          data[index].bidPricePerCarat = bigPricePerCarat;
          data[index].bidAmount = bigPricePerCarat * data[index].crt;
        } else {
          data[index].bidDiscount = oldValue;
          data[index].bidPricePerCarat = formatFloat(data[index].rap + data[index].rap * (oldValue / 100));
          data[index].bidAmount = (data[index].bidPricePerCarat || 0) * data[index].crt;
          if (isInIntervalDiff) {
            if (!(Number(maxPricePerCarat) >= data[index].ctPr)) {
              OpenNotification({
                type: 'error',
                title:
                  subTypeCode !== 'BIWI'
                    ? 'Your bidding value must be higher than base value.'
                    : `Your bidding amount can not be lower then ${Number(minDiscount * data[index].crt).toFixed(
                        2,
                      )}. Please, try again.`,
              });
            } else {
              OpenNotification({
                type: 'error',
                title:
                  subTypeCode !== 'BIWI'
                    ? 'Your bidding value must be higher than base value.'
                    : `Your bidding amount can not be lower then ${Number(minDiscount * data[index].crt).toFixed(
                        2,
                      )}. Please, try again.`,
              });
            }
          }
        }
      }
    } else {
      const oldData = [...this.state.oldData];
      const oldValue = data[index].bidDiscount || data[index].back;
      const isInIntervalDiff = this.checkInterval(value, oldValue, oldData[index].back);
      const max = this.state.nextBidData.sub_types.upwardInterval + Math.abs(oldData[index].bidDiscount);
      const maxPricePerCarat = formatFloat(data[index].rap + data[index].rap * (value / 100));
      if (calColumn !== 'ctPr') {
        if (value >= oldData[index].bidDiscount && value <= max && isInIntervalDiff && value >= -100 && value <= 100) {
          data[index].bidDiscount = value;
          const bigPricePerCarat = formatFloat(data[index].rap + data[index].rap * (value / 100));
          data[index].bidPricePerCarat = bigPricePerCarat;
          data[index].bidAmount = bigPricePerCarat * data[index].crt;
        } else {
          data[index].bidDiscount = oldValue;
          data[index].bidPricePerCarat = formatFloat(data[index].rap + data[index].rap * (oldValue / 100));
          data[index].bidAmount = (data[index].bidPricePerCarat || 0) * data[index].crt;
          if (isInIntervalDiff) {
            if (!(value >= oldData[index].back)) {
              OpenNotification({
                type: 'error',
                title: 'You can not decrease your previous bid.',
              });
            } else {
              OpenNotification({
                type: 'error',
                title: 'You can not decrease your previous bid.',
              });
            }
          }
        }
      } else {
        if (
          Number(maxPricePerCarat) >= Number(oldData[index].bidPricePerCarat) &&
          isInIntervalDiff &&
          value >= -100 &&
          value <= 100
        ) {
          data[index].bidDiscount = value;
          const bigPricePerCarat = formatFloat(data[index].rap + data[index].rap * (value / 100));
          data[index].bidPricePerCarat = bigPricePerCarat;
          data[index].bidAmount = bigPricePerCarat * data[index].crt;
        } else {
          data[index].bidPricePerCarat = formatFloat(oldData[index].bidPricePerCarat);
          data[index].bidAmount = (data[index].bidPricePerCarat || 0) * data[index].crt;
          data[index].bidDiscount = formatFloat((1 - data[index].bidPricePerCarat / data[index].rap) * -100);

          isInIntervalDiff &&
            OpenNotification({
              type: 'error',
              title: `You can not decrease your previous bid.`,
            });
        }
      }
    }
    const checked = data.filter((el) => find(this.state.checked, { id: el.id }));
    this.setState({ data, checked });
  };

  handleDiscountBlur = (...args) => this.handleDiscountChange(...args, true);
  getNewBid = (value, row) => {
    const data = [...this.state.data];
    const index = findIndex(data, { stoneId: row.stoneId });
    data[index] = { ...data[index] };
    const discountValue = data[index].isFcCol ? 0 : Number((1 - Number(value) / data[index].rap) * -100);
    const isPositive = data[index].ctPr > data[index].rap;
    const back = isPositive ? Math.abs(data[index].back) : -1 * Math.abs(data[index].back);
    const calColumn = this.state.nextBidData.sub_types.calColumn;
    data[index].back = back;
    if (this.props.pathname === LISTINGPAGES.BID) {
      const oldValue = data[index].bidDiscount || back;
      const isInIntervalDiff = data[index].isFcCol
        ? this.checkInterval(value, data[index].bidPricePerCarat, back)
        : this.checkInterval(discountValue, oldValue, back);
      const upInterval = this.state.nextBidData.sub_types.upwardInterval;
      const downInterval = this.state.nextBidData.sub_types.downwardInterval;
      const subTypeCode = this.state.nextBidData.sub_types.subTypeCode;
      const max =
        calColumn === 'ctPr' || data[index].isFcCol
          ? data[index].ctPr + data[index].ctPr * (upInterval / 100)
          : upInterval + Math.abs(back);
      const min =
        calColumn === 'ctPr' || data[index].isFcCol
          ? data[index].ctPr + data[index].ctPr * (downInterval / 100)
          : back + downInterval;
      const minValue = data[index].ctPr + data[index].ctPr * (downInterval / 100);
      // const minDisc = formatFloat(data[index].rap + ((back + downInterval) * data[index].rap) / 100);
      if (calColumn !== 'ctPr' && !data[index].isFcCol) {
        if (
          Number(discountValue) >= min &&
          Number(discountValue) <= max &&
          isInIntervalDiff &&
          Number(discountValue) >= -100 &&
          Number(discountValue) <= 100
        ) {
          data[index].bidPricePerCarat = value;
          data[index].bidAmount = (value || 0) * data[index].crt;
          data[index].bidDiscount = formatFloat((1 - value / data[index].rap) * -100);
        } else {
          data[index].bidDiscount = oldValue;
          data[index].bidPricePerCarat = data[index].isFcCol
            ? data[index].bidPricePerCarat
            : Number(data[index].rap + (data[index].bidDiscount * data[index].rap) / 100);
          data[index].bidAmount = (data[index].bidPricePerCarat || 0) * data[index].crt;
          isInIntervalDiff &&
            OpenNotification({
              type: 'error',
              title:
                subTypeCode !== 'BIWI'
                  ? 'Your bidding value must be higher than base value.'
                  : `Your bidding amount can not be lower then ${Number(minValue * data[index].crt).toFixed(
                      2,
                    )},Please try again.`,
            });
        }
      } else {
        if (
          Number(value) >= min &&
          Number(value) <= max &&
          isInIntervalDiff &&
          Number(discountValue) >= -100 &&
          Number(discountValue) <= 100
        ) {
          data[index].bidPricePerCarat = value;
          data[index].bidAmount = (value || 0) * data[index].crt;
          data[index].bidDiscount = formatFloat((1 - value / data[index].rap) * -100);
        } else {
          data[index].bidDiscount = oldValue;
          data[index].bidPricePerCarat = data[index].isFcCol
            ? data[index].bidPricePerCarat
            : Number(data[index].rap + (data[index].bidDiscount * data[index].rap) / 100);
          data[index].bidAmount = (data[index].bidPricePerCarat || 0) * data[index].crt;
          isInIntervalDiff &&
            OpenNotification({
              type: 'error',
              title:
                subTypeCode !== 'BIWI'
                  ? 'Your bidding value must be higher than base value.'
                  : `Your bidding amount can not be lower then ${Number(minValue * data[index].crt).toFixed(
                      2,
                    )},Please try again.`,
            });
        }
      }
    } else {
      const oldData = [...this.state.oldData];
      const oldValue = data[index].bidDiscount || data[index].back;
      const isInIntervalDiff = data[index].isFcCol
        ? this.checkInterval(value, data[index].bidPricePerCarat, oldData[index].back)
        : this.checkInterval(discountValue, oldValue, oldData[index].back);
      // const upInterval = this.state.nextBidData.sub_types.upwardInterval;
      // const downInterval = this.state.nextBidData.sub_types.downwardInterval;
      // const max = upInterval + Math.abs(oldData[index].bidDiscount);
      // const min = oldData[index].bidDiscount + downInterval;
      if (calColumn !== 'ctPr' && !data[index].isFcCol) {
        if (
          Number(discountValue) >= oldData[index].bidDiscount &&
          isInIntervalDiff &&
          Number(discountValue) >= -100 &&
          Number(discountValue) <= 100
        ) {
          data[index].bidPricePerCarat = value;
          data[index].bidAmount = (value || 0) * data[index].crt;
          data[index].bidDiscount = formatFloat((1 - value / data[index].rap) * -100);
        } else {
          data[index].bidDiscount = oldData[index].bidDiscount;
          data[index].bidPricePerCarat = oldData[index].isFcCol
            ? oldData[index].bidPricePerCarat
            : Number(data[index].rap + (data[index].bidDiscount * data[index].rap) / 100);
          data[index].bidAmount = (data[index].bidPricePerCarat || 0) * data[index].crt;
          isInIntervalDiff &&
            OpenNotification({
              type: 'error',
              title: `You can not decrease your previous bid.`,
            });
        }
      } else {
        if (
          Number(value) >= Number(oldData[index].bidPricePerCarat) &&
          isInIntervalDiff &&
          Number(discountValue) >= -100 &&
          Number(discountValue) <= 100
        ) {
          data[index].bidPricePerCarat = value;
          data[index].bidAmount = (value || 0) * data[index].crt;
          data[index].bidDiscount = formatFloat((1 - value / data[index].rap) * -100);
        } else {
          data[index].bidPricePerCarat = formatFloat(oldData[index].bidPricePerCarat);
          data[index].bidAmount = (data[index].bidPricePerCarat || 0) * data[index].crt;
          data[index].bidDiscount = formatFloat((1 - data[index].bidPricePerCarat / data[index].rap) * -100);
          isInIntervalDiff &&
            OpenNotification({
              type: 'error',
              title: `You can not decrease your previous bid.`,
            });
        }
      }
    }
    const checked = data.filter((el) => find(this.state.checked, { id: el.id }));
    this.setState({ data, checked });
  };

  // New price Ct changes
  handlePriceCtChange = (value, row) => {
    const data = [...this.state.data];
    const index = findIndex(data, { stoneId: row.stoneId });
    data[index] = { ...data[index] };
    const isPositive = data[index].ctPr > data[index].rap;
    const back = isPositive ? Math.abs(data[index].back) : -1 * Math.abs(data[index].back);
    data[index].back = back;
    if (this.props.pathname === LISTINGPAGES.BID) {
      const upInterval = this.state.nextBidData.sub_types.upwardInterval;
      const downInterval = this.state.nextBidData.sub_types.downwardInterval;
      const max = data[index].ctPr + data[index].ctPr * (upInterval / 100);
      const min = data[index].ctPr - data[index].ctPr * (downInterval / 100);
      if (value <= max && value >= min) {
        data[index].bidPricePerCarat = value;
        data[index].bidAmount = (value || 0) * data[index].crt;
        data[index].bidDiscount = !data[index]?.bidDiscountNull ? 0 : formatFloat((1 - value / data[index].rap) * -100);
      } else {
        if (value < min) {
          OpenNotification({
            type: 'error',
            title: `Your bidding amount cannot be lower then ${Number(min)},Please try again.`,
          });
        } else if (value > max) {
          OpenNotification({
            type: 'error',
            title: `Your bidding amount cannot be higher then ${Number(max)},Please try again.`,
          });
        }
      }
      const checked = data.filter((el) => find(this.state.checked, { id: el.id }));
      this.setState({ data, checked });
    }
  };

  // New Discount Changes
  handleNewDiscountChange = (value, row) => {
    const data = [...this.state.data];
    const index = findIndex(data, { stoneId: row.stoneId });
    data[index] = { ...data[index] };
    value = Number(value); // converting to number
    const isPositive = data[index].ctPr > data[index].rap;
    const back = isPositive ? Math.abs(data[index].back) || 0 : -1 * Math.abs(data[index].back) || 0;
    data[index].back = back;
    if (this.props.pathname === LISTINGPAGES.BID) {
      const upInterval = this.state.nextBidData.sub_types.upwardInterval;
      const downInterval = this.state.nextBidData.sub_types.downwardInterval;
      const max = back + upInterval;
      const min = back - downInterval;
      if (value <= max && value >= min) {
        data[index].bidDiscount = value;
        const bigPricePerCarat = formatFloat(data[index].rap + data[index].rap * (value / 100));
        data[index].bidPricePerCarat = bigPricePerCarat;
        data[index].bidAmount = bigPricePerCarat * data[index].crt;
      } else {
        if (value < min) {
          OpenNotification({
            type: 'error',
            title: `Your bidding discount cannot be lower then ${Number(min)}%,Please try again.`,
          });
        } else if (value > max) {
          OpenNotification({
            type: 'error',
            title: `Your bidding discount cannot be higher then ${Number(max)}%,Please try again.`,
          });
        }
      }
    }
    const checked = data.filter((el) => find(this.state.checked, { id: el.id }));
    this.setState({ data, checked });
  };

  handleNewBidBlur = (...args) => this.getNewBid(...args, true);
  handleValueChange = (value, row) => {
    const data = [...this.state.data];
    const index = findIndex(data, { stoneId: row.stoneId });
    data[index] = { ...data[index] };

    const bidPricePerCarat = formatFloat(value / data[index].crt);
    const discountValue = data[index].isFcCol ? 0 : Number((1 - bidPricePerCarat / data[index].rap) * -100);
    const isPositive = data[index].ctPr > data[index].rap;
    const back = isPositive ? Math.abs(data[index].back) : -1 * Math.abs(data[index].back);
    const calColumn = this.state.nextBidData.sub_types.calColumn;
    data[index].back = back;
    const subTypeCode = this.state.nextBidData.sub_types.subTypeCode;
    const upInterval = this.state.nextBidData.sub_types.upwardInterval;
    const downInterval = this.state.nextBidData.sub_types.downwardInterval * -1;
    const minCtPr = data[index].ctPr + data[index].ctPr * (downInterval / 100);
    const minAmount = formatFloat(minCtPr) * data[index].crt;

    if (this.props.pathname === LISTINGPAGES.BID) {
      const oldValue = data[index].bidDiscount || back;
      const isInIntervalDiff = data[index].isFcCol
        ? this.checkInterval(value, data[index].bidAmount, back)
        : this.checkInterval(discountValue, oldValue, back);
      const max =
        calColumn === 'ctPr' || data[index].isFcCol
          ? data[index].ctPr + data[index].ctPr * (upInterval / 100)
          : back + upInterval;
      const min =
        calColumn === 'ctPr' || data[index].isFcCol
          ? data[index].ctPr + data[index].ctPr * (downInterval / 100)
          : back + downInterval;
      if (calColumn !== 'ctPr' && !data[index].isFcCol) {
        if (
          Number(discountValue) >= min &&
          Number(discountValue) <= max &&
          isInIntervalDiff &&
          Number(discountValue) >= -100 &&
          Number(discountValue) <= 100
        ) {
          if (data[index].isFcCol) {
            data[index].bidAmount = value;
            data[index].bidPricePerCarat = formatFloat(data[index].bidAmount) / data[index].crt;
            data[index].bidDiscount = 0;
          } else {
            data[index].bidDiscount = discountValue;
            data[index].bidPricePerCarat = formatFloat(data[index].rap + (discountValue * data[index].rap) / 100);
            data[index].bidAmount = value;
          }
        } else {
          if (data[index].isFcCol) {
            // data[index].bidAmount = data[index].bidAmount; // 👏
            data[index].bidPricePerCarat = formatFloat(data[index].bidAmount) / data[index].crt;
            data[index].bidDiscount = 0;
            isInIntervalDiff &&
              OpenNotification({
                type: 'error',
                title:
                  subTypeCode !== 'BIWI'
                    ? 'Your bidding value must be higher than base value.'
                    : `Your bidding amount can not be lower then ${Number(minAmount).toFixed(2)}. Please, try again.`,
              });
          } else {
            data[index].bidDiscount = data[index].back;
            data[index].bidPricePerCarat = formatFloat(
              data[index].rap + (data[index].bidDiscount * data[index].rap) / 100,
            );
            data[index].bidAmount = (data[index].bidPricePerCarat || 0) * data[index].crt;
            isInIntervalDiff &&
              OpenNotification({
                type: 'error',
                title:
                  subTypeCode !== 'BIWI'
                    ? 'Your bidding value must be higher than base value.'
                    : `Your bidding amount can not be lower then ${Number(minAmount).toFixed(2)}. Please, try again.`,
              });
          }
        }
      } else {
        if (
          Number(bidPricePerCarat) >= min &&
          Number(bidPricePerCarat) <= max &&
          isInIntervalDiff &&
          Number(discountValue) >= -100 &&
          Number(discountValue) <= 100
        ) {
          if (data[index].isFcCol) {
            data[index].bidAmount = value;
            data[index].bidPricePerCarat = formatFloat(data[index].bidAmount) / data[index].crt;
            data[index].bidDiscount = 0;
          } else {
            data[index].bidDiscount = discountValue;
            data[index].bidPricePerCarat = formatFloat(data[index].rap + (discountValue * data[index].rap) / 100);
            data[index].bidAmount = value;
          }
        } else {
          if (data[index].isFcCol) {
            // data[index].bidAmount = data[index].bidAmount; // 👏
            data[index].bidPricePerCarat = formatFloat(data[index].bidAmount) / data[index].crt;
            data[index].bidDiscount = 0;
            isInIntervalDiff &&
              OpenNotification({
                type: 'error',
                title:
                  subTypeCode !== 'BIWI'
                    ? 'Your bidding value must be higher than base value.'
                    : `Your bidding amount can not be lower then ${Number(minAmount).toFixed(2)}. Please, try again.`,
              });
          } else {
            data[index].bidDiscount = data[index].back;
            data[index].bidPricePerCarat = formatFloat(
              data[index].rap + (data[index].bidDiscount * data[index].rap) / 100,
            );
            data[index].bidAmount = (data[index].bidPricePerCarat || 0) * data[index].crt;
            isInIntervalDiff &&
              OpenNotification({
                type: 'error',
                title:
                  subTypeCode !== 'BIWI'
                    ? 'Your bidding value must be higher than base value.'
                    : `Your bidding amount can not be lower then ${Number(minAmount).toFixed(2)}. Please, try again.`,
              });
          }
        }
      }
    } else {
      const oldData = [...this.state.oldData];
      const oldValue = data[index].bidDiscount || data[index].back;
      const isInIntervalDiff = data[index].isFcCol
        ? this.checkInterval(value, data[index].bidAmount, back)
        : this.checkInterval(discountValue, oldValue, oldData[index].back);
      // const upInterval = this.state.nextBidData.sub_types.upwardInterval;
      // const downInterval = this.state.nextBidData.sub_types.downwardInterval * -1;
      // const max =
      //   calColumn === 'ctPr'
      //     ? data[index].ctPr + data[index].ctPr * (upInterval / 100)
      //     : upInterval + Math.abs(oldData[index].bidDiscount);
      // const min =
      //   calColumn === 'ctPr'
      //     ? data[index].ctPr + data[index].ctPr * (downInterval / 100)
      //     : oldData[index].bidDiscount + downInterval;
      const maxPricePerCarat = formatFloat(data[index].rap + (discountValue * data[index].rap) / 100);
      if (calColumn !== 'ctPr' && !data[index].isFcCol) {
        if (
          Number(discountValue) >= oldData[index].bidDiscount &&
          isInIntervalDiff &&
          Number(discountValue) >= -100 &&
          Number(discountValue) <= 100
        ) {
          if (data[index].isFcCol) {
            data[index].bidAmount = value;
            data[index].bidPricePerCarat = formatFloat(data[index].bidAmount) / data[index].crt;
            data[index].bidDiscount = 0;
          } else {
            data[index].bidDiscount = discountValue;
            data[index].bidPricePerCarat = formatFloat(data[index].rap + (discountValue * data[index].rap) / 100);
            data[index].bidAmount = value;
          }
        } else {
          if (data[index].isFcCol) {
            data[index].bidAmount = oldData[index].bidAmount;
            data[index].bidPricePerCarat = formatFloat(data[index].bidAmount) / data[index].crt;
            data[index].bidDiscount = 0;
          } else {
            data[index].bidDiscount = oldData[index].bidDiscount;
            data[index].bidPricePerCarat = formatFloat(
              data[index].rap + (data[index].bidDiscount * data[index].rap) / 100,
            );
            data[index].bidAmount = (data[index].bidPricePerCarat || 0) * data[index].crt;
            isInIntervalDiff &&
              OpenNotification({
                type: 'error',
                title: `You can not decrease your previous bid.`,
              });
          }
        }
      } else {
        if (
          Number(maxPricePerCarat) >= Number(oldData[index].bidPricePerCarat) &&
          isInIntervalDiff &&
          Number(discountValue) >= -100 &&
          Number(discountValue) <= 100
        ) {
          if (data[index].isFcCol) {
            data[index].bidAmount = value;
            data[index].bidPricePerCarat = formatFloat(data[index].bidAmount) / data[index].crt;
            data[index].bidDiscount = 0;
          } else {
            data[index].bidDiscount = discountValue;
            data[index].bidPricePerCarat = formatFloat(data[index].rap + (discountValue * data[index].rap) / 100);
            data[index].bidAmount = value;
          }
        } else {
          if (data[index].isFcCol) {
            data[index].bidAmount = oldData[index].bidAmount;
            data[index].bidPricePerCarat = formatFloat(data[index].bidAmount) / data[index].crt;
            data[index].bidDiscount = 0;
          } else {
            data[index].bidDiscount = oldData[index].bidDiscount;
            data[index].bidPricePerCarat = formatFloat(
              data[index].rap + (data[index].bidDiscount * data[index].rap) / 100,
            );
            data[index].bidAmount = (data[index].bidPricePerCarat || 0) * data[index].crt;
            isInIntervalDiff &&
              OpenNotification({
                type: 'error',
                title: `You can not decrease your previous bid.`,
              });
          }
        }
      }
    }
    const checked = data.filter((el) => find(this.state.checked, { id: el.id }));
    this.setState({ data, checked });
  };

  handleValueBlur = (...args) => this.handleValueChange(...args, true);

  getNextBidConfig = async () => {
    const nextBidData = await getBidConfig();
    this.setState({ nextBidData });
  };

  async componentDidMount() {
    await this.getNextBidConfig();

    const data = this.props.checked
      .map((x) => {
        return {
          ...x,
          bidPricePerCarat: Number((x.bidPricePerCarat ? x.bidPricePerCarat : x.ctPr) || 0),
          bidAmount: x.bidAmount ? Number(x.bidAmount) : Number(x.amt) || 0,
          bidDiscount: Number(x.bidDiscount ? x.bidDiscount : x.back || 0),
          bidDiscountNull: x.bidDiscount ? x.bidDiscount : x.back || null,
        };
      })
      .map((x) => newDiamondPrice(x, 'bid'))
      .map((x) => {
        if (this.props.pathname !== LISTINGPAGES.MYBID) {
          return {
            ...x,
            bidDiscount:
              x.ctPr < x.rap ? -1 * Math.abs(Number(Number(x.bidDiscount))) : Math.abs(Number(Number(x.bidDiscount))),
          };
        } else {
          return {
            ...x,
            bidDiscount:
              Number(x.bidDiscount) < 0
                ? -1 * Math.abs(Number(Number(x.bidDiscount)))
                : Math.abs(Number(Number(x.bidDiscount))),
          };
        }
      });
    let disableBidStone = false;
    data.forEach((item) => {
      const back =
        item.ctPr < item.rap ? -1 * Math.abs(Number(Number(item.back))) : Math.abs(Number(Number(item.back)));
      if (back === item.bidDiscount) {
        disableBidStone = true;
      }
    });
    this.setState({ showModal: disableBidStone });
    const Columns = getColumn();
    let index = findIndex(Columns, { id: 'lbNm' });
    if (!index) index = 1;
    const columns = [
      ...Columns.slice(0, index + 1),
      {
        Header: 'Bid Disc(%)',
        accessor: 'bidDiscount',
        id: 'bidDiscount',
        Cell: ({ row, cell }) => {
          const originalValue = React.useMemo(() => {
            return Number(Number(cell.value));
          }, [cell.value]);

          const [value, setValue] = React.useState(
            isNumber(row.original.bidDiscount)
              ? Number(row.original.bidDiscount)
              : isNumber(originalValue)
              ? Number(originalValue)
              : '0.00',
          );

          React.useEffect(() => {
            setValue(Number(row.original.bidDiscount));
          }, [row.original]);

          // const handleChange = React.useCallback(() => {
          //   e.preventDefault();
          //   const _value = e.target.value;
          //   if (isNumeric(_value)) setValue(_value);
          // }, []);

          // const handleBlur = React.useCallback(() => {
          //   e.preventDefault();
          //   const _value = e.target.value;
          //   isNumeric(_value, true)
          //     ? this.handleDiscountBlur(_value, row.original)
          //     : this.handleDiscountBlur(0, row.original);
          // }, []);

          // const sign = Number(value) > 0 ? '+' : '';
          if (row.original.isFcCol) {
            return '-';
          }
          return (
            <div className="tableInput">
              {/* <span className="add-sign">{sign}</span> */}
              {row.original.bidDiscountNull !== null &&
                (this.state.nextBidData.sub_types.calColumn === 'all' ||
                  this.state.nextBidData.sub_types.calColumn === 'back') && (
                  <img
                    onClick={() => {
                      const _value = Number(value) - this.state.nextBidData.sub_types?.diffInterval ?? value;
                      if (isNumeric(_value)) setValue(_value);
                      isNumeric(_value, true)
                        ? this.handleNewDiscountChange(_value, row.original)
                        : this.handleNewDiscountChange(0, row.original);
                    }}
                    src={require('assets/img/minus.jpg')}
                    width="15px"
                    alt="minus"
                  />
                )}
              <input
                disabled
                type="number"
                style={{
                  width:
                    row.original.bidDiscountNull !== null &&
                    (this.state.nextBidData.sub_types.calColumn === 'all' ||
                      this.state.nextBidData.sub_types.calColumn === 'back')
                      ? '70%'
                      : '100%',
                  paddingLeft: Number(value) > 0 ? 8 : 2,
                }}
                value={value}
                // onChange={handleChange}
                // onBlur={handleBlur}
              />
              {row.original.bidDiscountNull !== null &&
                (this.state.nextBidData.sub_types.calColumn === 'all' ||
                  this.state.nextBidData.sub_types.calColumn === 'back') && (
                  <img
                    onClick={() => {
                      const _value = Number(value) + this.state.nextBidData.sub_types?.diffInterval ?? value;
                      if (isNumeric(_value)) setValue(_value);
                      isNumeric(_value, true)
                        ? this.handleNewDiscountChange(_value, row.original)
                        : this.handleNewDiscountChange(0, row.original);
                    }}
                    src={require('assets/img/plus.jpg')}
                    width="15px"
                    alt="plus"
                  />
                )}
            </div>
          );
        },
      },
      {
        Header: 'Bid Price/Ct',
        accessor: 'bidPricePerCarat',
        id: 'bidPricePerCarat',
        Cell: ({ row, cell }) => {
          const originalValue = React.useMemo(() => {
            return Number(Number(cell.value));
          }, [cell.value]);
          const countOnDis =
            row.original.bidDiscountNull !== null &&
            (this.state.nextBidData.sub_types.calColumn === 'all' ||
              this.state.nextBidData.sub_types.calColumn === 'back');
          const [value, setValue] = React.useState(
            isNumber(row.original.bidPricePerCarat)
              ? Number(row.original.bidPricePerCarat)
              : isNumber(originalValue)
              ? Number(originalValue)
              : '0.00',
          );
          const [valueDis, setValueDis] = React.useState(
            isNumber(row.original.bidDiscount)
              ? Number(row.original.bidDiscount)
              : isNumber(originalValue)
              ? Number(originalValue)
              : '0.00',
          );
          React.useEffect(() => {
            setValue(Number(row.original.bidPricePerCarat));
          }, [row.original]);

          // const handleChange = React.useCallback(() => {
          //   e.preventDefault();
          //   const _value = e.target.value;
          //   if (isNumeric(_value)) setValue(_value);
          // }, []);

          // const handleBlur = React.useCallback(() => {
          //   e.preventDefault();
          //   const _value = e.target.value;
          //   isNumeric(_value, true)
          //     ? this.handleNewBidBlur(_value, row.original)
          //     : this.handleNewBidBlur(0, row.original);
          // }, []);
          return (
            <div className="tableInput">
              {value &&
                (this.state.nextBidData.sub_types.calColumn === 'all' ||
                  this.state.nextBidData?.sub_types?.calColumn === 'ctPr') && (
                  <img
                    onClick={() => {
                      if (countOnDis) {
                        const _value = Number(valueDis) - this.state.nextBidData.sub_types?.diffInterval ?? valueDis;
                        if (isNumeric(_value)) setValueDis(_value);
                        isNumeric(_value, true)
                          ? this.handleNewDiscountChange(_value, row.original)
                          : this.handleNewDiscountChange(0, row.original);
                      } else {
                        const _value = Number(value) - this.state.nextBidData?.sub_types?.diffInterval ?? value;
                        if (isNumeric(_value)) setValue(_value);
                        isNumeric(_value, true)
                          ? this.handlePriceCtChange(_value, row.original)
                          : this.handlePriceCtChange(0, row.original);
                      }
                    }}
                    src={require('assets/img/minus.jpg')}
                    width="15px"
                    alt="minus"
                  />
                )}
              <input
                disabled
                style={{
                  width:
                    value &&
                    (this.state.nextBidData.sub_types.calColumn === 'all' ||
                      this.state.nextBidData?.sub_types?.calColumn === 'ctPr')
                      ? '70%'
                      : '100%',
                }}
                value={value}
                // onChange={handleChange}
                // onBlur={handleBlur}
              />
              {value &&
                (this.state.nextBidData.sub_types.calColumn === 'all' ||
                  this.state.nextBidData?.sub_types?.calColumn === 'ctPr') && (
                  <img
                    onClick={() => {
                      if (countOnDis) {
                        const _value = Number(valueDis) + this.state.nextBidData.sub_types?.diffInterval ?? valueDis;
                        if (isNumeric(_value)) setValueDis(_value);
                        isNumeric(_value, true)
                          ? this.handleNewDiscountChange(_value, row.original)
                          : this.handleNewDiscountChange(0, row.original);
                      } else {
                        const _value = Number(value) + this.state.nextBidData?.sub_types?.diffInterval ?? value;
                        if (isNumeric(_value)) setValue(_value);
                        isNumeric(_value, true)
                          ? this.handlePriceCtChange(_value, row.original)
                          : this.handlePriceCtChange(0, row.original);
                      }
                    }}
                    src={require('assets/img/plus.jpg')}
                    width="15px"
                    alt="plus"
                  />
                )}
            </div>
          );
        },
      },
      {
        Header: 'Bid Value',
        accessor: 'bidAmount',
        id: 'bidAmount',
        Cell: ({ row, cell }) => {
          const originalValue = React.useMemo(() => {
            return Number(Number(cell.value));
          }, [cell.value]);

          const [value, setValue] = React.useState(
            isNumber(row.original.bidAmount)
              ? Number(row.original.bidAmount)
              : isNumber(originalValue)
              ? Number(originalValue)
              : '0.00',
          );

          React.useEffect(() => {
            setValue(Number(row.original.bidAmount));
          }, [row.original]);

          const handleChange = React.useCallback((e) => {
            e.preventDefault();
            const _value = e.target.value;
            if (isNumeric(_value)) setValue(_value);
          }, []);

          const handleBlur = React.useCallback(
            (e) => {
              e.preventDefault();
              const _value = e.target.value;
              isNumeric(_value, true)
                ? this.handleValueBlur(_value, row.original)
                : this.handleValueBlur(0, row.original);
            },
            [row.original],
          );

          return (
            <div className="tableInput">
              <input disabled value={value} onChange={handleChange} onBlur={handleBlur} />
            </div>
          );
        },
      },

      ...Columns.slice(index + 1, Columns.length),
    ];
    this.setState({ columns, data, oldData: data, checked: data }, () => {
      const newData = [];
      this.state.data.forEach((d) => {
        this.state.checked.forEach((c) => {
          if (d.id === c.id) {
            newData.push(d);
          }
        });
      });
      this.getNextBidConfig();
      this.handleCalculation(newData);
    });
  }

  // getTerms = () => {
  //   return (
  //     <div>
  //       <select
  //         name="summ"
  //         value={this.state.summ}
  //         placeholder="Select Terms"
  //         onChange={() => {
  //           localStorage.setItem('Termvalue', e.target.value);
  //           this.setState({ summ: e.target.value }, () => {
  //             const newData = [];
  //             this.state.data.map((d) => {
  //               this.state.checked.forEach((c) => {
  //                 if (d.id === c.id) {
  //                   newData.push(d);
  //                 }
  //               });
  //             });
  //             this.handleCalculation(newData);
  //           });
  //         }}
  //       >
  //         <option value="" disabled selected hidden>
  //           Select Terms
  //         </option>
  //         {SELECT_STONE_TERMS_NEW.map((x) => {
  //           return <option value={x.id}>{x.name}</option>;
  //         })}
  //       </select>
  //     </div>
  //   );
  // };

  handleCalculation = (stoneList) => {
    const calc = {};
    stoneList.map((d) => {
      d.rap_avg = Number.isNaN(d.rap * d.crt) ? 0 : d.rap * d.crt;
      return d;
    });
    const totalRapAvg = reduceTotal(stoneList, 'rap_avg');
    calc.pieces = stoneList.length;
    const avgPricePerCarat = reduceTotal(stoneList, 'bidAmount') / reduceTotal(stoneList, 'crt');
    calc.totalCarats = reduceTotal(stoneList, 'crt');
    calc.avgDisc = totalRapAvg ? (1 - reduceTotal(stoneList, 'amt') / reduceTotal(stoneList, 'rap_avg')) * -100 : 0;
    // calc.avgDisc = (1 - reduceTotal(diams, 'amt') / reduceTotal(diams, 'rap_avg')) * -100 * -1;
    calc.AvgDisc = totalRapAvg
      ? (1 - avgPricePerCarat / (reduceTotal(stoneList, 'rap_avg') / calc.totalCarats)) * -100
      : 0;
    calc.finalNetValue = reduceTotal(stoneList, 'bidAmount');
    //calc.finalNetRate = ((100 - calc.finalAvgDisc) * reduceTotal(map(diams, 'rap'))) / 100;
    calc.finalNetRate = reduceTotal(stoneList, 'bidAmount') / reduceTotal(stoneList, 'crt');
    calc.netValue = reduceTotal(stoneList, 'bidAmount');
    calc.perCarat = reduceTotal(stoneList, 'amt') / calc.totalCarats;
    calc.BidPerCarat = reduceTotal(stoneList, 'bidAmount') / calc.totalCarats;
    calc.amt = reduceTotal(stoneList, 'amt');
    // SELECT_STONE_TERMS_POP.find(({ id }) => id === term)?.value ?? 0
    calc.addDis = SELECT_STONE_TERMS_NEW.find(({ id }) => id === this.state.summ) ?? 0;
    calc.finalAvgDisc = totalRapAvg
      ? (1 -
          reduceTotal(stoneList, 'bidAmount') /
            reduceTotal(stoneList, 'crt') /
            (reduceTotal(stoneList, 'rap_avg') / calc.totalCarats)) *
        -100
      : 0;

    if (this.state.summ) {
      const totalRapAvg = reduceTotal(stoneList, 'rap_avg');
      const options = SELECT_STONE_TERMS_NEW.find(({ id }) => id === this.state.summ) ?? 0;
      const discCalc = options.minAmt
        ? Number(calc.amt) >= options.minAmt
          ? options.value
          : options.resetDis
        : options.value;
      const termDis = discCalc;

      const avg = totalRapAvg ? (1 - calc.finalNetValue / reduceTotal(stoneList, 'rap_avg')) * -100 : 0;
      const final = calc.finalNetRate + calc.finalNetRate * (parseInt(termDis) / 100);
      const rap = totalRapAvg ? reduceTotal(stoneList, 'rap_avg') / calc.totalCarats : 0;
      //let final =  calc.finalNetValue + (calc.finalNetValue *(parseInt(termDis)));
      const amount = calc.finalNetValue + calc.finalNetValue * (parseInt(termDis) / 100);
      calc.BidPerCarat = reduceTotal(stoneList, 'bidAmount') / calc.totalCarats;
      calc.perCarat = reduceTotal(stoneList, 'amt') / calc.totalCarats;
      // Calculation for term
      calc.addDis = termDis;
      calc.AvgDisc = totalRapAvg ? avg : 0;
      calc.finalNetValue = amount;
      calc.finalNetRate = final;
      calc.finalAvgDisc = totalRapAvg ? (1 - Number(final) / rap) * -100 : 0;
    }
    this.setState({ summation: calc });
  };

  render() {
    const { summation } = this.state;
    localStorage.setItem('disc', summation.addDis);
    const options = SELECT_STONE_TERMS_NEW.find(({ id }) => id === this.state.summ) ?? 0;
    localStorage.setItem('termObj', JSON.stringify(options));
    localStorage.setItem('TermsDis', summation.addDis);
    // const terms = this.getTerms();
    const VALUES = isMobile()
      ? {
          // 'Pieces:': summation.pieces,
          // 'Crt:': Number(summation.totalCarats || 0),
          // 'Disc% :': Number(summation.avgDisc || 0),
          // 'Pr/Ct :': Number(summation.perCarat),
          // 'Amt. :': Number(summation.amt),
          'Total Carats': Number(summation.totalCarats || 0),
          //'Bid Avg Discount(%)': Number(summation.AvgDisc || 0),
          'Avg. Discount(%)': Number(summation.avgDisc || 0),
          //'Bid Per/Carat': Number(summation.BidPerCarat || 0),
          'Per/Carat': Number(summation.perCarat || 0),
          // 'Bid Term Discount(%)': Number(summation.addDis || 0),
          // Amount: Number(summation.amt || 0),
          'Final Avg. Discount(%)': Number(summation.finalAvgDisc || 0),
          'Final Price/Carat': Number(summation.finalNetRate || 0),
          'Final Amount': Number(summation.finalNetValue || 0),
          // TERMS: terms,
        }
      : [
          { title: 'Total Carats', value: Number(summation.totalCarats || 0) },
          { title: 'Bid Avg. Discount(%)', value: Number(summation.finalAvgDisc || 0) },

          // {
          //   title: 'Bid Avg Discount(%)',
          //   value: Number(summation.AvgDisc || 0),
          // },
          { title: 'Avg. Discount(%)', value: Number(summation.avgDisc || 0) },
          { title: 'Bid Price/Carat', value: Number(summation.finalNetRate || 0) },

          //{ title: 'Bid Per/Carat', value: Number(summation.BidPerCarat || 0) },
          { title: 'Per/Carat', value: Number(summation.perCarat || 0) },
          //{ title: 'Bid Term Discount(%)', value: Number(summation.addDis || 0) },
          { title: 'Bid Amount', value: Number(summation.finalNetValue || 0) },

          { title: 'Amount', value: Number(summation.amt || 0) },
          {},
          {},
          {},
          // {
          //   title: 'TERMS',
          //   value: terms,
          // },
        ];

    return isMobile() ? (
      <BIDStoneMobile
        {...this.state}
        currentType={currentType}
        onClose={this.props.onClose}
        VALUES={VALUES}
        parent={this}
      />
    ) : (
      <div>
        <div className="d-flex align-items-center offerTopBlock offerWrapper mb-20">
          <Heading className="popupInnerTitle mr-20" title={<IntlMessages id="app.bidStone" />} />
          {HeadingCalc(this.state.checked, null, true)}
        </div>
        <div className="searchPopupCommonTable">
          <div className="searchResultTable tabInnerTableScroll">
            <Table
              {...this.state}
              currentType={currentType}
              loading={this.state.loading}
              columns={this.state.columns}
              data={this.state.data}
              bidStatus={this.state.nextBidData?.sub_types?.subTypeCode}
              FilterOption={false}
              canSort={false}
              areAllChecked
              nodots
              noGrp
            />
          </div>
          <div className="mt-10 finalCalDetail">
            <div className="DiamondDetailPopup">
              {VALUES.map((field) => {
                return (
                  <div key={field.title} className="DiamondDetailPopupItem">
                    <span>{field.title && `${field.title} :`} </span>
                    <span>
                      {field.title === 'Bid Term Discount(%)' ||
                      field.title === 'Avg. Discount(%)' ||
                      field.title === 'TERMS'
                        ? field.value > 0
                          ? `+${field.value}`
                          : field.value
                        : field.value}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="sideBarPopupButton">
            <a
              id="bid-stone"
              className="commonButton"
              // this.state.showModal
              //   ? `commonButton disable`
              //   : `commonButton`
              onClick={() => {
                let showModal = false;
                this.state.checked.forEach((x) => {
                  if (Number(x.bidDiscount) === Number(x.back)) {
                    showModal = true;
                  }
                });
                if (showModal && this.state.nextBidData.sub_types.subTypeCode !== 'BIWI') {
                  OpenNotification({
                    type: 'error',
                    title: 'Bid discount and discount can not be same for bid.',
                  });
                }
                // } else if (!this.state.summ) {
                //   OpenNotification({
                //     type: 'error',
                //     title: 'Please select term to add bid on diamond(s).',
                //   });
                // }
                else {
                  this.submit();
                }
              }}
              // disabled={this.state.showModal || !this.state.summ}
            >
              <IntlMessages id="app.bidStone" />
            </a>
            <a className="commonButton" onClick={this.props.onClose}>
              <IntlMessages id="app.CancelBid" />
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(withPathname)(BidPopup);
