import React from 'react';

import moment from 'moment';

import { catchError, isEmpty } from 'util/utils';

class Countdown extends React.Component {
  get initialState() {
    return {
      diff: undefined,
      hours: undefined,
      minutes: undefined,
      seconds: undefined,
      hoursStr: undefined,
      minutesStr: undefined,
      secondsStr: undefined,
      hoursLabel: undefined,
      minutesLabel: undefined,
      secondsLabel: undefined,
    };
  }

  state = { ...this.initialState };

  time = undefined;
  clearInterval = undefined;

  componentDidMount() {
    this.setTimer(this.props.currTime);
  }

  componentDidUpdate(prevProps) {
    if (this.props.currTime !== prevProps.currTime) {
      this.setTimer(this.props.currTime);
    }
  }

  formatNumber = (num) => `${num < 10 ? '0' : ''}${num}`;

  formatLabel = (num, label) => `${label}${num > 1 ? 's' : ''}`;

  setTimer = async (time) => {
    void this.clearInterval?.();
    await new Promise((resolve) => setTimeout(resolve));

    this.time = time;
    this.clearInterval = setInterval(() => {
      catchError(
        () => {
          if (!moment(this.time)?._isValid) return this.setState({ ...this.initialState });
          this.time = moment(this.time).add(1, 'seconds');

          const diff = moment.duration(this.props.date.diff(this.time));
          if (diff.asSeconds() > -1 && diff.asSeconds() < 1) void this.props?.bidEndTime?.();

          const diffData = diff._data;
          const newState = {
            diff: diff.asSeconds(),
            hours: diffData.days ? diffData.hours + diffData.days * 24 : diffData.hours,
            minutes: diffData.minutes,
            seconds: diffData.seconds,
            hoursStr: this.formatNumber(diffData.hours),
            minutesStr: this.formatNumber(diffData.minutes),
            secondsStr: this.formatNumber(diffData.seconds),
            hoursLabel: this.formatLabel(diffData.hours, 'hour'),
            minutesLabel: this.formatLabel(diffData.minutes, 'minute'),
            secondsLabel: this.formatLabel(diffData.seconds, 'second'),
          };

          this.setState(newState);
        },
        () => {
          this.setState({ ...this.initialState });
        },
      );
    }, 1000);
  };

  render() {
    if (isEmpty(this.state.diff)) return null;

    const style =
      this.state.hours < 1 && this.state.minutes < 11
        ? 'background-color: #fdc6c6; border-color: red !important; color: red !important'
        : '';

    return (
      <div className="App" key={`${this.state.hours}`}>
        <div className="countDownBlock">
          <div key={this.state.hours} className="countdownItem" STYLE={style}>
            <span STYLE={style}>{this.state.hoursStr}</span>
            <span STYLE={style}>{this.state.hoursLabel}</span>
          </div>
          <div key={this.state.minutes} className="countdownItem" STYLE={style}>
            <span STYLE={style}>{this.state.minutesStr}</span>
            <span STYLE={style}>{this.state.minutesLabel}</span>
          </div>
          <div key={this.state.seconds} className="countdownItem" STYLE={style}>
            <span STYLE={style}>{this.state.secondsStr} </span>
            <span STYLE={style}>{this.state.secondsLabel}</span>
          </div>
        </div>
      </div>
    );
  }
}

export default Countdown;
