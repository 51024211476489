import React from 'react';

import { Modal, Input } from 'antd';

import { ListAction } from 'components/common/DiamondListing/ListAction';

import { StoneService } from 'services/StoneService';

import { useBoolean, useLoading } from 'util/hooks';
import { notify } from 'util/notify';
import { isEmpty } from 'util/utils';

import { store } from 'redux/store';

export const AddBidStoneViewRequest = (props) => {
  const { list, bidConfig, onSuccess, onError, ...rest } = props;

  const [loading, startLoading, stopLoading] = useLoading();
  const [remarks, setRemarks] = React.useState('');
  const [showForm, setShowForm] = useBoolean();

  const validateBeforeStart = React.useCallback(() => {
    const hasStones = !isEmpty(store.getState()?.diamond?.selectedRows?.[list]);
    if (!hasStones) {
      notify.info({ message: 'Please select stone(s) to make view request.' });
    }
    return hasStones;
  }, [list]);

  const openForm = React.useCallback(() => {
    setRemarks('');
    if (validateBeforeStart()) setShowForm.true();
  }, [setShowForm, validateBeforeStart]);

  const handleClick = React.useCallback(async () => {
    if (!validateBeforeStart()) return;
    startLoading();
    const [err, res] = await StoneService.addStoneToTrack(StoneService.TRACK_TYPE_FILTERS.BID_VIEW_REQUEST.trackType, {
      stones: store.getState()?.diamond?.selectedRows?.[list],
      bidConfig,
      remarks,
    });
    if (!err) setShowForm.false();
    stopLoading();

    void (!err ? onSuccess?.(res) : onError?.(res));
  }, [bidConfig, list, onError, onSuccess, remarks, setShowForm, startLoading, stopLoading, validateBeforeStart]);

  return (
    <>
      <ListAction title="Add selected stones to wish list" onClick={openForm} disabled={loading} {...rest}>
        <span>View Request</span>
      </ListAction>
      <Modal
        title="View Request"
        visible={showForm}
        className="commonModal"
        onCancel={setShowForm.false}
        footer={
          <div className="commonModalButton">
            <button className="fillButton" onClick={handleClick} disabled={loading}>
              View Request
            </button>
            <button className="outLineButton" onClick={setShowForm.false} disabled={loading}>
              Cancel
            </button>
          </div>
        }
        destroyOnClose
      >
        <div className="commonTextArea from-group">
          <Input.TextArea placeholder="Comment" onChange={(e) => setRemarks(e?.target?.value)} disabled={loading}>
            {remarks}
          </Input.TextArea>
        </div>
      </Modal>
    </>
  );
};
