import React from 'react';

import { every } from 'lodash';
import moment from 'moment';

import QuoteDiscCalcMobile from 'components/common/DiamondListing/QuoteDisCalcMobile';

import { formatDecimal } from 'util/utils';

import { getParams, LISTINGPAGES, getStoneInfo } from './DiamondListFunctions';

import { DIAMOND_BID } from '../../constants/Common';
import { OFFERSTATUS } from '../Offer';
import Countdown from '../Offer/Timer';
import { formatNumber } from '../common/DiamondListing/SelectStone';
import { STATUS_COLOR } from '../common/DiamondListing/Status';

const MobileViewListCard = (props) => {
  return (
    <div
      className={`diamodListItemWrapper ${STATUS_COLOR[props.data.wSts] ? STATUS_COLOR[props.data.wSts] : 'green'} ${
        props.activeClass ? props.activeClass : null
      } ${props.quotePage ? 'quotePageWrapper' : null}`}
    >
      {!props.quotePage && (
        <>
          <div className="mobileListHeade" onClick={props.onClick}>
            <div className="mobileListHeadeBlock">
              {/* <a className="mobileListItem" href={'/' + LISTINGPAGES.DETAIL + '/' + props.data.id}> */}
              <div className="mobileListItem">
                <span>{props.data.vStnId}</span>
              </div>
              {/* </a> */}
              <div className="mobileListItem">
                <span>{props.data.shpNm}</span>
              </div>
              <div className="mobileListItem">
                <span>{formatDecimal(parseFloat(props.data.back).toFixed(2))}%</span>
              </div>
              <div className="mobileListItem themeColor">
                <span>{formatDecimal(parseFloat(props.data.ctPr).toFixed(2))} $/Cts</span>
              </div>
            </div>
          </div>
          <div className="mobileDiamondListBottom" onClick={props.onClick}>
            <div className="mobileListHeadeBlock">
              <div className="mobileListItem basicDetail">
                <span>
                  {getParams(props.data).join(' ')} {props.data.lbNm}
                </span>
              </div>
              <div className="mobileListItem">
                <span>{formatDecimal(parseFloat(props.data.crt).toFixed(2))}</span>
              </div>
              <div className="mobileListItem">
                <span>{props.data.msrmnt}</span>
              </div>
              <div className="mobileListItem totalPrice">
                <span>{formatDecimal(parseFloat(props.data.amt).toFixed(2))} $/Amt</span>
              </div>
            </div>
          </div>
        </>
      )}
      {props.quote && (
        <>
          <div className="d-flex j-space-between quoteExtraDetail">
            <div className="quoteExtraItem" onClick={() => props.quoteClick('hours', props.data)}>
              <span>Hours: </span>
              <QuoteDiscCalcMobile hours {...props.quotePopUp} original={props.data} />
            </div>
            <div className="quoteExtraItem">
              <div className="tableInput">
                <span>Final Offer(%): </span>
                <input
                  style={{ paddingLeft: Number(props.finalOfferOffer) > 0 ? 8 : 2 }}
                  value={props.finalOfferOffer}
                  onChange={(e) => props.onQuoteChange(e.target.value, props.data)}
                  onBlur={() => props.handleQuoteBlur(props.finalOfferOffer, props.data)}
                />
              </div>
            </div>
            <div className="quoteExtraItem">
              <div className="tableInput">
                <span>Final Price/Ct.: </span>
                <input
                  style={{ paddingLeft: Number(props.newPricePerCaratOffer) > 0 ? 8 : 2 }}
                  value={props.newPricePerCaratOffer}
                  onChange={(e) => props.handlePricePerCarat(e.target.value, props.data)}
                  onBlur={() => props.handleFloatInputBlur(props.newPricePerCaratOffer, props.data)}
                  disabled={!props.data.isFcCol && every(props.dataArray, { isFcCol: true })}
                />
              </div>
            </div>
          </div>
          <div className="d-flex j-space-between quoteExtraDetail">
            <div className="quoteExtraItem">
              <div className="tableInput">
                <span>Final Disc: </span>
                <input
                  style={{ paddingLeft: Number(props.calcDiscountOffer) > 0 ? 8 : 2 }}
                  value={props.calcDiscountOffer}
                  disabled={true}
                />
              </div>
            </div>
            <div className="quoteExtraItem">
              <div className="tableInput">
                <span>Final Rate: </span>
                <input
                  style={{ paddingLeft: Number(props.calcPricePerCaratOffer) > 0 ? 8 : 2 }}
                  value={props.calcPricePerCaratOffer}
                  disabled={true}
                />
              </div>
            </div>
            <div className="quoteExtraItem">
              <div className="tableInput">
                <span>Final Value: </span>
                <input
                  style={{ paddingLeft: Number(props.calcAmountOffer) > 0 ? 8 : 2 }}
                  value={props.calcAmountOffer}
                  disabled={true}
                />
              </div>
            </div>
          </div>
        </>
      )}
      {props.bid && (
        <div className="d-flex j-space-between quoteExtraDetail">
          <div className="quoteExtraItem">
            <span>Type: </span>
            <span>{props.data.bidType === DIAMOND_BID.TYPE.OPEN ? 'Look' : 'Blind'}</span>
          </div>
          <div className="quoteExtraItem">
            <span>Status: </span>
            <span>{props.data.status === DIAMOND_BID.STATUS.WIN ? 'Win' : 'Loss'}</span>
          </div>
        </div>
      )}
      {props.finalCalc && (
        <div className="d-flex j-space-between quoteExtraDetail">
          <div className="quoteExtraItem">
            <span>Final Disc%: </span>
            <span>{parseFloat(props.data.calcDiscount).toFixed(2)}</span>
          </div>
          <div className="quoteExtraItem">
            <span>Final Rate: </span>
            <span>${parseFloat(props.data.calcPricePerCarat).toFixed(2)}</span>
          </div>
          <div className="quoteExtraItem">
            <span>Final Value: </span>
            <span>${parseFloat(props.data.calcAmount).toFixed(2)}</span>
          </div>
        </div>
      )}
      {props.bidPopup && (
        <div className="d-flex j-space-between quoteExtraDetail">
          <div className="quoteExtraItem">{props.bidPopup.getBidPrCell(props.data)}</div>
          <div className="quoteExtraItem">
            <span>Bid Disc(%): </span>
            <span>{parseFloat(props.data.bidDiscount || 0).toFixed(2)}</span>
          </div>
          <div className="quoteExtraItem">
            <span>Bid Value: </span>
            <span>${parseFloat(props.data.bidAmount || 0).toFixed(2)}</span>
          </div>
        </div>
      )}
      {props.watchpopup && (
        <div className="d-flex j-space-between quoteExtraDetail">
          <div className="quoteExtraItem">
            <span>Exp. Disc(%): </span>
            <span>{props.watchpopup(props.data)}</span>
          </div>
        </div>
      )}
      {props.note && (
        <div className="d-flex j-space-between quoteExtraDetail">
          <div className="quoteExtraItem">
            <span>Notes: </span>
            <span>{props.data.remarks || '-'}</span>
          </div>
        </div>
      )}
      {props.quotePage && (
        <>
          <div className="mobileListHeade" onClick={props.onClick}>
            <div className="mobileListHeadeBlock">
              <div className="mobileListItem">
                <Countdown date={moment(props.data.offerValidDate)} />
              </div>
              <div className="mobileListItem">
                <span>
                  {(props.data.back ? parseFloat(props.data.back).toFixed(2) + ' / ' : '') +
                    parseFloat(props.data.ctPr).toFixed(0)}
                </span>
              </div>
              <div className="mobileListItem themeColor">
                <span>
                  {(props.data.newDiscount ? parseFloat(props.data.newDiscount).toFixed(2) + ' / ' : '') +
                    parseFloat(props.data.newPricePerCarat).toFixed(0)}
                </span>
              </div>
            </div>
          </div>
          <div className="mobileDiamondListBottom" onClick={props.onClick}>
            <div className="mobileListHeadeBlock">
              <div className="mobileListItem basicDetail">
                <span>{getStoneInfo(props.data).join(' ')}</span>
              </div>
              <div className="mobileListItem activeStatus pending">
                <span>{OFFERSTATUS[props.data.offerStatus]}</span>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default MobileViewListCard;
