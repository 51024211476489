import React from 'react';

import { classNames } from 'util/utils';
import './title.less';

export const ListHeading = (props) => {
  const { className, title, children, ...rest } = props;
  return (
    <h2 className={classNames(['dn-list-title', className])} {...rest}>
      {children ?? title}
    </h2>
  );
};
ListHeading.displayName = 'ListHeading';
