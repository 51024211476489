import React from 'react';

import { usePathname } from 'util/hooks';
import { catchError, classNames, logWarn } from 'util/utils';

import Styles from './TableGroupCollapse.module.less';

import { getColumn } from '../DiamondListFunctions';

function TableCollapse(props) {
  const { children, data, colSpan, columns: _columns, visible, currentType: parentType } = props;

  const pathname = usePathname();

  const trRef = React.useRef();
  const tdRef = React.useRef();
  const divRef = React.useRef();
  const columns = React.useMemo(() => _columns ?? getColumn(), [_columns]);
  const currentType = parentType ?? pathname;

  const setWidth = React.useCallback(() => {
    try {
      divRef.current.style.width = `${visible ? tdRef.current?.offsetWidth - 15 : 0}px`;
    } catch (error) {
      logWarn(error);
    }
  }, [visible]);

  React.useLayoutEffect(() => {
    setWidth();
    window.addEventListener('resize', setWidth);
    return () => {
      window.removeEventListener('resize', setWidth);
    };
  }, [setWidth]);

  return (
    <tr ref={trRef} className={classNames([Styles.Row, !visible && Styles.RowHidden])}>
      <td ref={tdRef} colSpan={colSpan} className={Styles.Cell}>
        <div ref={divRef} className="table-collapse">
          {children({ data, columns, currentType })}
        </div>
      </td>
    </tr>
  );
}

export default React.memo(TableCollapse);
