import { callApi } from 'util/call-api';

import { GET_DASHBOARD_DATA } from 'constants/apiConstant';

import { STATS_ACTIONS } from 'redux/reducers/stats';
import { store } from 'redux/store';

export class StatsService {
  static setStats(payload) {
    return store.dispatch(STATS_ACTIONS.set(payload));
  }

  static resetStats() {
    return store.dispatch(STATS_ACTIONS.reset());
  }

  static async setDashboardStats() {
    const [err, res] = await callApi({ ...GET_DASHBOARD_DATA, payload: { track: true, savedSearch: true } });
    err ? StatsService.resetStats() : StatsService.setStats({ dashboard: res?.data });
  }
}

const addGlobals = () => {
  window.__StatsService = StatsService;
};

addGlobals();
void module?.hot?.accept?.(['./StatsService.js'], () => addGlobals());
