import React from 'react';

import { DatePicker } from 'antd';
import moment from 'moment';

import './datepicker.less';
import IntlMessages from 'util/IntlMessages';

const Datepicker = (props) => {
  let groupClass = 'from-group datePicker';
  if (props.hasError) {
    groupClass = groupClass + ' error-message';
  }
  return (
    <div className={`${groupClass} ${props.className}`}>
      {props.label ? <label className="commonLabel">{props.label}</label> : null}
      <IntlMessages id="app.selectdate">
        {(placeholder) => (
          <DatePicker
            dropdownClassName="datePickerZindex"
            format="ll"
            placeholder={props.placeholder ? props.placeholder : placeholder}
            value={props.expiryDate ? moment(props.expiryDate) : undefined}
            onChange={props.handleChange}
            disabledDate={props.disabledDate}
          />
        )}
      </IntlMessages>
    </div>
  );
};
export default Datepicker;
