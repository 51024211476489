import React, { useState, useEffect, useReducer } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Checkbox } from 'antd';
import _, { join, flatten, map, compact, uniq, each } from 'lodash';

import SendEmailPopup from './SendEmail';

import { BASE_DOWNLOAD_URL, FILE_URLS, SHARE_OPTIONS } from '../../../constants/Common';
import { LISTINGPAGES, shareExcel } from '../../DiamondList/DiamondListFunctions';
import OpenNotification from '../CommonButton/OpenNotification';
import Heading from '../Heading';

const ShareOption = (props) => {
  return (
    <li>
      <div className="dropdownBottomItem">
        <div className="dropdownIconCheckBox">
          <Checkbox onChange={props.onChange} checked={props.checked}></Checkbox>
        </div>
        <span
          className="shareOptionLabel"
          // onChange={(e) => {}}
        >
          {props.title}
        </span>
      </div>
    </li>
  );
};

const MobileShareOption = (props) => {
  const [sendEmail, setSendEmail] = useState(false);
  const [visible, setVisibile] = useState(true);
  const [visible1, setVisibile1] = useState(true);
  const [visible2, setVisibile2] = useState(true);
  const [visible3, setVisibile3] = useState(true);
  const [totalChecked, setTotalChecked] = useState([]);
  // const [totalLabels, setTotalLabels] = useState([]);
  const [images, setImages] = useState(false);
  const [excels, setExcels] = useState(false);
  const [videos, setVideos] = useState(false);
  const [certificates, setCertificates] = useState(false);
  const [roughs, setRoughs] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const Options = {
    img: 'Ideal Image',
    naturalImage: 'Natural Image',
    videoFile: '360 Video',
    mp4Video: 'Natural Video',
    certFile: 'Certificate',
    hAFile: 'HA Image',
    idealWhiteImage: '',
    arrBlack: '',
    heartImage: '',
    flsImage: 'Fluorescence Image',
    pltFile: 'Plotting Image',
    propImage: 'Proportion Image',
    isExcel: 'Download Excel',
  };
  const ShareOption = (props) => {
    return (
      <li id={props.uiid} className={props.activeClass}>
        <div className="dropdownBottomItem">
          <div className="dropdownIconCheckBox">
            <Checkbox onChange={props.onChange} checked={props.checked}></Checkbox>
          </div>
          <span className="shareOptionLabel">{props.title}</span>
        </div>
      </li>
    );
  };

  const WhatsAppShare = () => {
    let totalTrue = 0;
    Object.values(totalChecked).forEach((x) => x === true && totalTrue++);
    if (totalTrue >= 1) {
      let links = ['SL Diamonds:'];
      const keys = Object.keys(totalChecked).filter((k) => totalChecked[k]);
      props.checked.forEach((i) => {
        links.push(`Stone ID :  ${i.vStnId}`);
        // if (totalTrue === totalChecked.length) {
        links.push(`Diamond Details: ${window.location.origin}/diamond-details/${i.id}`);
        // }
        const temp = {};
        keys.forEach((k) => {
          if (k === 'isExcel') {
            return;
          } else {
            temp[k] = `${Options[k]} ${FILE_URLS[k] && FILE_URLS[k].replaceAll('***', i.vStnId)}`;
          }
        });
        each(Options, (val, key) => {
          if (temp[key]) {
            if (['img', 'flsImage', 'pltFile', 'propImage', 'naturalImage'].includes(key) && i.img) {
              links.push(temp[key]);
            }
            if (['videoFile', 'mp4Video'].includes(key) && i.videoFile) {
              links.push(temp[key]);
            }
            if (['certFile'].includes(key) && i.certFile) {
              links.push(temp[key]);
            }

            if (['idealWhiteImage', 'hAFile', 'arrBlack', 'heartImage'].includes(key) && i.hAFile) {
              // if (!window.location.pathname.includes("/predefine-match-pair")) {
              //   links.push(temp[key]);
              // }
              if (['arrBlack', 'heartImage'].includes(key) && i.shpNm === 'ROUND') {
                links.push(temp[key]);
              } else if (['idealWhiteImage', 'hAFile'].includes(key)) {
                links.push(temp[key]);
              }
            }
          }
        });
      });
      keys.includes('isExcel')
        ? shareExcel(map(props.checked, 'id'), (path) => {
            links.push(`Download Excel: ${BASE_DOWNLOAD_URL}${path}`);
            if (links.length === 3) {
              return OpenNotification({
                type: 'error',
                title: 'Diamond resource not available !',
              });
            }
            links = uniq(links);
            window.open(`https://web.whatsapp.com/send?text=${join(links, '%0A%0A')}`);
          })
        : links.length === 3
        ? OpenNotification({
            type: 'error',
            title: 'Diamond resource not available !',
          })
        : window.open(`https://web.whatsapp.com/send?text=${join(links, '%0A%0A')}`);
      props.onClose();
    } else {
      OpenNotification({
        type: 'error',
        title: 'Please Select atleast 1 Option to Share.',
      });
    }
  };

  const SkypeShare = () => {
    let totalTrue = 0;
    Object.values(totalChecked).forEach((x) => x === true && totalTrue++);
    if (totalTrue >= 1) {
      let links = ['SL Diamonds:'];
      const keys = Object.keys(totalChecked).filter((k) => totalChecked[k]);
      props.checked.forEach((i) => {
        links.push(`Stone ID :  ${i.vStnId}`);
        // if (totalTrue === totalChecked.length) {
        links.push(`Diamond Details: ${window.location.origin}/diamond-details/${i.id}`);
        // }
        const temp = {};
        keys.forEach((k) => {
          if (k === 'isExcel') {
            return;
          } else {
            temp[k] = `${Options[k]} ${FILE_URLS[k] && FILE_URLS[k].replaceAll('***', i.vStnId)}`;
          }
        });
        each(Options, (val, key) => {
          if (temp[key]) {
            if (['img', 'flsImage', 'pltFile', 'propImage', 'naturalImage'].includes(key) && i.img) {
              links.push(temp[key]);
            }
            if (['videoFile', 'mp4Video'].includes(key) && i.videoFile) {
              links.push(temp[key]);
            }
            if (['certFile'].includes(key) && i.certFile) {
              links.push(temp[key]);
            }

            if (['idealWhiteImage', 'hAFile', 'arrBlack', 'heartImage'].includes(key) && i.hAFile) {
              // if (!window.location.pathname.includes("/predefine-match-pair")) {
              //   links.push(temp[key]);
              // }
              if (['arrBlack', 'heartImage'].includes(key) && i.shpNm === 'ROUND') {
                links.push(temp[key]);
              } else if (['idealWhiteImage', 'hAFile'].includes(key)) {
                links.push(temp[key]);
              }
            }
          }
        });
      });
      keys.includes('isExcel')
        ? shareExcel(map(props.checked, 'id'), (path) => {
            links.push(`Download Excel: ${BASE_DOWNLOAD_URL}${path}`);
            if (links.length === 3) {
              return OpenNotification({
                type: 'error',
                title: 'Diamond resource not available !',
              });
            }
            links = uniq(links);
            window.open(`https://web.skype.com/share?url=${join(links, '%0A%0A')}`);
          })
        : links.length === 3
        ? OpenNotification({
            type: 'error',
            title: 'Diamond resource not available !',
          })
        : window.open(`https://web.skype.com/share?url=${join(links, '%0A%0A')}`);
      props.onClose();
    } else {
      OpenNotification({
        type: 'error',
        title: 'Please Select atleast 1 Option to Share.',
      });
    }
  };

  useEffect(() => {
    if (SHARE_OPTIONS.Images) {
      const mappedImagesKeys = _.map(SHARE_OPTIONS.Images.subLevel, 'key');
      Object.keys(totalChecked).forEach((v) => {
        mappedImagesKeys.includes(v) && (totalChecked[v] = images);
      });
      forceUpdate();
    }
  }, [images]);

  useEffect(() => {
    if (SHARE_OPTIONS.Videos) {
      const mappedVideosKeys = _.map(SHARE_OPTIONS.Videos.subLevel, 'key');
      Object.keys(totalChecked).forEach((v) => {
        mappedVideosKeys.includes(v) && (totalChecked[v] = videos);
      });
      forceUpdate();
    }
  }, [videos]);

  useEffect(() => {
    if (SHARE_OPTIONS.Roughs) {
      const mappedCertificatesKeys = _.map(SHARE_OPTIONS.Certificates.subLevel, 'key');
      Object.keys(totalChecked).forEach((v) => {
        mappedCertificatesKeys.includes(v) && (totalChecked[v] = certificates);
      });
      forceUpdate();
    }
  }, [certificates]);

  useEffect(() => {
    if (SHARE_OPTIONS.Roughs) {
      const mappedRoughsKeys = _.map(SHARE_OPTIONS.Roughs.subLevel, 'key');
      Object.keys(totalChecked).forEach((v) => {
        mappedRoughsKeys.includes(v) && (totalChecked[v] = roughs);
      });
      forceUpdate();
    }
  }, [roughs]);

  useEffect(() => {
    if (SHARE_OPTIONS.Excels) {
      const mappedExcelssKeys = _.map(SHARE_OPTIONS.Excels.subLevel, 'key');
      Object.keys(totalChecked).forEach((v) => {
        mappedExcelssKeys.includes(v) && (totalChecked[v] = excels);
      });
      forceUpdate();
    }
  }, [excels]);

  const getAllKeys = () => {
    const keys = _.uniq(
      _.flattenDeep(
        _.map(SHARE_OPTIONS, (val, key) => {
          let tmp = [];
          if (val && val.key) {
            tmp.push(val.key);
          }
          if (val && val.subLevel && val.subLevel.length) {
            tmp = _.concat(tmp, _.map(val.subLevel, 'key'));
          }
          return tmp;
        }),
      ),
    );
    return keys;
  };

  const getAllLabels = () => {
    const keys = _.uniq(
      _.flattenDeep(
        _.map(SHARE_OPTIONS, (val, key) => {
          let tmp = [];
          if (val && val.key) {
            tmp.push(val.key);
          }
          if (val && val.subLevel && val.subLevel.length) {
            tmp = _.concat(tmp, _.map(val.subLevel, 'label'));
          }
          return tmp;
        }),
      ),
    );
    return keys;
  };

  useEffect(() => {
    const definedKeys = getAllKeys();
    definedKeys.forEach((key) => {
      setTotalChecked((totalChecked) => ({
        ...totalChecked,
        [key]: false,
      }));
    });

    // let definedLabels = getAllLabels();
    // definedLabels.forEach((key) => {
    //   setTotalLabels((totalLabels) => ({
    //     ...totalLabels,
    //     [key]: [key],
    //   }));
    // });
  }, []);

  useEffect(() => {
    const definedKeys = getAllKeys();
    definedKeys.forEach((key) => {
      setTotalChecked((totalChecked) => ({
        ...totalChecked,
        [key]: selectAll,
      }));
    });
    setImages(selectAll);
    setVideos(selectAll);
    setCertificates(selectAll);
    setExcels(selectAll);
  }, [selectAll]);

  const hASelectionHandling = () => {
    if (totalChecked && totalChecked.hAFile) {
      totalChecked.idealWhiteImage = true;
      totalChecked.arrBlack = true;
      totalChecked.heartImage = true;
    } else {
      totalChecked.idealWhiteImage = false;
      totalChecked.hAFile = false;
      totalChecked.arrBlack = false;
      totalChecked.heartImage = false;
    }
  };
  useEffect(() => {
    hASelectionHandling();
  }, [totalChecked.hAFile]);

  return (
    <div>
      <Heading title="Share Stone" className="p-0" />
      <div className="">
        <div className="shareStoneMobileItem border-bottom-none">
          <div className="shareStoneMobileHead">
            <span className="shareDropdownMobileHead d-flex">
              <div className="dropdownIconCheckBox">
                <Checkbox
                  checked={images}
                  onChange={() => {
                    setImages(!images);
                    hASelectionHandling();
                  }}
                ></Checkbox>
              </div>
              {SHARE_OPTIONS.Images.label}
            </span>
            {/* <span onClick={() => setVisibile(!visible)}>
              {visible ? (
                <img src={require('../../../assets/svg/Search/down-arrow.svg')} alt="" />
              ) : (
                <img src={require('../../../assets/svg/Search/up-arrow.svg')} alt="" />
              )}
            </span> */}
          </div>
          {visible && (
            <div className="mobileShareOption">
              {SHARE_OPTIONS.Images.subLevel.map((record) => (
                <ShareOption
                  title={record.label}
                  activeClass={totalChecked[record.key] ? 'active' : ''}
                  checked={totalChecked[record.key]}
                  onChange={(e) => {
                    setTotalChecked({
                      ...totalChecked,
                      [record.key]: e.target.checked,
                    });
                  }}
                />
              ))}
            </div>
          )}
        </div>
        {/* <div className="shareStoneMobileItem">
          <div className="shareStoneMobileHead">
            <span className="shareDropdownMobileHead d-flex">
              <div className="dropdownIconCheckBox">
                <Checkbox
                  checked={videos}
                  onChange={() => {
                    setVideos(!videos);
                  }}
                ></Checkbox>
              </div>
              {SHARE_OPTIONS.Videos.label}
            </span>
            <span onClick={() => setVisibile1(!visible1)}>
              {visible1 ? (
                <img src={require('../../../assets/svg/Search/down-arrow.svg')} alt="" />
              ) : (
                <img src={require('../../../assets/svg/Search/up-arrow.svg')} alt="" />
              )}
            </span>
          </div>
          {visible1 && (
            <div className="mobileShareOption">
              {SHARE_OPTIONS.Videos.subLevel.map((record) => (
                <ShareOption
                  title={record.label}
                  activeClass={totalChecked[record.key] ? 'active' : ''}
                  checked={totalChecked[record.key]}
                  onChange={(e) => {
                    setTotalChecked({
                      ...totalChecked,
                      [record.key]: e.target.checked,
                    });
                  }}
                />
              ))}
            </div>
          )}
        </div> */}
        {/* <div className="shareStoneMobileItem">
          <div className="shareStoneMobileHead">
            <span className="shareDropdownMobileHead d-flex">
              <div className="dropdownIconCheckBox">
                {/* <Checkbox checked={certificates} onChange={() => setCertificates(!certificates)}></Checkbox> */}
        {/* </div>
              Certificates
            </span>
            <span onClick={() => setVisibile2(!visible2)}>
              {visible2 ? (
                <img src={require('../../../assets/svg/Search/down-arrow.svg')} alt="" />
              ) : (
                <img src={require('../../../assets/svg/Search/up-arrow.svg')} alt="" />
              )}
            </span>
          </div> */}
        {/*
          {visible2 && (
            <div className="mobileShareOption">
              <ShareOption
                title={SHARE_OPTIONS.Certificates?.label}
                activeClass={totalChecked[SHARE_OPTIONS.Certificates?.key] ? 'active' : ''}
                checked={totalChecked[SHARE_OPTIONS.Certificates?.key]}
                onChange={(e) => {
                  setTotalChecked({
                    ...totalChecked,
                    [SHARE_OPTIONS.Certificates?.key]: e.target.checked,
                  });
                }}
              />
            </div>
          )}
        </div> */}
        {/* <div className="shareStoneMobileItem"> */}
        {/* <div className="shareStoneMobileHead"> */}
        {/* <span className="shareDropdownMobileHead d-flex">
              <div className="dropdownIconCheckBox">{/* <Checkbox></Checkbox> */}
        {/* </div>
              Excel
            </span>  */}
        {/* <span onClick={() => setVisibile3(!visible3)}>
              {visible3 ? (
                <img src={require('../../../assets/svg/Search/down-arrow.svg')} alt="" />
              ) : (
                <img src={require('../../../assets/svg/Search/up-arrow.svg')} alt="" />
              )}
            </span> */}
        {/* </div> */}
        {/* {visible3 && (
            <div className="mobileShareOption">
              <ShareOption
                title={SHARE_OPTIONS.Excels?.label}
                activeClass={totalChecked[SHARE_OPTIONS.Excels?.key] ? 'active' : ''}
                checked={totalChecked[SHARE_OPTIONS.Excels?.key]}
                onChange={(e) => {
                  setTotalChecked({
                    ...totalChecked,
                    [SHARE_OPTIONS.Excels?.key]: e.target.checked,
                  });
                }}
              />
            </div>
          )} */}
        {/* </div> */}
      </div>
      <div className="sideBarPopupButton">
        <a onClick={() => WhatsAppShare()} className="commonButton">
          WhatsApp
        </a>
        <a onClick={() => SkypeShare()} className="commonButton">
          Skype
        </a>
        <a
          onClick={() => {
            let totalTrue = 0;
            Object.values(totalChecked).forEach((x) => x === true && totalTrue++);
            if (totalTrue >= 1) {
              setSendEmail(true);
            } else {
              OpenNotification({
                type: 'error',
                title: 'Please Select atleast 1 Option to Share.',
              });
            }
          }}
          className="commonButton"
        >
          Email
        </a>
      </div>
      <SendEmailPopup
        sendEmail={sendEmail}
        onClose={props.onClose}
        onCancel={() => setSendEmail(false)}
        ids={map(props.checked, 'id')}
        totalChecked={totalChecked}
        orderDiamond={props.order ? compact(uniq(flatten(map(props.checked, 'memoDetailId')))) : []}
      />
    </div>
  );
};
export default MobileShareOption;
