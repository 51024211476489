import React from 'react';

import { useIsMobile } from 'util/hooks';

export function withMobile(WrappedComponent) {
  return function WithMobile(props) {
    const [isMobile, isTablet] = useIsMobile();
    return <WrappedComponent {...props} isMobile={isMobile} isTablet={isTablet} />;
  };
}
withMobile.displayName = 'withMobile';
