export class EventManager {
  static _handleUpdate(key, callback) {
    return (event) => {
      callback(event.detail);
    };
  }

  static emit(name, detail = {}) {
    const event = new CustomEvent(name, { detail });
    window.dispatchEvent(event);
  }

  static listen(key, callback) {
    window.addEventListener(key, EventManager._handleUpdate(key, callback));
    return () => {
      window.removeEventListener(
        key,
        EventManager._handleUpdate(key, callback)
      );
    };
  }
}

export const eventBus = {
  on(event, callback) {
    document.addEventListener(event, callback);
  },
  dispatch(event, data) {
    document.dispatchEvent(new CustomEvent(event, data));
  },
  off(event, callback) {
    document.removeEventListener(event, () => {});
  },
};
