// 👏
const initialState = {
  seconds: 30,
  isDisabled : true
}
const setTimer = (state = initialState,  action) => {
  if (action.type === 'DEC'){
      if( state.seconds === 1){
          return{
              isDisabled: false,
              seconds: 0
          }
      }else if( state.seconds > 0){
          return{
              seconds: state.seconds - 1,
              isDisabled : true
          }}
  }
  return state;
};
export default setTimer;