export const DETAIL_VIEW_AUTH = {
  section1: {
    name: 'BasicDetail',
    data: [
      { part: 1, label: 'Packet No', key: 'vStnId', alt: '-' },
      { part: 1, label: 'Report No', key: 'rptNo', alt: '-' },
      { part: 1, label: 'Shape', key: 'shpNm', alt: '-' },
      { part: 1, label: 'Cut', key: 'cutNm', alt: '-' },
      { part: 1, label: 'Carat', key: 'crt', alt: '-' },
      { part: 1, label: 'Polish', key: 'polNm', alt: '-' },
      { part: 1, label: 'Color', key: 'colNm', alt: '-' },
      { part: 1, label: 'Symmetry', key: 'symNm', alt: '-' },
      { part: 1, label: 'Clarity', key: 'clrNm', alt: '-' },
      { part: 1, label: 'Fluorescence', key: 'fluNm', alt: '-' },
      { part: 1, label: 'Lab', key: 'lbNm', alt: '-' },
      { part: 1, label: 'Origin', key: 'org', alt: '-' },
      // { part: 2, label: <IntlMessage id="app.Shade" />, key: 'shdNm' },
    ],
  },
  section2: {
    name: 'Pricing details',
    data: [
      { label: 'Rap.($)', key: 'rap', alt: '-' },
      { label: 'Discount', key: 'back', alt: '-' },
      { label: 'Pr/Cr', key: 'ctPr', alt: '-' },
      { label: 'Amount', key: 'amt', alt: '-' },
    ],
  },
  section3: {
    name: 'Measurements',
    data: [
      { label: 'Length', key: 'length', alt: '-' },
      { label: 'Ratio', key: 'ratio', alt: '-' },
      { label: 'Width', key: 'width', alt: '-' },
      { label: 'Pav Angle', key: 'pAng', alt: '-' },
      { label: 'Height', key: 'height', alt: '-' },
      { label: 'Pav Height', key: 'pHgt', alt: '-' },
      { label: 'Table %', key: 'tblPer', alt: '-' },
      { label: 'Crown Angle', key: 'cAng', alt: '-' },
      { label: 'Depth %', key: 'depPer', alt: '-' },
      { label: 'Crown Height', key: 'cHgt', alt: '-' },
      { label: 'Girdle', key: 'girdleStr', alt: '-' },
      { label: 'Culet', key: 'cultNm', alt: '-' },
    ],
  },

  section4: {
    name: 'B G M Details',
    data: [
      { label: 'Brown Tinge', key: 'shdNm', alt: 'NONE' },
      { label: 'Mix Tinge', key: 'shdNm', alt: 'NONE' },
      { label: 'Milky', key: 'mlkNm', alt: 'NONE' },
      { label: 'Other Tinge', key: 'shdNm', alt: 'NONE' },
    ],
  },
  section5: {
    name: 'Other Details',
    data: [
      { label: 'Laser Ins', key: 'lsrInc', alt: 'NONE' },
      { label: 'Extra Facet', key: 'eFact', alt: 'NONE' },
      { label: 'Heart & Arrow', key: 'hANm', alt: 'NONE' },
      { label: 'Eye Clean', key: 'eClnNm', alt: 'NONE' },
      // { label: <IntlMessage id="app.keyToS" />, key: 'kToSStr' },
      // { label: <IntlMessage id="app.ReportComments" />, key: 'lbCmt' },
    ],
  },
  section6: {
    name: 'Inclusion',
    data: [
      { label: 'Table Black', key: 'blkTblNm', alt: '-' },
      { label: 'Table Open', key: 'opTblNm', alt: '-' },
      { label: 'Side Black', key: 'blkSdNm', alt: '-' },
      { label: 'Crown Open', key: 'opCrwnNm', alt: '-' },
      { label: 'Table Inclusion', key: 'wTblNm', alt: '-' },
      { label: 'Pavilion Open', key: 'opPavNm', alt: '-' },
      { label: 'Side Inclusion', key: 'wSdNm', alt: '-' },
      { label: 'Girdle Open', key: 'opGrdNm', alt: 'NONE' },
      { label: 'Key To Symbol', key: 'kToSStr', alt: '-' },
      { label: 'Lab Comments', key: 'lbCmt', alt: '-' },
      // { label: <IntlMessage id="app.Brilliancy" />, key: 'brlncyNm' },
      // { label: <IntlMessage id="app.T2CERT" />, key: 'type2' },
      // { label: <IntlMessage id="app.diamonddetails.Inclusion.luster" />, key: 'lstr' },
    ],
  },
};

export default DETAIL_VIEW_AUTH;
