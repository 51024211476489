import debounce from 'lodash/debounce';
import each from 'lodash/each';
import split from 'lodash/split';
import trim from 'lodash/trim';
import uniq from 'lodash/uniq';

import { callApi } from 'util/call-api';
import { notify } from 'util/notify';
import { catchError, isArray, isEmpty, pruneEmpty } from 'util/utils';

import {
  SEARCH_DIAMOND_LIST,
  SEARCH_LIST,
  CREATE_SAVED_SEARCH,
  CREATE_MATCH_PAIR,
  SELECT_SEARCH,
} from 'constants/apiConstant';

import UtilService from '../services/util';

export const SEARCH_PAGES = {
  SEARCH: '/diamond-search',
  MATCH_PAIR: '/search-match-pair',
  SEARCH_LAYOUT: '/search-layout',
};

export const handleNewArrival = async (filter, cb) => {
  // let apiObj = { ...filter, layoutNo: { in: ["0", ""] } };
  const apiObj = { ...filter };
  each(apiObj, (val, key) => {
    if (isArray(val) && val.length === 0) delete apiObj[key];
    //else if (isArray(val) && isEmpty(val)) delete apiObj[key];
    else if (isArray(val)) apiObj[key] = val;
  });

  const obj = {
    filters: [apiObj],
    viewType: 2,
    isNotReturnTotal: true,
    isReturnCountOnly: true,
  };

  const objData = {
    ...SEARCH_DIAMOND_LIST,
    request: obj,
  };

  UtilService.callApi(objData, (err, data) => {
    if (err) {
      if (err.data && err.data.message) {
        return notify.error({ message: err.data.message });
      }
    } else if (data && data.code === 'OK') {
      if (data.data[0].count === 0) {
        cb({ NOSTONE: 'NOSTONE', id: data.data[0].filter.id });
      } else {
        cb({ NOSTONE: '', id: data.data[0].filter.id });
      }
    }
  });
};

export const handleUpcoming = async (filter, cb) => {
  // let apiObj = { ...filter, layoutNo: { in: ["0", ""] } };
  const apiObj = { ...filter };
  each(apiObj, (val, key) => {
    if (isArray(val) && val.length === 0) delete apiObj[key];
    //else if (isArray(val) && isEmpty(val)) delete apiObj[key];
    else if (isArray(val)) apiObj[key] = val;
  });

  const obj = {
    filters: [apiObj],
    isNotReturnTotal: true,
    isReturnCountOnly: true,
  };

  const objData = {
    ...SEARCH_DIAMOND_LIST,
    request: obj,
  };

  UtilService.callApi(objData, (err, data) => {
    if (err) {
      if (err.data && err.data.message) {
        return notify.error({ message: err.data.message });
      }
    } else if (data && data.code === 'OK') {
      if (data.data[0].count === 0) {
        cb({ NOSTONE: 'NOSTONE', id: data.data[0].filter.id });
      } else {
        cb({ NOSTONE: '', id: data.data[0].filter.id });
      }
    }
  });
};

export const handleSearch = async (filter, newArr, cb) => {
  const apiObj = pruneEmpty(filter);

  each(apiObj, (val, key) => {
    if (isArray(val)) {
      void (isEmpty(val) ? delete apiObj[key] : (apiObj[key] = val));
    }
  });

  const obj = {
    filters: [apiObj],
    isNotReturnTotal: true,
    isReturnCountOnly: true,
  };

  if (newArr) obj.viewType = 2;

  const objData = {
    ...SEARCH_DIAMOND_LIST,
    request: obj,
  };

  UtilService.callApi(objData, (err, res) => {
    if (err) {
      if (err.data && err.data.message) {
        return notify.error({ message: err.data.message });
      }
    } else if (res && res.code === 'OK') {
      if (res.data[0].count === 0) {
        cb({ NOSTONE: 'NOSTONE', id: res.data[0].filter.id, count: res.data[0].count });
      } else {
        cb({ NOSTONE: '', id: res.data[0].filter.id, count: res.data[0].count });
      }
    }
  });
};

export const getSearchList = (cb) => {
  const obj = {
    type: 2,
    isAppendMasters: true,
  };
  const api = SEARCH_LIST.url;
  const objData = {
    method: SEARCH_LIST.method,
    url: api,
    request: obj,
  };
  UtilService.callApi(objData, (err, data) => {
    if (err) throw err;
    if (data && data.code === 'OK') {
      cb(data.data.list);
    }
  });
};

export const handleSavedSearch = (filter, title, id, cb) => {
  const apiObj = filter;
  each(apiObj, (val, key) => {
    if (isArray(val) && val.length === 0) delete apiObj[key];
    //else if (isEmpty(val)) delete apiObj[key];
    else if (isArray(val)) apiObj[key] = uniq(val);
  });

  if (trim(title) === '') {
    return notify.error({ message: 'Please enter search title.' });
  }

  const obj = {
    filter: apiObj,
    name: title,
    searchType: 2,
    sort: [{ createdAt: 'DESC' }],
    id: id,
  };

  const api = CREATE_SAVED_SEARCH.url;
  const objData = {
    method: CREATE_SAVED_SEARCH.method,
    url: api,
    request: obj,
  };

  UtilService.callApi(objData, (err, data) => {
    if (err) {
      notify.error({ message: data.data.message || 'Something went wrong.' });
    } else if (data && data.code === 'OK') {
      cb(data.data.id);
    }
  });
};

export const handleMatchPair = async (filter, cb) => {
  filter = { ...filter };

  Object.entries(filter).forEach(([key, val]) => {
    if (isArray(val)) isEmpty(val) ? delete filter[key] : void (filter[key] = val);
  });

  const [err, res] = await callApi({
    ...CREATE_MATCH_PAIR,
    request: { filter, page: 1 },
    // request: { filter, page: 1, limit: 1000, isPredefinedPair: true },
  });
  void cb?.(res?.data?.filter?.id);

  if (err?.data?.message) notify.error({ message: err.data.message });
  if (res?.code !== 'OK') notify.error({ message: res.data.message });
  return !err && res?.code === 'OK' ? res?.data?.filter?.id : undefined;
};

export const handleLayoutSearch = (filter, cb) => {
  const apiObj = filter;
  each(apiObj, (val, key) => {
    if (isArray(val) && val.length === 0) delete apiObj[key];
    //else if (isEmpty(val)) delete apiObj[key];
    else if (isArray(val)) apiObj[key] = val;
  });
  const obj = {
    filter: apiObj,
    page: 1,
    limit: 1000,
    isLayout: true,
    isPredefinedPair: true,
  };
  const api = CREATE_MATCH_PAIR.url;
  const objData = {
    method: CREATE_MATCH_PAIR.method,
    url: api,
    request: obj,
  };

  UtilService.callApi(objData, (err, data) => {
    if (err && err.data && err.data.message) {
      return notify.error({ message: err.data.message });
    } else if (data && data.code === 'OK') {
      cb(data.data);
    }
  });
};

export const handleSelectSearch = (searchId, cb) => {
  return UtilService.callApi({ ...SELECT_SEARCH, request: { id: searchId, isAppendMasters: true } }, (err, res) => {
    catchError(() => cb?.(res.data?.list?.[0]?.searchData, res.data?.list?.[0]?.name, res.data?.list?.[0]?.type));
  });
};

// To get match pair by PairStkNo
export const getPairByPairStkNo = (pairStkNo, cb) => {
  const obj = {
    isNotReturnTotal: true,
    isReturnCountOnly: true,
    isSkipSave: false,
    // isPredefinedPair: true,
    filter: { or: [{ pairStkNo: [pairStkNo] }] },
  };

  const api = CREATE_MATCH_PAIR.url;
  const objData = {
    method: CREATE_MATCH_PAIR.method,
    url: api,
    request: obj,
  };
  UtilService.callApi(objData, (err, data) => {
    if (err) throw err;
    if (data && data.code === 'OK') {
      cb(data.data && data.data.filter.id);
    }
  });
};

export const getCount = async (filter, cb, isSearchUpcoming = false, upComingCb) => {
  const PATH = `/${split(window.location.pathname, '/')[1]}`;

  each(filter, (val, key) => {
    if (isArray(val) && val.length === 0) delete filter[key];
    else if (isArray(val)) filter[key] = uniq(val);
  });
  if (PATH === SEARCH_PAGES.SEARCH) {
    const { excludeFilter, ...rest } = filter;
    filter = JSON.stringify(filter.excludeFilter) === '{}' ? { ...rest } : filter;
    // filter = { ...filter, layoutNo: { in: ["0", ""] } };
  } else if (PATH === SEARCH_PAGES.MATCH_PAIR) {
    filter = { ...filter };
  } else if (PATH === SEARCH_PAGES.SEARCH_LAYOUT) {
    filter = { layoutNo: { nin: ['', '0'] }, ...filter };
  }

  const obj = {
    isNotReturnTotal: true,
    isReturnCountOnly: true,
    isSkipSave: true,
    filters: [filter],
  };
  if (isSearchUpcoming) {
    obj.isSearchUpcoming = true;
  }

  const api = SEARCH_DIAMOND_LIST.url;
  const objData = {
    method: SEARCH_DIAMOND_LIST.method,
    url: api,
    request: obj,
  };

  return new Promise((resolve) => {
    UtilService.callApi(objData, (err, res) => {
      if (err) throw err;
      if (res && res.code === 'OK') {
        cb(res.data[0].count);
        if (res.data[0].upcomingDiamondCount) upComingCb(res.data[0].upcomingDiamondCount);
        resolve(res.data?.[0]?.count, res.data?.[0]?.upcomingDiamondCount);
      }
      resolve(false);
    });
  });
};

export const getCountDebounced = debounce(getCount, 320);

export const handleAddDemand = (filter, title, date, cb) => {
  const apiObj = filter;
  each(apiObj, (val, key) => {
    if (isArray(val) && val.length === 0) {
      delete apiObj[key];
    } else if (isArray(val)) {
      apiObj[key] = uniq(val);
    }
  });

  if (trim(title) === '') {
    return notify.error({ message: 'Please enter demand title.' });
  }

  if (!date) {
    return notify.error({ message: 'Please select date for adding demand.' });
  }

  const obj = {
    filter: apiObj,
    name: title,
    searchType: 3,
    expiryDate: date,
  };

  const api = CREATE_SAVED_SEARCH.url;
  const objData = {
    method: CREATE_SAVED_SEARCH.method,
    url: api,
    request: obj,
  };

  UtilService.callApi(objData, (err, data) => {
    if (err) {
      notify.error({ message: data.data.message || 'Something went wrong.' });
    } else if (data && data.code === 'OK') {
      cb(true);
      notify.success({ message: 'Your demand is added successfully.' });
    }
  });
};
export const handleAddOrderRequest = (filter, object, cb) => {
  // if (isArray(object.images) && object.images.length === 0) {
  //   return notify.error({ message: 'Please upload image.' });
  // }
  if (!object.orderName) {
    return notify.error({ message: 'Please enter order name.' });
  }
  if (!object.budgetFrom) {
    return notify.error({ message: 'Please enter budget from.' });
  }
  if (!object.budgetTo) {
    return notify.error({ message: 'Please enter budget to.' });
  }

  if (!object.expiryDate) {
    return notify.error({ message: 'Please enter time limit.' });
  }
  if (!object.description) {
    return notify.error({ message: 'Please enter message.' });
  }
  const apiObj = filter;
  each(apiObj, (val, key) => {
    if (isArray(val) && val.length === 0) delete apiObj[key];
    //else if (isEmpty(val)) delete apiObj[key];
    else if (isArray(val)) apiObj[key] = uniq(val);
  });

  const obj = {
    filter: apiObj,
    searchType: 14,
    ...object,
    // budgetFrom:
    //   expiryDate: date,
  };

  const api = CREATE_SAVED_SEARCH.url;
  const objData = {
    method: CREATE_SAVED_SEARCH.method,
    url: api,
    request: obj,
  };

  UtilService.callApi(objData, (err, data) => {
    if (err) {
      notify.error({ message: err.data.message || 'Something went wrong.' });
    } else if (data && data.code === 'OK') {
      cb(true);
      notify.success({ message: 'Your order request is added successfully.' });
    }
  });
};

export const handleMultiSearch = (list, cb) => {
  const filters = list.map((filter) => {
    let apiObj = { ...filter };
    if (!isEmpty(filter.isFm)) {
      apiObj = { ...apiObj, lbNm: 'FM' };
    }

    if (filter.wSts?.includes?.('B')) {
      delete apiObj['prcStg'];
      apiObj = { ...apiObj };
    }

    each(apiObj, (val, key) => {
      if (isArray(val) && val.length === 0) delete apiObj[key];
      else if (isArray(val)) apiObj[key] = val;
    });
    return apiObj;
  });

  const objData = {
    ...SEARCH_DIAMOND_LIST,
    request: {
      filters,
      isNotReturnTotal: true,
      isReturnCountOnly: true,
    },
  };

  UtilService.callApi(objData, (err, data) => {
    if (err) {
      if (err.data && err.data.message) {
        return notify.error({ message: err.data.message });
      }
    } else if (data && data.code === 'OK') {
      const ids = [];
      data.data.map((d) => ids.push(d.filter.id));
      cb({ ids });
    }
  });
};
