import { isEmpty } from 'lodash';

import { updateList } from 'components/DiamondList/table-utils';

import { isArray, isObject, sortArrayByKey } from 'util/utils';

const initialState = {
  list: [],
  map: {},
};

export const ViewedStonesActionTypes = {
  Add: '@viewed-stones/add',
  Remove: '@viewed-stones/remove',
  Set: '@viewed-stones/set',
  Reset: '@viewed-stones/reset',
};

export const ViewedStonesActions = {
  add: (payload) => ({ type: ViewedStonesActionTypes.Add, payload }),
  remove: (payload) => ({ type: ViewedStonesActionTypes.Remove, payload }),
  set: (payload) => ({ type: ViewedStonesActionTypes.Set, payload }),
  reset: () => ({ type: ViewedStonesActionTypes.Reset }),
};

const getFormattedPayload = (payload) => {
  const list = updateList(isArray(payload) ? payload : isObject(payload) ? [payload] : []).filter(
    (record) => !isEmpty(record?.vStnId),
  );
  const map = Object.fromEntries(list?.map((record) => [record.vStnId, record]));
  return { list, map };
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ViewedStonesActionTypes.Add: {
      const { map } = getFormattedPayload(payload);
      const newMap = { ...state.map, ...map };
      const newList = Object.values(newMap).sort(sortArrayByKey('__updateTs__', true));
      return { ...state, list: newList, map: newMap };
    }

    case ViewedStonesActionTypes.Remove: {
      const { list } = getFormattedPayload(payload);
      const newMap = { ...state.map };
      list.forEach((record) => delete newMap[record?.vStnId]);
      const newList = Object.values(newMap);
      return { ...state, list: newList, map: newMap };
    }

    case ViewedStonesActionTypes.Set: {
      const { list, map } = getFormattedPayload(payload);
      return { ...state, list, map };
    }

    case '@@RESET':
    case ViewedStonesActionTypes.Reset:
      return { ...initialState };

    default:
      return state;
  }
};
