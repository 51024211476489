import React from 'react';

import { get } from 'lodash';

import IntlMessages from 'util/IntlMessages';
import { Menu } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';

export default function getData(USER_PERMIT) {
  const sidebarArr = [];

  if (get(USER_PERMIT, 'SEARCH_DIAMOND.view', false))
    sidebarArr.push({ name: <IntlMessages id="app.Search" />, url: '/diamond-search', subMenu: false });

  if (get(USER_PERMIT, 'SEARCH_MATCH_PAIR.view', false))
    sidebarArr.push({ name: 'Search Match Pair', url: '/search-match-pair', subMenu: false });

  // if (get(USER_PERMIT, 'SEARCH_LAYOUT.view', false))
  //   sidebarArr.push({ name: 'Search Layout', url: '/search-layout', subMenu3: false });

  // if (get(USER_PERMIT, 'BID.view', false))
  //   sidebarArr.push({ name: 'Bid It', url: '/new-arrival-bid-it', subMenu3: false });

  if (get(USER_PERMIT, 'EXCLUSIVE.view', false)) sidebarArr.push({ name: 'SL Exclusive', url: '/SL-exclusive' });

  // if (get(USER_PERMIT, 'DEMO.view', false))
  sidebarArr.push({ name: <IntlMessages id="app.More" />, subMenu: true });

  // <Menu onClick={this.handleClick} selectedKeys={1} mode="horizontal">
  //   <SubMenu key="SubMenu" title="Demo" className="singal-submenu">
  //     <Menu.ItemGroup>
  //       <Menu.Item key="setting:1">
  //         <a>Option 1</a>
  //       </Menu.Item>
  //       <Menu.Item key="setting:2">
  //         <a>Option 2</a>
  //       </Menu.Item>
  //       <Menu.Item key="setting:3">
  //         <a>Option3</a>
  //       </Menu.Item>
  //       <Menu.Item key="setting:4">
  //         <a>Option 4</a>
  //       </Menu.Item>
  //     </Menu.ItemGroup>
  //   </SubMenu>
  // </Menu>;
  // if (get(USER_PERMIT, 'EXCLUSIVE.view', false))
  // sidebarArr.push({ name: 'Stone of the Day', url: '/feature-stone' });

  // sidebarArr.push({ name: <IntlMessages id="app.fancy-diamonds" />, url: '/fancy-search', subMenu1: true });
  // sidebarArr.push({ name: <IntlMessages id="app.Upcoming" />, url: '/upcoming', subMenu4: false });
  // sidebarArr.push({ name: <IntlMessages id="app.myAccount" />, url: '/account/profile', subMenu4: false });
  // sidebarArr.push({ name: <IntlMessages id="app.Exclusive" />, url: '/diamond-search', subMenu2: true });
  return sidebarArr;
}
