import memoize from 'lodash/lodash';

export {
  LOCALE,
  CURRENCY,
  TIMEZONE_IANA,
  LUXON_FORMAT,
  FRACTION_LENGTH,
  MOBILE_MAX_WIDTH,
  TABLET_MAX_WIDTH,
} from 'util/constants';

export const PROJECT_SHORT_NAME = 'SL';
export const PROJECT_FULL_NAME = 'SL Diamonds';

export const BASE_URL =
  process.env.REACT_APP_ENV === 'production' ? 'https://api.sldiamonds.be/' : 'https://betapi.sldiamonds.be/';
export const DNA_URL =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://my.diamondviewer360.com/'
    : 'https://beta.diamondviewer360.com/';

export const BASE_DOWNLOAD_URL = `${BASE_URL}/data`;
export const BASE_URL_ATTACH = BASE_URL;
export const LOCAL_STORAGE_VAR = 'sl';
export const MEDIA_URL = 'https://s3.ap-south-1.amazonaws.com/finestargroup';

export const EXCEEDING_LIMIT = 2700;
export const TABLE_PAGE_LIMIT = 250;
export const RECENT_STONES_MAX_LENGTH = 6;
export const IS_TABLE_COLLAPSABLE = true;

export const ENABLE_DNA_DRAWER = true;
export const ENABLE_SEARCH_TAGS = true;
export const ENABLE_MULTIPLE_SEARCH = true;
export const ENABLE_QUICK_SEARCH = true;

export const PAGE_LIMIT = TABLE_PAGE_LIMIT;
export const TABLE_LIMIT = TABLE_PAGE_LIMIT;
export const DEFAULT_PAGE_LIMIT = PAGE_LIMIT;
export const SEARCH_RESULT_MAX_SIZE = DEFAULT_PAGE_LIMIT;

export const RANGE_WISE_MASTER_SELECTION = ['col', 'clr', 'flu'];

export const OTHER_COUNTRY = ['HONGKONG', 'INDIA', 'TRANSIT-PROCESSING', 'NEW YORK', 'UPCOMING'];

export const MASTER_NAME_LIST = [
  'BLACK_INCLUSION_CROWN',
  'BLACK_INCLUSION_SIDE',
  'BLACK_INCLUSION_TABLE',
  'BLACK_INCLUSION',
  'BRILLIANCY',
  'BUSINESS_TYPE',
  'CLARITY',
  'COLOR',
  'COMPANY_DESIGNATION',
  'COMPANY_SIZE',
  'COUNTRY',
  'CULET_COND',
  'CULET',
  'CUT',
  'DAY_TERM',
  'DOC_TYPE_BUSINESS',
  'DOC_TYPE_PERSONAL',
  'EYECLEAN',
  'FANCY_COLOR',
  'FLUORESCENCE',
  'GIRDLE_COND',
  'GIRDLE',
  'H_AND_A',
  'INTENSITY',
  'KEY_TO_SYMBOLS',
  'LAB',
  'LOCATION',
  'MILKEY',
  'MIX_TINT',
  'NATURAL',
  'NATURE_OF_ORG',
  'OPEN_CROWN',
  'OPEN_INCLUSION_CROWN',
  'OPEN_INCLUSION_GIRDLE',
  'OPEN_INCLUSION_PAVILION',
  'OPEN_INCLUSION_TABLE',
  'OPEN_INCLUSION',
  'OPEN_PAVILION',
  'OPEN_TABLE',
  'ORIGIN',
  'OVERTONE',
  'POLISH',
  'SHADE',
  'SHAPE_GROUP',
  'SHAPE',
  'SIZE',
  'SOCIALS',
  'SYMMETRY',
  'WHITE_INCLUSION_CROWN',
  'WHITE_INCLUSION_SIDE',
  'WHITE_INCLUSION_TABLE',
];

export const LAB_LINKS = {
  IGI: 'https://igi.org/verify.php?r=***',
  GSI: 'http://wg.gemscience.net/vr/veri.aspx',
  GIA: 'https://www.gia.edu/report-check?reportno=***',
  HRD: 'https://my.hrdantwerp.com/?id=34&record_number=***',
};

const FILE_URL = 'http://sldiamondadmin.democ.in/sl/';
const FILE_PATH = {
  JPG: 'Imaged/IMAGE/***.jpg',
  JPG_ALT: 'HDView/imaged/***/***.jpg',
  JPG_NATURAL: 'Imaged/NIMAGE/***.jpg',
  JPG_LAYOUT: 'Imaged/Layout/***.jpg',
  HTML_360: 'HDView/Vision360.html?d=***',
  MP4_360: 'HDView/imaged/***/video.mp4',
  MP4_360_P: 'Imaged/PAIRVIDEO/***.mp4',
  MP4_NATURAL: 'Imaged/NVIDEO/***.mp4',
  PDF_CERT: 'Imaged/PDF/***.pdf',
  PDF_CERT_T2: 'Imaged/TYPE2PDF/***.pdf',
  JPG_ASSET: 'Imaged/HA/***/***-ASET%20white-01.jpg',
  JPG_IDEAL: 'Imaged/HA/***/***-IdealScope-01.jpg',
  JPG_HEARTS: 'Imaged/HA/***/***-Hearts-01.jpg',
  JPG_ARROWS: 'Imaged/HA/***/***-Arrows-01.jpg',
  JPG_FLU: 'HDView/imaged/***/fluorescence.jpg',
  JPG_PLT: 'Imaged/PLOTING/***.png',
  JPG_MSR: 'Imaged/PLOTING/MEASUREMENT/***.png',
  JPG_IMG: 'Imaged/THUMB/***.jpg',
};

export const getFileUrl = (key = 'JPG', replace = '###') => {
  switch (key) {
    case 'JPG_ALT':
      return 'https://ugcdn.azureedge.net/dim/hd4/imaged/141427-7/still.jpg';

    case 'HTML_360':
      return 'https://ugcdn.azureedge.net/dim/hd4/Vision360.html?d=141427-7&sv=0&btn=0&z=0&sr=100&sr=15';

    default:
      return `${FILE_URL}${FILE_PATH[key] ?? ''}`.replace(/[*]{3}/g, replace);
  }
};

export const FILE_URLS = {
  IMAGE_LAYOUT: 'http://sldiamondadmin.democ.in/sl/Imaged/Layout/***.jpg',
  img: 'https://ugcdn.azureedge.net/dim/hd4/imaged/141427-7/still.jpg',
  videoFile: 'https://ugcdn.azureedge.net/dim/hd4/Vision360.html?d=141427-7&sv=0&btn=0&z=0&sr=100&sr=15',
  videoHDFile: 'http://sldiamondadmin.democ.in/sl/HDView/imaged/***/video.mp4',
  certFile: 'https://erp.barakdiamonds.com/SLDiamonds/Output/Certificates/2211919835.pdf',
  hAFile: 'http://sldiamondadmin.democ.in/sl/Imaged/HA/***/***-ASET%20white-01.jpg',
  heartImage: 'http://sldiamondadmin.democ.in/sl/Imaged/HA/***/***-Hearts-01.jpg',
  arrBlack: 'http://sldiamondadmin.democ.in/sl/Imaged/HA/***/***-Arrows-01.jpg',
  idealWhiteImage: 'http://sldiamondadmin.democ.in/sl/Imaged/HA/***/***-IdealScope-01.jpg',
  pltFile: 'http://sldiamondadmin.democ.in/sl/Imaged/PLOTING/***.png',
  propImage: 'http://sldiamondadmin.democ.in/sl/Imaged/PLOTING/MEASUREMENT/***.png',
  mp4Video: 'http://sldiamondadmin.democ.in/sl/Imaged/NVIDEO/***.mp4',
  roughImage: 'http://sldiamondadmin.democ.in/sl/Imaged/ROUGH/***/still.jpg',
  planImg: 'http://sldiamondadmin.democ.in/sl/Imaged/3D/***.png',
  roughVideoFile: 'http://sldiamondadmin.democ.in/sl/Imaged/ROUGH/***/video.mp4',
  multiVideos: 'http://sldiamondadmin.democ.in/sl/HDView/Vision360.html?d=***',
  naturalImage: 'http://sldiamondadmin.democ.in/sl/Imaged/NIMAGE/***.jpg',
  flsImage: 'http://sldiamondadmin.democ.in/sl/HDView/imaged/***/fluorescence.jpg',
  thumbImg: 'http://sldiamondadmin.democ.in/sl/Imaged/THUMB/***.jpg',
};

export const DIAMONDCONFIRM = { STATUS: { PENDING: 1, ACCEPTED: 2, REJECTED: 3, CANCELED: 4 } };

export const DIAMOND_SEARCH_STATUS = { PENDING: 1, APPROVED: 2, DELIVERED: 3, REJECT: 4 };

export const bussinessTypeArray = {
  'Independent Jeweler': 'Independent Jeweler',
  'Online Jewelry Store': 'Online Jewelry Store',
  'Diamond Dealer/ Broker': 'Diamond Dealer/ Broker',
  'Diamond Manufacturer / Cutter': 'Diamond Manufacturer / Cutter',
  'Jewelry Manufacturer': 'Jewelry Manufacturer',
  'Jewelry Retail Chain': 'Jewelry Retail Chain',
  'Pawn shop': 'Pawn shop',
  Appraiser: 'Appraiser',
  Designer: 'Designer',
  'Not in the Diamond / Jewelry Trade': 'Not in the Diamond / Jewelry Trade',
  'Gold Buyer': 'Gold Buyer',
  'Diamond Mining': 'Diamond Mining',
  Auctioneer: 'Auctioneer',
  'Trade Association': 'Trade Association',
  'Watch Dealer': 'Watch Dealer',
  'Finance / Banking': 'Finance / Banking',
  Investor: 'Investor',
  'Jewelry Appraiser': 'Jewelry Appraiser',
  'Jewelry Retailer': 'Jewelry Retailer',
  'Diamond and Pearl Dealer / Broker': 'Diamond and Pearl Dealer / Broker',
  'Colored Stone Dealer / Broker': 'Colored Stone Dealer / Broker',
  'Estate Jewelry Dealer / Broker': 'Estate Jewelry Dealer / Broker',
  Other: 'Other',
};

export const ADDRESS_TYPES = ['HOME', 'ACCOUNT', 'BUYER', 'SHIPPING', 'BILLING', 'OTHER'];

export const page = { path: '/search-result', component: 'DiamondSearchGrid' };

export const reference = [
  'Select Reference',
  'Sales Person',
  'Third Party Reference',
  'RapNet',
  'VDB App',
  'LinkedIn',
  'WhatsApp',
  'Facebook',
  'Instagram',
  'Google',
  'WeChat',
  'Twitter',
  'QQ',
  'News',
  'Visit',
  'Walk In',
  'Other',
];

export const authorizedPages = [
  'diamond-search',
  'fancy-search',
  'quick-search',
  'search-result',
  'match-pair',
  'parcel-search',
  'parcel-list',
  'upcoming-diamonds,',
  'compare',
  'selected-stones',
  'dashboard',
  'my-cart',
  'my-tracked-diamonds',
  'my-reminder',
  'my-comment',
  'my-enquiry',
  'price-calculator',
  'my-account',
  'appointment-history',
  'my-demand',
  'my-saved-search',
  'change-password',
  'settings',
  'company-details',
  'address-book',
  'change-email-address',
  'invoice',
  'best-of-sl',
  'new-diamonds',
  'customer-care/:name',
  'matchpair-settings',
  'my-order',
  'recommended',
  'customer-care/appointment',
  'upload-diamonds',
  'upload-images',
  'upload-certificates',
  'diamond-history',
  'people',
  'manage-diamonds',
  'uploaded-sheet-details',
  'feedback',
  'thankyou-for-feedback',
];
export const myAccount = {
  personalDetails: { key: 'my-account', label: 'Personal Details', logo: 'personal-detail.svg' },
  companyDetails: { key: 'company-details', label: 'Company Details', hideType: [3], logo: 'company-detail.svg' },
  userDetails: { key: 'user-details', label: 'User Details', hideType: [3], logo: 'user-detail.svg' },
  settings: { key: 'settings', label: 'Settings', logo: 'setting.svg' },
  changePswd: { key: 'change-password', label: 'Change Password', logo: 'change-password.svg' },
  otherDetails: { key: 'other-details', label: 'Other Details', hideType: [3], logo: 'otherDetail.svg' },
  manufacturingDetails: {
    key: 'manufacturing-details',
    label: 'Manufacturing Facility Details',
    hideType: [3],
    logo: 'manufacturing.svg',
  },
};

export const diamondParameters = [
  { key: 'shp', name: 'Shape', master: 'SHAPE' },
  { key: 'col', name: 'Color', master: 'COLOR' },
  { key: 'clr', name: 'Clarity', master: 'CLARITY' },
  { key: 'flu', name: 'Fluorescence', master: 'FLUORESCENCE' },
  { key: 'shd', name: 'Color Shade', master: 'SHADE' },
  { key: 'lb', name: 'Lab', master: 'LAB' },
  { key: 'cut', name: 'Cut', master: 'CUT' },
  { key: 'pol', name: 'Polish', master: 'POLISH' },
  { key: 'sym', name: 'Symmetry', master: 'SYMMETRY' },
  { key: 'loc', name: 'Location' },
  { key: 'country', name: 'Country' },
  { key: 'inten', name: 'Intensity', master: 'INTENSITY' },
  { key: 'ovrtn', name: 'Overtone', master: 'OVERTONE' },
  { key: 'blkInc', name: 'Black Table', master: 'BLACK_INCLUSION' },
  { key: 'eCln', name: 'Eye Clean', master: 'EYECLEAN' },
  { key: 'mlk', name: 'Milky', master: 'MILKEY' },
  { key: 'hA', name: 'Heart and Arrow', master: 'H_AND_A' },
  { key: 'rap', name: 'Rap Discount' },
  { key: 'ctPr', name: 'Price/Carat' },
  { key: 'amt', name: 'Amount' },
  { key: 'back', name: 'Disc%' },
  { key: 'depPer', name: 'Depth%' },
  { key: 'tblPer', name: 'Table%' },
  { key: 'grdl', name: 'Girdle' },
  { key: 'pAng', name: 'Pavilion Angle' },
  { key: 'cAng', name: 'Crown Angle' },
  { key: 'cHgt', name: 'Crown Height' },
  { key: 'pHgt', name: 'Pavilian Depth' },
  { key: 'dmtrMn', name: 'Diameter Min' },
  { key: 'dmtrMxn', name: 'Diameter Max' },
  { key: 'or', name: 'Carat', master: 'CARAT' },
  { key: 'ratio', name: 'Ratio' },
  { key: 'showSrNo', name: 'Show ID' },
  { key: 'kToSArr', name: 'Key To Symbol', master: 'KEY_TO_SYMBOLS' },
  { key: 'fcCol', name: 'Fancy Color' },
  { key: 'grdlCond', name: 'Girdle Condition' },
  { key: 'cultCond', name: 'Culet Condition' },
  { key: 'cult', name: 'Culet' },
  { key: 'stoneId', name: 'Stone ID/ Report Number' },
  { key: 'vnd', name: 'Seller Name' },
  { key: 'vStnId', name: 'Seller Stone Number' },
  { key: 'length', name: 'Length' },
  { key: 'width', name: 'Width' },
  { key: 'height', name: 'Depth' },
  { key: 'pairStkNo', name: 'Pair No' },
  { key: 'isSeal', name: 'Seal' },
  { key: 'brlncy', name: 'Brilliance' },
  { key: 'isDor', name: 'DOR' },
  { key: 'isFm', name: 'FM' },
  { key: 'blkTbl', name: 'Black Table' },
  { key: 'blkSd', name: 'Side Natts' },
  { key: 'wSd', name: 'Side' },
  { key: 'lwr', name: 'Lower Half' },
  { key: 'strLn', name: 'Star Length' },
  { key: 'isXray', name: 'Xray' },
  { key: 'type2', name: 'Type2' },
  { key: 'blkCrn', name: 'Black Crown' },
  { key: 'blkCrn', name: 'Black Crown' },
  { key: 'wTbl', name: 'White Table' },
  { key: 'wCrn', name: 'White Crown' },
  { key: 'opTbl', name: 'Open On Table' },
  { key: 'opCrwn', name: 'Open On Crown' },
  { key: 'opPav', name: 'Open On Pavillion' },
  { key: 'opGrd', name: 'Open On Girdle' },
];

export const TRACK_TYPES = {
  CART: 1,
  WATCHLIST: 2,
  REQUEST: 3,
  QUOTE: 4,
  REMINDER: 5,
  ENQUIRY: 6,
  COMMENT: 99,
  SHIPMENT: 7,
  SHOWSELECT: 8,
  NOTES: 99,
};

export const fields = {
  firstName: 'First Name',
  lastName: 'Last Name',
  name: 'Name',
  email: 'Email',
  emails: 'Email Address',
  mobile: 'Mobile No.',
  mobiles: 'Mobile No.',
  countryCode: 'Countrycode',
  username: 'Username',
  password: 'Password',
  cpassword: 'Confirm Password',
  dob: 'Birth Date',
  phone: 'Telephone No.',
  companyName: 'Company Name',
  designation: 'Designation',
  BusinessType: 'Business Type',
  address1: 'Address Line1',
  address2: 'Address Line2',
  country: 'Country',
  state: 'State',
  city: 'City',
  pinCode: 'Zip Code',
  fax: 'Fax',
  howKnow: 'How do you know us?',
  photoId: 'Photo Identity Proof',
  anniversary: 'Anniversary Date',
  businessId: 'Business identity Proof',
  isTermsCondAgree: 'Terms & Conditions',
};

export const requiredFields = [
  'firstName',
  'lastName',
  'email',
  'username',
  'password',
  'cpassword',
  'mobile',
  'companyName',
  'BusinessType',
  'address1',
  'country',
  'state',
  'city',
  'pinCode',
  'photoId',
  'businessId',
  'isTermsCondAgree',
];

export const requiredCompanyFields = [
  'companyName',
  'address',
  'landMark',
  'zipCode',
  'businessType',
  'designation',
  'howKnow',
  'country',
  'state',
  'city',
  'faxes',
];

export const requiredaddressField = ['address', 'zipCode', 'country', 'state', 'city'];

export const requiredContactFields = ['name', 'email', 'mobile', 'messege'];

export const contactFields = { name: 'Name', email: 'Email', mobile: 'Mobile', messege: 'Message' };

export const companyFields = {
  companyName: 'Company Name',
  address: 'Address',
  landMark: 'LandMark',
  zipCode: 'ZipCode',
  businessType: 'BusinessType',
  designation: 'Designation',
  howKnow: 'HowKnow',
  country: 'Country',
  state: 'State',
  city: 'City',
  faxes: 'Faxes',
};

export const addressField = {
  address: 'Address',
  zipCode: 'ZipCode',
  country: 'Country',
  state: 'State',
  city: 'City',
};

export const changePswdFields = {
  currentPassword: 'Current Password',
  newPassword: 'New Password',
  repeatPassword: 'Repeat Password',
};

export const requiredChangePswdFields = ['currentPassword', 'newPassword', 'repeatPassword'];

export const requiredResetPswdFields = ['confirmPassword', 'newPassword'];

export const resetPswdFields = ['confirmPassword', 'newPassword'];

export const fieldsrequiredGuest = ['name', 'companyName', 'mobile', 'email'];

export const fieldsGuest = {
  name: 'Name',
  companyName: 'Company Name',
  countryCode: 'Countrycode',
  mobile: 'Contact No.',
  email: 'Email',
};

export const SELECT_STONE_TERMS_NEW = [
  { id: '0', value: 0, resetDis: 0, name: '5 Days Rupee Bill' },
  { id: '1', value: 1, resetDis: 0, name: '30 Days Rupee Bill' },
  { id: '2', value: -1, resetDis: 0, minAmt: 15000, name: '5 Days DD bill' },
  { id: '3', value: -2, resetDis: -1, minAmt: 15000, name: '5 Days Export' },
  { id: '4', value: -1, resetDis: 0, minAmt: 15000, name: '30 Days Export' },
  { id: '5', value: -1.5, resetDis: -1, minAmt: 15000, name: 'HK Delivery' },
];

export const BANNERS_TYPES = {
  HOME_TOP_LEFT_1: '9',
  HOME_TOP_LEFT_2: '10',
  HOME_TOP_LEFT_3: '11',
  HOME_TOP_RIGHT_1: '12',
  HOME_TOP_RIGHT_2: '13',
  HOME_TOP_RIGHT_3: '14',
  HOME_TOP_CENTRE: '15',
  HOME_BOTTOM_LEFT_1: '16',
  HOME_BOTTOM_LEFT_2: '17',
  HOME_BOTTOM_LEFT_3: '18',
  HOME_BOTTOM_RIGHT_1: '19',
  HOME_BOTTOM_RIGHT_2: '20',
  HOME_BOTTOM_RIGHT_3: '21',
  HOME_BOTTOM_CENTRE: '22',
  HOME_CENTRE: '23',
};

export const BANNER_TYPES = {
  LOGIN_PAGE: 1,
  REGISTER_PAGE: 2,
  FORGOT_PASSWORD_PAGE: 3,
  GUEST_LOGIN: 4,
  CALENDAR: 5,
  DASHBOARD: 6,
};

export const quickSearch = [
  {
    allowColors: [
      'D',
      'E',
      'F',
      'G',
      'H',
      'I',
      'J',
      'K',
      'L',
      'M',
      'N',
      'O',
      'P',
      'Q',
      'R',
      'S',
      'T',
      'U',
      'V',
      'W',
      'X',
      'Y',
      'Z',
    ],
    TotalColorsGroups: ['DEF', 'GHI', 'J-', 'FANCY COLOR'],
    TotalClarityGroups: ['IF', 'VVS', 'VS', 'SI', 'I', 'OTHER'],
    colors: [
      { id: 'D', name: 'DEF', sequence: 1, innerSequence: 1 },
      { id: 'E', name: 'DEF', sequence: 1, innerSequence: 2 },
      { id: 'F', name: 'DEF', sequence: 1, innerSequence: 3 },
      { id: 'G', name: 'GHI', sequence: 2, innerSequence: 4 },
      { id: 'H', name: 'GHI', sequence: 2, innerSequence: 5 },
      { id: 'I', name: 'GHI', sequence: 2, innerSequence: 6 },
      { id: 'J', name: 'J-', sequence: 3, innerSequence: 7 },
      { id: 'K', name: 'J-', sequence: 3, innerSequence: 8 },
      { id: 'L', name: 'J-', sequence: 3, innerSequence: 9 },
      { id: 'M', name: 'J-', sequence: 4, innerSequence: 10 },
      { id: 'N', name: 'J-', sequence: 4, innerSequence: 11 },
      { id: 'O', name: 'J-', sequence: 4, innerSequence: 12 },
      { id: 'P', name: 'J-', sequence: 4, innerSequence: 13 },
      { id: 'Q-R', name: 'J-', sequence: 4, innerSequence: 14 },
      { id: 'S-T', name: 'J-', sequence: 4, innerSequence: 15 },
      { id: 'U-V', name: 'J-', sequence: 4, innerSequence: 16 },
      { id: 'W-X', name: 'J-', sequence: 4, innerSequence: 17 },
      { id: 'Y-Z', name: 'J-', sequence: 4, innerSequence: 18 },
    ],
    clarity: [
      { id: 'FL', name: 'IF', sequence: 1, innerSequence: 1 },
      { id: 'IF', name: 'IF', sequence: 1, innerSequence: 2 },
      { id: 'VVS1', name: 'VVS', sequence: 2, innerSequence: 3 },
      { id: 'VVS2', name: 'VVS', sequence: 2, innerSequence: 4 },
      { id: 'VS1', name: 'VS', sequence: 3, innerSequence: 5 },
      { id: 'VS1-', name: 'VS', sequence: 3, innerSequence: 6 },
      { id: 'VS1+', name: 'VS', sequence: 3, innerSequence: 7 },
      { id: 'VS2', name: 'VS', sequence: 3, innerSequence: 8 },
      { id: 'VS2-', name: 'VS', sequence: 3, innerSequence: 9 },
      { id: 'VS2+', name: 'VS', sequence: 3, innerSequence: 10 },
      { id: 'SI1', name: 'SI', sequence: 4, innerSequence: 11 },
      { id: 'SI1-', name: 'SI', sequence: 4, innerSequence: 12 },
      { id: 'SI1+', name: 'SI', sequence: 4, innerSequence: 13 },
      { id: 'SI2', name: 'SI', sequence: 4, innerSequence: 14 },
      { id: 'SI2+', name: 'SI', sequence: 4, innerSequence: 15 },
      { id: 'SI2-', name: 'SI', sequence: 4, innerSequence: 16 },
      { id: 'SI3', name: 'SI', sequence: 4, innerSequence: 17 },
      { id: 'SI3-', name: 'SI', sequence: 4, innerSequence: 18 },
      { id: 'SI3+', name: 'SI', sequence: 4, innerSequence: 19 },
      { id: 'I1', name: 'I', sequence: 5, innerSequence: 20 },
      { id: 'I1-', name: 'I', sequence: 5, innerSequence: 21 },
      { id: 'I1+', name: 'I', sequence: 5, innerSequence: 22 },
      { id: 'I2', name: 'I', sequence: 5, innerSequence: 23 },
      { id: 'I2-', name: 'I', sequence: 5, innerSequence: 24 },
      { id: 'I2+', name: 'I', sequence: 5, innerSequence: 25 },
      { id: 'I3', name: 'I', sequence: 5, innerSequence: 26 },
      { id: 'I3-', name: 'I', sequence: 5, innerSequence: 27 },
      { id: 'I3+', name: 'I', sequence: 5, innerSequence: 28 },
    ],
    width: '',
  },
];

export const MonthName = {
  JAN: 'JAN',
  FEB: 'FEB',
  MAR: 'MAR',
  APR: 'APR',
  MAY: 'MAY',
  JUNE: 'JUNE',
  JULY: 'JULY',
  AUG: 'AUG',
  SEP: 'SEP',
  OCT: 'OCT',
  NOV: 'NOV',
  DEC: 'DEC',
  INFO: 'INFO',
  SHOW: 'SHOW',
};

export const RapPer = [-99, -80, -60, -40, -20, 0, 20, 40, 60, 80, 99];
const labMaster = localStorage.getItem('fs-master');
const labGIA = labMaster && JSON.parse(labMaster) && JSON.parse(labMaster).LAB.find((x) => x.code === 'GIA');

export const Inventory = [
  { carat: { '>=': 0.18, '<=': 0.96 }, lab: labGIA && labGIA.id },
  { carat: { '>=': 0.96, '<=': 2 }, lab: labGIA && labGIA.id },
];

export const FILE_TYPES = {
  'image/png': ['89504e47'],
  'image/jpeg': ['ffd8ffe0', 'ffd8ffe3', 'ffd8ffe2', 'ffd8dde0'],
  'image/jpg': ['ffd8ffe0', 'ffd8ffe8', 'ffd8ffe1'],
};

export const FILE_TYPES_PDF = {
  'image/png': ['89504e47'],
  'image/jpeg': ['ffd8ffe0', 'ffd8ffe3', 'ffd8ffe2', 'ffd8dde0'],
  'image/jpg': ['ffd8ffe0', 'ffd8ffe8', 'ffd8ffe1'],
  'application/pdf': ['25504446'],
};

export const CALENDER_FIELD = [
  { action: 'JANUARY', label: 'JAN' },
  { action: 'FEBRUARY', label: 'FEB' },
  { action: 'MARCH', label: 'MAR' },
  { action: 'APRIL', label: 'APR' },
  { action: 'MAY', label: 'MAY' },
  { action: 'JUNE', label: 'JUN' },
  { action: 'JULY', label: 'JUL' },
  { action: 'AUGUST', label: 'AUG' },
  { action: 'SEPTEMBER', label: 'SEP' },
  { action: 'OCTOBER', label: 'OCT' },
  { action: 'NOVEMBER', label: 'NOV' },
  { action: 'DECEMBER', label: 'DEC' },
  { action: 'SHOW', label: 'SHOW' },
  { action: 'INFO', label: 'INFO' },
];

export const DiamondAllotingDate = 'April 5,2018';

export const DiamondAssesingDate = 'April 4,2018';

export const DiamondGradingDate = 'May 22,2018';

export const DiamondOriginDate = 'March 21,2018';

export const DiamondPlanningDate = 'April 10,2018';

export const DiamondPolishingDate = 'April 27,2018';

export const DiamondQualityDate = 'May 3,2018';

export const DiamondLaserDate = 'April 10,2018';

export const storeSessionUUID = () => {
  const getSessionUUID = localStorage.getItem('fs-uuid');

  if (!getSessionUUID || !getSessionUUID.length) {
    localStorage.setItem('fs-uuid', new Date().getTime());
  }
};
storeSessionUUID();

export const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const capitalizeFirstLetters = (str) => {
  return str.toLowerCase().replace(/^\w|\s\w/g, (letter) => {
    return letter.toUpperCase();
  });
};

export const DASHBOARD_TOOLTIP = [
  { element: '.user', intro: 'My Portal' },
  { element: '.lang-steps', intro: 'Language DropDown' },
  { element: '.calculator-tooltip', intro: 'Calculator' },
  { element: '.notification-tooltip', intro: 'View Notification Here' },
  { element: '.cart-tooltip', intro: 'Your Cart' },
  { element: '.message-tooltip', intro: 'Chat with us' },
  { element: '.sidebar-steps', intro: 'Discover Menu' },
  { element: '.search-voice ', intro: 'Search diamonds directly' },
];

export const DETAIL_PAGE_TOOLTIP_STEPS = [
  { element: '.packetNo-tooltip', intro: 'Diamond Packet-No' },
  { element: '.verify-tooltip', intro: 'Verify Certificate' },
  { element: '.x-ray-tooltip', intro: 'Share Diamond X-ray' },
  { element: '.whatsapp-tooltip', intro: 'Share On Whatsapp' },
  { element: '.send-x-ray-tooltip', intro: 'Copy Detail Link' },
  { element: '.download-zip-tooltip', intro: 'Download Zip File' },
];

export const SEARCH_RESULT_STEPS = [
  { element: '.add-to-cart', intro: 'Add to cart' },
  { element: '.add-watchList', intro: 'Add to Tracklist' },
  { element: '.compare', intro: 'Compare Diamonds' },
  { element: '.showSelect', intro: 'Show Selected Diamonds' },
  { element: '.comment', intro: 'Note' },
  { element: '.reminder', intro: 'Reminder' },
  { element: '.export-tooltip', intro: 'you can Print,Download and Email listed diamond excel' },
  { element: '.filter-tooltip', intro: 'You can Apply Filter' },
  { element: '.setting-tooltip', intro: 'You can Change Column Settings' },
];

export const DIAMOND_SEARCH_STEPS = [
  { element: '.all-shape', intro: 'Select All Criteria' },
  { element: '.advance-tooltip', intro: 'Advance Search' },
  { element: '.search-tooltip', intro: 'Search Diamond' },
  { element: '.add-more-tooltip', intro: 'Add more Search' },
  { element: '.save-tooltip', intro: 'You Can Save Your Search' },
  { element: '.demand-tooltip', intro: 'You Can add Your demand Diamond Search' },
  { element: '.match-pair-tooltip', intro: 'Match Pair' },
  { element: '.match-pair-tooltip', intro: 'Match Pair' },
  { element: '.create-excel-tooltip', intro: 'You can Create Excel' },
  { element: '.import-excel-tooltip', intro: 'You can Import Excel' },
  { element: '.sample-excel-tooltip', intro: 'Download Sample' },
  { element: '.reset-filter-tooltip', intro: 'Reset Your Filter' },
];

export const SIZEEX = [
  { name: 'sizeEx', value: '3EX' },
  { name: 'sizeEx', value: '2EX' },
  { name: 'sizeEx', value: '2EX' },
  { name: 'sizeEx', value: 'NO BGM' },
];

export const MOBILE_VIEW_ACTION_BTN = [
  { intelMsg: 'add-track', icon: 'add-track' },
  { intelMsg: 'compare', icon: 'compare' },
  { intelMsg: 'comment', icon: 'comment' },
  { intelMsg: 'showSelect', icon: 'showSelect' },
  { intelMsg: 'print', icon: 'print' },
  { intelMsg: 'downloadExcel', icon: 'downloadExcel' },
];

export const uploadDiamond = {
  actions: { ReplaceAll: 2, AddUpdate: 1 },
  uploadStatus: {
    PENDING: 1,
    PROCESSING: 2,
    COMPLETED: 3,
    FAILED: 4,
    UPLOADING: 5,
    UPLOADING_COMPLETED: 6,
    UPLOADING_FAILED: 7,
    REPROCESSING: 8,
  },
  uploadType: { IMAGE: 1, CERTIFICATE: 2 },
};

export const gridColumns = { GRID_MY_DIAMOND_PAGE_LIST: 'GRID_MY_DIAMOND_PAGE_LIST' };

export const ACTIVITIES = {
  'sheet-upload-V1': 'Diamond Sheet Upload',
  'sheet-upload-V2': 'Diamond Sheet Reload',
  enquiry: 'Enquiry Added',
  user: 'User Added',
  track: 'Diamond Added in Track',
  comment: 'Note added on Diamond',
};

export const ACTIVITY_REDIRECTS = {
  'sheet-upload-V1': '/diamond-history',
  'sheet-upload-V2': '/diamond-history',
  enquiry: '/my-enquiry',
  user: '/user-details',
  track: '/my-tracked-diamonds',
};

export const TIME_ZONE_OPTIONS = [
  { name: 'Mumbai', timeZone: 'IST', offset: 330 },
  { name: 'New York', timeZone: 'PST', offset: -240 },
  { name: 'Antwerp', timeZone: 'CEST', offset: 120 },
  { name: 'Tel Aviv', timeZone: 'IDT', offset: 180 },
  { name: 'Hong Kong', timeZone: 'HKT', offset: 480 },
  { name: 'Shanghai', timeZone: 'CST', offset: 480.1 },
  { name: 'Dubai', timeZone: 'GST', offset: 240 },
  { name: 'Tokyo', timeZone: 'JST', offset: 540 },
];

export const EVENT_TYPES = { EVENT: '1', NEWS: '2', SHOW: '3', OTHER: '4', CSR: '5' };

export const ADD_REQUEST = '/add-request';

export const DIAMOND_SEARCH = '/diamond-search';

export const MIME_TYPES = {
  'image/png': ['89504e47'],
  'image/jpeg': ['ffd8ffe0', 'ffd8ffe1', 'ffd8ffee', 'ffd8ffdb'],
  'image/jpg': ['ffd8ffe0', 'ffd8ffe1', 'ffd8ffee', 'ffd8ffdb'],
  'application/pdf': ['25504446'],
};

export const DIAMOND_BID = { TYPE: { BLIND: 1, OPEN: 2 }, STATUS: { PENDING: 1, WIN: 2, LOSS: 3 } };

export const DIAMOND_FEATURED_GROUP_TYPE = {
  PAIR: 'pair',
  STONE: 'stone',
  EXCLUSIVE: 'exclusive',
  BEST: 'best',
  STONE_OF_DAY: 'stone_of_day',
};

export const DIAMOND_WEB_STATUS = { A: 'A', M: 'M', NA: 'NA', UPCOMING: 'U', BID: 'B', SHOW: 'S', OFFICE: 'O' };

export const DIAMOND_AVAILABLE_WEB_STATUS = ['A', 'M'];

export const DIAMOND_ADDED_FROM = { SEARCH: 1, MATCH_PAIR: 2, LAYOUT: 3 };

export const PURCHASED_STATUS = { PENDIND: 1, APPROVED: 2, REJECT: 3, RELEASE: 4 };

export const DIAMOND_DISPLAY_TYPE = {
  BUSINESS: 1,
  NEW: 2,
  AVAILABLE: 3,
  INLAB: 4,
  EXCLUSIVE: 11,
  BEST: 12,
  STONE_OF_DAY: 13,
  LUCKY: 14,
};

export const PROJECT_SETTINGS_TYPE = { CM_CHARGES: 1, BANK_RATES: 2, SYNC_JOB_SETTINGS: 3, FM_CHARGES: 4 }; //cosntant to manage check on table row click

export const IsRowWiseChecked = false;

export const OFFICE_STATUS = { 1: 'PENDING', 2: 'APPROVED', 3: 'REJECTED' };

export const DOWNLOAD_OPTIONS = {
  Images: {
    label: 'Image',
    key: 'img',
    // subLevel: [
    //   { label: 'Ideal Image', key: 'img' },
    //   // { label: 'Natural Image', key: 'naturalImage' },
    //   // { label: 'Heart & Arrow Image', key: 'hAFile' },
    //   // { label: 'Fluorescence Image', key: 'flsImage' },
    //   // { label: 'Plot Image', key: 'pltFile' },
    //   // { label: 'Proportion Image', key: 'propImage' },
    // ],
  },
  Videos: {
    label: ' Video',
    key: 'videoFile',
    // subLevel: [
    //   // { label: 'Natural Video', key: 'videoFile' },
    //   // { label: 'HD Video', key: 'mp4Video' },
    // ],
  },
  Certificates: { label: 'Certificate', key: 'certFile' },
  Excels: { label: 'Excel', key: 'isExcel' },
};

export const SHARE_OPTIONS = {
  Images: {
    label: 'Image',
    // key: 'img',
    subLevel: [
      { label: 'Ideal Image', key: 'img' },
      { label: 'Natural Video', key: 'videoFile' },
      { label: 'Certificate', key: 'certFile' },
      { label: 'Excel', key: 'isExcel' },
      //   // { label: 'Natural Image', key: 'naturalImage' },
      //   // { label: 'Heart & Arrow Image', key: 'hAFile' },
      //   // { label: 'Fluorescence Image', key: 'flsImage' },
      //   // { label: 'Plot Image', key: 'pltFile' },
      //   // { label: 'Proportion Image', key: 'propImage' },
    ],
  },
  Videos: {
    label: 'Video',
    // key: 'videoFile',
    subLevel: [
      // { label: 'Natural Video', key: 'videoFile' },
      //   // { label: 'HD Video', key: 'mp4Video' },
    ],
  },
  detailLink: {
    // label: 'Detail Link',
    subLevel: [
      // { label: 'With Price', key: 'withoutPrice', value: false },
      { label: 'Diamond detail', key: 'withoutPrice' },
      // { label: 'With Price', key: 'withPrice' },
      // { label: 'Without Price', key: 'withoutPrice' },
    ],
  },
  // Certificates: { label: 'Certificate', key: 'certFile' },
  // Excels: {},
  // Excels: { label: 'Excel', key: 'isExcel' },
};

export const BID_LIST_TYPE_TITLE = {
  ALL: 'All',
  BID_WISH_LIST: 'Wish List',
  BID_VIEW_REQUEST: 'View Request',
  BID_STONE_VIEWED: 'Stone Viewed',
};

export const trackTypeObj = TRACK_TYPES;

export const KYC_STATUS = { PENDING: 1, APPROVED: 2, REJECT: 3 };
