import { logWarn } from './logWarn';

/**
 * Handles exceptions in a function
 * @param   {Function} func
 * @param   {Object}   options
 * @returns {Promise}
 */
export async function asyncHandler(func) {
  try {
    const response = await func();
    return [false, response];
  } catch (error) {
    logWarn(error);
    return [true, error];
  }
}
