import 'react-app-polyfill/ie9';
import './services';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { ConnectedProgress } from 'components/ConnectedProgress';

import Root from 'config/Root';
import { store } from 'redux/store';

import 'styles/main.less';
import 'components/auth.less';

String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

ReactDOM.render(
  <Provider store={store}>
    <ConnectedProgress />
    <Root />
  </Provider>,
  document.getElementById('root'),
);
