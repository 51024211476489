const initialState = {};

export const STATS_ACTION_TYPES = {
  SET: '@stats/set',
  RESET: '@stats/reset',
};

export const STATS_ACTIONS = {
  set: (payload) => ({ type: STATS_ACTION_TYPES.SET, payload }),
  reset: () => ({ type: STATS_ACTION_TYPES.RESET }),
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case STATS_ACTION_TYPES.SET: {
      return { ...state, ...payload };
    }

    case '@@RESET':
    case STATS_ACTION_TYPES.RESET:
      return { ...initialState };

    default:
      return state;
  }
};
