import React from 'react';

import { Tooltip } from 'antd';

import { LISTINGPAGES } from 'components/DiamondList/DiamondListFunctions';
import DiamondTotalCard from 'components/common/DiamondListing/DiamondTotalCard';
import { Appointment } from 'components/common/DiamondListing/OfficeSidebar';

import { usePathname } from 'util/hooks';
import { useGroupSelect } from 'util/useGroupSelect';
import { formatDate, formatTime, isNotEmpty } from 'util/utils';

import CheckBox from '../CheckBox';

const GroupCheckBox = React.memo((props) => {
  const { groupRows, currentType } = props;

  const [checked, indeterminate, toggle] = useGroupSelect(groupRows, currentType);

  const groupKey = React.useMemo(() => groupRows?.[0]?.['_groupKey_'], [groupRows]);

  return <CheckBox antd name={groupKey} checked={checked} indeterminate={indeterminate} onChange={toggle} />;
});

const TableGrouping = (props) => {
  const { row } = props;
  const pathname = usePathname();

  return (
    <>
      {!row?.isMatchFooter && (
        <>
          <div className="tableGropupingBox">
            <div className="tableGroupTitle">
              <span>
                {/* {props.Collapse && (
                  <div className="tableCollapse" onClick={props.onClick}>
                    <img src={props.img} alt="" />
                  </div>
                )}
                {props.showCheckBox && (
                  <Tooltip title="Select All">
                    <div className="checkBoxSelect">
                      <GroupCheckBox
                        groupRows={props?.row?.subRows || props?.groupRows}
                        currentType={props.currentType}
                      />
                    </div>
                  </Tooltip>
                )}
                {props.multipleCheckBox && props.children && <div className="checkBoxSelect">{props.children}</div>}
                {row?.groupingTitle && <span>{row?.groupingTitle}</span>} */}
                {row?.isConfirmHeader && pathname === LISTINGPAGES.CONFIRM ? (
                  row?.createdAt ? (
                    <span>
                      {row?._groupKey_ ?? [(row?.memoNo, formatDate(row?.createdAt))].filter(isNotEmpty).join(' | ')}{' '}
                    </span>
                  ) : (
                    <span>Order No.{row?.memoNo}</span>
                  )
                ) : null}
                {row?.isOfferHeader && row?.updatedAt && <span>{formatDate(row?.updatedAt)}</span>}
                {/* {row?.isEnquiryRemarks && LISTINGPAGES.ENQUIRY && <span>{row?.remarks}</span>} */}
                {/* {row?.isUpcomingHeader && <span>{formatDate(row?.inDt)} 3 PM IST</span>} */}
                {row?.isOfficeHeader && (
                  <span>
                    {formatDate(row?.date)} - [{formatTime(row?.cabinSlot[0].start)}
                    {' - '}
                    {formatTime(row?.cabinSlot[0].end)}]{' - '}
                    {Appointment.virtualType.filter((x) => x.id === row?.meetingType)[0].name}
                    {' - '}
                    {row?.status}
                  </span>
                )}
              </span>
              <div className="tableHeaderMain"></div>
              {/* <div className="tableGroupTitle">
                <span className="tableGroupTitleInner">
                  { row?.isConfirmHeader && row?.createdAt && (
                    <SelectStone rows={groupRows} fromGrouping />
                  )}
                </span>
              </div>{' '} */}
            </div>
          </div>
        </>
      )}
      <tr className="tableGroupingTr">
        {!props.columns && (
          // <td>
          <div className="tableGropupingBox">
            <div className="tableGroupTitle">
              {/* {row?.groupingTitle && ( */}
              <span className="tableGroupTitleInner">
                {props.Collapse && (
                  <div className="tableCollapse" onClick={props.onClick}>
                    <img src={props.img} alt="" />
                  </div>
                )}
                {props.multipleCheckBox && props.children && <div className="checkBoxSelect">{props.children}</div>}
                {row?.groupingTitle && <span>{row?.groupingTitle}</span>}
                {/* {row?.isEnquiryRemarks && LISTINGPAGES.ENQUIRY && <span>{row?.remarks}</span>} */}
                {row?.isUpcomingHeader && <span>{formatDate(row?.inDt)} 3 PM IST</span>}
              </span>
              {/* )} */}
            </div>
          </div>
          // </td>
        )}

        {!props.columns && (
          <td className="groupingTableAmount" style={{ color: 'transparent' }}>
            0
          </td>
        )}
        {props?.columns?.map?.((column) => (
          <td className="groupingTableAmount" key={column.id}>
            {props.displayTotal.includes(column.id) && <DiamondTotalCard row={row} col={column.id} />}
          </td>
        ))}
      </tr>
    </>
  );
};
export default TableGrouping;
