import React from 'react';

import { Tag } from 'antd';
import _ from 'lodash';

import { isArray, isEmpty, isObject, unique } from 'util/utils';

import { diamondParameters, OTHER_COUNTRY } from 'constants/Common';

const MasterTags = (props) => {
  const { filterData } = props;

  if (!isObject(filterData) || isEmpty(filterData)) return null;

  return Object.keys(filterData).map((filter, index) => {
    const param = _.find(diamondParameters, { key: filter });
    if (!param) return null;

    if (param.key === 'or') {
      if (filterData['or'] && _.has(filterData['or'][0], 'crt')) {
        const arr = filterData['or'].map((carat) => {
          return carat['crt']['>='] + ' to ' + carat['crt']['<='];
        });
        return (
          <Tag key={index} className="listTagList">
            <span className="listLabel">Carat :</span>
            <span>{_.join(arr, ', ')}</span>
          </Tag>
        );
      }
    } else if (isObject(filterData[filter]) && !isArray(filterData[filter])) {
      if (filter === 'kToSArr') {
        const arr = Object.values(filterData['kToSArr'])[0];
        const key = Object.keys(filterData['kToSArr'])[0];

        return (
          <div key={index} className="savedSearchValue">
            <span>
              {param.name}&nbsp;:&nbsp;
              <span>{key === 'in' ? 'Contains' : 'Not contains'}</span>
            </span>
            <span>{_.join(arr, ', ')}</span>
          </div>
        );
      } else {
        return (
          <Tag key={index} className="listTagList">
            <span className="listLabel">{param.name} :</span>
            <span>{filterData[filter]['>='] + '  to  ' + filterData[filter]['<=']}</span>
          </Tag>
        );
      }
    } else if (typeof filterData[param.key] === 'boolean') {
      return (
        <Tag className="listTagList">
          <span className="listLabel">{param.name} :</span>
          <span>{filterData[param.key] === true ? 'YES' : 'NO'}</span>
        </Tag>
      );
    } else {
      const disp = filterData[param.key];
      const otherCountry = OTHER_COUNTRY;
      const filterCountry = unique(disp.map((country) => (otherCountry.includes(country) ? 'OTHER' : country)));

      return (
        <Tag className="listTagList">
          {param.name == 'Country' ? (
            <>
              <span className="listLabel">{param.name} :</span>
              <span>{_.join(filterCountry, ', ')}</span>
            </>
          ) : (
            <>
              <span className="listLabel">{param.name} :</span>
              <span>{_.join(disp, ', ')}</span>
            </>
          )}
        </Tag>
      );
    }
    return null;
  });
};
export default MasterTags;
