import memoize from 'lodash/memoize';
import { DateTime } from 'luxon';
import clone from 'nanoclone';

import { FLOAT_COLUMNS, ROUND_COLUMNS, CURRENCY_COLUMNS } from 'components/DiamondList/table-config';

import { StoneService } from 'services/StoneService';

import { catchError, formatCurrency, formatDecimal, formatNumber, isArray, isEmpty, isObject } from 'util/utils';

import { getColumn } from './DiamondListFunctions';

export const getColumns = getColumn;

export const updateRecord = (record, options = {}) => {
  if (!isObject(record)) return clone(record);
  if (isObject(record.__originalBeforeUpdate__)) record = clone(record.__originalBeforeUpdate__);

  record = {
    ...record,
    ...options,
    __originalBeforeUpdate__: clone(record),
    __updateTs__: DateTime.local().toMillis(),
  };
  return StoneService.formatStoneRecord({ ...record });
};

export const updateList = (list, options = {}) => {
  if (!isArray(list)) return;
  return list.map((record) => updateRecord(record, options));
};

export const filterRecord = (record, remove = []) => {
  if (!isObject(record)) return clone(record);
  if (isObject(record.__originalBeforeUpdate__)) record = clone(record.__originalBeforeUpdate__);

  record = { ...record, __filterTs__: DateTime.local().toMillis() };

  const byUpdater = ['srNo'];
  const byGroupping = ['isFooter', 'isHeader', 'groupData', 'totalDiamonds', '_groupTitle_'];
  const byGroupType = ['isHoldHeader', 'isMatchHeader', 'userGroupHold'];
  const keysToDelete = [...byUpdater, ...byGroupping, ...byGroupType, ...(remove ?? [])];
  keysToDelete.forEach((key) => delete record[key]);
  return record;
};

export const filterList = (list, remove = []) => {
  if (!isArray(list)) return;
  return list.map((record) => filterRecord({ ...record }, remove));
};

export const getStickyStyle = memoize((noStatus = false) => {
  const stickyHeader = { position: 'sticky', zIndex: 2000 };
  const stickyCell = { position: 'sticky', backgroundColor: '#fff', zIndex: 1000 };
  const firstLeft = -10;
  const firstWidth = noStatus ? 40 : 140;
  const secondLeft = firstWidth + firstLeft - 5;
  const secondWidth = 160;
  const thirdLeft = firstWidth + secondWidth + firstLeft - 10;

  return {
    head: {
      first: { left: firstLeft + 'px', ...stickyHeader, minWidth: firstWidth + 'px' },
      second: { left: secondLeft + 'px', ...stickyHeader, minWidth: secondWidth + 'px' },
      third: { left: thirdLeft + 'px', ...stickyHeader },
    },
    cell: {
      first: { left: firstLeft + 'px', ...stickyCell, minWidth: firstWidth + 'px' },
      second: { left: secondLeft + 'px', ...stickyCell, minWidth: secondWidth + 'px' },
      third: { left: thirdLeft + 'px', ...stickyCell },
    },
  };
});

export const formatValues = (value, key, defaultReturn = '-') => {
  if (!value) return defaultReturn;

  return CURRENCY_COLUMNS.includes(key)
    ? formatCurrency(value)
    : FLOAT_COLUMNS.includes(key)
    ? formatDecimal(value)
    : ROUND_COLUMNS.includes(key)
    ? formatNumber(Math.round(value))
    : value;
};

export const filterGridColumns = (columns = [], options = {}) => {
  columns =
    (options?.dateList && options?.customerList) || options?.customerList || options?.inClient
      ? columns.filter((el) => !['user'].includes(el.id))
      : columns;

  return columns;
};

export const updateRowClassList = (accessor, rowId, select = false) =>
  catchError(() => {
    const tableRows = document.querySelectorAll(accessor ? `[table-row="${accessor}${rowId}"]` : `tr[table-row]`);
    if (!isEmpty(tableRows)) for (const row of tableRows) row.classList[select ? 'add' : 'remove']('selectTr');
  });

export const viewStoneCertFile = async (stone) => {
  const link = await StoneService.getCertVerifyLink(stone);
  return window.open(link, '_blank');
};
