import React from 'react';
import { useBoolean } from 'util/hooks';
import { isString } from 'util/utils';

import './embed.less';

function Embed(props) {
  const { tag = 'iframe', name, title, clickToOpen, hideOnError, ..._props } = props;
  const [error, setError] = useBoolean(false);
  const [show, setShow] = useBoolean(false);

  const ref = React.useRef();

  React.useEffect(() => {
    setTimeout(() => setShow.true(), 0);
    return () => setShow.false();
  }, [setShow]);

  React.useLayoutEffect(() => {
    setTimeout(() => {
      if (isString(ref.current?.src)) {
        ref.current.src = props?.src ?? ref.current.src;
      }
    }, 0);
  });

  const handleClick = React.useCallback(() => {
    if (clickToOpen) window.open(_props.src, '_blank');
  }, [clickToOpen, _props.src]);

  const component = React.useMemo(() => {
    return error
      ? _props?.children ?? <div className="no-msg">{name} Not available</div>
      : React.createElement(tag, {
          ref,
          onError: setError.true,
          style: { border: 'none' },
          title,
          ..._props,
        });
  }, [error, name, _props, title, setError, tag]);

  if (!show || (hideOnError && error)) return null;

  return clickToOpen ? (
    <div className="embed-wrapper">
      <div className="embed-overlay" title={title} onClick={handleClick} />
      {component}
    </div>
  ) : (
    component
  );
}

export default React.memo(Embed);
