import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { Drawer } from 'antd';
import _, { get, isString } from 'lodash';

import Logo from 'components/Header/Logo';
import MobileSidebar from 'components/MobileHeader/sidebar';
import CovidStrip from 'components/common/Header/CovidStrip';

import UtilService from 'services/util';

import { isEmpty, titleCase } from 'util/utils';

import { LOCAL_STORAGE_VAR } from 'constants/Common';
import { GET_DASHBOARD_DATA } from 'constants/apiConstant';

import { setLoader, covidStrip } from 'redux/actions/Common';

import supportSvg from 'assets/svg/Account/support.svg';
import notificationSvg from 'assets/svg/Header/notification-white.svg';
import searchSvg from 'assets/svg/Header/search-white.svg';
import userSvg from 'assets/svg/Header/user-white.png';
import menuSvg from 'assets/svg/MobileHeader/menu.svg';
import dollarCircleWhiteSvg from 'assets/svg/dollar-circle-white.svg';

class MobileHeader extends Component {
  state = {
    isHovered: false,
    isHovered1: false,
    isHovered2: false,
    visible: false,
    covid: true,
    HeaderStripList: true,
    visibleCredit: false,
    visibleSales: false,
    seller: {},
  };

  componentDidMount() {
    this.props.covidStrip(this.state.covid);
    this.getSidebarCounts();
  }

  closeStrip = () => {
    this.props.covidStrip(!this.props.data.common.strip);
    this.setState({ covid: false });
  };

  HeaderStripCick = () => {
    this.props.covidStrip(!this.props.data.common.strip);
    this.setState({ HeaderStripList: !this.state.HeaderStripList });
  };

  getSidebarCounts = () => {
    const request = {
      seller: true,
    };

    const objData = { ...GET_DASHBOARD_DATA, request };

    UtilService.callApi(objData, async (err, data) => {
      if (err) throw err;

      if (data && data.code === 'OK') {
        this.setState({
          seller: data.data.seller,
        });
      }
    });
  };

  showDrawer = () => this.setState({ visible: true });

  onClose = () => this.setState({ visible: false });

  handleHover = () => this.setState({ isHovered: !this.state.isHovered, isHovered1: false, isHovered2: false });

  handleHover1 = () => this.setState({ isHovered1: !this.state.isHovered1, isHovered: false, isHovered2: false });

  handleHover2 = () => this.setState({ isHovered2: !this.state.isHovered2, isHovered: false, isHovered1: false });

  handleOut = () => this.setState({ isHovered: false });

  handleOut1 = () => this.setState({ isHovered1: false });

  handleOut2 = () => this.setState({ isHovered2: false });

  render() {
    const { name, account } = UtilService.getLocalStorageItem(`user`);
    const { seller } = this.state;
    return (
      <>
        {this.state.covid && <CovidStrip onClick={this.closeStrip} />}
        <div className="headerTopMenu">
          <div className="headerWrapper">
            <div className="mobileMenu" onClick={this.showDrawer}>
              <img src={menuSvg} alt="menuIcon" />
            </div>
            <div className="headerLeft">
              <div className="headerLogo">
                <Link to="/" className="">
                  <Logo />
                </Link>
              </div>
              <div className="headerMobileIcon">
                <Link className="headerMobileIconItem" to="/diamond-search">
                  <img src={searchSvg} />
                </Link>
                {/* <a
                  onClick={() => {
                    this.setState({ visibleCredit: !this.state.visibleCredit });
                  }}
                  className="headerMobileIconItem"
                  to="/account"
                >
                  <img src={dollarCircleWhiteSvg} />
                </a> */}
                <a
                  onClick={() => {
                    this.setState({ visibleSales: !this.state.visibleSales });
                  }}
                  className="headerMobileIconItem"
                  to="/account"
                >
                  <img src={supportSvg} />
                </a>
                <Link className="headerMobileIconItem" to="/account">
                  <img src={userSvg} />
                </Link>
                <Link className="headerMobileIconItem" to="/notification">
                  <img src={notificationSvg} />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <Drawer
          title={false}
          onClose={this.onClose}
          visible={this.state.visible}
          wrapClassName="mobileSidebar mobileSidebarHeader"
        >
          <div>
            <MobileSidebar
              onClose={() => {
                this.setState({ visible: false });
              }}
            />
          </div>
        </Drawer>
        {this.state.visibleCredit && (
          <Drawer
            title={false}
            onClose={() => {
              this.setState({ visibleCredit: false });
            }}
            visible={this.state.visibleCredit}
            wrapClassName="stone-detail-sidebarChange sm-size"
          >
            <div style={{ fontWeight: 'bold', color: 'black' }} className="menuItem">
              Credit Limit: ${account?.crdLmt}
            </div>
          </Drawer>
        )}
        {this.state.visibleSales && (
          <Drawer
            title={false}
            onClose={() => {
              this.setState({ visibleSales: false });
            }}
            visible={this.state.visibleSales}
            wrapClassName="stone-detail-sidebarChange sm-size"
          >
            {name && (
              <>
                <div className="salesPersonInnerItem">
                  {name && (
                    <div style={{ color: 'black', fontWeight: 250 }} className="salespersonInnerDetail">
                      Hello, {name.toLocaleUpperCase()}
                    </div>
                  )}
                </div>
                <div className="salesPersonInnerItem">
                  {this.state.seller && (this.state.seller.firstName || this.state.seller.lastName) && (
                    <div style={{ color: 'black', fontWeight: 250 }} className="salespersonInnerDetail">
                      Your customer representative is,&nbsp;
                    </div>
                  )}
                </div>
                <div className="salesPersonInnerItem">
                  {this.state.seller && (this.state.seller.firstName || this.state.seller.lastName) && (
                    <div className="salespersonInnerDetail" style={{ color: 'black', fontWeight: 700 }}>
                      {isString(this.state.seller.firstName) && titleCase(this.state.seller.firstName)}
                      &nbsp;
                      {isString(this.state.seller.lastName) && titleCase(this.state.seller.lastName)}
                      {!isEmpty(seller.mobile) && <span>&nbsp;|&nbsp;</span>}
                      <a style={{ color: 'black', fontWeight: 700 }} href={`tel:${this.state.seller.mobile}`}>
                        {seller.mobile}
                      </a>
                      <br />
                      {!isEmpty(seller.email) && (
                        <a style={{ color: 'black', fontWeight: 700 }} href={`mailto:${seller.email}`}>
                          Email&nbsp;:&nbsp;{seller.email}
                        </a>
                      )}
                      <br />
                      {!isEmpty(seller.skype) && (
                        <a style={{ color: 'black', fontWeight: 700 }} href={`skype:${seller.skype}`}>
                          Skype&nbsp;:&nbsp;{seller.skype}
                        </a>
                      )}
                    </div>
                  )}
                </div>
              </>
            )}
          </Drawer>
        )}
      </>
    );
  }
}

function mapStateToProps(props) {
  return { data: props };
}

export default connect(mapStateToProps, { setLoader, covidStrip })(MobileHeader);
