import React, { useState, useEffect, useRef } from 'react';
import Slider from 'react-slick';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col } from 'reactstrap';

import classnames from 'classnames';
import { split } from 'lodash';

import VideoPlayer from 'components/MediaPlayer/VideoPlayer';

import IntlMessage from 'util/IntlMessages';

import Embed from './Embed';
import MagnifierImage from './MagnifierImage';

import { FILE_URLS } from '../../constants/Common';
import IFrame from '../common/IFrame';
import Image from '../common/Image';

import '../common/Tab/tab.less';

const DiamondDetailLeft = (props) => {
  const [activeTab, setActiveTab] = useState('1');
  const [data, setData] = useState({});
  const [main1, setMain1] = useState('');
  const [main2, setMain2] = useState('');
  const [ha1, setHa1] = useState('');
  const [ha2, setHa2] = useState('');
  const [main1Bool, setMain1Bool] = useState(false);
  const [main2Bool, setMain2Bool] = useState(false);
  const [ha1Bool, setHa1Bool] = useState(false);
  const [ha2Bool, setHa2Bool] = useState(false);
  useEffect(() => {
    if (props.data) {
      setData({ ...props.data });
    }
  }, [props.data]);
  useEffect(() => {
    if (activeTab) {
      if (activeTab === '2') {
        setMain1(data?.files?.img);
        // setMain2(FILE_URLS.lightBlack.replace("***", props.data.mfgStnId));
      }
      if (activeTab === '3') {
        setHa1(FILE_URLS.arrowFile.replace('***', data.mfgStnId));
        setHa2(FILE_URLS.hAFile.replace('***', data.mfgStnId));
      }
    }
  }, [activeTab]);
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      props.activeTabChange && props.activeTabChange(tab);
    }
  };

  const [, setNav1] = useState();
  const [nav2] = useState();
  const [nav5, setNav5] = useState();
  const [nav6, setNav6] = useState();

  const isDiaCamLink = (data.files?.videoFile?.match?.('up.diacam360.com')?.index ?? -1) > -1;
  const videoLink = data?.videoFile && isDiaCamLink ? `${data?.files?.videoFile}/video` : data?.files?.videoFile;

  return (
    <>
      <Nav tabs className="customerMaintab diamondDetailTopTab">
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '1' })}
            onClick={() => {
              toggle('1');
            }}
          >
            <IntlMessage id="app.Image" />
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '2' })}
            onClick={() => {
              toggle('2');
            }}
          >
            <IntlMessage id="app.Video" />
          </NavLink>
        </NavItem>
        {/* <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "3" })}
            onClick={() => {
              toggle("3");
            }}
          >
            H&A
          </NavLink>
        </NavItem> */}
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '4' })}
            onClick={() => {
              toggle('4');
            }}
          >
            <IntlMessage id="app.Certificate" />
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab} style={props.style}>
        <TabPane tabId="1">
          {activeTab === '1' && (
            <>
              <Slider
                asNavFor={nav6}
                ref={(slider5) => {
                  setNav5(slider5);
                }}
                arrows={false}
              >
                {data?.files?.img ? (
                  <div className="diamondDetailImageBox">
                    <MagnifierImage image={data?.files?.img} />
                  </div>
                ) : (
                  <div className="diamondDetailImageBox no-msg">No Image available.</div>
                )}
              </Slider>
              <Slider
                asNavFor={nav5}
                ref={(slider6) => setNav6(slider6)}
                slidesToShow={2}
                swipeToSlide={true}
                focusOnSelect={true}
                className="smallSliderBlock smallSliderBlockimage"
                beforeChange={props.currentSubTab}
              >
                {!main1Bool && (
                  <React.Fragment>
                    <div className="smallSliderImage">
                      <img
                        onError={() => {
                          setMain1Bool(true);
                          setMain1(require('./traffic-signal.svg'));
                        }}
                        src={main1}
                        alt=""
                      />
                    </div>
                  </React.Fragment>
                )}
                {!main2Bool && (
                  <React.Fragment>
                    <div className="smallSliderImage">
                      <img
                        onError={() => {
                          setMain2Bool(true);
                          setMain2(require('./traffic-signal.svg'));
                        }}
                        src={main2}
                        alt=""
                      />
                    </div>
                  </React.Fragment>
                )}
              </Slider>
            </>
          )}
        </TabPane>
        <TabPane tabId="2">
          {activeTab === '2' && (
            <Slider asNavFor={nav2} infinite={false} arrows={false} ref={(slider1) => setNav1(slider1)}>
              {data?.videoFile ? (
                <div className="diamondDetailImageBox htmlViewImage">
                  {isDiaCamLink ? (
                    <Embed src={videoLink} aspectRatio="4:3" autoPlay loop controls responsive />
                  ) : (
                    <Embed src={videoLink} aspectRatio="4:3" autoplay loop controls responsive />
                  )}
                </div>
              ) : (
                <div className="diamondDetailImageBox no-msg">No video available.</div>
              )}
            </Slider>
          )}
        </TabPane>
        {/* <TabPane tabId="3">
          {activeTab === "3" && (
            <>
              <Slider
                asNavFor={nav4}
                ref={(slider3) => setNav3(slider3)}
                arrows={false}
              >
                <div className="diamondDetailImageBox">
                  <MagnifierImage
                    tempSrc={require("../../assets/svg/camera.svg")}
                    image={FILE_URLS.arrowFile.replace("***", data.mfgStnId)}
                  />
                </div>
                <div className="diamondDetailImageBox">
                  <MagnifierImage
                    tempSrc={require("../../assets/svg/camera.svg")}
                    image={FILE_URLS.hAFile.replace("***", data.mfgStnId)}
                  />
                </div>
              </Slider>
              <Slider
                asNavFor={nav3}
                ref={(slider4) => setNav4(slider4)}
                slidesToShow={2}
                swipeToSlide={true}
                focusOnSelect={true}
                // infinite={false}
                className="smallSliderBlock smallSliderBlockimage"
                beforeChange={props.currentSubTab}
              >
                <React.Fragment>
                  {!ha1Bool && (
                    <div className="smallSliderImage">
                      <img
                        onError={() => {
                          setHa1Bool(true);
                          setHa1(require("./traffic-signal.svg"));
                        }}
                        src={ha1}
                        alt=""
                      />
                    </div>
                  )}
                </React.Fragment>
                <React.Fragment>
                  {!ha2Bool && (
                    <div className="smallSliderImage">
                      <img
                        onError={() => {
                          setHa2Bool(true);

                          setHa2(require("./traffic-signal.svg"));
                        }}
                        src={ha2}
                        alt=""
                      />
                    </div>
                  )}
                </React.Fragment>
              </Slider>
            </>
          )}
        </TabPane> */}

        <TabPane tabId="4">
          {data?.certFile ? (
            <div className="diamondDetailImageBox">
              {data?.files?.certFile.split('.').pop()?.toLowerCase() === 'pdf' ? (
                <iframe
                  src={data?.files?.certFile}
                  height="100%"
                  width="100%"
                  title="certi"
                  scrolling="no"
                  frameBorder="0"
                />
              ) : (
                <MagnifierImage
                  data-role="imagemagnifier"
                  data-magnifier-mode="glass"
                  data-lens-type="circle"
                  data-lens-size="800"
                  image={data.files?.certFile}
                  type="CERT"
                />
              )}
            </div>
          ) : (
            <div className="imageSliderFullBlock no-msg">No certificate available.</div>
          )}
        </TabPane>
      </TabContent>
    </>
  );
};

export default DiamondDetailLeft;
