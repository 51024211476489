import React from 'react';

import { Drawer } from 'antd';
import findLastIndex from 'lodash/findLastIndex';
import groupBy from 'lodash/groupBy';
import memoize from 'lodash/memoize';
import QRCode from 'qrcode.react';

import { handleSearch } from 'containers/APIFunctions';

import DiamondDetail from 'components/DiamondDetail';
import Image from 'components/DiamondDetail/Image';
import { getParams, LISTINGPAGES } from 'components/DiamondList/DiamondListFunctions';
import CheckBox from 'components/common/CheckBox';
import { STATUS, STATUS_COLOR } from 'components/common/DiamondListing/Status';

import { RowSelectService } from 'services/RowSelectService';

import { history } from 'util/history';
import { useCompositeState } from 'util/hooks';
import { useRowSelect } from 'util/useRowSelect';
import { classNames, formatCurrency, formatDecimal, isEmpty, isNotEmpty, isNumber } from 'util/utils';

import { LAB_LINKS } from 'constants/Common';
import { PAGES } from 'constants/paths.const';

import noImageSvg from 'assets/svg/noimage.svg';

function getPathname() {
  return `${window?.location?.pathname}`?.split?.('/')?.pop?.()?.split('?')?.[0];
}

function comparePathname(pathnames) {
  pathnames = Array.isArray(pathnames) ? pathnames : [pathnames];
  return pathnames.includes(getPathname() || undefined);
}

function StoneGridView(props) {
  const { areAllChecked, currentType, data, isMatchPair } = props;

  const [visible, setVisible, resetVisible] = useCompositeState({});

  const { isRowChecked, toggleRowSelection } = useRowSelect(props.currentType);

  const getHref = React.useMemo(() => {
    return memoize(
      (stone) => {
        return stone?.lbNm && stone?.rptNo ? LAB_LINKS[stone.lbNm.toUpperCase()]?.replace('***', stone.rptNo) : '';
      },
      (stone) => `${stone?.lbNm}|${stone?.rptNo}`,
    );
  }, []);

  const getDnaHref = React.useMemo(() => {
    return memoize(
      (stone) => `/dna/${comparePathname(PAGES.CONFIRM) ? stone?.diamondId : stone?.id}`,
      (stone) => `${stone?.id}${stone?.diamondId}`,
    );
  }, []);

  const handleSearchList = React.useMemo(() => {
    return memoize(
      (vStnId = []) => {
        return () => {
          if (isEmpty(vStnId)) return true;

          handleSearch(
            {
              isFcCol: false,
              layoutNo: ['', '0'],
              pairStkNo: { '!=': '' },
              or: [{ vStnId }, { rptNo: vStnId }],
            },
            (cb) => {
              if (cb.NOSTONE !== 'NOSTONE') {
                history.push(`/${LISTINGPAGES.PREDEFINEMATCHPAIR}/${cb.id}`, { viewList: true });
              }
            },
          );
        };
      },
      (vStnId) => JSON.stringify(vStnId?.sort?.() ?? vStnId),
    );
  }, []);

  const getStoneDetails = React.useCallback(
    (stone, stoneIds = []) => {
      return (
        <div className="hr-matchpair-top">
          <div className="d-flex flex-row j-space-between flex-wrap width-full mt-2">
            <a
              target="_blank"
              rel="noreferrer"
              className="link"
              style={{ display: 'flex' }}
              onClick={handleSearchList(stoneIds.filter(isNotEmpty))}
            >
              <div onClick={() => setVisible(stone)}>
                <strong>{stone.vStnId}</strong>
              </div>
              <span>
                {!isEmpty(stone.layoutNo) && `${stone.layoutNo}` !== '0' ? (
                  <div>
                    {/* <Tooltip title="Layout">
                      <img style={{ minWidth: '20px', height: '20px', marginLeft: '5px' }} src={layoutSvg} alt="" />
                    </Tooltip> */}
                  </div>
                ) : !isEmpty(stone.groupNo) ? (
                  <div>
                    <a target="_blank" rel="noreferrer" href={`/dna/${stone?.id}`} style={{ marginLeft: '5px' }}>
                      <span>DNA</span>
                    </a>
                    {/* <Tooltip title="Match pair">
                      <img style={{ minWidth: '20px', height: '20px', marginLeft: '5px' }} src={matchPairSvg} alt="" />
                    </Tooltip> */}
                  </div>
                ) : null}
              </span>
            </a>

            <div className="status-container" style={{ height: '40px' }}>
              <p>
                {formatDecimal(stone.crt)}
                {'  '}
                {getParams(stone, true).join(' ')}
              </p>
              <p className="flex-end">
                {[
                  stone?.msrmnt ? stone?.msrmnt : undefined,
                  isNumber(Number(stone?.ratio)) ? formatDecimal(Number(stone?.ratio)) : undefined,
                  stone?.depPer ? `${parseFloat(stone?.depPer).toFixed(2)}%` : undefined,
                  stone?.tblPer ? `${parseFloat(stone?.tblPer).toFixed(2)}%` : undefined,
                ]
                  .filter(isNotEmpty)
                  .join(' | ')}
              </p>
              {!comparePathname('upcoming') && (
                <div className="d-flex flex-row j-space-between flex-wrap width-full flex-end">
                  <p>
                    {[
                      isNumber(Number(stone.back)) ? `${parseFloat(stone?.back).toFixed(2)}%` : '-',
                      isNumber(Number(stone.ctPr)) ? `${formatDecimal(stone.ctPr)} $/Ct` : '-',
                      isNumber(Number(stone.amt), true) ? `${formatDecimal(stone.amt)} $` : '-',
                    ]
                      .filter(isNotEmpty)
                      .join(' | ')}
                  </p>
                </div>
              )}
            </div>
          </div>

          <div className="d-flex flex-row j-space-between flex-wrap width-full">
            <span className="status-container">
              <a target="_blank" rel="noreferrer" href={getHref(stone)}>
                <span className="statusShow" style={{ marginLeft: '0' }}>
                  {stone.lbNm} | {STATUS[stone.wSts]}
                  <span className={classNames(['statusIcon', STATUS_COLOR[stone.wSts]])}></span>
                </span>
              </a>
            </span>

            {/* <div className="status-container">
              <p className="flex-end">
                {[stone?.msrmnt, isNumber(Number(stone?.ratio)) ? formatDecimal(Number(stone?.ratio)) : undefined]
                  .filter(isNotEmpty)
                  .join(' | ')}
              </p>
              {!comparePathname('upcoming') && (
                <div className="d-flex flex-row j-space-between flex-wrap width-full flex-end">
                  <p>
                    {[
                      isNumber(Number(stone.ctPr)) ? formatCurrency(Math.round(stone.ctPr)) : '-',
                      isNumber(Number(stone.amt), true) ? formatCurrency(Math.round(stone.amt)) : '-',
                    ]
                      .filter(isNotEmpty)
                      .join(' | ')}
                  </p>
                </div>
              )}
            </div> */}
          </div>
        </div>
      );
    },
    [getHref, handleSearchList, setVisible],
  );

  React.useEffect(() => {
    if (!isEmpty(currentType)) {
      if (areAllChecked && !isEmpty(data)) {
        RowSelectService.selectRows(currentType, data, true);
      } else {
        RowSelectService.selectRows(currentType);
      }
    } else {
      RowSelectService.resetSelectedRows(currentType);
    }
  }, [areAllChecked, currentType, data]);

  if (props.loading) return null;

  return (
    <div className={classNames(['stone-grid-view', isMatchPair ? 'match-pair-grid-view' : 'single-stone-grid-view'])}>
      {isMatchPair
        ? Object.entries(groupBy(data, 'groupNo'))?.map?.(([, stones]) => {
            const [stone1, stone2] = stones;
            const isChecked = isRowChecked(stone1.id);
            return (
              <div key={`grid_${stone1.id}`} className="stone-grid-item">
                <div className="stone-grid-image dubble-image">
                  <div className="stone-grid-checkbox">
                    <CheckBox
                      checked={isChecked}
                      onChange={(e) => {
                        !e.target.checked
                          ? RowSelectService.unSelectRows(props.currentType, stones)
                          : RowSelectService.selectRows(props.currentType, stones);
                      }}
                    />
                  </div>

                  <Image
                    src={isEmpty(stone1?.files?.img) ? noImageSvg : stone1?.files?.img}
                    style={isEmpty(stone1?.files?.img) ? { width: '86px', cursor: 'pointer' } : { cursor: 'pointer' }}
                    onClick={handleSearchList([stone1.vStnId, stone2?.vStnId].filter(isNotEmpty))}
                    alt=""
                  >
                    <div className="d-flex w-100 combain-img">
                      <img src={noImageSvg} style={{ width: '86px' }} alt="" />
                      <img src={noImageSvg} style={{ width: '86px' }} alt="" />
                    </div>
                  </Image>
                  <Image
                    src={isEmpty(stone2?.files?.img) ? noImageSvg : stone2?.files?.img}
                    style={isEmpty(stone2?.files?.img) ? { width: '86px', cursor: 'pointer' } : { cursor: 'pointer' }}
                    onClick={handleSearchList([stone1.vStnId, stone2?.vStnId].filter(isNotEmpty))}
                    alt=""
                  >
                    <div className="d-flex w-100 combain-img">
                      <img src={noImageSvg} style={{ width: '86px' }} alt="" />
                      <img src={noImageSvg} style={{ width: '86px' }} alt="" />
                    </div>
                  </Image>
                </div>
                <div className="stone-grid-content">
                  <QRCode size={80} value={`${window.location.origin}/dna/${stone1?.id}`} level="L" />
                  <div className="gridLeftContent">
                    <div className="status-container">
                      <p style={{ display: 'flex' }}>
                        <h2>
                          {isEmpty(stone1.shpNm) ? 'OTHER' : stone1.shpNm}
                          {/* <a target="_blank" rel="noreferrer" href={`/dna/${stone1?.id}`}>
                            <img src={dnaImg} alt="wLink" className="ml-5" />
                          </a> */}
                        </h2>
                      </p>
                    </div>
                    <div className="status-container">
                      <p>
                        {stones.length} | {formatDecimal(stone1?.crt + (stone2?.crt || 0))} ct
                      </p>
                    </div>
                    <div className="status-container">
                      <p>
                        Avg $/CT {' : '}
                        {formatCurrency((stone1?.amt + (stone2?.amt ?? 0)) / (stone1?.crt + (stone2?.crt ?? 0)))}
                      </p>
                    </div>
                    <div className="status-container">
                      <p>
                        Total Amt{' : '}
                        {formatCurrency(stone1?.amt + (stone2?.amt ?? 0))}
                      </p>
                    </div>
                  </div>
                  <QRCode size={80} value={`${window.location.origin}/dna/${stone2?.id}`} level="L" />
                  {stones.slice(0, 2).map((stone) => {
                    return getStoneDetails(
                      stone,
                      stones.map((s) => s.vStnId),
                    );
                  })}
                </div>
              </div>
            );
          })
        : data?.map?.((stone, index, list) => {
            const isChecked = isRowChecked(stone.id);

            const lastExactSearchIndex = findLastIndex(list, (record) => Boolean(record.isExactSearch));
            const searchResultEnd = lastExactSearchIndex === index && data.length - 1 !== index;
            const similarStoneCount = data?.length - (lastExactSearchIndex + 1);

            const card = (
              <div key={`grid_${stone.id}`} className="stone-grid-item">
                <div className="stone-grid-image">
                  <div className="stone-grid-checkbox">
                    <CheckBox checked={isChecked} onChange={() => toggleRowSelection(stone, !isChecked)} />
                  </div>
                  <Image
                    src={isEmpty(stone?.files?.img) ? noImageSvg : stone?.files?.img}
                    alt=""
                    style={isEmpty(stone?.files?.img) ? { width: '86px' } : {}}
                  >
                    <img src={noImageSvg} style={{ width: '86px' }} alt="" />
                  </Image>
                </div>
                <div className="stone-grid-content">
                  <div className="d-flex flex-row j-space-between flex-wrap width-full">
                    <div>
                      <p style={{ display: 'flex' }}>
                        <h2>
                          {isEmpty(stone.shpNm) ? 'OTHER' : stone.shpNm}
                          {/* <a target="_blank" rel="noreferrer" href={`/diamond-details/${stone?.id}`}>
                            <img src={dnaImg} alt="wLink" className="ml-5" />
                          </a> */}
                        </h2>
                      </p>
                    </div>
                    {/* <Tooltip
                      className="tool"
                      title={[stone?.fcColNm, stone?.intenNm, stone?.ovrtnNm].filter(isNotEmpty).join(' | ')}
                    > */}
                    <div className="status-container">
                      <span>
                        <b>{`${formatDecimal(stone.crt)}`}</b>
                      </span>
                      &nbsp;
                      <span>
                        <b>{getParams(stone).join(' ')}</b>
                      </span>{' '}
                      |{' '}
                      <a
                        rel="noreferrer"
                        target="_blank"
                        className="IGI-grid"
                        href={getHref(stone)}
                        // style={{ display: 'flex', justifyContent: 'flex-end' }}
                      >
                        <span className="IGI-grid">
                          <u>
                            <b>{stone.lbNm}</b>
                          </u>
                        </span>
                      </a>
                      {/* {!comparePathname('upcoming') && (
                        <span className="statusShow">
                          {[
                            isNumber(Number(stone.ctPr)) ? `${formatCurrency(Math.round(stone.ctPr))}/Ct` : '-',
                            isNumber(Number(stone.amt), true) ? `${formatCurrency(Math.round(stone.amt))} Amt` : '-',
                          ]
                            .filter(isNotEmpty)
                            .join(' | ')}
                        </span>
                      )} */}
                    </div>
                    {/* </Tooltip> */}
                  </div>
                  <div className="d-flex flex-row j-space-between flex-wrap width-full">
                    <b style={{ display: 'flex' }}>
                      <small
                        target="_blank"
                        rel="noreferrer"
                        className="link cursor-pointer"
                        onClick={() => setVisible(stone)}
                      >
                        <u>{stone.vStnId}</u>
                      </small>
                      {!isEmpty(stone.layoutNo) && `${stone.layoutNo}` !== '0' ? (
                        <div style={{ position: 'relative' }}>
                          {/* <Tooltip title="Layout">
                            <img
                              style={{ minWidth: '20px', height: '20px', marginLeft: '5px' }}
                              src={layoutSvg}
                              alt=""
                            />
                          </Tooltip> */}
                        </div>
                      ) : !isEmpty(stone.groupNo) ? (
                        <div style={{ position: 'relative' }}>
                          {/* <Tooltip title="Match pair">
                            <img
                              style={{ minWidth: '20px', height: '20px', marginLeft: '5px' }}
                              src={matchPairSvg}
                              alt=""
                            />
                          </Tooltip> */}
                        </div>
                      ) : null}
                    </b>
                    {/* <div className="gridRightContent">
                      <p>{getParams(stone).join(' ')}</p>
                    </div> */}
                    <span className="statusShow">
                      {[
                        stone?.msrmnt ? stone?.msrmnt : undefined,
                        isNumber(Number(stone?.ratio)) ? formatDecimal(Number(stone?.ratio)) : undefined,
                      ]
                        .filter(isNotEmpty)
                        .join(' | ')}
                    </span>
                  </div>

                  <div className="d-flex flex-row j-space-between flex-wrap width-full">
                    <QRCode size={80} value={`${window.location.origin}/dna/${stone?.id}`} level="L" />
                    <div className="d-flex flex-column flex-wrap">
                      <div className="status-container">
                        <span className="statusShow">
                          {[
                            stone?.depPer ? `${parseFloat(stone?.depPer).toFixed(2)}%` : undefined,
                            stone?.tblPer ? `${parseFloat(stone?.tblPer).toFixed(2)}%` : undefined,
                          ]
                            .filter(isNotEmpty)
                            .join(' | ')}
                        </span>
                      </div>
                      <div className="status-container">
                        <span className="statusShow">
                          {[stone?.back ? `${parseFloat(stone?.back).toFixed(2)}%` : undefined]}
                          <b> {stone?.isFcCol ? stone?.fcColDesc : undefined}</b>
                        </span>
                      </div>
                      <div className="status-container">
                        {!comparePathname('upcoming') && (
                          <span className="statusShow">
                            {[
                              isNumber(Number(stone.ctPr)) ? `${formatDecimal(stone.ctPr)} $/Ct` : '-',
                              isNumber(Number(stone.amt), true) ? `${formatDecimal(stone.amt)} $` : '-',
                            ]
                              .filter(isNotEmpty)
                              .join(' | ')}
                          </span>
                        )}
                      </div>
                      <div className="status-container">
                        <a
                          rel="noreferrer"
                          target="_blank"
                          href={getHref(stone)}
                          style={{ display: 'flex', justifyContent: 'flex-end' }}
                        >
                          {/* <span className="IGI-grid">{stone.lbNm}</span> | */}
                          <span className="statusShow">
                            {/* <u>{stone.lbNm}</u>
                            &nbsp;|&nbsp; */}
                            <span className={classNames(['statusIcon', STATUS_COLOR[stone.wSts]])}></span>
                            {STATUS[stone.wSts]}
                          </span>{' '}
                          &nbsp;|&nbsp;
                          <div className="status-container">
                            <a target="_blank" rel="noreferrer" href={getDnaHref(stone)}>
                              <span className="dna">
                                <u>DNA</u>
                              </span>
                            </a>
                          </div>
                        </a>
                      </div>
                      {/* <div className="status-container">
                        <a target="_blank" rel="noreferrer" href={getDnaHref(stone)}>
                          <span style={{ color: 'rgb(0, 140, 186)' }}>DNA</span>
                        </a>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            );

            const saperator = Boolean(searchResultEnd && similarStoneCount) && (
              <div className="saperator width-full">
                <div className="label">Similar Stones ({similarStoneCount})</div>
              </div>
            );

            return [card, saperator];
          })}

      <Drawer onClose={resetVisible} visible={!isEmpty(visible)} wrapClassName="diamondDetailPopup" destroyOnClose>
        {!isEmpty(visible) && <DiamondDetail markAsSeen diamondPopup data={visible} onClose={resetVisible} />}
      </Drawer>
    </div>
  );
}

export default React.memo(StoneGridView);
