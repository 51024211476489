import React, { Component } from 'react';

import { Drawer } from 'antd';
import { isEmpty, head, last, split, map } from 'lodash';
import moment from 'moment';

import UtilService from 'services/util';

import IntlMessages from 'util/IntlMessages';

import { LOCAL_STORAGE_VAR } from 'constants/Common';
import { CREATE_SCHEDULE, GET_SLOTS } from 'constants/apiConstant';

import SlotItem from './SlotItem';

import OpenNotification from '../../CommonButton/OpenNotification';
import Heading from '../../Heading';
import LeftDatePicker from '../../LeftMultipleSelect/LeftDatePicker';
import SelectOption from '../../SelectOption';
import TextArea from '../../TextArea';

export const Appointment = {
  location: [{ name: 'India' }, { name: 'Spain' }, { name: 'Hong Kong' }, { name: 'Dubai' }],
  virtualType: [
    { name: 'Phone Call', id: 1 },
    { name: 'Web Conference', id: 2 },
    { name: 'In Person', id: 3 },
  ],
};
export const OfficeFill = ({ onClose, onChangeType, valueType, commentValue, onChangeComment, handleSave }) => {
  return (
    <div>
      <div className="offerInputBox">
        <SelectOption
          selectValue={Appointment.virtualType}
          placeholder={<IntlMessages id="app.SelectVirtualType" />}
          label={<IntlMessages id="app.SelectVirtualType" />}
          value={valueType}
          onChange={onChangeType}
        ></SelectOption>
        <TextArea value={commentValue} onChange={onChangeComment} label="Comment" placeholder="Comment"></TextArea>
      </div>
      <div className="sideBarPopupButton">
        <a onClick={handleSave} className="commonButton">
          <IntlMessages id="app.RequestAppointment" />
        </a>
        <a onClick={onClose} className="commonOutline">
          Cancel
        </a>
      </div>
    </div>
  );
};
class OfficeSidebar extends Component {
  constructor() {
    super();
    this.state = {
      date: moment().toISOString(),
      slot: '',
      comment: '',
      slots: [],
      apiSlot: {},
      meetingType: undefined,
      bidSlots: [],
      office: false,
    };
  }
  onClose = () => {
    this.setState({
      office: false,
    });
  };
  officeView = () => {
    if (this.state.slot) {
      this.setState({
        office: true,
      });
    } else {
      return OpenNotification({
        type: 'error',
        title: 'Please select slot.',
      });
    }
  };

  componentDidMount = () => {
    const self = this;
    const objData = {
      ...GET_SLOTS,
      request: { sort: [{ end: 'ASC' }] },
    };
    UtilService.callApi(objData, async (err, data) => {
      if (err) throw err;
      if (data && data.code === 'OK') {
        const bidSlots = [];
        data.data.list.map((ls) => {
          const start = moment(ls.start).format('hh:mm A');
          const end = moment(ls.end).format('hh:mm A');
          const bidTime = start < '11:00 AM' && end > '03:00 PM';
          if (!bidTime) {
            bidSlots.push(ls);
          }
        });

        self.setState({ slots: data.data.list, bidSlots });
      }
    });
  };

  handleSave = () => {
    const self = this;
    const { comment, slot, date, apiSlot, meetingType } = this.state;

    if (!meetingType) {
      return OpenNotification({
        type: 'error',
        title: 'Please Select Appointment Type.',
      });
    }
    if (!slot)
      return OpenNotification({
        type: 'error',
        title: 'Please select slot.',
      });

    if (!date)
      return OpenNotification({
        type: 'error',
        title: 'Please select date.',
      });

    const start = moment(`${date} ${head(split(slot, ' - '))}`, 'YYYY-MM-DD HH:mm:ss A').toISOString();
    const end = moment(`${date} ${last(split(slot, ' - '))}`, 'YYYY-MM-DD HH:mm:ss A').toISOString();

    const obj = {
      purpose: comment,
      date: moment(date).toISOString(),
      type: 2,
      start: start,
      end: end,
      cabinSlot: [apiSlot],
      diamonds: map(this.props.checked, 'id'),
      meetingType: meetingType,
    };

    const objData = {
      ...CREATE_SCHEDULE,
      request: obj,
    };

    UtilService.callApi(objData, (err, data) => {
      if (err) {
        return OpenNotification({
          type: 'error',
          title: data ? data.data.message : err && err.data ? err.data.message : '',
        });
      }
      if (data && data.code === 'OK') {
        OpenNotification({
          type: 'success',
          title: data.data.message,
        });
        self.props.onClose();
        self.props.clearAll();
      }
    });
  };

  getWorkingDays = (workingDays) => {
    const workingDates = [];
    let date = moment(new Date());
    date = date.subtract(1, 'days');
    while (workingDays > 0) {
      date = date.add(1, 'days');
      if (date.isoWeekday() !== 7) {
        workingDays -= 1;
        workingDates.push(moment(date).toISOString());
      }
    }
    return workingDates;
  };

  render() {
    const isMobile = window.innerWidth <= 991;
    const { account = {} } = JSON.parse(window.atob(localStorage.getItem(`${LOCAL_STORAGE_VAR}-user`)));
    const finalSlots = this.props.bid ? this.state.bidSlots : this.state.slots;
    const workingDays = this.props.quoteDay ? 3 : 7;

    return (
      <>
        <div className="appoinmentOfficeRightTop">
          <Heading className="popupInnerTitle" title={<IntlMessages id="app.Appointment" />}></Heading>
          <span className="companyName">{!isEmpty(account) ? `(${account?.companyName})` : ''}</span>
          <span className="appointmentDate">{moment(this.state.date).format('ddd, MMM Do YYYY')}</span>
        </div>
        <div className="officeBookWrapper">
          {!this.props.bid && (
            <div className="officeBookLeft">
              <div className="officeDateListing">
                <div className="officeTopLeft">
                  <LeftDatePicker
                    date={this.state.date ? this.state.date : ''}
                    Singledate
                    title={'Select Date'}
                    onDateChange={(dt) => {
                      this.setState({ date: moment(dt).toISOString() });
                    }}
                    disabledDate={(current) => {
                      return (current && current <= moment().startOf('day')) || moment(current).isoWeekday() === 7;
                    }}
                  />
                </div>
                <div className="cardListinOffice">
                  {this.getWorkingDays(workingDays).map((date) => (
                    <div
                      onClick={() => this.setState({ date })}
                      className={`cardListOfficeItem ${
                        moment(this.state.date).format('DD/MM/YYYY') === moment(date).format('DD/MM/YYYY') && `active`
                      }`}
                    >
                      <div className="cardListOfficeTop">
                        <div className="cardListOfficeDate">{moment(date).format('ddd, MMM Do YYYY')}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          <div className="officeBookRight">
            <div className="soltWrapper">
              <div className="soltBookLeft">
                <div className="officeBookSolt smallScroll grayScroll">
                  <div className="officeBookSoltItem">
                    <div className="officeBookSoltTitle">
                      <IntlMessages id="app.SelectTimeSlot" />
                    </div>
                    <div className="officeBookSoltInner">
                      {finalSlots.map((d, index) => {
                        const start = moment(d.start).format('hh:mm A');
                        const end = moment(d.end).format('hh:mm A');
                        const display = `${start} - ${end}`;
                        const disabled = moment(`${moment(this.state.date).format('YYYY-MM-DD')} ${start}`) < moment();
                        return (
                          <SlotItem
                            key={index}
                            onClick={() => this.setState({ slot: display, apiSlot: d })}
                            time={display}
                            className={this.state.slot === display ? `soltBook` : disabled ? `allReadyBook` : ''}
                            tooltip={<IntlMessages id="app.customerCare.BookAppointment" />}
                          />
                        );
                      })}
                    </div>
                  </div>
                  {/* <OfficeSlot title={<IntlMessages id="app.Appointment" />} data={timeSlot.slot1} /> */}
                </div>
                {isMobile ? (
                  <div className="sideBarPopupButton">
                    <a className="commonButton" onClick={this.officeView}>
                      <IntlMessages id="app.RequestAppointment" />
                    </a>
                  </div>
                ) : (
                  <div className="selectVirtualAction">
                    <div className="commonTextArea from-group">
                      <label>
                        <IntlMessages id="app.comment" />
                      </label>
                      <IntlMessages id="app.comment">
                        {(placeholder) => (
                          <textarea
                            placeholder={placeholder}
                            onChange={(e) => this.setState({ comment: e.target.value })}
                          ></textarea>
                        )}
                      </IntlMessages>
                    </div>
                    <div className="d-flex align-items-center j-space-between mt-20">
                      <SelectOption
                        value={this.state.meetingType}
                        placeholder={<IntlMessages id="app.SelectVirtualType" />}
                        //defaultValue={<IntlMessages id="app.SelectVirtualType" />}
                        selectValue={Appointment.virtualType}
                        label={<IntlMessages id="app.AvailableRoom" />}
                        onChange={(value) => this.setState({ meetingType: value })}
                      />
                      <button onClick={() => this.handleSave()}>
                        <IntlMessages id="app.RequestAppointment" />
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {this.state.office && (
          <Drawer
            title={false}
            onClose={this.onClose}
            visible={this.state.office}
            wrapClassName="stone-detail-sidebar smallSidebarMobilePopup"
          >
            <OfficeFill
              onClose={this.props.onClose}
              onChangeType={(value) => this.setState({ meetingType: value })}
              valueType={this.state.meetingType}
              commentValue={this.state.comment}
              onChangeComment={(e) => this.setState({ comment: e.target.value })}
              handleSave={this.handleSave}
            />
          </Drawer>
        )}
      </>
    );
  }
}

export default OfficeSidebar;
