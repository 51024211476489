import React from 'react';
import { useSelector } from 'react-redux';

import { Drawer, Tag } from 'antd';
import PropTypes from 'prop-types';

import StoneDrawer from 'components/DiamondDetail';

import { useAction, useCompositeState } from 'util/hooks';
import { clone, isEmpty, isNotEmpty } from 'util/utils';

import { RECENT_STONES_MAX_LENGTH } from 'constants/Common';

import { ViewedStonesActions } from 'redux/reducers/viewed-stones';

function ViewedStonesTags(props) {
  const { maxLength = RECENT_STONES_MAX_LENGTH } = props;

  const [stoneDetails, setStoneDetails, resetStoneDetails] = useCompositeState();
  const list = useSelector((state) => state.viewedStones.list);
  const removeFromList = useAction(ViewedStonesActions.remove);

  const listToDisplay = React.useMemo(() => clone(list).slice(0, maxLength), [maxLength, list]);

  return (
    <div className="dn-list-tags">
      {!isEmpty(list) && (
        <div key={`viewedStonesList_${listToDisplay?.length}`} className="dn-list-tags-title">
          Recently Viewed:{' '}
        </div>
      )}
      {listToDisplay.map((stone) => (
        <Tag
          key={stone?.vStnId}
          visible={stone?.vStnId}
          onClick={() => setStoneDetails(stone)}
          onClose={() => setTimeout(() => removeFromList(stone), 360)}
          closable
        >
          {stone?.vStnId}
        </Tag>
      ))}
      <Drawer
        visible={isNotEmpty(stoneDetails)}
        onClose={resetStoneDetails}
        wrapClassName="diamondDetailPopup"
        destroyOnClose
      >
        {isNotEmpty(stoneDetails) && <StoneDrawer diamondPopup data={stoneDetails} />}
      </Drawer>
    </div>
  );
}

ViewedStonesTags.propTypes = {
  maxLength: PropTypes.number,
};

export default ViewedStonesTags;
