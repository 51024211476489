import { Storage } from 'services/Storage';

const initialState = {
  smallSidebar: Storage.get('settings')?.smallSidebar ?? false,
  permissions: Storage.get('settings')?.permissions ?? {},
};

export const SettingsActionTypes = {
  SET_PERMISSIONS: '@settings/set-permissions',
  TOGGLE_SIDEBAR_WIDTH: '@settings/toggle-sidebar-width',
  RESET: '@settings/reset',
};

export const SettingsActions = {
  setPermissions: (payload) => ({ type: SettingsActionTypes.SET_PERMISSIONS, payload }),
  toggleSidebarWidth: () => ({ type: SettingsActionTypes.TOGGLE_SIDEBAR_WIDTH }),
  reset: () => ({ type: SettingsActionTypes.RESET }),
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SettingsActionTypes.TOGGLE_SIDEBAR_WIDTH: {
      const newState = { ...state, smallSidebar: !state?.smallSidebar };
      Storage.set('settings', newState);
      return newState;
    }

    case SettingsActionTypes.SET_PERMISSIONS: {
      const newState = { ...state, permissions: payload };
      Storage.set('settings', newState);
      return newState;
    }

    case '@@RESET':
    case SettingsActionTypes.RESET: {
      Storage.delete('settings');
      return { ...initialState };
    }

    default:
      return state;
  }
};
