import React from 'react';

import { classNames } from 'util/utils';
import './title.less';

const Heading = (props) => {
  const { className, title, children, ...rest } = props;
  return (
    <h2 className={classNames([`mainHeadingTitle`, `titleheading`, className])} {...rest}>
      {children ?? title}
    </h2>
  );
};

export default Heading;
