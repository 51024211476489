import { isNotEmpty } from './isNotEmpty';
import { lowerCase } from './lowerCase';
import { titleCase } from './titleCase';

export function getUserName(input, replace = '-') {
  input = input?.user ?? input;
  const name = titleCase([input?.firstName, input?.lastName].join(' '));
  const email = lowerCase(input?.email);
  return [name, input?.name, input?.username, email].find(isNotEmpty) ?? replace;
}
