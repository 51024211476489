import { Storage } from 'services/Storage';

import { axios } from 'util/Api';
import { history } from 'util/history';
import { logInfo } from 'util/utils';

import { USER_LOGOUT } from 'constants/apiConstant';

import { store } from 'redux/store';

import { LoaderService } from './LoaderService';

export class AuthService {
  static async signOut(redirect = true) {
    AuthService.signOutRequest();
    Storage.deleteAll();
    store.dispatch({ type: '@@RESET' });
    if (redirect) history.push('/signin');
  }

  static async signOutRequest(token) {
    LoaderService.startLoading();
    try {
      token = token ?? Storage.get('token');
      await axios.request({ ...USER_LOGOUT, headers: { Authorization: `JWT ${token}` } });
    } catch (error) {
      logInfo({ error });
    }
    LoaderService.stopLoading();
  }
}
