import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Select, Dropdown } from 'antd';

import SalesPerson from 'components/common/SalesPerson';
import DefaultUser from 'components/common/UserProfileDefault';

import { LOCAL_STORAGE_VAR, BASE_URL_ATTACH } from 'constants/Common';

import LangDropdown from './LangDropdown';

import './headerstrip.less';

class HeaderStrip extends Component {
  state = {
    date: new Date(),
    salesOption: false,
    rates: {},
    isGuest: localStorage.getItem(`${LOCAL_STORAGE_VAR}-guest`) || false,
    currentLocale: 'Asia/Kolkata',
  };

  salesDropdown = () => this.setState({ salesOption: !this.state.salesOption });

  HeaderStirpItem = (props) => {
    return (
      <div className="headerStripItem">
        <span>{props.title}</span>
        {props.country && (
          <Select
            value={this.state.currentLocale}
            dropdownClassName="timeLagSelectDropdown"
            className="timeLagSelect"
            onChange={(value) => this.setState({ currentLocale: value })}
          >
            <Select.Option value="Asia/Kolkata">India</Select.Option>
            <Select.Option value="Asia/Hong_Kong">HK</Select.Option>
            <Select.Option value="America/New_York">USA</Select.Option>
            <Select.Option value="Asia/Dubai">Dubai</Select.Option>
            <Select.Option value="Asia/Jerusalem">Israel</Select.Option>
            <Select.Option value="Australia/Sydney">Australia</Select.Option>
            <Select.Option value="Asia/Tokyo">Japan</Select.Option>
          </Select>
        )}
        <span className="headerStripValue">{props.value}</span>
      </div>
    );
  };

  render() {
    const { salesData } = this.props;

    return (
      <div className="m-view-right">
        <div className={`HeaderStripBlock ${this.props.className}`}>
          <div className="HeaderStripRight">
            <div className="SelectLanguage">
              <LangDropdown />
            </div>
            {salesData && !this.state.isGuest && (
              <Dropdown
                getPopupContainer={(trigger) => trigger}
                overlay={
                  <div className="salesPersonDropdown">
                    <SalesPerson
                      email={salesData && salesData.email ? salesData.email : '--'}
                      phone={salesData && salesData.mobile ? salesData.mobile : '--'}
                      skype={salesData && salesData.skype ? salesData.skype : '--'}
                    />
                  </div>
                }
                trigger={['click']}
              >
                <div className="SalesPersonWrapper" onClick={(e) => e.preventDefault()}>
                  <>
                    <div className="salesPersonProfile">
                      {salesData && salesData.photoId ? (
                        <img className="profilePicImg" src={`${BASE_URL_ATTACH}/${salesData.photoId}`} alt="profile" />
                      ) : (
                        <DefaultUser />
                      )}
                    </div>
                    <div className="salesPersonDetail" onClick={this.salesDropdown}>
                      {salesData && (
                        <span>
                          {salesData.firstName} {salesData.lastName}
                        </span>
                      )}
                      {this.state.salesOption ? (
                        <img
                          className="buttonArrow"
                          src={require('../../../../assets/svg/Dashboard/up-arrow-black.svg')}
                          alt=""
                        />
                      ) : (
                        <img
                          className="buttonArrow"
                          src={require('../../../../assets/svg/Dashboard/down-arrow.svg')}
                          alt=""
                        />
                      )}
                    </div>
                  </>
                </div>
              </Dropdown>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    salesData: state.sales?.salesPerson,
  }),
  {},
)(HeaderStrip);
