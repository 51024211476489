import React from "react"
import MainFilterTop from "./FilterItem"
import { Menu, Dropdown } from 'antd';
const menu = (
    <Menu>
      <Menu.Item key="0">
            <span>0.500-0.589</span>
            <span className="filterClose"><img src={require("../../../assets/svg/DiamondList/close.svg")} alt=""/></span>
      </Menu.Item>
      <Menu.Item key="1">
            <span>0.500-0.589</span>
            <span className="filterClose"><img src={require("../../../assets/svg/DiamondList/close.svg")} alt=""/></span>
      </Menu.Item>
      <Menu.Item key="1">
            <span>0.500-0.589</span>
            <span className="filterClose"><img src={require("../../../assets/svg/DiamondList/close.svg")} alt=""/></span>
      </Menu.Item>
      <Menu.Item key="1">
            <span>0.500-0.589</span>
            <span className="filterClose"><img src={require("../../../assets/svg/DiamondList/close.svg")} alt=""/></span>
      </Menu.Item>
      <Menu.Item key="1">
            <span>IF</span>
            <span className="filterClose"><img src={require("../../../assets/svg/DiamondList/close.svg")} alt=""/></span>
      </Menu.Item>
      <Menu.Item key="1">
            <span>M</span>
            <span className="filterClose"><img src={require("../../../assets/svg/DiamondList/close.svg")} alt=""/></span>
      </Menu.Item>
    </Menu>
  );

const FilterTop = (props) => {
    return(
        <Dropdown overlay={menu} trigger={['click']} overlayClassName="filterMainDropdown">
              <div onClick={e => e.preventDefault()}>
                  <MainFilterTop title={props.filterTitle} eyeImage={props.image}/>
              </div>
        </Dropdown>
    )
}
export default FilterTop;