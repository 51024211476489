import clone from 'nanoclone';

import { callApi } from 'util/call-api';
import { isEmpty, hasKey, isNotEmpty, pruneEmpty, isString } from 'util/utils';

import { getFileUrl, LAB_LINKS } from 'constants/Common';
import { CREATE_TRACK, DELETE_TRACK, FETCH_DIAMOND_TRACK } from 'constants/apiConstant';
import { PAGES } from 'constants/paths.const';

import { ViewedStonesActions } from 'redux/reducers/viewed-stones';
import { store } from 'redux/store';

import { MediaService } from './MediaService';

function getPathname() {
  return `${window?.location?.pathname}`?.split?.('/')?.pop?.()?.split('?')?.[0];
}

function comparePathname(pathnames) {
  pathnames = Array.isArray(pathnames) ? pathnames : [pathnames];
  return pathnames.includes(getPathname() || undefined);
}

export class StoneService {
  static CHECK_FILE_HEADERS = false;

  static get FILE_KEYS() {
    return ['DEFIMG', 'NATIMG', '360VID', 'NATVID', 'LBCERT', 'T2CERT', 'ASTIMG', 'IDLIMG', 'HRTIMG', 'ABLIMG', 'FLSIMG', 'PLTIMG', 'MSRIMG']; // prettier-ignore
  }

  static get INCLUSION_KEYS() {
    return ['blkTblNm', 'opTblNm', 'blkSdNm', 'opCrwnNm', 'wTblNm', 'opPavNm', 'wSdNm', 'opGrd', 'kToSStr', 'lbCmt']; // prettier-ignore
  }

  static get TRACK_TYPE_FILTERS() {
    return {
      ALL: {},
      CART: { trackType: 1 },
      WATCH_LIST: { trackType: 2 },
      REQUEST: { trackType: 3 },
      QUOTE: { trackType: 4 },
      REMINDER: { trackType: 5 },
      ENQUIRY: { trackType: 6 },
      SHIPMENT: { trackType: 7 },
      SELECTED: { trackType: 8 },
      BID_WISH_LIST: { trackType: 10 },
      BID_VIEW_REQUEST: { trackType: 11, status: 1 },
      BID_VIEW_REQUEST_APPROVED: { trackType: 11, status: 2 },
      BID_STONE_VIEWED: { trackType: 11, status: 5 },
      COMMENT: { trackType: 99 },
      NOTES: { trackType: 99 },
    };
  }

  static formatStoneRecord(record) {
    const output = clone(record);

    if (!isEmpty(output.girdleStr) && !isEmpty(output.grdlPer))
      output.girdleStr = `${output.girdleStr} (${output.grdlPer}%)`;

    // if (output.shpNm === 'ROUND') output.ratio = undefined;

    if (!isEmpty(output.clrNm) && ['FL', 'IF'].includes(output.clrNm)) {
      const inclusionFieldsExclude = ['kToSStr'];

      StoneService.INCLUSION_KEYS.forEach((field) => {
        if (!inclusionFieldsExclude.includes(field)) output[field] = 'NONE';
      });
    }

    output.disableSelection = (() => {
      if (comparePathname([PAGES.SEARCH_LAYOUT, PAGES.PREDEFINEMATCHPAIR]) && hasKey(record, 'pairStkNo')) return true;
      if (comparePathname(PAGES.OFFER_LIST) && (record.offerStatus !== 1 || record.confirmedStone)) return true;
      if (hasKey(record, 'groupNo')) return true;

      return output.disableSelection;
    })();

    return output;
  }

  static formatStoneList(list) {
    return list.map(StoneService.formatStoneRecord);
  }

  static markStoneAsSeen(listOrObject) {
    store.dispatch(ViewedStonesActions.add(listOrObject));
  }

  static async getCertFileLink(stone) {
    const [error] = await (!stone.certFile ? MediaService.getHeaders(getFileUrl('PDF_CERT', stone?.vStnId)) : [false]);
    return error
      ? LAB_LINKS[stone?.lbNm?.toUpperCase?.()]?.replace?.('***', stone?.rptNo)
      : getFileUrl('PDF_CERT', stone?.vStnId);
  }

  static getCertVerifyLink(stone) {
    return LAB_LINKS[stone?.lbNm?.toUpperCase?.()]?.replace?.(/(?:[*]{3}){1}/gm, stone?.rptNo);
  }

  static async checkFileAvailability(stone) {
    const list = [
      ['DEFIMG', getFileUrl('JPG_ALT', stone?.vStnId), true, stone?.img],
      ['NATIMG', getFileUrl('JPG_NATURAL', stone?.vStnId), true, stone?.isNtrlImg],
      ['360VID', getFileUrl('MP4_360', stone?.vStnId), true, stone?.videoFile],
      ['NATVID', getFileUrl('MP4_NATURAL', stone?.vStnId), true, stone?.isNtrlVideo],
      ['LBCERT', getFileUrl('PDF_CERT', stone?.vStnId), true, stone?.certFile],
      ['ASTIMG', getFileUrl('JPG_ASSET', stone?.vStnId), true, stone?.hAFile],
      ['IDLIMG', getFileUrl('JPG_IDEAL', stone?.vStnId), true, stone?.hAFile],
      ['HRTIMG', getFileUrl('JPG_HEARTS', stone?.vStnId), stone?.shpNm === 'ROUND', stone?.hAFile],
      ['ABLIMG', getFileUrl('JPG_ARROWS', stone?.vStnId), stone?.shpNm === 'ROUND', stone?.hAFile],
      ['FLSIMG', getFileUrl('JPG_FLU', stone?.vStnId), true, stone?.flsImage],
      ['PLTIMG', getFileUrl('JPG_PLT', stone?.vStnId), true, stone?.isPltImg],
      ['MSRIMG', getFileUrl('JPG_MSR', stone?.vStnId), true, stone?.isMsr],
    ];

    const promiseList = list.map(async ([key, url, show, available]) => {
      if (show && StoneService.CHECK_FILE_HEADERS) {
        const [error] = await MediaService.getHeaders(url);
        if (!error) return key;
      } else {
        if (show && available) return key;
      }
    });

    const resolvedList = await Promise.all(promiseList);

    return resolvedList.filter(isNotEmpty);
  }

  static async listStoneTrack(trackTypeFilter = this.TRACK_TYPE_FILTERS.ALL, options = {}) {
    const { page = 1, limit = 50 } = options;

    const config = {
      ...FETCH_DIAMOND_TRACK,
      request: { page, limit, ...trackTypeFilter },
      options: { notifyError: true },
    };

    const [err, res] = await callApi(pruneEmpty(config));

    return [err, res];
  }

  static async addStoneToTrack(trackType, trackData) {
    const { bidConfig, stones, remarks } = trackData;

    const stoneList = stones?.map?.((stone) => ({
      diamond: stone?.id,
      remarks: isString(remarks) ? remarks : isString(remarks?.[stone?.id]) ? remarks?.[stone?.id] : undefined,
    }));

    const config = {
      ...CREATE_TRACK,
      request: {
        trackType,
        bidConfigurationId: bidConfig?.id,
        bidResultDateTime: bidConfig?.sub_types?.currentBidEndDate,
        diamonds: stoneList ?? [],
      },
      options: { notifyResponse: true, notifyError: true },
    };

    const [err, res] = await callApi(pruneEmpty(config));

    return [err, res];
  }

  static async removeStoneFromTrack(trackType, trackData) {
    const { stones } = trackData;

    const stoneList = stones?.map?.((stone) => stone?.idToSend ?? stone?.id);

    const config = {
      ...DELETE_TRACK,
      request: { trackType, id: stoneList ?? [] },
      options: { notifyResponse: true, notifyError: true },
    };
    const [err, res] = await callApi(pruneEmpty(config));

    return [err, res];
  }
}

window.__StoneService = StoneService;
