export const PURCHASE_COLUMNS = [
  { Header: 'Sr. No.', id: 'srno', Cell: ({ row }) => Number(row.id) + 1 },
  { Header: 'Invoice Date', accessor: 'invoiceDate', id: 'invoiceDate' },
  { Header: 'Order No', accessor: 'orderNo', id: 'orderNo' },
  { Header: 'Invoice No', accessor: 'memoNo', id: 'memoNo' },
  { Header: 'Remark', accessor: 'remarks', id: 'remarks' },
  { Header: 'Total Pieces', accessor: 'piece', id: 'piece' },
  { Header: 'Total Carat', accessor: 'crt', id: 'crt' },
  { Header: 'Total Pr/Ct', accessor: 'ctpr', id: 'ctpr' },
  { Header: 'Total Amount', accessor: 'amount', id: 'amount' },
];
