import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { Storage } from 'services/Storage';

import IntlMessages from 'util/IntlMessages';
import { callApi } from 'util/call-api';
import { useLoading, usePathname } from 'util/hooks';
import { isNotEmpty } from 'util/utils';

import { GET_EXCLUSIVE_COLLECTION } from 'constants/apiConstant';

import logoSvg from 'assets/img/logo-white.png';
import dashboardActiveSvg from 'assets/svg/sidebar/Dashboard-active.svg';
import dashboardSvg from 'assets/svg/sidebar/Dashboard.svg';
import accountActiveSvg from 'assets/svg/sidebar/account-active.svg';
import accountSvg from 'assets/svg/sidebar/account.svg';
import coupleDiamondActiveSvg from 'assets/svg/sidebar/couple-diamond-active.svg';
import coupleDiamondSvg from 'assets/svg/sidebar/couple-diamond.svg';
import demandActiveSvg from 'assets/svg/sidebar/demand-active.svg';
import demandSvg from 'assets/svg/sidebar/demand.svg';
import exclusiveActiveSvg from 'assets/svg/sidebar/exclusive-active.svg';
import exclusiveSvg from 'assets/svg/sidebar/exclusive.svg';
import featureActiveSvg from 'assets/svg/sidebar/featured-stone-active.svg';
import featureSvg from 'assets/svg/sidebar/featured-stone.svg';
import myConfirmListActiveSvg from 'assets/svg/sidebar/my-confirm-list-active.svg';
import myConfirmListSvg from 'assets/svg/sidebar/my-confirm-list.svg';
import myInvoiceHistoryActiveSvg from 'assets/svg/sidebar/my-invoice-history-active.svg';
import myInvoiceHistorySvg from 'assets/svg/sidebar/my-invoice-history.svg';
import savedSearchActiveSvg from 'assets/svg/sidebar/saved-search-active.svg';
import savedSearchSvg from 'assets/svg/sidebar/saved-search.svg';
import searchActiveSvg from 'assets/svg/sidebar/search-active.svg';
import searchSvg from 'assets/svg/sidebar/search.svg';
import specialStoneActiveSvg from 'assets/svg/sidebar/special-stone-active.svg';
import specialStoneSvg from 'assets/svg/sidebar/special-stone.svg';
import upcomingActiveSvg from 'assets/svg/sidebar/upcoming-active.svg';
import upcomingSvg from 'assets/svg/sidebar/upcoming.svg';

import './sidebarHeader.less';

function SidebarHeader() {
  const [exclusiveCollection, setExclusiveCollection] = React.useState();

  const settings = useSelector((state) => state?.settings);
  const pathname = usePathname();

  // const menuRef = React.useRef();
  const USER_PERMIT = React.useMemo(() => (pathname, Storage.get(`userPermissions`)), [pathname]);

  const fetchExclusiveCollection = React.useCallback(async () => {
    const [err, res] = await callApi(GET_EXCLUSIVE_COLLECTION);
    if (!err) setExclusiveCollection(res?.data?.list ?? []);
  }, []);

  React.useEffect(() => {
    fetchExclusiveCollection();
  }, [fetchExclusiveCollection, pathname]);

  const { smallSidebar } = settings;

  return (
    <div className="sidebarHeader sidebarFixed" style={!smallSidebar ? { width: 80 } : { width: 60 }}>
      {/* <Button className="menubutton" type="primary" onClick={SettingsService.toggleSidebarWidth}>
        {smallSidebar ? <MenuFoldIcon /> : <MenuUnfoldIcon />}
      </Button> */}

      <div>
        <NavLink to="/dashboard" className="sidebarLogo text-center">
          <img width="50px" src={logoSvg} />
        </NavLink>
        <div className="sidebarMenuList">
          <ul>
            {USER_PERMIT?.DASHBOARD?.view && (
              <li>
                <NavLink to="/dashboard">
                  <img alt="" className="defualtImg img-65" src={dashboardSvg} />
                  <img className="activeImg img-65" alt="" src={dashboardActiveSvg} />
                  <div style={{ width: '100%' }}>
                    {!smallSidebar ? (
                      <p>
                        <IntlMessages id="app.dashboard" />
                      </p>
                    ) : (
                      ''
                    )}
                  </div>
                </NavLink>
              </li>
            )}
            {USER_PERMIT?.SEARCH_DIAMOND?.view && (
              <li>
                <NavLink to="/diamond-search">
                  <img alt="" className="defualtImg img-65" src={searchSvg} />
                  <img className="activeImg img-65" alt="" src={searchActiveSvg} />
                  <div style={{ width: '100%' }}>
                    {!smallSidebar ? (
                      <p>
                        <IntlMessages id="app.Search" />
                      </p>
                    ) : (
                      ''
                    )}
                  </div>
                </NavLink>
              </li>
            )}
            {USER_PERMIT?.SEARCH_MATCH_PAIR?.view && (
              <li>
                <NavLink to="/search-match-pair">
                  <img alt="" className="defualtImg img-65" src={coupleDiamondSvg} />
                  <img className="activeImg img-65" alt="" src={coupleDiamondActiveSvg} />
                  <div style={{ width: '100%' }}>
                    {!smallSidebar ? (
                      <p>
                        <IntlMessages id="app.match-pair" />
                      </p>
                    ) : (
                      ''
                    )}
                  </div>
                </NavLink>
              </li>
            )}
            {USER_PERMIT?.NEW_ARRIVAL?.view && (
              <li>
                <NavLink to="/new-arrival">
                  <img alt="" className="defualtImg img-65" src={specialStoneSvg} />
                  <img className="activeImg img-65" alt="" src={specialStoneActiveSvg} />
                  <div style={{ width: '100%' }}>
                    {!smallSidebar ? (
                      <p>
                        <IntlMessages id="app.NewArrivals" />
                      </p>
                    ) : (
                      ''
                    )}
                  </div>
                </NavLink>
              </li>
            )}
            {/* {USER_PERMIT?.UPCOMING?.view && (
              <li>
                <NavLink to="/upcoming">
                  <img alt="" className="defualtImg img-65" src={upcomingSvg} />
                  <img className="activeImg img-65" alt="" src={upcomingActiveSvg} />
                  <div style={{ width: '100%' }}>{!smallSidebar ? <p>Upcoming</p> : ''}</div>
                </NavLink>
              </li>
            )} */}
            {isNotEmpty(exclusiveCollection) &&
              exclusiveCollection?.map?.((collection) => (
                <li key={collection.id}>
                  <NavLink
                    to={{ pathname: `/collection/${collection?.id}`, state: { moreData: { name: collection?.name } } }}
                  >
                    <img alt="" className="defualtImg img-65" src={exclusiveSvg} />
                    <img className="activeImg img-65" src={exclusiveActiveSvg} alt="" />
                    <div style={{ width: '100%' }}>
                      <p> {collection ? collection.name : 'Exclusive Collection'} </p>
                    </div>
                  </NavLink>
                </li>
              ))}
            {USER_PERMIT?.FEATURE_STONE?.view && (
              <li>
                <NavLink to="/feature-stone">
                  <img alt="" className="defualtImg img-65 mrb-2" src={featureSvg} />
                  <img alt="" className="activeImg" src={featureActiveSvg} />
                  <div style={{ width: '100%' }}>
                    <p>
                      <IntlMessages id="app.featuredStoneDashboard" />
                    </p>
                  </div>
                </NavLink>
              </li>
            )}
            {USER_PERMIT?.CONFIRM_STONE?.view && (
              <li>
                <NavLink to="/account/my-purchase">
                  <img alt="" className="defualtImg img-65 mrb-2" src={myInvoiceHistorySvg} />
                  <img alt="" className="activeImg" src={myInvoiceHistoryActiveSvg} />
                  <div style={{ width: '100%' }}>
                    <p>
                      <IntlMessages id="app.MyPurchaseHistory" />
                    </p>
                  </div>
                </NavLink>
              </li>
            )}
            {USER_PERMIT?.SAVE_SEARCH?.view && (
              <li>
                <NavLink to="/account/saved-search">
                  <img alt="" className="defualtImg img-65" src={savedSearchSvg} />
                  <img alt="" className="activeImg" src={savedSearchActiveSvg} />
                  <div style={{ width: '100%' }}>
                    <p>
                      <IntlMessages id="app.saved-search" />
                    </p>
                  </div>
                </NavLink>
              </li>
            )}
            {USER_PERMIT?.DEMAND?.view && (
              <li>
                <NavLink to="/account/my-demand">
                  <img alt="" className="defualtImg img-65" src={demandSvg} />
                  <img alt="" className="activeImg" src={demandActiveSvg} />
                  <div style={{ width: '100%' }}>
                    <p>
                      <IntlMessages id="app.Demand" />
                    </p>
                  </div>
                </NavLink>
              </li>
            )}
            {USER_PERMIT?.CONFIRM_STONE?.view && (
              <li>
                <NavLink to="/account/confirm-list">
                  <img alt="" className="defualtImg img-65" src={myConfirmListSvg} />
                  <img className="activeImg img-65" alt="" src={myConfirmListActiveSvg} />
                  <div style={{ width: '100%' }}>
                    {!smallSidebar ? (
                      <p>
                        <IntlMessages id="app.confirm-list" />
                      </p>
                    ) : (
                      ''
                    )}
                  </div>
                </NavLink>
              </li>
            )}
            <li>
              <NavLink to="/account/profile">
                <img alt="" className="defualtImg img-65" src={accountSvg} />
                <img className="activeImg img-65" alt="" src={accountActiveSvg} />
                <div style={{ width: '100%' }}>
                  {!smallSidebar ? (
                    <p>
                      <IntlMessages id="app.myAccount" />
                    </p>
                  ) : (
                    ''
                  )}
                </div>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default SidebarHeader;
