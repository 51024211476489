import React from 'react';

import { Dropdown, Tooltip } from 'antd';

import SortingFilterDropdown from './OverlayDropdownBack';
import { FILTER_COLUMNS } from './table-config';

import closeSvg from 'assets/svg/DiamondList/close.svg';
import sortAscSvg from 'assets/svg/DiamondList/sort-arrow-asc.svg';
import sortDescSvg from 'assets/svg/DiamondList/sort-arrow-desc.svg';
import sortNotAscSvg from 'assets/svg/DiamondList/sort-arrow-not-asc.svg';
import sortNotDescSvg from 'assets/svg/DiamondList/sort-arrow-not-desc.svg';

function stopDOMEvent(e) {
  void (e?.stopPropogation?.(), e?.preventDefault?.());
}

const TableHeaderBack = (props) => {
  const { column, FilterOption, nodots, sortBy = [], setSortBy, toggleSortBy } = props;

  const sortAsc = column.isSortedDesc === false;

  return (
    <div className={`tableHeaderMain ${nodots && 'squeceChange'} ${!column.isSorted && 'sortBoxShow'}`}>
      <Dropdown
        overlayClassName="tabelDropdownClass"
        key={`filterOverlayDropdown${column.id}`}
        overlay={
          <React.Fragment>
            {(column.isSorted || (FilterOption !== false && FILTER_COLUMNS.includes(column.id))) && (
              <SortingFilterDropdown
                setSortBy={setSortBy}
                sortBy={sortBy}
                toggleSortBy={toggleSortBy}
                column={column}
              />
            )}
          </React.Fragment>
        }
        trigger={['click']}
      >
        {column.desc ? (
          <Tooltip key={`headerDesc${column.desc}`} title={column.desc}>
            <span className="taleHeadTitle">{column.render('Header')}</span>
          </Tooltip>
        ) : (
          <span className="taleHeadTitle">{column.render('Header')}</span>
        )}
      </Dropdown>
      {column.canSort && (
        <div className="dn-sort" onClick={stopDOMEvent}>
          <div
            {...column.getSortByToggleProps()}
            title={`Toggle Sort${column.isSorted ? ` | Sort Order: ${sortAsc ? 'Ascending' : 'Descending'}` : ''}`}
            className="dn-sort-icons"
          >
            <img src={column.isSorted && sortAsc ? sortAscSvg : sortNotAscSvg} alt="Ascending" />
            <img src={column.isSorted && !sortAsc ? sortDescSvg : sortNotDescSvg} alt="Descending" />
          </div>
          {column.isSorted && (
            <div className="dn-sort-count" title={`Sort Sequence: ${column.sortedIndex}`}>
              {column.sortedIndex + 1}
            </div>
          )}
          {column.isSorted && (
            <div className="dn-sort-clear" title="Clear Sort" onClick={column.clearSortBy}>
              <img src={closeSvg} alt="clear" />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default React.memo(TableHeaderBack);
