import React from 'react';

import { usePathname } from 'util/hooks';
import { classNames, objectToQueryString } from 'util/utils';

import { DIAMOND_BID } from 'constants/Common';
import { PAGES } from 'constants/paths.const';

export const STATUS = {
  all: 'All',
  A: 'Available',
  B: 'Bid',
  BA: 'Bid',
  C: 'Confirm',
  CP: 'Confirm Pending',
  H: 'Busy',
  M: 'Busy',
  U: 'Upcoming',
};

export const STATUS_COLOR = {
  A: 'green',
  B: '#cddc39',
  BA: '#cddc39',
  CP: 'yellow',
  H: 'red',
  L: 'red',
  M: 'red',
  U: 'orange',
  W: 'green',
};

const Status = React.memo((props) => {
  const { row, vStnId } = props;

  const pathname = usePathname();

  const statusAbbrev = (() => {
    const bidStatus = pathname === PAGES.MYBID ? row.status : undefined;
    return bidStatus === DIAMOND_BID.STATUS.WIN ? 'W' : bidStatus === DIAMOND_BID.STATUS.LOSS ? 'L' : row?.wSts;
  })();

  const colorClass = STATUS_COLOR[statusAbbrev];

  return (
    <div className="showSatusBlock">
      {row?.wSts !== 'all' && <div className={classNames([`SelectcolorWiseList`, colorClass])}></div>}
      <span>
        {pathname !== PAGES.BID && ['B', 'BA'].includes(row?.wSts) ? (
          <a href={`/${PAGES.BID}${objectToQueryString({ vStnId: vStnId })}`}>{STATUS[row?.wSts]}</a>
        ) : (
          STATUS[row?.wSts]
        )}
      </span>
    </div>
  );
});

Status.displayName = 'Status';

export default Status;
