import { DEFAULT_PAGE_LIMIT } from 'constants/Common';

export { LAB_LINKS } from 'constants/Common';

export const SORTABLE_COLUMNS = {
  stoneId: 'Stone No',
  shpNm: 'Shape',
  crt: 'Carat',
  colNm: 'Color',
  clrNm: 'Clarity',
};

// prettier-ignore
export const ALT_COLUMNS = ['selection', 'srNo', 'vStnId', 'locNm', 'back', 'rap', 'amt', 'ctPr'];
// prettier-ignore
export const BOLD_COLUMNS = ['stoneId', 'vStnId', 'amt', 'prvCtPr', 'prvBack', 'colNm', 'shpNm', 'crt', 'clrNm', 'shdNm', 'rap', 'back', 'ctPr', 'cutNm', 'polNm', 'symNm', 'brlncyNm', 'bidDiscount', 'bidPricePerCarat', 'bidAmount', 'fluNm'];
// prettier-ignore
export const TOTAL_COLUMNS = ['ctPr', 'amt', 'rap', 'crt', 'fnCtpr', 'fnAmt', 'back'];
// prettier-ignore
export const FILTER_COLUMNS = ['colNm', 'fluNm', 'shpNm', 'lbNm', 'clrNm'];
// prettier-ignore
export const FLOAT_COLUMNS = ['depPer', 'tblPer', 'ratio', 'crt', 'rapAvg', 'pAng', 'pHgt', 'cHgt', 'back', 'cAng', 'fnDis', 'height', 'width', 'length', 'grdlPer', 'strLn', 'ctPr'];
// prettier-ignore
export const NOCHECK_COLUMNS = ['Details', 'Action', 'quote', 'expBack', 'hours', 'bidPricePerCarat', 'note', 'finalquote', 'bidAmount', 'bidDiscount', 'newPricePerCarat'];
// prettier-ignore
export const ROUND_COLUMNS = ['amt', 'rap', 'lwrHal'];

export const CURRENCY_COLUMNS = ['amt', 'rap', 'ctPr'];

export const TABLE_PAGE_LIMIT = DEFAULT_PAGE_LIMIT;
