import React from 'react';
import { useSelector } from 'react-redux';

import MagnifierImage from 'components/DiamondDetail/MagnifierImage';
import CheckBox from 'components/common/CheckBox';
import Heading from 'components/common/Heading';
import NoDataShow from 'components/common/NoDataShow';

import { RowSelectService } from 'services/RowSelectService';
import { Storage } from 'services/Storage';

import IntlMessage from 'util/IntlMessages';
import { usePathname, useSelectedRows } from 'util/hooks';
import { notify } from 'util/notify';
import { isArray, isEmpty } from 'util/utils';

import { FILE_URLS, DIAMOND_BID } from 'constants/Common';

import { LISTINGPAGES } from './DiamondList/DiamondListFunctions';
import { filterList, formatValues } from './DiamondList/table-utils';
import { getBidType } from './NewArrivalBidIt';
import { COMPARE_LIST } from './_compare.data';
import DiamondListingAction from './common/DiamondListing/DiamondListingAction';

import CommonBackButton from '../containers/commonBackButton';

import closeRedSvg from 'assets/svg/Compare/close-red.svg';

const CompareCheckbox = React.memo((props) => {
  const { currentType, stone } = props;

  const isChecked = useSelector((store) =>
    store.diamond.selectedRowIds?.[currentType]?.includes(stone.id ?? stone.diamondId ?? stone.stoneId),
  );

  const toggleSelection = React.useCallback(() => {
    isChecked ? RowSelectService.unSelectRows(currentType, stone) : RowSelectService.selectRows(currentType, stone);
  }, [currentType, isChecked, stone]);

  return (
    <div className="checkImageBox">
      <CheckBox checked={isChecked} onChange={toggleSelection} />
    </div>
  );
});

CompareCheckbox.displayName = 'CompareCheckbox';

export const Compare = React.memo((props) => {
  const { parentType, toggle } = props;

  const P = React.useMemo(() => Storage.get('userPermissions'), []);

  const [data, setData] = React.useState([]);

  const [selectedRows] = useSelectedRows(parentType);
  const pathname = usePathname();
  const currentType = `${pathname}/compare`;

  const compareStones = async (list) => {
    if (!isArray(list)) return false;
    else if (list.length < 1) {
      notify.error({ message: 'Please, select stone(s) to compare.' });
      return false;
    } else if (list.length < 2) {
      notify.error({ message: 'Please, select minimum two stones to compare.' });
      return false;
    } else if (list.length > 15) {
      notify.error({ message: 'Maximum 15 stones can be compared.' });
      return false;
    }
    return true;
  };
  const removeStone = React.useCallback((stone) => {
    setData((data) => {
      const index = data.findIndex((rec) => rec.vStnId === stone.vStnId);
      data.splice(index, 1);
      return [...data];
    });
  }, []);

  React.useEffect(() => {
    setData(filterList(selectedRows));
  }, [selectedRows]);

  return (
    <div className="compareWrapper">
      <div className="compareMainPage">
        <div className="d-flex justify-content-between align-items-center mb-10 comparPageHead">
          <IntlMessage id="app.CompareStone">
            {(placeholder) => <Heading title={placeholder + '(' + data?.length + ')'} className="mb-0" />}
          </IntlMessage>
          <CommonBackButton onClick={toggle} />
        </div>
        {isEmpty(data) ? (
          <NoDataShow />
        ) : (
          <div className="compareTable">
            <table>
              <tr>
                <td className="compareHead">Diamond Image</td>
                {data.map((stone) => (
                  <td key={stone.vStnId} className="compareHead compareImage">
                    {stone.img && <MagnifierImage image={stone?.files?.img} className="diamondCompareImg" />}

                    <div className="closeImage" onClick={() => removeStone(stone)}>
                      <img src={closeRedSvg} alt="" />
                    </div>
                    <CompareCheckbox currentType={currentType} stone={stone} />
                  </td>
                ))}
              </tr>
              {COMPARE_LIST.map(([title, list]) => (
                <React.Fragment key="title">
                  <Heading>{title}</Heading>
                  {list.map(({ label, field }) => (
                    <tr key={field}>
                      <td>{label}</td>
                      {data.map((record, index) => {
                        if (field === 'back') {
                          return <td key={index}>{`${formatValues(record[field], field)}%`}</td>;
                        } else return <td key={index}>{formatValues(record[field], field)}</td>;
                      })}
                    </tr>
                  ))}
                </React.Fragment>
              ))}
            </table>
          </div>
        )}
      </div>
      <DiamondListingAction
        {...props}
        currentType={currentType}
        noCompareNow={P?.COMPARE?.view}
        compareStones={compareStones}
        toggle={toggle}
        nopaginate
        noenquiry={
          !P?.ENQUIRY?.view ||
          pathname === LISTINGPAGES.CONFIRM ||
          pathname === LISTINGPAGES.UPCOMING ||
          pathname === LISTINGPAGES.BID ||
          pathname === LISTINGPAGES.MYBID
        }
        noconfirm={
          !P?.CONFIRM_STONE?.view ||
          pathname === LISTINGPAGES.CONFIRM ||
          pathname === LISTINGPAGES.BID ||
          pathname === LISTINGPAGES.MYBID ||
          pathname === LISTINGPAGES.UPCOMING
        }
        nocart={
          !P?.CART?.view ||
          pathname === LISTINGPAGES.CONFIRM ||
          pathname === LISTINGPAGES.CART ||
          pathname === LISTINGPAGES.BID ||
          pathname === LISTINGPAGES.MYBID ||
          pathname === LISTINGPAGES.UPCOMING
        }
        noquote={
          !P?.QUOTE?.view ||
          pathname === LISTINGPAGES.QUOTE ||
          pathname === LISTINGPAGES.CONFIRM ||
          pathname === LISTINGPAGES.BID ||
          pathname === LISTINGPAGES.MYBID ||
          pathname === LISTINGPAGES.UPCOMING
        }
        nooffice={
          !P?.OFFICE?.view ||
          getBidType() === DIAMOND_BID.TYPE.BLIND ||
          pathname === LISTINGPAGES.BID ||
          pathname === LISTINGPAGES.MYBID ||
          pathname === LISTINGPAGES.CONFIRM ||
          pathname === LISTINGPAGES.OFFICE ||
          pathname === LISTINGPAGES.UPCOMING
        }
        nowatch={
          !P?.WATCHLIST?.view ||
          pathname === LISTINGPAGES.CONFIRM ||
          pathname === LISTINGPAGES.WATCHLIST ||
          pathname === LISTINGPAGES.BID ||
          pathname === LISTINGPAGES.MYBID
        }
        noreminder={
          !P?.REMINDER?.view ||
          pathname === LISTINGPAGES.CONFIRM ||
          pathname === LISTINGPAGES.BID ||
          pathname === LISTINGPAGES.MYBID ||
          pathname === LISTINGPAGES.UPCOMING
        }
        nonote={
          !P?.NOTES?.view ||
          pathname === LISTINGPAGES.CONFIRM ||
          pathname === LISTINGPAGES.BID ||
          pathname === LISTINGPAGES.MYBID ||
          pathname === LISTINGPAGES.UPCOMING
        }
        nofinalcalc={pathname === LISTINGPAGES.UPCOMING}
        noshare={!P?.SHARE_VIA_MAIL?.view || !P?.SHARE_VIA_WHATSAPP?.view || !P?.SHARE_VIA_SKYPE?.view}
        nodownload={!P?.DOWNLOAD?.view}
        noprint={!P?.PRINT?.view}
      />
    </div>
  );
});
