import React, { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { Checkbox, Radio } from 'antd';
import { uniq, concat, flattenDeep, join, flatten, map, compact, each } from 'lodash';

import IntlMessage from 'util/IntlMessages';
import { usePathname, useIsMobile } from 'util/hooks';
import { classNames, formatDecimal, isNotEmpty } from 'util/utils';

import { PAGES } from 'constants/paths.const';

import SendEmailPopup from './SendEmail';

import { BASE_DOWNLOAD_URL, DNA_URL, FILE_URLS, SHARE_OPTIONS as OPTIONS } from '../../../constants/Common';
import { shareExcel } from '../../DiamondList/DiamondListFunctions';
import OpenNotification from '../CommonButton/OpenNotification';

const Options = {
  img: 'Ideal Image',
  naturalImage: 'Natural Image',
  videoFile: '360 Video',
  mp4Video: 'Natural Video',
  certFile: 'Certificate',
  hAFile: 'HA Image',
  idealWhiteImage: '',
  arrBlack: '',
  heartImage: '',
  flsImage: 'Fluorescence Image',
  pltFile: 'Plotting Image',
  propImage: 'Proportion Image',
  isExcel: 'Download Excel',
};

const ShareCheckbox = (props) => {
  const { activeClass, checked, onClick, title, ...restProps } = props;

  const [isMobile] = useIsMobile();

  return (
    <li className={classNames([checked && activeClass])} onClick={onClick}>
      <div className="dropdownBottomItem">
        <div className="dropdownIconCheckBox">
          <Checkbox title={title} checked={checked} {...restProps}></Checkbox>
        </div>
        <span className="shareOptionLabel">{title}</span>
      </div>
    </li>
  );
};

const ShareRadio = (props) => {
  const { activeClass, checked, onClick, title, ...restProps } = props;

  const [isMobile] = useIsMobile();

  return (
    <li className={classNames([checked && activeClass])} onClick={onClick}>
      <div className="dropdownBottomItem">
        <div className="dropdownIconCheckBox">
          <Radio title={title} checked={checked} {...restProps}></Radio>
        </div>
        <span className="shareOptionLabel">{title}</span>
      </div>
    </li>
  );
};

const ShareDropdown = (props) => {
  const [isMobile] = useIsMobile();

  const [values, setValues] = useState({ withoutPrice: true });
  const [sendEmail, setSendEmail] = useState(false);
  const [images, setImages] = useState(false);
  const [excels, setExcels] = useState(false);
  const [videos, setVideos] = useState(false);
  const [link, setLink] = useState(true);
  const [certificates, setCertificates] = useState(false);
  const [roughs, setRoughs] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  const pathname = usePathname();

  const isDna = React.useMemo(() => window.location.pathname?.split('/')?.[1] === 'dna', []);

  const WhatsAppShare = () => {
    let totalTrue = 0;
    Object.values(values).forEach((x) => x === true && totalTrue++);

    if (totalTrue >= 1) {
      let links = ['Hello,'];
      const keys = Object.keys(values)?.filter?.((key) => values[key]);

      props.checked.forEach((stone) => {
        const videoLink =
          stone?.videoFile && stone?.files?.videoFile.match('https://up.diacam360.com')
            ? stone?.files?.videoFile + '/video'
            : stone?.files?.videoFile;
        links.push(`Stone ID :  ${stone.vStnId}`);

        links.push(
          [
            stone.shpNm,
            formatDecimal(stone.crt),
            stone.colNm,
            stone.clrNm,
            stone.polNm,
            stone.symNm,
            stone.fluNm,
            stone.lbNm,
            stone.rptNo,
          ]
            .filter(isNotEmpty)
            .join(' | '),
        );

        // if (link) {
        if (pathname === PAGES.CONFIRM || pathname === PAGES.PURCHASE) {
          links.push(`Diamond Details: ${DNA_URL}dna/${stone.diamondId}${values?.withoutPrice ? '' : '?wp=true'}`);
        } else {
          links.push(`Diamond Details: ${DNA_URL}dna/${stone.id}${values?.withoutPrice ? '' : '?wp=true'}`);
        }
        // }
        const temp = {};

        keys.forEach((k) => {
          if (k === 'isExcel') {
            return;
          } else {
            temp[k] = `${Options[k]} ${FILE_URLS[k] && FILE_URLS[k].replaceAll('***', stone.vStnId)}`;
          }
        });

        each(Options, (val, key) => {
          if (temp[key]) {
            if (['img', 'flsImage', 'pltFile', 'propImage', 'naturalImage'].includes(key) && stone.img) {
              links.push(`Image: ${stone.files?.img}`);
            }

            if (['videoFile', 'mp4Video'].includes(key) && stone.videoFile) {
              links.push(`Video: ${videoLink}`);
            }

            if (['certFile'].includes(key) && stone.certFile) {
              links.push(`Certificate: ${stone.files?.certFile}`);
            }

            if (['idealWhiteImage', 'hAFile', 'arrBlack', 'heartImage'].includes(key) && stone.hAFile) {
              // if (!window.location.pathname.includes("/predefine-match-pair")) {
              //   links.push(temp[key]);
              // }
              if (['arrBlack', 'heartImage'].includes(key) && stone.shpNm === 'ROUND') {
                links.push(temp[key]);
              } else if (['idealWhiteImage', 'hAFile'].includes(key)) {
                links.push(temp[key]);
              }
            }
          }
        });
      });

      keys.includes('isExcel')
        ? shareExcel(
            map(props.checked, 'id'),
            (path) => {
              links.push(`Download Excel: ${BASE_DOWNLOAD_URL}${path}`);

              if (links.length === 3) {
                return OpenNotification({
                  type: 'error',
                  title: 'Diamond resource not available !',
                });
              }
              links = uniq(links);
              window.open(`https://web.whatsapp.com/send?text=${join(links, '%0A%0A')}`);
            },
            pathname === PAGES.PREDEFINEMATCHPAIR
              ? { isPairExcel: true }
              : pathname === PAGES.CONFIRM || pathname === PAGES.PURCHASE
              ? { orderDiamond: true }
              : pathname === PAGES.MY_MEMO
              ? { filter: { blockid: map(props?.checked, 'blockId') }, excelType: 3, ...values }
              : {},
          )
        : links.length < 3
        ? OpenNotification({
            type: 'error',
            title: 'Diamond resource not available !',
          })
        : window.open(`https://web.whatsapp.com/send?text=${join(links, '%0A%0A')}`);

      props.onClose();
    } else {
      OpenNotification({
        type: 'error',
        title: 'Please Select atleast 1 Option to Share.',
      });
    }
  };

  const SkypeShare = () => {
    let totalTrue = 0;
    Object.values(values).forEach((x) => x === true && totalTrue++);

    if (totalTrue >= 1) {
      let links = ['SL Diamonds:'];
      const keys = Object.keys(values)?.filter?.((key) => values[key]);

      props.checked.forEach((stone) => {
        links.push(`Stone ID :  ${stone.vStnId}`);

        if (link) {
          if (pathname === PAGES.CONFIRM || pathname === PAGES.PURCHASE) {
            links.push(`${window.location.origin}/dna/${stone.diamondId}${values?.withoutPrice ? '' : '?wp=true'}`);
          } else {
            links.push(`${window.location.origin}/dna/${stone.id}${values?.withoutPrice ? '' : '?wp=true'}`);
          }
        }
        const temp = {};

        keys.forEach((key) => {
          if (key === 'isExcel') {
            return;
          } else {
            temp[key] = `${FILE_URLS[key] && FILE_URLS[key].replaceAll('***', stone.vStnId)}`;
          }
        });

        each(Options, (val, key) => {
          if (temp[key]) {
            if (['img', 'flsImage', 'pltFile', 'propImage', 'naturalImage'].includes(key) && stone.img) {
              links.push(`${stone.files?.img}`);
            }

            if (['videoFile', 'mp4Video'].includes(key) && stone.videoFile) {
              links.push(`${stone.files?.videoFile}`);
            }

            if (['certFile'].includes(key) && stone.certFile) {
              links.push(`${stone.files?.certFile}`);
            }

            if (['idealWhiteImage', 'hAFile', 'arrBlack', 'heartImage'].includes(key) && stone.hAFile) {
              // if (!window.location.pathname.includes("/predefine-match-pair")) {
              //   links.push(temp[key]);
              // }
              if (['arrBlack', 'heartImage'].includes(key) && stone.shpNm === 'ROUND') {
                links.push(temp[key]);
              } else if (['idealWhiteImage', 'hAFile'].includes(key)) {
                links.push(temp[key]);
              }
            }
          }
        });
      });

      keys.includes('isExcel')
        ? shareExcel(
            map(props.checked, 'id'),
            (path) => {
              links.push(`${BASE_DOWNLOAD_URL}${path}`);

              if (links.length === 3) {
                return OpenNotification({
                  type: 'error',
                  title: 'Diamond resource not available !',
                });
              }
              links = uniq(links);
              window.open(`https://web.skype.com/share?url=${join(links, '%0A%0A')}`);
            },
            pathname === PAGES.PREDEFINEMATCHPAIR
              ? { isPairExcel: true }
              : pathname === PAGES.CONFIRM || pathname === PAGES.PURCHASE
              ? { orderDiamond: true }
              : {},
          )
        : links.length < 3
        ? OpenNotification({
            type: 'error',
            title: 'Diamond resource not available !',
          })
        : window.open(`https://web.skype.com/share?url=${join(links, '%0A%0A')}`);
      props.onClose();
    } else {
      OpenNotification({
        type: 'error',
        title: 'Please Select atleast 1 Option to Share.',
      });
    }
  };

  useEffect(() => {
    setValues((totalChecked) => {
      totalChecked = { ...totalChecked };

      if (OPTIONS.Images) {
        const mappedImagesKeys = OPTIONS?.Images?.subLevel?.map?.((rec) => rec?.key);

        void Object.keys(totalChecked)?.forEach?.((v) => {
          mappedImagesKeys?.includes(v) && (totalChecked[v] = images);
        });
      }
      return totalChecked;
    });
  }, [images]);

  useEffect(() => {
    setValues((totalChecked) => {
      totalChecked = { ...totalChecked };

      if (OPTIONS.Roughs) {
        const mappedVideosKeys = OPTIONS?.Videos?.subLevel?.map?.((rec) => rec?.key);

        void Object.keys(totalChecked)?.forEach?.((v) => {
          mappedVideosKeys?.includes(v) && (totalChecked[v] = videos);
        });
      }
      return totalChecked;
    });
  }, [videos]);

  useEffect(() => {
    setValues((totalChecked) => {
      totalChecked = { ...totalChecked };

      if (OPTIONS.Roughs) {
        const mappedCertificatesKeys = OPTIONS?.Certificates?.subLevel?.map?.((rec) => rec?.key);

        void Object.keys(totalChecked)?.forEach?.((v) => {
          mappedCertificatesKeys?.includes(v) && (totalChecked[v] = certificates);
        });
      }
      return totalChecked;
    });
  }, [certificates]);

  useEffect(() => {
    setValues((totalChecked) => {
      totalChecked = { ...totalChecked };

      if (OPTIONS.Roughs) {
        const mappedRoughsKeys = OPTIONS?.Roughs?.subLevel?.map?.((rec) => rec?.key);

        void Object.keys(totalChecked)?.forEach?.((v) => {
          mappedRoughsKeys?.includes(v) && (totalChecked[v] = roughs);
        });
      }
      return totalChecked;
    });
  }, [roughs]);

  useEffect(() => {
    setValues((totalChecked) => {
      totalChecked = { ...totalChecked };

      if (OPTIONS.detailLink) {
        const mappedDnaKeys = OPTIONS?.detailLink?.subLevel?.map?.((rec) => rec?.key);

        void Object.keys(totalChecked)?.forEach?.((v) => {
          mappedDnaKeys?.includes(v) && (totalChecked[v] = link);
        });
      }
      // else if (!isEmpty(OPTIONS.Excels.key)) {
      //   totalChecked[OPTIONS.Excels.key] = excels;
      // }
      return totalChecked;
    });
  }, [link]);

  useEffect(() => {
    setValues((totalChecked) => {
      totalChecked = { ...totalChecked };

      if (OPTIONS.Roughs) {
        const mappedExcelssKeys = OPTIONS?.Excels?.subLevel?.map?.((rec) => rec?.key);

        void Object.keys(totalChecked)?.forEach?.((v) => {
          mappedExcelssKeys?.includes(v) && (totalChecked[v] = excels);
        });
      }
      // else if (!isEmpty(OPTIONS.Excels.key)) {
      //   totalChecked[OPTIONS.Excels.key] = excels;
      // }
      return totalChecked;
    });
  }, [excels]);

  const getAllKeys = () => {
    const keys = uniq(
      flattenDeep(
        Object.entries(OPTIONS).map(([, val]) => {
          let tmp = [];

          if (val && val.key) {
            tmp.push(val.key);
          }

          if (val && val.subLevel && val.subLevel?.length) {
            tmp = concat(tmp, map(val.subLevel, 'key'));
          }
          return tmp;
        }),
      ),
    );

    return keys.filter((k) => (k === 'isExcel' ? !isDna : true));
  };

  useEffect(() => {
    const definedKeys = getAllKeys();

    definedKeys.forEach((key) => {
      setValues((totalChecked) => ({ ...totalChecked, [key]: false }));
    });
  }, []);

  useEffect(() => {
    const definedKeys = getAllKeys();

    definedKeys.forEach((key) => {
      setValues((totalChecked) => ({
        ...totalChecked,
        [key]: selectAll,
      }));
    });
    setImages(selectAll);
    setVideos(selectAll);
    // setLink(selectAll);
    setCertificates(selectAll);
    !isDna && setExcels(selectAll);
  }, [isDna, selectAll]);

  React.useEffect(() => {
    if (link) setValues((values) => ({ ...values, withoutPrice: true }));
  }, [link]);

  return (
    <React.Fragment>
      <div className={classNames([isMobile ? '' : 'searchPopupCommon'])}>
        <div className={classNames([isMobile ? 'shareDropdownMobileHead' : 'shareOptionSet d-flex'])}>
          <div className={classNames([isMobile ? 'shareStoneMobileItem border-bottom-none' : 'shareOptionSetItem'])}>
            <div className={classNames([isMobile ? 'shareStoneMobileHead' : 'dropdownMainTitle'])}>
              <div className="dropdownIconCheckBox">
                <Checkbox checked={selectAll} onChange={() => setSelectAll((v) => !v)}></Checkbox>
              </div>
              <span>Select All</span>
            </div>
            {/* Images */}
            {OPTIONS?.Images?.subLevel ? (
              <ul className="bottom border">
                {OPTIONS?.Images?.subLevel?.length > 0 && (
                  <>
                    {/* <div className="dropdownMainTitle">
                        <div className="dropdownIconCheckBox">
                          <Checkbox
                            checked={images}
                            onChange={() => {
                              setImages(!images);
                              hASelectionHandling();
                            }}
                          >
                            <span>{OPTIONS?.Images?.label}</span>
                          </Checkbox>
                        </div>
                      </div> */}
                    <div className={classNames([isMobile ? 'mobileShareOption' : 'dropdownRightCheck'])}>
                      {OPTIONS?.Images?.subLevel.map((record) =>
                        isDna && record.key === 'isExcel' ? null : (
                          <ShareCheckbox
                            title={record.label}
                            key={record.label}
                            activeClass={values[record.key] ? 'active' : ''}
                            checked={values[record.key]}
                            onClick={() => setValues((v) => ({ ...v, [record.key]: !values[record.key] }))}
                          />
                        ),
                      )}
                    </div>
                  </>
                )}
              </ul>
            ) : OPTIONS.Images ? (
              <div className="singleOnly">
                <ShareCheckbox
                  title={OPTIONS?.Images?.label}
                  activeClass={values[OPTIONS.Images.key] ? 'active' : ''}
                  checked={values[OPTIONS.Images.key]}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      [OPTIONS.Images.key]: e.target.checked,
                    });
                  }}
                />
              </div>
            ) : null}
            {/* Images */}

            {/* Videos */}
            {/* {OPTIONS?.Videos?.subLevel ? (
<>
                {OPTIONS?.Videos?.subLevel?.length > 0 && (
                  <ul>
                    <div className="dropdownMainTitle">
                      <div className="dropdownIconCheckBox">
                        <Checkbox
                          checked={videos}
                          onChange={() => {
                            setVideos(!videos);
                          }}
                        >
                          <span>{OPTIONS?.Videos?.label}</span>
                        </Checkbox>
                      </div>
                    </div>
                    <div className="dropdownRightCheck">
                      {OPTIONS?.Videos?.subLevel.map((record) => (
                        <ShareCheckbox
                          title={record.label}
                          key={record.label}
                          activeClass={values[record.key] ? 'active' : ''}
                          checked={values[record.key]}
                          onClick={() => setValues((v) => ({ ...v, [record.key]: !values[record.key] }))}
                        />
                      ))}
                    </div>
                   </ul>
                )}
 </>
            ) : OPTIONS.Videos ? (
              <div className="singleOnly">
                <ShareCheckbox
                  // title={OPTIONS?.Videos?.label}
                  activeClass={values[OPTIONS.Videos.key] ? 'active' : ''}
                  checked={values[OPTIONS.Videos.key]}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      [OPTIONS.Videos.key]: e.target.checked,
                    });
                  }}
                />
              </div>
            ) : null} */}
            {/* Videos */}

            {/* Link */}
            {OPTIONS?.detailLink?.subLevel ? (
              <ul>
                {OPTIONS?.detailLink?.subLevel?.length > 0 && (
                  <>
                    {/* <div className="dropdownMainTitle">
                      <div className="dropdownIconCheckBox">
                        {/* <Checkbox checked={link} onChange={() => setLink((link) => !link)}>
                            <span>{OPTIONS?.detailLink?.label}</span>
                          </Checkbox> */}
                    {/* </div>
                    </div> */}

                    <div className={classNames([isMobile ? 'mobileShareOption' : 'dropdownRightCheck'])}>
                      {OPTIONS?.detailLink?.subLevel.map((record) => (
                        <>
                          <ShareCheckbox
                            // disabled={!link}
                            key={record.label}
                            title={record.label}
                            activeClass={values[record.key] ? 'active' : ''}
                            checked={values[record.key]}
                            onClick={() => setValues((values) => ({ ...values, [record?.key]: !values[record.key] }))}
                          />
                        </>
                      ))}
                    </div>
                  </>
                )}
              </ul>
            ) : OPTIONS.detailLink ? (
              <div className="singleOnly">
                <ShareCheckbox
                  title={OPTIONS?.detailLink?.label}
                  activeClass={values[OPTIONS.detailLink.key] ? 'active' : ''}
                  checked={values[OPTIONS.detailLink.key]}
                  onChange={(e) => setValues((c) => ({ ...c, [OPTIONS.detailLink.key]: e.target.checked }))}
                />
              </div>
            ) : null}
            {/* Link */}

            {/* Certificates */}
            {OPTIONS?.Certificates?.subLevel ? (
              <ul>
                {OPTIONS?.Certificates?.subLevel?.length > 0 && (
                  <>
                    <div className={classNames([isMobile ? 'shareStoneMobileHead' : 'dropdownMainTitle'])}>
                      <div className="dropdownIconCheckBox">
                        <Checkbox
                          checked={certificates}
                          onChange={() => {
                            setCertificates(!certificates);
                          }}
                        >
                          <span>{OPTIONS?.Certificates?.label}</span>
                        </Checkbox>
                      </div>
                    </div>
                    <div className="dropdownRightCheck">
                      {OPTIONS?.Certificates?.subLevel.map((record) => (
                        <ShareCheckbox
                          title={record.label}
                          key={record.label}
                          activeClass={values[record.key] ? 'active' : ''}
                          checked={values[record.key]}
                          onClick={() => setValues((v) => ({ ...v, [record.key]: !values[record.key] }))}
                        />
                      ))}
                    </div>
                  </>
                )}
              </ul>
            ) : OPTIONS.Certificates ? (
              <div className="singleOnly">
                <ShareCheckbox
                  title={OPTIONS?.Certificates?.label}
                  activeClass={values[OPTIONS.Certificates.key] ? 'active' : ''}
                  checked={values[OPTIONS.Certificates.key]}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      [OPTIONS.Certificates.key]: e.target.checked,
                    });
                  }}
                />
              </div>
            ) : null}
            {/* Certificates */}

            {/* Roughs */}
            {OPTIONS?.Roughs?.subLevel ? (
              <ul>
                {OPTIONS?.Roughs?.subLevel?.length > 0 && (
                  <>
                    <div className={classNames([isMobile ? 'shareStoneMobileHead' : 'dropdownMainTitle'])}>
                      <div className="dropdownIconCheckBox">
                        <Checkbox
                          checked={roughs}
                          onChange={() => {
                            setRoughs(!roughs);
                          }}
                        >
                          <span>{OPTIONS?.Roughs?.label}</span>
                        </Checkbox>
                      </div>
                    </div>
                    <div className="dropdownRightCheck">
                      {OPTIONS?.Roughs?.subLevel.map((record) => (
                        <ShareCheckbox
                          title={record.label}
                          key={record.label}
                          activeClass={values[record.key] ? 'active' : ''}
                          checked={values[record.key]}
                          onClick={() => setValues((v) => ({ ...v, [record.key]: !values[record.key] }))}
                        />
                      ))}
                    </div>
                  </>
                )}
              </ul>
            ) : OPTIONS.Roughs ? (
              <div className="singleOnly">
                <ShareCheckbox
                  title={OPTIONS?.Roughs?.label}
                  activeClass={values[OPTIONS.Roughs.key] ? 'active' : ''}
                  checked={values[OPTIONS.Roughs.key]}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      [OPTIONS.Roughs.key]: e.target.checked,
                    });
                  }}
                />
              </div>
            ) : null}
            {/* Roughs */}

            {/* Excels */}
            {OPTIONS?.Excels?.subLevel ? (
              <ul>
                {OPTIONS?.Excels?.subLevel?.length > 0 && (
                  <>
                    <div className={classNames([isMobile ? 'shareStoneMobileHead' : 'dropdownMainTitle'])}>
                      <div className="dropdownIconCheckBox">
                        <Checkbox
                          checked={excels}
                          onChange={() => {
                            setExcels(!excels);
                          }}
                        >
                          <span>{OPTIONS?.Excels?.label}</span>
                        </Checkbox>
                      </div>
                    </div>
                    <div className="dropdownRightCheck">
                      {OPTIONS?.Excels?.subLevel.map((record) => (
                        <ShareCheckbox
                          title={record.label}
                          key={record.label}
                          activeClass={values[record.key] ? 'active' : ''}
                          checked={values[record.key]}
                          onClick={() => setValues((v) => ({ ...v, [record.key]: !values[record.key] }))}
                        />
                      ))}
                    </div>
                  </>
                )}
              </ul>
            ) : OPTIONS.Excels ? (
              <div className="singleOnly">
                <ShareCheckbox
                  title={OPTIONS?.Excels?.label}
                  activeClass={values[OPTIONS.Excels.key] ? 'active' : ''}
                  checked={values[OPTIONS.Excels.key]}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      [OPTIONS.Excels.key]: e.target.checked,
                    });
                  }}
                />
              </div>
            ) : null}
          </div>
        </div>
        <div className={classNames([isMobile ? 'sideBarPopupButton' : 'commonModalButton mt-50'])}>
          <button
            className={classNames([isMobile ? 'commonButton' : 'fillButton'])}
            onClick={() => {
              let totalTrue = 0;
              Object.values(values).forEach((x) => x === true && totalTrue++);

              if (totalTrue >= 1) {
                setSendEmail(true);
              } else {
                OpenNotification({
                  type: 'error',
                  title: 'Please Select atleast 1 Option to Share.',
                });
              }
            }}
          >
            <IntlMessage id="app.my-account.email" />
          </button>
          <button
            onClick={() => {
              WhatsAppShare();
            }}
            className={classNames([isMobile ? 'commonButton' : 'fillButton ml-5'])}
          >
            <IntlMessage id="app.WhatsAppLabel" />
          </button>
          <button
            onClick={() => {
              SkypeShare();
            }}
            className={classNames([isMobile ? 'commonButton' : 'fillButton ml-5'])}
          >
            <IntlMessage id="app.SkypeLabel" />
          </button>
        </div>
      </div>
      <SendEmailPopup
        sendEmail={sendEmail}
        onClose={props.onClose}
        onCancel={() => setSendEmail(false)}
        ids={map(props.checked, 'id')}
        cnfrmPath={pathname === PAGES.CONFIRM || pathname === PAGES.PURCHASE}
        blockId={pathname === PAGES.MY_MEMO ? map(props?.checked, 'blockId') : undefined}
        totalChecked={values}
        orderDiamond={pathname === PAGES.PURCHASE ? compact(uniq(flatten(map(props.checked, 'memoDetailId')))) : []}
      />
    </React.Fragment>
  );
};

export default ShareDropdown;
