import React from 'react';

import { Tooltip } from 'antd';

import { useGroupSelect } from 'util/useGroupSelect';

import CheckBox from '../CheckBox';

const GroupCheckBox = React.memo((props) => {
  const { groupRows, currentType } = props;

  const [checked, indeterminate, toggle] = useGroupSelect(groupRows, currentType);

  const groupKey = React.useMemo(() => groupRows?.[0]?.['_groupKey_'], [groupRows]);

  return <CheckBox antd name={groupKey} checked={checked} indeterminate={indeterminate} onChange={toggle} />;
});

function RowGroupHeader(props) {
  const { row, groupRows, colSpan, children } = props;

  return (
    <tr className="dn-group tableGroupingTr">
      <div className="tableGropupingBox">
        <div className="tableGroupTitle">
          <span className="tableGroupTitleInner">
            {props.showCheckBox && (
              <Tooltip title="Select All">
                <div className="checkBoxSelect">
                  <GroupCheckBox groupRows={row?.subRows || groupRows} currentType={props.currentType} />
                </div>
              </Tooltip>
            )}
            <span>{children}</span>
          </span>
        </div>
      </div>

      <td className="groupingTableAmount" colSpan={colSpan} style={{ color: 'transparent' }}>
        &nbsp;
      </td>
    </tr>
  );
}

export default React.memo(RowGroupHeader);
