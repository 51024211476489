import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import groupBy from 'lodash/groupBy';

import { CURRENCY_COLUMNS, FLOAT_COLUMNS, ROUND_COLUMNS } from 'components/DiamondList/table-config';
import CommonModal from 'components/common/CommonModal';
import DownloadDropdown from 'components/common/DiamondListing/DownloadDropdown';
import SendEmail from 'components/common/DiamondListing/SendEmail';
import ShareDropdown from 'components/common/DiamondListing/ShareDropdown';

import { StoneService } from 'services/StoneService';
import { Storage } from 'services/Storage';

import { IntlMessage } from 'util/IntlMessages';
import { callApi, withURLParams } from 'util/call-api';
import { withMobile, compose } from 'util/hocs';
import { notify } from 'util/notify';
import { formatDecimal, isEmpty, isObject, parseDecimal, pruneEmpty } from 'util/utils';

import { KYC_STATUS, FILE_URLS, getFileUrl, OTHER_COUNTRY } from 'constants/Common';
import { DETAIL_VIEW_AUTH } from 'constants/DiamAuth';
import { DETAIL_VIEW } from 'constants/DiamondDetail';
import { VIEW_DIAMOND } from 'constants/apiConstant';

import DiamondDetailAction from './DiamondDetailAction';
import EnquiryDetail from './EnquiryDetail';
import DiamondDetailLeft from './LeftSection';
import MobileDiamondDetail from './MobileDiamondDetail';
import OfferOrder from './OfferOrder';
import PlaceOrderDetail from './PlaceOrderDetail';

import { getGridHeight } from '../DiamondList';
import { downloadVideo, downloadFile, LISTINGPAGES } from '../DiamondList/DiamondListFunctions';

import shareThemeSvg from 'assets/svg/DNA/share-theme.svg';
import diamondDetailDownloadZipSvg from 'assets/svg/DiamondDetail/download-zip.svg';
import diamondDetailXRaySvg from 'assets/svg/DiamondDetail/x-ray.svg';
import copylinkThemeSvg from 'assets/svg/copylink-theme.svg';
import copylinkWhiteSvg from 'assets/svg/copylink-white.svg';
import downloadThemeSvg from 'assets/svg/download-theme.svg';
import downloadWhiteSvg from 'assets/svg/download-white.svg';

let gridHeight = 0;

export function setGridHeightFn() {
  const allContainers = {};

  let totalHeight = window.innerHeight - 10;
  // allContainers.headerHeight = document.getElementsByClassName("headerTopMenu")[0];
  // allContainers.covidStrip = document.getElementsByClassName("covidStrip")[0];
  allContainers.HeaderStripBlock = document.getElementsByClassName('HeaderStripBlock')[0];
  allContainers.diamondDetailBottomBox = document.getElementsByClassName('diamondDetailBottomBox ')[0];

  Object.keys(allContainers).map((key) => {
    if (allContainers[key] && allContainers[key].offsetHeight) {
      totalHeight = totalHeight - allContainers[key].offsetHeight;
    }
    return true;
  });
  return totalHeight;
}
let gridHeight1 = 0;

export function setGridHeightFn1() {
  const allContainers = {};

  let totalHeight = window.innerHeight - 15;
  allContainers.headerHeight = document.getElementsByClassName('headerTopMenu')[0];
  allContainers.covidStrip = document.getElementsByClassName('covidStrip')[0];
  allContainers.HeaderStripBlock = document.getElementsByClassName('HeaderStripBlock ')[0];
  allContainers.diamondDetailTopTab = document.getElementsByClassName('diamondDetailTopTab')[0];

  Object.keys(allContainers).map((key) => {
    if (allContainers[key] && allContainers[key].offsetHeight) {
      totalHeight = totalHeight - allContainers[key].offsetHeight;
    }
    return true;
  });
  return totalHeight;
}

class DiamondDetail extends Component {
  state = {
    showOrderDetail: false,
    showEnquiryDetail: false,
    detail: true,
    data: {},
    sendEmail: false,
    showOffer: false,
    visibleEmail: false,
    activeTab: '1',
    currentSubTab: 0,
  };

  get data() {
    return pruneEmpty(this.state.data) ?? pruneEmpty(this.props.data) ?? {};
  }

  get stoneId() {
    return this.data?.id ?? this.props.match?.params?.id;
  }

  getStoneData = async () => {
    if (!isEmpty(this.data)) {
      this.setState({ data: this.data }, () => this.markStoneAsSeen());
      return;
    }

    this.setState({ loading: true }, () => this.markStoneAsSeen());
    const [err, res] = await callApi(withURLParams(VIEW_DIAMOND, [this.stoneId]));

    this.setState(
      {
        data: !err
          ? isObject(res?.data)
            ? res?.data
            : res?.data?.find?.((stone) => [stone?.id, stone?.vStnId].includes(this.stoneId))
          : {},
        loading: false,
      },
      () => this.markStoneAsSeen(),
    );
  };

  markStoneAsSeen = () => {
    if (!this.props.markAsSeen) return;
    if (isEmpty(this.data)) return;
    StoneService.markStoneAsSeen(this.data);
  };

  showOrderDetail = () => this.setState({ showOrderDetail: true, showEnquiryDetail: false, showOffer: false });

  showEnquiryDetail = () => this.setState({ showEnquiryDetail: true, showOffer: false, showOrderDetail: false });

  closeDrawers = () => this.setState({ showEnquiryDetail: false, showOffer: false, showOrderDetail: false });

  leftPart = (data) => {
    const gridHeight1 = getGridHeight(['headerTopMenu', 'covidStrip', 'HeaderStripBlock', 'diamondDetailTopTab'], 15);
    return (
      <div className="diamondDetailInnerDetail">
        {data && !isEmpty(data) ? (
          <DiamondDetailLeft
            data={data}
            shared={this.state.shared}
            rptNo={data?.rptNo}
            pktNo={data?.vStnId}
            style={
              this.props.isMobile
                ? {}
                : !this.props.diamondPopup
                ? { height: gridHeight1, overflow: 'auto' }
                : { height: '' }
            }
            activeTabChange={(tab) => this.setState({ activeTab: tab })}
            currentSubTab={(prev, current) => {
              this.setState({ currentSubTab: current });
            }}
          />
        ) : null}
      </div>
    );
  };

  handleCopyLink = () => {
    const { activeTab, currentSubTab } = this.state;
    const data = (this.props.match?.params?.id ? this.state.data : this.props.data) ?? {};
    const isDiaCamLink = (data.files?.videoFile?.match?.('up.diacam360.com')?.index ?? -1) > -1;
    const videoLink = data?.videoFile && isDiaCamLink ? `${data?.files?.videoFile}/video` : data?.files?.videoFile;

    let value = ``;

    if (activeTab === '1') {
      {
        data?.img ? (value = data?.files?.img) : (value = null);
      }
    } else if (activeTab === '2') {
      {
        data?.videoFile ? (value = videoLink) : (value = null);
      }
    }
    // else if (activeTab === '3') {
    //   value =
    //     currentSubTab === 0
    //       ? FILE_URLS.hAFile.replace('***', data.mfgStnId)
    //       : FILE_URLS.arrowFile.replace('***', data.mfgStnId);
    // }
    else if (activeTab === '4') {
      data?.certFile ? (value = data?.files?.certFile) : (value = null);
    }

    navigator.clipboard.writeText(value);
    return value === null
      ? notify.error({ message: 'No Media Link found' })
      : notify.success({ message: 'Link Copied.' });
  };

  handleDownload = () => {
    const { activeTab, currentSubTab } = this.state;
    const data = this.data;

    if (activeTab === '1') {
      const obj = {
        path: FILE_URLS.mp4Video.split('***').join(data.mfgStnId),
        fileName: `video-${data.mfgStnId}`,
        ext: '.mp4',
        id: data.id,
      };

      downloadVideo(obj);
    } else if (activeTab === '2') {
      const obj = {
        path: FILE_URLS.img.replace('***', data.rptNo),
        fileName: currentSubTab === 0 && `still-${data.mfgStnId}`,
        ext: '.jpg',
      };

      downloadFile(obj);
    } else if (activeTab === '3') {
      const obj = {
        path:
          currentSubTab === 0
            ? FILE_URLS.arrowFile.replace('***', data.mfgStnId)
            : FILE_URLS.hAFile.replace('***', data.mfgStnId),
        fileName: currentSubTab === 0 ? `Arrow-${data.mfgStnId}` : `hA-${data.mfgStnId}`,
        ext: '.jpg',
      };

      downloadFile(obj);
    } else if (activeTab === '4') {
      const obj = {
        path: FILE_URLS.certFile.replace('***', data.rptNo),
        fileName: `certi-${data.rptNo}`,
        ext: '.pdf',
      };

      downloadFile(obj);
    }
  };

  componentDidMount() {
    this.getStoneData();
  }

  getEmailModal = (data) => {
    return (
      <SendEmail
        path={`${window.location.origin}/${LISTINGPAGES.DETAILS}/${data.id}`}
        xRay={true}
        sendEmail={this.state.sendEmail}
        onCancel={() => this.setState({ sendEmail: false })}
        onDetailClose={() => this.setState({ sendEmail: false })}
      />
    );
  };

  render() {
    const { visibleEmail, loading } = this.state;
    const data = this.data;

    const P = Storage.get('userPermissions');
    const userData = Storage.get('user');
    const DETAILS = userData?.account?.isApproved === KYC_STATUS.APPROVED ? DETAIL_VIEW : DETAIL_VIEW_AUTH;

    const message = userData?.account
      ? !userData.account.isKycUploaded
        ? 'Please complete your KYC procedure to access the functionality.'
        : userData.account.isApproved === KYC_STATUS.APPROVED
        ? 'Functionality is disabled for sometime.'
        : 'Please wait to access the functionality untill your KYC documents get verify.'
      : 'Register yourself to access the functionality.';

    gridHeight = setGridHeightFn();
    gridHeight1 = setGridHeightFn1();

    const params = Object.keys(DETAILS).map((section) => (
      <div className="diamondDetailMore" key={DETAILS[section]['name']}>
        <h2 className="detailSmallTitle">{DETAILS[section]['name']}</h2>
        <div className="diamondDetailMoreHalf">
          {DETAILS[section]?.data?.map?.((item) => {
            if (data?.wSts === 'U' && ['back', 'ctPr', 'amt'].includes(item?.key)) return null;

            return (
              <ul key={item?.key}>
                <li>
                  {!item?.hide && <span>{item?.label}</span>}
                  {!item?.hide && (
                    <span>
                      {!data[item?.key]
                        ? '-'
                        : FLOAT_COLUMNS.includes(item?.key)
                        ? formatDecimal(data[item?.key])
                        : ROUND_COLUMNS.includes(item?.key)
                        ? Math.round(data[item?.key])
                        : data[item?.key]}
                    </span>
                  )}
                </li>
              </ul>
            );
          })}
        </div>
      </div>
    ));

    if (this.props.isMobile) return <MobileDiamondDetail params={params} {...this.state} parent={this} />;

    return (
      !loading && (
        <div className="diamondDetailWrapper">
          <div className="diamondDetailInner">
            {this.props.diamondPopup && (
              <div className="diamondDetailBottomBox">
                <div className="diamondDetailBottomAction">
                  <div className="d-flex">
                    <DiamondDetailAction
                      onClick={() =>
                        P?.SHARE_VIA_MAIL?.mailExcel
                          ? this.setState({ sendEmail: !this.state.sendEmail })
                          : notify.error({ message })
                      }
                      image={shareThemeSvg}
                      tooltip={<IntlMessage id="app.Share" />}
                      placement="bottomLeft"
                    />

                    <DiamondDetailAction
                      onClick={() => {
                        if (P?.DOWNLOAD?.downloadExcel) {
                          this.setState({ visibleEmail: true });
                        } else {
                          notify.error({ message });
                        }
                      }}
                      image={diamondDetailDownloadZipSvg}
                      tooltip={<IntlMessage id="app.downloadZip" />}
                      placement="bottomLeft"
                    />
                  </div>
                </div>
              </div>
            )}

            <div className="diamondDetailMainTab">
              {this.props.diamondPopup && (
                <>
                  <div className="detailHalfWhite"></div>
                  <div className="detailHalfGray"></div>
                </>
              )}
              <div className="diamondDetailLeft">
                <div className="diamondDetailInnerDetail">
                  <DiamondDetailLeft
                    data={data}
                    style={!this.props.diamondPopup ? { height: gridHeight1, overflow: 'auto' } : { height: '' }}
                    activeTabChange={(tab) => this.setState({ activeTab: tab })}
                    currentSubTab={(prev, current) => this.setState({ currentSubTab: current })}
                  />
                  {this.state.activeTab === '2' ? (
                    <div className="d-flex j-content-center">
                      {/* <div className="diamondDetailSperateBlock commonOutline" onClick={() => this.handleDownload()}>
                      <img className="defualtImg" src={downloadThemeSvg} alt="" />
                      <img className="hoverImg" src={downloadWhiteSvg} alt="" />
                      <span>Download</span>
                    </div> */}
                      <div className="diamondDetailSperateBlock commonOutline" onClick={() => this.handleCopyLink()}>
                        <img className="defualtImg" src={copylinkThemeSvg} alt="" />
                        <img className="hoverImg" src={copylinkWhiteSvg} alt="" />
                        <IntlMessage id="app.copy-link" />
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex j-content-center">
                      <div className="diamondDetailSperateBlock commonOutline" onClick={() => this.handleCopyLink()}>
                        <img className="defualtImg" src={copylinkThemeSvg} alt="" />
                        <img className="hoverImg" src={copylinkWhiteSvg} alt="" />
                        <IntlMessage id="app.copy-link" />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className={`diamondDetailRight ${!this.props.diamondPopup && 'diamondDetailPage'}`}>
                {!this.props.diamondPopup && (
                  <div className="diamondDetailBottomBox">
                    <div className="diamondDetailBottomAction">
                      <div className="d-flex">
                        {userData && userData.account ? (
                          <>
                            <DiamondDetailAction
                              onClick={() =>
                                P?.SHARE_VIA_MAIL?.mailExcel
                                  ? this.setState({ sendEmail: !this.state.sendEmail })
                                  : notify.error({ message })
                              }
                              image={shareThemeSvg}
                              tooltip="Share"
                              placement="bottomLeft"
                            />

                            <DiamondDetailAction
                              onClick={() => {
                                if (P?.DOWNLOAD?.downloadExcel) {
                                  this.setState({ visibleEmail: true });
                                } else {
                                  notify.error({ message });
                                }
                              }}
                              image={diamondDetailDownloadZipSvg}
                              tooltip="Download Zip"
                              placement="bottomLeft"
                            />
                          </>
                        ) : (
                          <>
                            {window.location.search === '?direct-share' && (
                              <DiamondDetailAction
                                word="Buy Now"
                                tooltip="Buy Now"
                                placement="bottomLeft"
                                onClick={() =>
                                  this.props.history.push({ pathname: '/quick-signup', state: { id: this.stoneId } })
                                }
                              />
                            )}

                            <DiamondDetailAction
                              word="Signin"
                              tooltip="Signin"
                              placement="bottomLeft"
                              onClick={() => this.props.history.push('/signin')}
                            />
                            <DiamondDetailAction
                              word="Register"
                              tooltip="Register"
                              placement="bottomLeft"
                              onClick={() => this.props.history.push('/signup')}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                <div
                  className="mt-10"
                  style={!this.props.diamondPopup ? { height: gridHeight, overflow: 'auto' } : { height: 'auto' }}
                >
                  {Object.keys(DETAILS).map((section) => (
                    <div className="diamondDetailMore" key={section}>
                      <h2 className="detailSmallTitle">{DETAIL_VIEW[section]['name']}</h2>
                      <div className="diamondDetailMoreHalf">
                        {Object.keys(groupBy(DETAIL_VIEW[section]['data'], 'part')).map((part) => {
                          const source = groupBy(DETAIL_VIEW[section]['data'], 'part');

                          return source[part].map((field) => (
                            <ul key={field?.key}>
                              <li>
                                <span>{field.label}</span>
                                <span>
                                  {field?.key === 'lbCmt'
                                    ? data[field?.key]?.replace('<br/>', '')
                                    : field?.key === 'lsrInc'
                                    ? data[field?.key]?.replace('<br>', '')
                                    : field?.key === 'colNm'
                                    ? data?.isFcCol
                                      ? data?.fcColDesc
                                      : data?.colNm
                                    : field?.key == 'countryNm'
                                    ? OTHER_COUNTRY.includes(data[field?.key])
                                      ? 'Other'
                                      : data[field.key]
                                    : CURRENCY_COLUMNS?.includes(field?.key)
                                    ? isNaN(data[field?.key])
                                      ? '-'
                                      : `$${formatDecimal(data[field?.key])}`
                                    : field?.key === 'back'
                                    ? isNaN(formatDecimal(data[field?.key]))
                                      ? '-'
                                      : `${formatDecimal(data[field?.key])}%`
                                    : FLOAT_COLUMNS?.includes(field?.key)
                                    ? isNaN(formatDecimal(data[field?.key]))
                                      ? '-'
                                      : formatDecimal(data[field?.key])
                                    : data[field?.key] ?? field?.alt}
                                </span>
                              </li>
                            </ul>
                          ));
                        })}
                      </div>
                    </div>
                  ))}
                </div>

                {this.state.showEnquiryDetail && (
                  <EnquiryDetail
                    style={!this.props.diamondPopup ? { height: gridHeight, overflow: 'auto' } : { height: 'auto' }}
                    checked={[data]}
                    onCancel={() => this.closeDrawers()}
                    onClose={() => this.props.onClose()}
                  />
                )}

                {this.state.showOrderDetail && (
                  <PlaceOrderDetail
                    style={!this.props.diamondPopup ? { height: gridHeight, overflow: 'auto' } : { height: 'auto' }}
                    checked={[data]}
                    onCancel={() => this.closeDrawers()}
                    onClose={() => this.props.onClose()}
                  />
                )}

                {this.state.showOffer && (
                  <OfferOrder
                    style={!this.props.diamondPopup ? { height: gridHeight, overflow: 'auto' } : { height: 'auto' }}
                    checked={[data]}
                    onCancel={() => this.closeDrawers()}
                    onClose={() => this.closeDrawers()}
                    clearAll={() => this.props.onClose()}
                  />
                )}
              </div>
            </div>
          </div>

          <CommonModal
            title={'Share by'}
            handleCancel={() => this.setState({ sendEmail: false })}
            visible={this.state.sendEmail}
            wrapClassName="stone-detail-sidebar"
          >
            <ShareDropdown {...this.props} checked={[data]} onClose={() => this.setState({ sendEmail: false })} />
          </CommonModal>

          <CommonModal
            title={<IntlMessage id="app.Download" />}
            handleCancel={() => this.setState({ visibleEmail: false })}
            visible={visibleEmail}
          >
            <DownloadDropdown {...this.props} checked={[data]} onClose={() => this.setState({ visibleEmail: false })} />
          </CommonModal>
        </div>
      )
    );
  }
}

export default compose(
  connect((props) => ({ common: props.common }), {}),
  withRouter,
  withMobile,
)(DiamondDetail);
