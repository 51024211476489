import React, { useState, useEffect, useReducer } from 'react';

import { Checkbox } from 'antd';
import _, { filter, keys, pickBy } from 'lodash';

import { getLoginUser } from 'services/Commonfunction';

import IntlMessages from 'util/IntlMessages';
import { usePathname } from 'util/hooks';
import { classNames } from 'util/utils';

import { DOWNLOAD_OPTIONS } from 'constants/Common';
import { PAGES } from 'constants/paths.const';

import {
  downloadZip,
  getPath,
  handleDownloadExcel,
  isMobile,
  LISTINGPAGES,
} from '../../DiamondList/DiamondListFunctions';
import OpenNotification from '../CommonButton/OpenNotification';

const DownloadDropdown = (props) => {
  const [totalChecked, setTotalChecked] = useState([]);
  const [images, setImages] = useState(false);
  const [excels, setExcels] = useState(false);
  const [videos, setVideos] = useState(false);
  const [certificates, setCertificates] = useState(false);
  const [roughs, setRoughs] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const loginUser = getLoginUser();

  const pathname = usePathname();
  const isDna = React.useMemo(() => window.location.pathname?.split('/')?.[1] === 'dna', []);

  const isAllChecked = React.useMemo(
    () => [totalChecked.img, totalChecked.videoFile, totalChecked.certFile].findIndex((item) => item === false) === -1,
    [totalChecked],
  );

  const toggleIsAllChecked = React.useCallback(
    (e) => {
      const checked = e.target.checked;

      setTotalChecked({
        img: checked,
        videoFile: checked,
        certFile: checked,
        isExcel: isDna ? false : checked,
      });
    },
    [isDna],
  );

  const ShareOption = (props) => {
    return (
      // <div className="d-flex">
      <li id={props.uiid} className={props.activeClass}>
        <div className="dropdownBottomItem">
          <div className="dropdownIconCheckBox">
            <Checkbox onChange={props.onChange} checked={props.checked}></Checkbox>
          </div>
          <span className="shareOptionLabel">{props.title}</span>
        </div>
      </li>
      // </div>
    );
  };

  const handleDownloadClick = () => {
    const trueAny = Object.values(totalChecked).some((x) => x === true);
    let totalTrue = 0;
    Object.values(totalChecked).forEach((x) => x === true && totalTrue++);

    if (trueAny) {
      let obj = {};
      const singleTrue = keys(pickBy(totalChecked));

      if (singleTrue.length === 1 && singleTrue[0] === 'isExcel') {
        obj = { zip: false };
      } else if (totalTrue >= 2 || props.checked.length >= 2) {
        obj = { zip: true };
      } else {
        obj = { zip: false };
      }

      obj = {
        ...obj,
        filter: {
          vStnId: _.map(props.checked, 'vStnId'),
        },
        ...totalChecked,
        ...(props.orderDiamond && { orderDiamond: true }),
        mp4Video: totalChecked.videoFile,
        companyName: loginUser?.account?.companyName ?? '',
      };

      if (pathname === LISTINGPAGES.PURCHASE) {
        obj.orderDiamond = true;
      }

      if (pathname === LISTINGPAGES.MY_MEMO) {
        obj.filter = { ...obj.filter, blockid: _.map(props.checked, 'blockId') };
        obj.excelType = 3;
      }

      if (pathname === LISTINGPAGES.SEARCH_LAYOUT || pathname === LISTINGPAGES.PREDEFINEMATCHPAIR) {
        obj.isPairExcel = true;

        if (pathname === LISTINGPAGES.SEARCH_LAYOUT) {
          obj.isLayoutExcel = true;
        }
      }

      if (singleTrue.length === 1 && singleTrue[0] === 'isExcel') {
        if (pathname === PAGES.MY_MEMO) {
          handleDownloadExcel(
            {},
            _.map(props.checked, 'id'),
            () => {
              return props?.onClose(), props?.clearAll();
            },
            {
              excelType: 3,
              filter: {
                blockid: _.map(props.checked, 'blockId'),
              },
            },
          );
        } else {
          handleDownloadExcel({}, _.map(props.checked, 'id'), () => {
            return props?.onClose(), props?.clearAll();
          });
        }
        console.log(_.map(props.checked));
      } else {
        downloadZip(obj, () => {
          return (
            OpenNotification({
              type: 'success',
              title: 'Wait for sometime, your download is in process. File(s) will be downloaded soon.',
            }),
            props?.onClose(),
            props?.clearAll()
          );
        });
      }
    } else {
      OpenNotification({
        type: 'error',
        title: 'Please select atleast 1 option to download.',
      });
    }
  };

  useEffect(() => {
    if (DOWNLOAD_OPTIONS.Images) {
      const mappedImagesKeys = _.map(DOWNLOAD_OPTIONS.Images.subLevel, 'key');

      Object.keys(totalChecked).forEach((v) => {
        mappedImagesKeys.includes(v) && (totalChecked[v] = images);
      });
      forceUpdate();
    }
  }, [images]);

  useEffect(() => {
    if (DOWNLOAD_OPTIONS.Videos) {
      const mappedVideosKeys = _.map(DOWNLOAD_OPTIONS.Videos.subLevel, 'key');

      Object.keys(totalChecked).forEach((v) => {
        mappedVideosKeys.includes(v) && (totalChecked[v] = videos);
      });
      forceUpdate();
    }
  }, [videos]);

  useEffect(() => {
    if (DOWNLOAD_OPTIONS.Roughs) {
      const mappedCertificatesKeys = _.map(DOWNLOAD_OPTIONS.Certificates.subLevel, 'key');

      Object.keys(totalChecked).forEach((v) => {
        mappedCertificatesKeys.includes(v) && (totalChecked[v] = certificates);
      });
      forceUpdate();
    }
  }, [certificates]);

  useEffect(() => {
    if (DOWNLOAD_OPTIONS.Roughs) {
      const mappedRoughsKeys = _.map(DOWNLOAD_OPTIONS.Roughs.subLevel, 'key');

      Object.keys(totalChecked).forEach((v) => {
        mappedRoughsKeys.includes(v) && (totalChecked[v] = roughs);
      });
      forceUpdate();
    }
  }, [roughs]);

  useEffect(() => {
    if (DOWNLOAD_OPTIONS.Excels) {
      const mappedExcelssKeys = _.map(DOWNLOAD_OPTIONS.Excels.subLevel, 'key');

      Object.keys(totalChecked).forEach((v) => {
        mappedExcelssKeys.includes(v) && (totalChecked[v] = excels);
      });
      forceUpdate();
    }
  }, [excels]);

  const getAllKeys = () => {
    const keys = _.uniq(
      _.flattenDeep(
        _.map(DOWNLOAD_OPTIONS, (val, key) => {
          let tmp = [];

          if (val && val.key) {
            tmp.push(val.key);
          }

          if (val && val.subLevel && val.subLevel.length) {
            tmp = _.concat(tmp, _.map(val.subLevel, 'key'));
          }
          return tmp;
        }),
      ),
    );
    return keys;
  };

  useEffect(() => {
    const definedKeys = getAllKeys();

    definedKeys.forEach((key) => {
      setTotalChecked((totalChecked) => ({
        ...totalChecked,
        [key]: false,
      }));
    });
  }, []);

  const hASelectionHandling = () => {
    if (totalChecked && totalChecked.hAFile) {
      totalChecked.idealWhiteImage = true;
      totalChecked.arrBlack = true;
      totalChecked.heartImage = true;
    } else {
      totalChecked.idealWhiteImage = false;
      totalChecked.hAFile = false;
      totalChecked.arrBlack = false;
      totalChecked.heartImage = false;
    }
  };

  useEffect(() => {
    hASelectionHandling();
  }, [totalChecked.hAFile]);

  return (
    <>
      <div className="m-p-r">
        <div id="setLoader" className={classNames([isMobile ? '' : 'searchPopupCommon'])}>
          <div className={classNames([isMobile ? 'shareDropdownMobileHead' : 'shareOptionSet d-flex'])}>
            <div className={classNames([isMobile ? 'shareStoneMobileItem border-bottom-none' : 'shareOptionSetItem'])}>
              <div className={classNames([isMobile ? 'shareStoneMobileHead' : 'dropdownMainTitle'])}>
                <div className="dropdownIconCheckBox">
                  <Checkbox checked={isAllChecked} onChange={toggleIsAllChecked}></Checkbox>
                </div>
                <span>Select All</span>
              </div>

              <div className="m-chanage">
                {/* Images */}
                {DOWNLOAD_OPTIONS && DOWNLOAD_OPTIONS.Images && DOWNLOAD_OPTIONS.Images.subLevel ? (
                  <ul>
                    {DOWNLOAD_OPTIONS.Images.subLevel.length > 0 && (
                      <>
                        <div className={classNames([isMobile ? 'shareStoneMobileHead' : 'dropdownMainTitle'])}>
                          <div className="dropdownIconCheckBox">
                            <Checkbox
                              checked={images}
                              onChange={() => {
                                setImages(!images);
                                hASelectionHandling();
                              }}
                            >
                              <span>{DOWNLOAD_OPTIONS.Images.label}</span>
                            </Checkbox>
                          </div>
                        </div>
                        <div className={classNames([isMobile ? 'mobileShareOption' : 'dropdownRightCheck'])}>
                          {DOWNLOAD_OPTIONS.Images.subLevel.map((record) => (
                            <ShareOption
                              key={record.key}
                              title={record.label}
                              activeClass={totalChecked[record.key] ? 'active' : ''}
                              checked={totalChecked[record.key]}
                              onChange={(e) => {
                                setTotalChecked({
                                  ...totalChecked,
                                  [record.key]: e.target.checked,
                                });
                              }}
                            />
                          ))}
                        </div>
                      </>
                    )}
                  </ul>
                ) : DOWNLOAD_OPTIONS.Images ? (
                  // <div className="singleOnly m-chnage">
                  <ShareOption
                    title={DOWNLOAD_OPTIONS.Images.label}
                    activeClass={totalChecked[DOWNLOAD_OPTIONS.Images.key] ? 'active' : ''}
                    checked={totalChecked[DOWNLOAD_OPTIONS.Images.key]}
                    onChange={(e) => {
                      setTotalChecked({
                        ...totalChecked,
                        [DOWNLOAD_OPTIONS.Images.key]: e.target.checked,
                      });
                    }}
                  />
                ) : // </div>
                null}
                {/* Images */}

                {/* Videos */}
                {DOWNLOAD_OPTIONS && DOWNLOAD_OPTIONS.Videos && DOWNLOAD_OPTIONS.Videos.subLevel ? (
                  <ul>
                    {DOWNLOAD_OPTIONS.Videos.subLevel.length > 0 && (
                      <>
                        <div className={classNames([isMobile ? 'shareStoneMobileHead' : 'dropdownMainTitle'])}>
                          <div className="dropdownIconCheckBox">
                            <Checkbox
                              checked={videos}
                              onChange={() => {
                                setVideos(!videos);
                              }}
                            >
                              <span>{DOWNLOAD_OPTIONS.Videos.label}</span>
                            </Checkbox>
                          </div>
                        </div>
                        <div className={classNames([isMobile ? 'mobileShareOption' : 'dropdownRightCheck'])}>
                          {DOWNLOAD_OPTIONS.Videos.subLevel.map((record) => (
                            <ShareOption
                              key={record.key}
                              title={record.label}
                              activeClass={totalChecked[record.key] ? 'active' : ''}
                              checked={totalChecked[record.key]}
                              onChange={(e) => {
                                setTotalChecked({
                                  ...totalChecked,
                                  [record.key]: e.target.checked,
                                });
                              }}
                            />
                          ))}
                        </div>
                      </>
                    )}
                  </ul>
                ) : DOWNLOAD_OPTIONS.Videos ? (
                  // <div className="singleOnly">
                  <ShareOption
                    title={DOWNLOAD_OPTIONS.Videos.label}
                    activeClass={totalChecked[DOWNLOAD_OPTIONS.Videos.key] ? 'active' : ''}
                    checked={totalChecked[DOWNLOAD_OPTIONS.Videos.key]}
                    onChange={(e) => {
                      setTotalChecked({
                        ...totalChecked,
                        [DOWNLOAD_OPTIONS.Videos.key]: e.target.checked,
                      });
                    }}
                  />
                ) : // </div>
                null}
                {/* Videos */}

                {/* Certificates */}
                {DOWNLOAD_OPTIONS && DOWNLOAD_OPTIONS.Certificates && DOWNLOAD_OPTIONS.Certificates.subLevel ? (
                  <ul>
                    {DOWNLOAD_OPTIONS.Certificates.subLevel.length > 0 && (
                      <>
                        <div className={classNames([isMobile ? 'shareStoneMobileHead' : 'dropdownMainTitle'])}>
                          <div className="dropdownIconCheckBox">
                            <Checkbox
                              checked={certificates}
                              onChange={() => {
                                setCertificates(!certificates);
                              }}
                            >
                              <span>{DOWNLOAD_OPTIONS.Certificates.label}</span>
                            </Checkbox>
                          </div>
                        </div>
                        <div className={classNames([isMobile ? 'mobileShareOption' : 'dropdownRightCheck'])}>
                          {DOWNLOAD_OPTIONS.Certificates.subLevel.map((record) => (
                            <ShareOption
                              key={record.key}
                              title={record.label}
                              activeClass={totalChecked[record.key] ? 'active' : ''}
                              checked={totalChecked[record.key]}
                              onChange={(e) => {
                                setTotalChecked({
                                  ...totalChecked,
                                  [record.key]: e.target.checked,
                                });
                              }}
                            />
                          ))}
                        </div>
                      </>
                    )}
                  </ul>
                ) : DOWNLOAD_OPTIONS.Certificates ? (
                  // <div className="singleOnly">
                  <ShareOption
                    title={DOWNLOAD_OPTIONS.Certificates.label}
                    activeClass={totalChecked[DOWNLOAD_OPTIONS.Certificates.key] ? 'active' : ''}
                    checked={totalChecked[DOWNLOAD_OPTIONS.Certificates.key]}
                    onChange={(e) => {
                      setTotalChecked({
                        ...totalChecked,
                        [DOWNLOAD_OPTIONS.Certificates.key]: e.target.checked,
                      });
                    }}
                  />
                ) : // </div>
                null}
                {/* Certificates */}

                {/* Roughs */}
                {DOWNLOAD_OPTIONS && DOWNLOAD_OPTIONS.Roughs && DOWNLOAD_OPTIONS.Roughs.subLevel ? (
                  <ul>
                    {DOWNLOAD_OPTIONS.Roughs.subLevel.length > 0 && (
                      <>
                        <div className={classNames([isMobile ? 'shareStoneMobileHead' : 'dropdownMainTitle'])}>
                          <div className="dropdownIconCheckBox">
                            <Checkbox
                              checked={roughs}
                              onChange={() => {
                                setRoughs(!roughs);
                              }}
                            >
                              <span>{DOWNLOAD_OPTIONS.Roughs.label}</span>
                            </Checkbox>
                          </div>
                        </div>
                        <div className={classNames([isMobile ? 'mobileShareOption' : 'dropdownRightCheck'])}>
                          {DOWNLOAD_OPTIONS.Roughs.subLevel.map((record) => (
                            <ShareOption
                              key={record.key}
                              title={record.label}
                              activeClass={totalChecked[record.key] ? 'active' : ''}
                              checked={totalChecked[record.key]}
                              onChange={(e) => {
                                setTotalChecked({
                                  ...totalChecked,
                                  [record.key]: e.target.checked,
                                });
                              }}
                            />
                          ))}
                        </div>
                      </>
                    )}
                  </ul>
                ) : DOWNLOAD_OPTIONS.Roughs ? (
                  <div className="singleOnly">
                    <ShareOption
                      title={DOWNLOAD_OPTIONS.Roughs.label}
                      activeClass={totalChecked[DOWNLOAD_OPTIONS.Roughs.key] ? 'active' : ''}
                      checked={totalChecked[DOWNLOAD_OPTIONS.Roughs.key]}
                      onChange={(e) => {
                        setTotalChecked({
                          ...totalChecked,
                          [DOWNLOAD_OPTIONS.Roughs.key]: e.target.checked,
                        });
                      }}
                    />
                  </div>
                ) : null}
                {/* Roughs */}

                {/* Excels */}
                {DOWNLOAD_OPTIONS && DOWNLOAD_OPTIONS.Excels && DOWNLOAD_OPTIONS.Excels.subLevel ? (
                  <ul>
                    {DOWNLOAD_OPTIONS.Excels.subLevel.length > 0 && (
                      <>
                        <div className={classNames([isMobile ? 'shareStoneMobileHead' : 'dropdownMainTitle'])}>
                          <div className="dropdownIconCheckBox">
                            <Checkbox
                              checked={excels}
                              onChange={() => {
                                setExcels(!excels);
                              }}
                            >
                              <span>{DOWNLOAD_OPTIONS.Excels.label}</span>
                            </Checkbox>
                          </div>
                        </div>
                        <div className={classNames([isMobile ? 'mobileShareOption' : 'dropdownRightCheck'])}>
                          {DOWNLOAD_OPTIONS.Excels.subLevel.map((record) => (
                            <ShareOption
                              key={record.key}
                              title={record.label}
                              activeClass={totalChecked[record.key] ? 'active' : ''}
                              checked={totalChecked[record.key]}
                              onChange={(e) => {
                                setTotalChecked({
                                  ...totalChecked,
                                  [record.key]: e.target.checked,
                                });
                              }}
                            />
                          ))}
                        </div>
                      </>
                    )}
                  </ul>
                ) : DOWNLOAD_OPTIONS.Excels ? (
                  // <div className="singleOnly">
                  !isDna && (
                    <ShareOption
                      title={DOWNLOAD_OPTIONS.Excels.label}
                      activeClass={totalChecked[DOWNLOAD_OPTIONS.Excels.key] ? 'active' : ''}
                      checked={totalChecked[DOWNLOAD_OPTIONS.Excels.key]}
                      onChange={(e) => {
                        setTotalChecked({
                          ...totalChecked,
                          [DOWNLOAD_OPTIONS.Excels.key]: e.target.checked,
                        });
                      }}
                    />
                  )
                ) : // </div>
                null}
              </div>
            </div>
          </div>
          <div className={classNames([isMobile ? 'sideBarPopupButton' : 'commonModalButton mt-50'])}>
            <button className={classNames([isMobile ? 'commonButton' : 'fillButton'])} onClick={handleDownloadClick}>
              <IntlMessages id="app.Download" />
            </button>
            <button
              onClick={() => props.onClose()}
              className={classNames([isMobile ? 'commonButton' : 'fillButton ml-5'])}
            >
              <IntlMessages id="app.Cancel" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default DownloadDropdown;
