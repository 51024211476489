import React from 'react';

import { Select } from 'antd';

import leftArrowSvg from 'assets/svg/DiamondList/left-arrow.svg';
import rightArrowSvg from 'assets/svg/DiamondList/right-arrow.svg';

const { Option } = Select;

function getPathname() {
  return `${window?.location?.pathname}`?.split?.('/')?.pop?.()?.split('?')?.[0];
}

const LIMITS = [250, 500, 1000];
const dots = '...';

function getPages(totalPage, pg) {
  const pages = [];
  const prevPage = pg - 1 > 0 ? pg - 1 : 1;
  if (prevPage > 1) pages.push(1);
  if (prevPage > 1) pages.push(dots);

  for (let i = prevPage; i <= prevPage + 2 && i <= totalPage; i++) pages.push(i);
  if (prevPage + 3 < totalPage) pages.push(dots);
  if (prevPage + 2 < totalPage) pages.push(totalPage);
  return pages;
}

function Pagination(props) {
  // count - total count
  // page - current page
  // limit - current limit
  // noLimitChange - display limit Selection
  // noItemCount - display items 1-10 of 1000
  // data - actual page data length
  const { onPaginationChange } = props;
  let count = props.count;
  if (
    ['upcoming', 'new-arrival-bid-it', 'SL-exclusive', 'predefine-match-pair'].includes(getPathname()) &&
    props.count > 250
  )
    count = 250;
  const totalPage = count === 0 ? 1 : parseInt((count + props.limit - 1) / props.limit);

  return (
    <div className="listingPagePagination">
      <div className="clientPagination">
        {!props.noLimitChange && (
          <Select value={props.limit} onChange={(e) => onPaginationChange(1, e)} className="paginationSelect">
            {LIMITS.map((l) => (
              <Option value={l} key={l}>
                {l}
              </Option>
            ))}
          </Select>
        )}
        <ul>
          <li onClick={() => props.page !== 1 && onPaginationChange(props.page - 1, props.limit)}>
            <img src={leftArrowSvg} alt="arrow" />
          </li>
          {getPages(totalPage, props.page).map((x, index) => (
            <li
              key={index}
              className={x === props.page ? 'active' : null}
              onClick={() => x !== dots && onPaginationChange(x, props.limit)}
            >
              {x}
            </li>
          ))}
          <li onClick={() => props.page !== totalPage && onPaginationChange(props.page + 1, props.limit)}>
            <img src={rightArrowSvg} alt="arrow" />
          </li>
        </ul>
        {!props.noItemCount && (
          <span className="paginationText">
            {count > 0 ? (props.page - 1) * props.limit + 1 : 0} -{' '}
            {count > 0 ? (props.page - 1) * props.limit + props.data.length : 0} of {count} items
          </span>
        )}
      </div>
    </div>
  );
}

export default React.memo(Pagination);
