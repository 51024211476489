import React from 'react';
import { useSelector } from 'react-redux';

import { isNumber } from 'util/utils';

import { store } from 'redux/store';

import { CellNumber } from './CellNumber';

export class User {
  static identifier = 'User';

  props = {};
  account = {};
  #roles;

  constructor(details = {}) {
    this.account = this.props.account;

    this.props = details?.current
      ? store.getState().auth?.authUser ?? {}
      : (User.isUser(details) ? details.props : details) ?? {};
  }

  static isUser(input) {
    return input?.constructor === User;
  }

  static ROLES = {
    1: { code: 'superAdmin', label: 'Super Admin' },
    2: { code: 'admin', label: 'Admin' },
    3: { code: 'subUser', label: 'Sub User' },
    4: { code: 'primary', label: 'Primary' },
    5: { code: 'permanentGuest', label: 'Guest' },
    6: { code: 'employee', label: 'Employee' },
    7: { code: 'apiUser', label: 'Api User' },
    8: { code: 'salesperson', label: 'Sales Person' },
    9: { code: 'customer', label: 'Customer' },
    10: { code: 'secondary', label: 'Secondary' },
    11: { code: 'internalSalesperson', label: 'Internal Salesperson' },
  };

  get id() {
    return this.props.id;
  }

  get type() {
    return this.props.type;
  }

  get roles() {
    this.#roles =
      this.#roles ??
      Object.fromEntries(Object.entries(User.ROLES).map(([roleId, role]) => [role.code, roleId === `${this.type}`]));

    return this.#roles;
  }

  get roleLabel() {
    return User.ROLES[this.type].label;
  }

  get isSuperAdmin() {
    return this.roles.superAdmin;
  }

  get isAdmin() {
    return this.roles.superAdmin || this.roles.admin;
  }

  get isInternalSalesperson() {
    return this.roles.internalSalesperson;
  }

  get isSalesperson() {
    return this.roles.salesperson || this.isInternalSalesperson;
  }

  get companyType() {
    const value = Number(this.props.companyType) || undefined;
    return isNumber(value) ? `${value}` : undefined;
  }

  get cellNumber() {
    return new CellNumber(this.props.mobile, this.props.countryCode);
  }
}

User.use = function useUser(details) {
  const user = React.useMemo(() => new User(details), [details]);
  React.useDebugValue(user);
  return user;
};

User.useCurrent = function useCurrentUser() {
  return User.use(useSelector((state) => state.auth?.authUser));
};
