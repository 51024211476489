import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'redux';

import { Tooltip, Menu } from 'antd';
import { omit } from 'lodash';
import first from 'lodash/first';
import without from 'lodash/without';

import { getSearchList } from 'containers/APIFunctions';

// import Logo from 'components/Header/Logo';
// import Sidebar from 'components/Sidebar';
import { NotificationCount } from 'components/common/CountBox';
import NotificationDropdown from 'components/common/Header/NotificationDropdown';
import HeaderSearch from 'components/common/Header/Search';
import HeaderAccountList from 'components/common/HeaderAccountList';
import HeaderRightSidebar from 'components/common/HeaderRightSidebar';
import Stickybar from 'components/common/StickybarTop';
import DefaultUser from 'components/common/UserProfileDefault';

import { AuthService } from 'services/Auth';
import { Storage } from 'services/Storage';
import UtilService from 'services/util';

import IntlMessages from 'util/IntlMessages';
import { isEmpty, isString, titleCase } from 'util/utils';

import { trackTypeObj, LOCAL_STORAGE_VAR, BASE_URL_ATTACH } from 'constants/Common';
import { GET_DASHBOARD_DATA, GET_EVENTS, GET_NOTIFICATIONS, MARK_AS_READ } from 'constants/apiConstant';

import { setLoader, covidStrip } from 'redux/actions/Common';

import LangDropdown from './common/Header/HeaderStrip/LangDropdown';

import supportSvg from 'assets/svg/Account/support.svg';
import CartListSvg from 'assets/svg/Header/cart-list.svg';
import logoutWhiteSvg from 'assets/svg/Header/logout-white.svg';
import notificationWhiteSvg from 'assets/svg/Header/notification-white.svg';
import searchWhiteSvg from 'assets/svg/Header/search-white.svg';
import userWhiteSvg from 'assets/svg/Header/user-white.png';
import dollarCircleWhiteSvg from 'assets/svg/dollar-circle-white.svg';

class Header extends Component {
  state = {
    isHovered: false,
    isHovered1: false,
    isHovered2: false,
    covid: true,
    search: false,
    details: {},
    HeaderStripList: true,
    counts: {},
    rates: {},
    notifications: 0,
    event: [],
    searchList: [],
    isGuest: localStorage.getItem(`${LOCAL_STORAGE_VAR}-guest`),
    showGuest: false,
    seller: {},
  };

  componentDidUpdate(prevProps) {
    if (this.props.deleteReminder.count !== prevProps.deleteReminder.count) {
      this.getSidebarCounts();
      this.getNotifications();
    }
  }

  componentDidMount() {
    this.getSidebarCounts();
    this.getEvents();
    this.getNotifications();
    this.props.covidStrip(this.state.covid);
    getSearchList((list) => this.setState({ searchList: list }));
  }

  closeStrip = () => {
    this.props.covidStrip(!this.props.common.strip);
    this.setState({ covid: false });
  };

  searchOption = () => {
    if (!this.state.isGuest) {
      this.setState({ search: !this.state.search });
    } else {
      this.setState({ showGuest: true });
    }
  };

  showGuest = () => {
    this.setState({ showGuest: true });
  };

  handleHover = () => {
    this.setState({
      isHovered: !this.state.isHovered,
      isHovered1: false,
      isHovered2: false,
    });
  };

  handleHover1 = () => {
    this.setState({
      isHovered1: !this.state.isHovered1,
      isHovered: false,
      isHovered2: false,
    });
  };

  handleHover2 = () => {
    this.setState({
      isHovered2: !this.state.isHovered2,
      isHovered: false,
      isHovered1: false,
    });
  };

  handleOut = () => {
    this.setState({ isHovered: false });
  };

  handleOut1 = () => {
    this.setState({ isHovered1: false });
  };

  handleOut2 = () => {
    this.setState({ isHovered2: false });
  };

  HeaderStripCick = () => {
    this.props.covidStrip(!this.props.common.strip);
    this.setState({ HeaderStripList: !this.state.HeaderStripList });
  };

  getSidebarCounts = () => {
    const request = {
      savedSearch: true,
      recentSearch: true,
      track: true,
      orderData: true,
      dashboardCount: true,
      shippingCount: true,
      seller: true,
      account: true,
    };

    const objData = { ...GET_DASHBOARD_DATA, request };

    UtilService.callApi(objData, async (err, data) => {
      if (err) throw err;

      if (data && data.code === 'OK') {
        this.setState({
          details: data.data.tracks,
          rates: data.data.rates,
          counts: omit(data.data, 'tracks'),
          seller: data.data.seller,
        });
      }
    });
  };

  getEvents = () => {
    const obj = { ...GET_EVENTS, request: { page: 1, limit: 10, type: 6 } };

    UtilService.callApi(obj, (err, data) => {
      if (data && data.code === 'OK') {
        if (data.data) {
          const eventData = data.data.list[0];
          this.setState({ event: eventData });
        }
      }
    });
  };

  getNotifications = () => {
    const obj = { ...GET_NOTIFICATIONS };

    UtilService.callApi(obj, (err, data) => {
      if (data?.data && data.code === 'OK') {
        const total = data?.data?.unReadCount;
        this.setState({ notifications: total });
      }
    });
  };

  MarkAsRead = () => {
    const obj = { ...MARK_AS_READ };

    UtilService.callApi(obj, (err, data) => {
      if (data.data && data.code === 'OK') {
        this.setState({ notifications: 0 });
      }
    });
  };

  get HeaderLinks() {
    return [
      {
        link: '/account/cart',
        title: <IntlMessages id="app.my-cart" />,
        details: this.state.details[trackTypeObj.CART],
      },
      {
        link: '/account/watchlist',
        title: <IntlMessages id="app.my-watchlist" />,
        details: this.state.details[trackTypeObj.WATCHLIST],
      },
      {
        link: '/account/appointment-list',
        title: <IntlMessages id="app.office-list" />,
        details: this.state.details[trackTypeObj.REQUEST],
      },
      {
        link: '/account/confirm-list',
        title: <IntlMessages id="app.confirm-list" />,
        details:
          this.state.counts.orderData && this.state.counts.orderData.order ? this.state.counts.orderData.order : {},
      },
      {
        link: '/account/notes',
        title: <IntlMessages id="app.my-comment" />,
        details: this.state.details[trackTypeObj.COMMENT],
      },
      {
        link: '/account/memo-list',
        title: 'My Memo',
        details: this.state.diamondBlock ? this.state.diamondBlock?.[0] : {},
      },

      { link: '/my-bid', title: <IntlMessages id="app.myBid" />, basicDetailnull: true },
      { link: '/account/saved-search', title: <IntlMessages id="app.saved-search" />, basicDetailnull: true },
      { link: '/account/my-demand', title: <IntlMessages id="app.my-demand-tab" />, basicDetailnull: true },
      { link: '/account/profile', title: <IntlMessages id="app.MyProfile" />, basicDetailnull: true },
      {
        link: '',
        title: <IntlMessages id="app.SignOut" />,
        onClick: () => AuthService.signOut(),
        basicDetailnull: true,
      },
    ];
  }

  render() {
    const { seller } = this.state;
    const btnClass = this.state.isHovered ? 'active' : '';
    const btnClass2 = this.state.isHovered2 ? 'active' : '';
    const { name, profileImage, firstName, account } = Storage.get(`user`) ?? {};
    const P = Storage.get(`userPermissions`);

    const HeaderLinks = this.HeaderLinks;

    return (
      <div className="HeaderSticky">
        <div className={`headerTopMenu ${this.state.covid && 'active'}`}>
          <div className="headerWrapper m-header">
            <div className="headerLeftSide d-flex">
              <Stickybar />
            </div>
            <div className="headerRightSide">
              <div className="d-flex align-middle lang-color" align="middle">
                <LangDropdown />
                {Boolean(account?.crdLmt) && (
                  <div className="headerRightOption">
                    <div className="headerIcon">
                      <Tooltip
                        overlayStyle={{ zIndex: '10002', position: 'fixed' }}
                        color={'#414143'}
                        placement="bottom"
                        title={<div className="menuItem">Credit Limit: ${account?.crdLmt}</div>}
                      >
                        <img
                          className="defulticon"
                          src={dollarCircleWhiteSvg}
                          alt="icon"
                          style={{ height: '18px', width: '18px' }}
                        />
                      </Tooltip>
                    </div>
                  </div>
                )}
                <div className="headerRightOption">
                  <div className="headerIcon">
                    <Tooltip
                      getPopupContainer={(target) => target.parentNode}
                      overlayStyle={{ zIndex: '10002' }}
                      placement="bottom"
                      color="#414143"
                      title={
                        name && (
                          <>
                            <div className="salesPersonInnerItem">
                              {name && <div className="salespersonInnerDetail">Hello, {name.toLocaleUpperCase()}</div>}
                            </div>
                            <div className="salesPersonInnerItem">
                              {this.state.seller && (this.state.seller.firstName || this.state.seller.lastName) && (
                                <div className="salespersonInnerDetail">Your customer representative is,&nbsp;</div>
                              )}
                            </div>
                            <div className="salesPersonInnerItem">
                              {this.state.seller && (this.state.seller.firstName || this.state.seller.lastName) && (
                                <div className="salespersonInnerDetail" style={{ fontWeight: 700 }}>
                                  {isString(this.state.seller.firstName) && titleCase(this.state.seller.firstName)}
                                  &nbsp;
                                  {isString(this.state.seller.lastName) && titleCase(this.state.seller.lastName)}
                                  {!isEmpty(seller.mobile) && <span>&nbsp;|&nbsp;</span>}
                                  <a style={{ color: 'white' }} href={`tel:  ${this.state.seller.mobile}`}>
                                    {`+${seller.countryCode} ${seller.mobile}`}
                                  </a>
                                  <br />
                                  {!isEmpty(seller.email) && (
                                    <a style={{ color: 'white' }} href={`mailto:${seller.email}`}>
                                      Email&nbsp;:&nbsp;{seller.email}
                                    </a>
                                  )}
                                  <br />
                                  {!isEmpty(seller.skype) && (
                                    <a style={{ color: 'white' }} href={`skype:${seller.skype}`}>
                                      Skype&nbsp;:&nbsp;{seller.skype}
                                    </a>
                                  )}
                                </div>
                              )}
                            </div>
                          </>
                        )
                      }
                    >
                      <img
                        className="defulticon"
                        src={supportSvg}
                        alt="icon"
                        style={{ height: '23px', width: '23px' }}
                      />
                    </Tooltip>
                  </div>
                </div>
                <div className="headerRightBlock">
                  <div className="headerRightOption">
                    <Link to="/account/profile" className="headerIcon">
                      <img className="defulticon" src={userWhiteSvg} alt="icon" />
                      <img className="hoverIcon" src={userWhiteSvg} alt="icon" />
                      <span className="headerUserName">
                        <IntlMessages id="app.Welcome" />, {firstName ? firstName : '-'}
                      </span>
                    </Link>
                    <HeaderRightSidebar activeClass={btnClass}>
                      <Link className="dropdownUserIcon" to="/account/profile">
                        <div className="userProfileLogo">
                          {profileImage ? (
                            <img src={`${BASE_URL_ATTACH}/${profileImage}`} alt="user" />
                          ) : (
                            <DefaultUser />
                          )}
                        </div>
                        <div className="userProfileContent">
                          <h2>{name ? name : '-'}</h2>
                          <p>
                            <IntlMessages id="app.SeeYourProfile" />
                          </p>
                        </div>
                      </Link>
                      <div className="userDetailList">
                        <ul>
                          {HeaderLinks.map((x, i) => {
                            return (
                              <HeaderAccountList
                                key={i}
                                isGuest={this.state.isGuest}
                                showGuest={this.showGuest}
                                {...this.props}
                                {...x}
                              />
                            );
                          })}
                        </ul>
                      </div>
                    </HeaderRightSidebar>
                  </div>

                  <div className="headerRightOption">
                    <div className="headerIcon" onClick={this.searchOption}>
                      <img className="defulticon" src={searchWhiteSvg} alt="icon" />
                      <img className="hoverIcon" src={searchWhiteSvg} alt="icon" />
                    </div>
                  </div>
                  <HeaderSearch
                    className={this.state.search && 'active'}
                    onClick={this.searchOption}
                    searchList={this.state.searchList}
                  />
                  {P?.NOTIFICATIONS?.view && (
                    <div className="headerRightOption">
                      <div className="headerIcon" onClick={this.handleHover2}>
                        <img className="defulticon notification-bell" src={notificationWhiteSvg} alt="icon" />
                        <img className="hoverIcon notification-bell" src={notificationWhiteSvg} alt="icon" />
                        <NotificationCount count={this.state.notifications} />
                      </div>
                      <HeaderRightSidebar activeClass={btnClass2} handleOut={this.handleOut2}>
                        <NotificationDropdown active={this.state.isHovered2} />
                      </HeaderRightSidebar>
                    </div>
                  )}
                  <div className="headerRightOption">
                    <div className="headerIcon">
                      <img
                        onClick={() => AuthService.signOut()}
                        className="defulticon"
                        src={logoutWhiteSvg}
                        alt="icon"
                      />

                      <img className="hoverIcon" src={notificationWhiteSvg} alt="icon" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  connect(
    (state) => ({
      deleteReminder: state.deleteReminder,
      common: state.common,
    }),
    {
      setLoader,
      covidStrip,
    },
  ),
  withRouter,
)(Header);
