import React, { useState, useEffect } from 'react';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import { handleInsertTrack, getColumn, isMobile } from '../../DiamondList/DiamondListFunctions';
import Table from '../../DiamondList/TableBack';
import IntlMessages from '../../../util/IntlMessages';
import { trackTypeObj } from '../../../constants/Common';
import OpenNotification from '../CommonButton/OpenNotification';
import { HeadingCalc } from './ConfirmStonePopup';
import { useSelector } from 'react-redux';
import WatchPopupMobile from './WatchPopupMobile';

/* eslint jsx-a11y/anchor-is-valid: 0 */

const currentType = 'WatchlistPopup';

const WatchlistPopup = (props) => {
  const [columns, setColumns] = useState([]);
  const [exp, setExp] = useState(true);
  const [data, setData] = useState(props.checked.map((x) => ({ ...x, expBack: x.back - 1 })));
  const checked = useSelector((state) => state.diamond.selectedRows[currentType]) || [];

  const getBackSelect = (row) => {
    row.back = row.back ? row.back : 0;
    return (
      <select
        dropdownClassName="popupZindex"
        value={parseFloat(row.expBack)}
        onChange={(e) => {
          let val = e.target.value;
          let line = [...data];
          let ind = findIndex(line, { id: row.id });
          line[ind].expBack = parseFloat(val);
          setData(line);
          setExp(!exp);
        }}
        style={{ width: 120 }}
      >
        <option value={row.back - 1}>{(row.back - 1).toFixed(2)}</option>
        <option value={row.back - 2}>{(row.back - 2).toFixed(2)}</option>
        <option value={row.back - 3}>{(row.back - 3).toFixed(2)}</option>
      </select>
    );
  };

  useEffect(() => {
    let Columns = getColumn();
    let index = findIndex(Columns, { id: 'back' });
    // if (!index) {
    //   index = 1;
    // }
    let columns = [
      ...Columns.slice(0, index !== -1 ? index : 23),
      {
        Header: 'Exp Disc%',
        accessor: 'expBack',
        id: 'expBack',
        Cell: ({ row }) => getBackSelect(row.original),
      },
    ];
    columns = [...columns, ...Columns.filter((el) => !find(columns, { id: el.id }))];
    setColumns(columns);
  }, [exp]);

  const btnAction = () => {
    return (
      <div className="sideBarPopupButton">
        <a
          onClick={() => {
            if (!checked.length) {
              OpenNotification({
                type: 'error',
                title: 'Please select stone(s) to add in watch.',
              });
              return;
            }
            handleInsertTrack(
              trackTypeObj.WATCHLIST,
              checked.map((c) => ({
                ...c,
                expBack: find(data, { id: c.id }).expBack,
              })),
              (status) => {
                props.onClose();
                props.clearAll();
                if (status) props.setCount(!props.deleteReminder.count);
              },
            );
          }}
          className="commonButton"
        >
          <IntlMessages id="app.Add" />
        </a>
        <a className="commonButton" onClick={props.onClose}>
          <IntlMessages id="app.Cancel" />
        </a>
      </div>
    );
  };

  return isMobile() ? (
    <WatchPopupMobile data={data} currentType={currentType} parent={{ btnAction, getBackSelect }} />
  ) : (
    <div>
      <div className="d-flex align-items-center offerTopBlock offerWrapper mb-20">
        <h2 className="popupInnerTitle mr-40">
          <IntlMessages id="app.WatchList" />
        </h2>
        {HeadingCalc(checked)}
      </div>
      <div className="searchPopupCommonTable">
        <div className="searchResultTable tabInnerTableScroll watchpopup">
          <Table data={data} columns={columns} areAllChecked={true} noGrp canSort={false} currentType={currentType} />
        </div>
        {/* <div className="d-flex justify-content-between offerBottomBox flex-wrap mt-20">
          <p className="offerNote">
            <b>
              <IntlMessages id="app.Note" />:
            </b>{" "}
            <IntlMessages id="app.offerNote" />
          </p>
        </div> */}
        {btnAction()}
      </div>
    </div>
  );
};

export default WatchlistPopup;
