import React from 'react';

import debounce from 'lodash/debounce';

import { catchError } from 'util/utils';

export function useIsIntersecting(ref, initialValue = () => false) {
  const [isIntersecting, setIntersecting] = React.useState(initialValue);

  const observer = React.useMemo(() => {
    const debouncedSetIntersecting = debounce(setIntersecting);
    return new IntersectionObserver(([entry]) =>
      entry.isIntersecting ? setIntersecting(entry.isIntersecting) : debouncedSetIntersecting(entry.isIntersecting),
    );
  }, []);

  React.useLayoutEffect(() => {
    catchError(() => observer.observe(ref.current));
    return () => observer.disconnect();
  }, [observer, ref]);

  return isIntersecting;
}
