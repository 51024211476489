import React from 'react';

import { IntlMessage } from 'util/IntlMessages';

export const DETAIL_VIEW = {
  section1: {
    name: <IntlMessage id="app.basicDetail" />,
    data: [
      { part: 1, label: <IntlMessage id="app.StockNo" />, key: 'vStnId', alt: '-' },
      { part: 1, label: <IntlMessage id="app.ReportNo" />, key: 'rptNo', alt: '-' },
      { part: 1, label: <IntlMessage id="app.Shape" />, key: 'shpNm', alt: '-' },
      { part: 1, label: <IntlMessage id="app.cut" />, key: 'cutNm', alt: '-' },
      { part: 1, label: <IntlMessage id="app.carat" />, key: 'crt', alt: '-' },
      { part: 1, label: <IntlMessage id="app.polish" />, key: 'polNm', alt: '-' },
      { part: 1, label: <IntlMessage id="app.Color" />, key: 'colNm', alt: '-' },
      { part: 1, label: <IntlMessage id="app.symm" />, key: 'symNm', alt: '-' },
      { part: 1, label: <IntlMessage id="app.Clarity" />, key: 'clrNm', alt: '-' },
      { part: 1, label: <IntlMessage id="app.flor" />, key: 'fluNm', alt: '-' },
      { part: 1, label: <IntlMessage id="app.Lab" />, key: 'lbNm', alt: '-' },
      { part: 1, label: <IntlMessage id="app.Country" />, key: 'countryNm', alt: '-' },
      { part: 1, label: 'Mounted', key: 'mount', alt: '-' },
      // { part: 1, label: <IntlMessage id="app.CountryOfOrigin" />, key: 'org', alt: '-' },
      // { part: 2, label: <IntlMessage id="app.Shade" />, key: 'shdNm' },
    ],
  },
  section2: {
    name: <IntlMessage id="app.pricing-details" />,
    data: [
      { label: <span>Rap.($)</span>, key: 'rap', alt: '-' },
      { label: <IntlMessage id="app.Discount" />, key: 'back', alt: '-' },
      { label: <IntlMessage id="app.Pr/Cr" />, key: 'ctPr', alt: '-' },
      { label: <IntlMessage id="app.Amount" />, key: 'amt', alt: '-' },
    ],
  },
  section3: {
    name: <IntlMessage id="app.Measurements" />,
    data: [
      { label: <IntlMessage id="app.length" />, key: 'length', alt: '-' },
      { label: <IntlMessage id="app.ratio" />, key: 'ratio', alt: '-' },
      { label: <IntlMessage id="app.width" />, key: 'width', alt: '-' },
      { label: <IntlMessage id="app.pavAn" />, key: 'pAng', alt: '-' },
      { label: <IntlMessage id="app.depth" />, key: 'height', alt: '-' },
      { label: <IntlMessage id="app.pavHt" />, key: 'pHgt', alt: '-' },
      { label: <IntlMessage id="app.compare.tableper" />, key: 'tblPer', alt: '-' },
      { label: <IntlMessage id="app.crAn" />, key: 'cAng', alt: '-' },
      { label: <IntlMessage id="app.total-depth" />, key: 'depPer', alt: '-' },
      { label: <IntlMessage id="app.crHt" />, key: 'cHgt', alt: '-' },
      { label: <IntlMessage id="app.Girdle" />, key: 'girdleStr', alt: '-' },
      { label: <IntlMessage id="app.culet" />, key: 'cultNm', alt: '-' },
    ],
  },
  section4: {
    name: <IntlMessage id="app.bgm-details" />,
    data: [
      // { label: <IntlMessage id="app.brown-tinge" />, key: 'shdNm', alt: '-' },
      // { label: <IntlMessage id="app.mix-tinge" />, key: 'shdNm', alt: '-' },
      { label: <IntlMessage id="app.milky" />, key: 'mlkNm', alt: '-' },
      // { label: <IntlMessage id="app.other-tinge" />, key: 'shdNm', alt: '-' },
      { label: <IntlMessage id="app.Shade" />, key: 'shdNm', alt: '-' },
    ],
  },
  section5: {
    name: <IntlMessage id="app.OtherInformation" />,
    data: [
      { label: <IntlMessage id="app.diamonddetails.Additionalinfo.laserinscription" />, key: 'lsrInc', alt: '-' },
      // { label: <IntlMessage id="app.diamonddetails.Inclusion.extrafacet" />, key: 'eFact', alt: '-' },
      { label: <IntlMessage id="app.keyToS" />, key: 'kToSStr', alt: '-' },
      { label: <IntlMessage id="app.heart-arrow" />, key: 'hANm', alt: '-' },
      { label: <IntlMessage id="app.diamonddetails.Additionalinfo.Comment" />, key: 'lbCmt' },
      // { label: <IntlMessage id="app.keyToS" />, key: 'kToSStr' },
    ],
  },
  section6: {
    name: <IntlMessage id="app.diamonddetails.Inclusion" />,
    data: [
      // { label: <IntlMessage id="app.CenterNatts" />, key: 'blkTblNm', alt: '-' },
      { label: <IntlMessage id="app.diamonddetails.Inclusion.blacktable" />, key: 'blkTblNm', alt: '-' },
      { label: <IntlMessage id="app.TableOpen" />, key: 'opTblNm', alt: '-' },
      { label: <IntlMessage id="app.diamonddetails.Inclusion.blackcrown" />, key: 'blkSdNm', alt: '-' },
      { label: <IntlMessage id="app.PavilionOpen" />, key: 'opPavNm', alt: '-' },
      { label: <IntlMessage id="app.diamonddetails.Inclusion.whitetable" />, key: 'wTblNm', alt: '-' },
      { label: <IntlMessage id="app.CrownOpen" />, key: 'opCrwnNm', alt: '-' },
      { label: <IntlMessage id="app.diamonddetails.Inclusion.whitecrown" />, key: 'wSdNm', alt: '-' },
      { label: <IntlMessage id="app.eye-clean" />, key: 'eClnNm', alt: '-' },
      // { label: <IntlMessage id="app.SideNatts" />, key: 'blkSdNm', alt: '-' },
      // { label: <IntlMessage id="app.CenterWhite" />, key: 'wTblNm', alt: '-' },
      // { label: <IntlMessage id="app.SideWhite" />, key: 'wSdNm', alt: '-' },
      // { label: <span>Girdle Open</span>, key: 'opGrdNm', alt: '-' },
      // { label: <IntlMessage id="app.ReportComments" />, key: 'lbCmt', alt: '-' },
      // { label: <IntlMessage id="app.Brilliancy" />, key: 'brlncyNm' },
      // { label: <IntlMessage id="app.T2CERT" />, key: 'type2' },
      // { label: <IntlMessage id="app.diamonddetails.Inclusion.luster" />, key: 'lstr' },
    ],
  },
};
export default DETAIL_VIEW;
