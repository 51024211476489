import React from 'react';
import { useSelector } from 'react-redux';

import { Drawer } from 'antd';

import { STATUS_COLOR } from 'components/common/DiamondListing/Status';
import Heading from 'components/common/Heading';

import { RowSelectService } from 'services/RowSelectService';

import { useBoolean } from 'util/hooks';
import { notify } from 'util/notify';
import { classNames, formatDecimal } from 'util/utils';

import { PAGES } from 'constants/paths.const';

import { getParams } from './DiamondListFunctions';

const StoneCard = React.memo((props) => {
  const { stone, currentType } = props;

  const isSelected = useSelector((state) =>
    Boolean(state.diamond.status?.[currentType]?.[stone.selectionKey ?? stone.id]),
  );

  const toggleSelection = React.useCallback(
    (e) => {
      e.stopPropagation();
      if (stone?.disableSelection) {
        if (stone?.onCheckError) notify.error({ message: stone?.onCheckError });
        return;
      }
      isSelected
        ? RowSelectService.unSelectRows(currentType, [stone])
        : RowSelectService.selectRows(currentType, [stone]);
    },
    [currentType, isSelected, stone],
  );

  return (
    <div
      className={classNames(['diamodListItemWrapper', isSelected && 'active', STATUS_COLOR[stone.wSts] ?? 'green'])}
      onClick={toggleSelection}
    >
      <div className="mobileListHeade" onClick={toggleSelection}>
        <div className="mobileListHeadeBlock">
          <a className="mobileListItem" href={'/' + PAGES.DETAIL + '/' + stone.id}>
            <span>{stone.vStnId}</span>
          </a>
          <div className="mobileListItem">
            <span>{stone.shpNm}</span>
          </div>
          <div className="mobileListItem">
            <span>{formatDecimal(stone.back)}%</span>
          </div>
          <div className="mobileListItem themeColor">
            <span>{formatDecimal(stone.ctPr)} $/Cts</span>
          </div>
        </div>
      </div>
      <div className="mobileDiamondListBottom">
        <div className="mobileListHeadeBlock">
          <div className="mobileListItem basicDetail">
            <span>
              {getParams(stone).join(' ')} {stone.lbNm}
            </span>
          </div>
          <div className="mobileListItem">
            <span>{formatDecimal(stone.crt)}</span>
          </div>
          <div className="mobileListItem">
            <span>{stone.msrmnt}</span>
          </div>
          <div className="mobileListItem totalPrice">
            <span>{formatDecimal(stone.amt)} $/Amt</span>
          </div>
        </div>
      </div>
    </div>
  );
});
StoneCard.displayName = 'StoneCard';

const OrderCard = React.memo((props) => {
  const { order, currentType } = props;

  const [showDetails, setShowDetails] = useBoolean();

  return (
    <div className={classNames(['diamodListItemWrapper', 'noStatus'])} onClick={setShowDetails.toggle}>
      <div className="mobileListHeade">
        <div className="mobileListHeadeBlock">
          <div className="mobileListItem">
            <span>{order.id}</span>
          </div>
          <div className="mobileListItem">
            <span>{order.invoiceDate}</span>
          </div>
          <div className="mobileListItem">
            <span>{order.memoNo}</span>
          </div>
          <div className="mobileListItem">
            <span>Pcs : {order.piece}</span>
          </div>
        </div>
      </div>
      <div className="mobileDiamondListBottom">
        <div className="mobileListHeadeBlock invoice-history">
          <div className="mobileListItem crt-value">
            <span>{formatDecimal(order.crt)}</span>
          </div>
          <div className="mobileListItem themeColor ctpr-value">
            <span>{formatDecimal(order.ctpr)} $/Cts</span>
          </div>
          <div className="mobileListItem totalPrice amt-value">
            <span>{formatDecimal(order.amount)} $/Amt</span>
          </div>
        </div>
      </div>
      {showDetails && (
        <div style={{ padding: '0.25rem', marginBottom: '-0.5rem' }}>
          {order.subRows.map((stone) => (
            <StoneCard key={stone.vStnId} stone={stone} currentType={currentType} />
          ))}
        </div>
      )}
    </div>
  );
});
OrderCard.displayName = 'OrderCard';

export default function MyPurchaseMobile(props) {
  const { parent, data, currentType } = props;

  return (
    <div className="searchResultListWrapper">
      <div className="selectStonHeader">
        <div className="listingTopBlock width-100">
          <div className="d-flex justify-content-between align-items-center width-100">
            <Heading title={parent.getTitle()} />
            {parent.getListTop?.() ?? null}
          </div>
          {parent.getSelection?.() ?? null}
        </div>
      </div>
      <div className="searchInnerResult">
        <div className="diamondListMobile">
          {data.map((order, index) => (
            <OrderCard key={order?.id ?? index} order={order} currentType={currentType} />
          ))}
        </div>
        {parent.getAction()}
      </div>
    </div>
  );
}
