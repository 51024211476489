import React from 'react';

import { useIsMobile } from 'util/hooks';
import { classNames } from 'util/utils';

function stopDOMEvent(e) {
  void (e?.stopPropogation?.(), e?.preventDefault?.());
}

export const ListAction = (props) => {
  const { icon, onClick, children, className, ...rest } = props;

  const [isMobile] = useIsMobile();

  const handleClick = React.useCallback((e) => void (stopDOMEvent(e), onClick?.()), [onClick]);

  return isMobile ? (
    <div className="mobileSearchActionItem">
      <a href="#!" onClick={handleClick}>
        {icon && <img alt="" src={icon} />}
        {children}
      </a>
    </div>
  ) : (
    <a href="#!" className={classNames(['bottomStickyButton', className])} onClick={handleClick} {...rest}>
      {children}
    </a>
  );
};
