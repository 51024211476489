import React from 'react';

function Loader({ loading = true }) {
  if (!loading) return null;

  return (
    <div id="loader">
      <div id="loader-inner">{/* <img src={require('assets/img/loader.png')} alt="Sl  Diamonds" /> */}</div>
    </div>
  );
}

export default React.memo(Loader);
