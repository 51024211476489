import React from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { Redirect, Router, Route, Switch } from 'react-router-dom';

import { history } from 'util/history';
import { loadable } from 'util/loadable';
import { isEmpty } from 'util/utils';

import AppLocale from 'lngProvider';

import Routes from './Routes';

const ForgotPassword = loadable(() => import('components/Auth/ForgotPassword'));
const ResetPassword = loadable(() => import('components/Auth/ResetPassword'));
const DiamondDetail = loadable(() => import('components/DiamondDetail'));
const EmailVerify = loadable(() => import('components/EmailVerify'));
const GuestLogin = loadable(() => import('components/GuestLogin'));
const MainDNA = loadable(() => import('components/MainDNA'));
const PageNotFound = loadable(() => import('components/PageNotFound'));
const SignIn = loadable(() => import('components/SignIn'));
const SignUp = loadable(() => import('components/SignUp'));
const PrivicyPolicy = loadable(() => import('components/PrivicyPolicy'));
const ThankYouAfterSignup = loadable(() => import('components/ThankYou'));
const VerifyOtp = loadable(() => import('components/VerifyOtp'));
const ContactUs = loadable(() => import('components/common/ContactUs'));

const SignOutRoute = React.memo(({ component: Component, ...rest }) => {
  const token = useSelector((state) => state.auth?.token);
  return (
    <Route {...rest} render={(props) => (isEmpty(token) ? <Component {...props} /> : <Redirect to={'/dashboard'} />)} />
  );
});
SignOutRoute.displayName = 'SignOutRoute';

function App() {
  const currentLocale = useSelector((state) => AppLocale[state.common?.locale?.locale ?? 'en']);

  return (
    <IntlProvider locale={currentLocale.locale} messages={currentLocale.messages}>
      <Router history={history}>
        <Switch>
          <SignOutRoute path="/" component={SignIn} exact />
          <SignOutRoute path="/signin" component={SignIn} />
          <SignOutRoute path="/signup" component={SignUp} />
          <SignOutRoute path="/device/signup" component={SignUp} />
          <SignOutRoute path="/guest-login" component={GuestLogin} />
          <SignOutRoute path="/forgot-password" component={ForgotPassword} />
          <SignOutRoute path="/reset-password/:token" component={ResetPassword} />
          <Route exact path="/verify-otp" component={VerifyOtp} />

          <Route path="/thank-you" component={ThankYouAfterSignup} />

          <Route path="/verify-email" component={EmailVerify} />
          {/* <Route path="/verify-device" component={VerifyDevice} /> */}
          <Route path="/contact-us" component={ContactUs} />
          {/* <Route exact path="/news" component={News} /> */}
          {/* <Route exact path="/bidding-procedure" component={BiddingProcedure} /> */}
          {/* <Route exact path="/book-appointment" component={BookAppointment} /> */}
          <Route path="/privacy-policy" component={PrivicyPolicy} exact />

          <Route exact path="/privacy-policy" component={PrivicyPolicy} />
          <Route path="/device/contact-us" component={ContactUs} />
          {/* <Route path="/xrays" component={XRAY} /> */}
          {/* <Route path="/DiamFTC" component={FTC} /> */}
          <Route exact path="/diamond-detail/:id" component={DiamondDetail} />
          <Route exact path="/dna/:id" component={MainDNA} />
          {/* <Route path="/career" component={Career} /> */}
          {/* <Route path="/career-detail" component={CareerDetail} /> */}

          <Route path="/not-found" component={PageNotFound} />

          <Routes />
        </Switch>
      </Router>
    </IntlProvider>
  );
}

export default App;
