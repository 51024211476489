import { CellNumber } from 'entities/CellNumber';
import { User } from 'entities/User';

import { Action } from 'services/Action';
import { LoaderService } from 'services/LoaderService';
import { MasterService } from 'services/MasterService';
import { Storage } from 'services/Storage';

import * as Utils from 'util/utils';

const entities = [CellNumber, User];
const services = [Action, Storage, LoaderService, MasterService];
window.__DN = {};
services.forEach((service) => service.initialize());

const initialize = () => {
  if (!(Utils.isDevEnv() || window.__DN?.DEBUG)) return;
  window.__DN.Utils = Utils;

  window.__DN.entities = {};
  entities.forEach((entity) => (window.__DN.entities[entity.identifier ?? entity.name] = entity));

  window.__DN.services = {};
  services.forEach((service) => (window.__DN.services[service.identifier ?? service.name] = service));
};

window.__DN._INIT = () => initialize();

window.__DN._INIT();
