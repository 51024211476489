import find from 'lodash/find';

import {
  SET_LOADER,
  CURRENT_PAGE_DATA,
  SWITCH_LANGUAGE,
  PAGE_PERMISSION,
  RESET_SEARCH,
  MODIFY_SEARCH,
} from '../../constants/ActionTypes';
import { LOCAL_STORAGE_VAR } from '../../constants/Common';
import languageData from '../../constants/languageData';

export const currentPageData = (data) => {
  return (dispatch) => {
    dispatch({ type: CURRENT_PAGE_DATA, payload: data });
  };
};

export function switchLanguage(language) {
  const locale = find(languageData, { languageId: language });
  localStorage.setItem(`${LOCAL_STORAGE_VAR}-localisation`, JSON.stringify(locale));
  return {
    type: SWITCH_LANGUAGE,
    payload: locale,
  };
}

export function setLoader(loading) {
  return {
    type: SET_LOADER,
    loading,
  };
}
export const PagePermission = (props) => {
  return (dispatch) => {
    dispatch({ type: PAGE_PERMISSION, payload: props });
  };
};

export const resetDiamondSearch = (data) => {
  return (dispatch) => {
    dispatch({ type: RESET_SEARCH, payload: data });
  };
};

export const modifyDiamondSearch = (data) => {
  return (dispatch) => {
    dispatch({ type: MODIFY_SEARCH, payload: data });
  };
};

export const covidStrip = (props) => {
  return (dispatch) => {
    dispatch({ type: 'STRIP_SHOW', payload: props });
  };
};
