import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import { Drawer, Select, Tooltip } from 'antd';
import moment from 'moment';

import Table from 'components/DiamondList/TableBack';
import GridHeightContainer from 'components/GridHeightContainer/GridHeightContainer';
import BidTermsConditions from 'components/NewArrivalBidIt/BidTermsConditions';
import NewArrivalBidMobile from 'components/NewArrivalBidIt/NewArrivalBidMobile';
import Countdown from 'components/Offer/Timer';
import BidPopup from 'components/common/DiamondListing/BidPopup';
import DiamondListingAction from 'components/common/DiamondListing/DiamondListingAction';
import SelectStone from 'components/common/DiamondListing/SelectStone';
import Heading from 'components/common/Heading';
import NoDataShow from 'components/common/NoDataShow';

import { RowSelectService } from 'services/RowSelectService';
import { StoneService } from 'services/StoneService';
import { Storage } from 'services/Storage';
import { getCurrentTime, getBidConfig } from 'services/util';

import { IntlMessage } from 'util/IntlMessages';
import { history } from 'util/history';
import { useBoolean, useIsMobile, usePathname, useQueryParams, useSelectedRows } from 'util/hooks';
import { isEmpty, isObject, isString, titleCase } from 'util/utils';

import { BID_LIST_TYPE_TITLE, DIAMOND_BID, DIAMOND_WEB_STATUS, LOCAL_STORAGE_VAR } from 'constants/Common';
import { PAGES, PAGE_TITLE, PAGE_TITLE_DESC } from 'constants/paths.const';

import { getState, isArrayEqual } from '../DiamondList';
import { getColumn, fetchDiamondPaginate, prepareStoneTrackResponse } from '../DiamondList/DiamondListFunctions';

const getBidStartTime = (bidType) =>
  bidType === DIAMOND_BID.TYPE.OPEN
    ? moment().startOf('day').add(11, 'hours')
    : moment().startOf('day').add(15, 'hours');

const getBidEndTime = (bidType) =>
  bidType === DIAMOND_BID.TYPE.OPEN
    ? moment().startOf('day').add(14, 'hours').add(59, 'minutes')
    : moment().startOf('day').add(10, 'hours').add(59, 'minutes');

export const getBidEndCounter = (bidType) =>
  bidType === DIAMOND_BID.TYPE.OPEN
    ? moment().startOf('day').add(14, 'hours').add(54, 'minutes')
    : moment() < getBidStartTime(DIAMOND_BID.TYPE.OPEN)
    ? moment().startOf('day').add(10, 'hours').add(54, 'minutes')
    : moment().startOf('day').add(1, 'days').add(10, 'hours').add(54, 'minutes');

export const getBidType = () =>
  moment() >= getBidStartTime(DIAMOND_BID.TYPE.OPEN) && moment() <= getBidEndTime(DIAMOND_BID.TYPE.OPEN)
    ? DIAMOND_BID.TYPE.OPEN
    : moment() >= getBidStartTime(DIAMOND_BID.TYPE.BLIND) || moment() <= getBidEndTime(DIAMOND_BID.TYPE.BLIND)
    ? DIAMOND_BID.TYPE.BLIND
    : 0;

const NewArrivalBidIt = (props) => {
  const match = useRouteMatch();
  const pathname = usePathname();
  const currentType = pathname;
  const [isMobile] = useIsMobile();

  const [selectedRows] = useSelectedRows(currentType);

  const [state, setState] = React.useState(getState);

  const [listType, setListType] = React.useState('ALL');
  const [bidDrawer, setBidDrawer] = useBoolean();
  const [showTermDrawer, setShowTermDrawer] = useBoolean();

  const [terms, showTerm] = React.useState(false);
  const [currTime, setCurrTime] = React.useState('');
  const [bidConfig, setBidConfig] = React.useState({});

  const sortRef = React.useRef([]);
  const USER_PERMIT = Storage.get('userPermissions');

  const queryParams = useQueryParams();

  const clearSelection = React.useCallback(() => RowSelectService.resetSelectedRows(currentType), [currentType]);

  const setResponse = (res) => {
    const columns = getColumn();
    if (res) {
      const diamond = res.data || [];
      let defaultChecked = [];
      if (localStorage.getItem(`${LOCAL_STORAGE_VAR}-more`)) {
        defaultChecked = localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`)
          ? JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`))
          : [];
        if (defaultChecked && defaultChecked.length > 0) {
          diamond.map((d) => {
            if (defaultChecked.includes(d.id)) {
              d['isDefaultChecked'] = true;
            }
            return d;
          });
        }
        localStorage.removeItem(`${LOCAL_STORAGE_VAR}-more`);
      }
      setState((state) => ({ ...state, ...res, data: diamond, defaultChecked, columns, loading: false }));
    } else {
      setState((state) => ({ ...state, loading: false, defaultChecked: [] }));
    }
  };

  const prependStonesFromQuery = React.useCallback(async () => {
    if (!isString(queryParams.vStnId) || isEmpty(queryParams.vStnId)) return setBidDrawer.false();

    const vStnId = queryParams.vStnId?.split?.(',');

    const res = await new Promise((resolve) => {
      fetchDiamondPaginate({ page: 1, limit: vStnId?.length, filters: [{ vStnId }] }, DIAMOND_WEB_STATUS.BID, resolve);
    });

    RowSelectService.selectRows(currentType, res?.data ?? [], true);
    setTimeout(setBidDrawer.true, 100);
  }, [currentType, queryParams.vStnId, setBidDrawer]);

  const closeBidDrawer = React.useCallback(() => {
    setBidDrawer.false();
    history.replace(history.location.pathname);
    RowSelectService.resetSelectedRows(currentType);
  }, [currentType, setBidDrawer]);

  React.useEffect(() => {
    prependStonesFromQuery();
  }, [prependStonesFromQuery]);

  const fetch = React.useCallback(async () => {
    setState((state) => ({ ...state, loading: true, data: [] }));

    if (listType !== 'ALL') {
      const [err, res] = await StoneService.listStoneTrack(StoneService.TRACK_TYPE_FILTERS[listType], {
        page: state.page,
        limit: state.limit,
        sort: sortRef.current,
      });
      setResponse(prepareStoneTrackResponse(err, res));
      return;
    }

    fetchDiamondPaginate(
      {
        page: state.page,
        limit: state.limit,
        sort: sortRef.current,
        filters: [
          {
            wSts: bidConfig?.sub_types?.allowDiamondFilterWSts ?? ['B', 'BA'],
            diamondSearchId: match?.params?.id,
          },
        ],
      },
      DIAMOND_WEB_STATUS.BID,
      setResponse,
    );
  }, [listType, state.page, state.limit, bidConfig?.sub_types?.allowDiamondFilterWSts, match?.params?.id]);

  const setTimers = React.useCallback(async () => {
    const time = await getCurrentTime();
    const bidConfig = await getBidConfig()
      .then()
      .catch((err) => {
        if (err && err.data && err.data.code === 'E_NOT_FOUND') {
          setState((state) => ({ ...state, loading: false, data: [] }));
        }
      });
    const data = bidConfig && bidConfig.sub_types && bidConfig.sub_types.ruleFile;
    showTerm(Boolean(data));
    setCurrTime(time.iso);
    setBidConfig(bidConfig ?? {});
    RowSelectService.resetSelectedRows(currentType);
  }, [currentType]);

  const resetAndFetch = React.useCallback(() => {
    RowSelectService.resetSelectedRows(currentType);
    fetch();
  }, [currentType, fetch]);

  React.useEffect(() => {
    setTimers();
  }, [setTimers]);

  React.useEffect(() => {
    fetch();
  }, [fetch]);

  const bidEndTime = async () => {
    const nextBid = await getBidConfig()
      .then()
      .catch((err) => {
        if (err && err.data && err.data.code === 'E_NOT_FOUND') {
          setState((state) => ({ ...state, loading: false, data: [] }));
        }
      });

    setBidConfig(nextBid ?? []);
  };

  const handleSort = React.useCallback(
    (currentSort) => {
      if (currentSort.length !== sortRef.current.length || !isArrayEqual(currentSort, sortRef.current)) {
        sortRef.current = currentSort;
        fetch();
      }
    },
    [fetch],
  );

  const onPaginationChange = React.useCallback((page, limit) => setState((state) => ({ ...state, page, limit })), []);

  const getTitle = () => {
    return !isEmpty(bidConfig) && state.data && state.data.length
      ? `${PAGE_TITLE[PAGES.BID]} (${titleCase(bidConfig?.sub_types?.subTypeCode?.toLowerCase())}) (${state.count})`
      : `${PAGE_TITLE[PAGES.BID]} (0)`;
  };

  const getSelection = () => {
    return (
      <SelectStone
        sum={state.sum}
        profilListing={getBidType() ? 'listingSelected diamondListStone bid_it' : ''}
        currentType={currentType}
      />
    );
  };

  const getBidBlock = () => {
    return getBidType() && !isEmpty(bidConfig?.id) ? (
      <div className={'bidEndBlock' + (!isMobile ? ' d-flex align-items-center' : '')}>
        <span className="bidEndLabel">
          <IntlMessage id="app.BidEndIn" /> :
        </span>
        <Countdown
          currTime={currTime}
          date={moment(bidConfig?.sub_types?.currentBidEndDate)?.subtract?.(moment().utcOffset(), 'minutes')}
          fetch={bidConfig?.sub_types?.currentBidEndDate && fetch}
          bidEndTime={bidEndTime}
        />
      </div>
    ) : null;
  };

  const getAction = () => {
    return (
      <DiamondListingAction
        {...state}
        {...props}
        currentType={currentType}
        onPaginationChange={onPaginationChange}
        bidStone
        clearAll={clearSelection}
        fetch={fetch}
        dealOfTheDay={USER_PERMIT?.BID?.view}
        createBidCartStone
        myBidCartStone
        myBid
        nocart
        nowatch
        noCompare
        noreminder
        noquote
        noconfirm
        nonote
        nohold={!USER_PERMIT?.HOLD?.view || pathname === PAGES.BID}
        nofinalcalc
        noenquiry={!USER_PERMIT?.ENQUIRY?.view || pathname === PAGES.BID}
        noshare={
          !USER_PERMIT?.SHARE_VIA_MAIL?.view ||
          !USER_PERMIT?.SHARE_VIA_WHATSAPP?.view ||
          !USER_PERMIT?.SHARE_VIA_SKYPE?.view
        }
        nodownload={!USER_PERMIT?.DOWNLOAD?.view}
        noprint={!USER_PERMIT?.PRINT?.view}
        nooffice
        modify
        onModify={() => {
          const id = window.location.search.split('?')[1];
          props.history.push(
            id ? `/${PAGES.SEARCH_DIAMOND}/${id}` : { pathname: `/${PAGES.SEARCH_DIAMOND}`, state: { isActive: true } },
          );
        }}
        bidConfig={bidConfig}
        addBidStoneToWishList={['ALL'].includes(listType)}
        onAddBidStoneToWishListSuccess={resetAndFetch}
        addBidStoneViewRequest={['ALL'].includes(listType)}
        onAddBidStoneViewRequestSuccess={resetAndFetch}
      />
    );
  };

  const bidTermsDrawer = React.useMemo(() => {
    const bidTermsShown = Storage.get('bid-terms-shown');

    return (
      <Drawer
        visible={!isEmpty(bidConfig?.id) && !bidTermsShown && terms}
        onClose={() => {
          showTerm(false);
          Storage.set('bid-terms-shown', true);
        }}
        wrapClassName="stone-detail-sidebar sm-size"
        destroyOnClose
      >
        {terms && <BidTermsConditions nextBidData={bidConfig} />}
      </Drawer>
    );
  }, [bidConfig, terms]);

  const getDrawer = () => bidTermsDrawer;

  return isMobile ? (
    <NewArrivalBidMobile
      {...props}
      {...state}
      currentType={currentType}
      parent={{ getTitle, getSelection, getBidBlock, getAction, getDrawer }}
    />
  ) : (
    <div className="searchResultListWrapper">
      <div
        className="selectStonHeader d-flex justify-content-between align-items-center width-100 listingTopBlock pos_rel flex-wrap"
        style={{ height: '3.75rem' }}
      >
        <Heading style={{ minWidth: '390px' }}>
          <Tooltip title={PAGE_TITLE_DESC[PAGES.BID] ?? getTitle()} placement="bottom">
            {getTitle()}
          </Tooltip>
        </Heading>
        <Select value={listType} onChange={setListType} style={{ width: '9rem', margin: 'auto' }}>
          {Object.entries(BID_LIST_TYPE_TITLE).map(([key, value]) => (
            <Select.Option key={key} value={key}>
              {value}
            </Select.Option>
          ))}
        </Select>
        <div className="d-flex align-items-center" style={{ minWidth: '390px' }}>
          {!state.loading && !isEmpty(bidConfig?.id) && (
            <>
              <a
                href="#!"
                title="Open Bid Terms"
                onClick={setShowTermDrawer.true}
                style={{ marginLeft: '1rem', marginRight: '2rem', textDecoration: 'underline' }}
              >
                Bid Policy
              </a>
              {getBidBlock()}
            </>
          )}
        </div>
        {!state.loading && !isEmpty(bidConfig?.id) ? getSelection() : ''}
      </div>
      <div className="searchInnerResult">
        {state.loading && <div className="loading-indicator"></div>}
        <GridHeightContainer
          className="searchResultTable"
          subtractFrom=".HeaderSticky,.listingTopBlock,.diamondListingAction"
          adjustment={-12}
        >
          {!isEmpty(bidConfig) && (
            <Table
              data={state.data}
              loading={state.loading}
              columns={state.columns}
              handleSort={handleSort}
              currentType={currentType}
              defaultChecked={state.defaultChecked}
              bidStatus={bidConfig?.sub_types?.subTypeCode}
              bidStatusTooltip={bidConfig?.sub_types?.tooltip}
            />
          )}
          {isObject(bidConfig) && isEmpty(bidConfig) && (
            <NoDataShow message="Currently Bid is Inactive, Kindly visit again. You will be notified when Bid is Active." />
          )}
        </GridHeightContainer>
        {!isEmpty(bidConfig) && state.data && state.data.length ? (
          getAction()
        ) : (
          <DiamondListingAction
            {...state}
            {...props}
            currentType={currentType}
            nopaginate
            myBid
            noconfirm
            nocart
            nowatch
            noquote
            nooffice
            noenquiry
            nonote
            noCompare
            noshare
            nodownload
            noprint
          />
        )}
      </div>
      {bidTermsDrawer}
      <Drawer
        visible={!isEmpty(bidConfig?.id) && showTermDrawer}
        onClose={setShowTermDrawer.false}
        wrapClassName="stone-detail-sidebar sm-size"
        destroyOnClose
      >
        {showTermDrawer && <BidTermsConditions nextBidData={bidConfig} />}
      </Drawer>
      <Drawer
        onClose={closeBidDrawer}
        visible={!isEmpty(bidConfig?.id) && bidDrawer}
        wrapClassName="stone-detail-sidebar xl-size"
        destroyOnClose
      >
        {bidDrawer && <BidPopup checked={selectedRows} onClose={closeBidDrawer} />}
      </Drawer>
    </div>
  );
};

export default NewArrivalBidIt;
