import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';

import { Drawer } from 'antd';

import { Compare } from 'components/Compare';
import { GuestUserPopup } from 'components/GuestUserPopup';
import OpenNotification from 'components/common/CommonButton/OpenNotification';
import CommonModal from 'components/common/CommonModal';
import BidPopup from 'components/common/DiamondListing/BidPopup';
import ConfirmStonePopup from 'components/common/DiamondListing/ConfirmStonePopup';
import DownloadDropdown from 'components/common/DiamondListing/DownloadDropdown';
import EnquiryPopup from 'components/common/DiamondListing/EnquiryPopup';
import FinalCalculations from 'components/common/DiamondListing/FinalCalculations';
import { AddBidStoneToWishList } from 'components/common/DiamondListing/ListAction/AddBidStoneToWishList';
import { AddBidStoneViewRequest } from 'components/common/DiamondListing/ListAction/AddBidStoneViewRequest';
import MobileDownloadOption from 'components/common/DiamondListing/MobileDownloadOption';
import MobileShareOption from 'components/common/DiamondListing/MobileShareOption';
import NotePopup from 'components/common/DiamondListing/NotePopup';
import OfficeSidebar from 'components/common/DiamondListing/OfficeSidebar';
import QuotePopup from 'components/common/DiamondListing/QuotePopup';
import ShareDropdown from 'components/common/DiamondListing/ShareDropdown';
import WatchlistPopup from 'components/common/DiamondListing/WatchlistPopup';
import Pagination from 'components/common/Pagination';
import Reminder from 'components/common/Reminder';
import UpdateReminder from 'components/common/UpdateReminder';

// import { RowSelectService } from 'services/RowSelectService';
import { StatsService } from 'services/StatsService';
import { Storage } from 'services/Storage';

import { IntlMessage } from 'util/IntlMessages';
import { callApi } from 'util/call-api';
import { history } from 'util/history';
import { useBoolean, useIsMobile, usePathname, useQueryParams, useSelectedRows } from 'util/hooks';
import { notify } from 'util/notify';
import { classNames, isArray, isEmpty, isNotEmpty, objectToQueryString } from 'util/utils';

import { TRACK_TYPES } from 'constants/Common';
import { GET_DIAMOND_DATA } from 'constants/apiConstant';
import { PAGES } from 'constants/paths.const';

import { setCount } from 'redux/actions/deleteReminder';

import {
  handleInsertTrack,
  handlePrint,
  handleTrackDelete,
  handleUpsertTrack,
  handleBidDelete,
  handleNoteDelete,
  handleDeleteOffice,
  handleConfirmCancel,
  DiamondTrackStatusUpdate,
  handleDownloadExcel,
} from '../../DiamondList/DiamondListFunctions';

import bidStoneSvg from 'assets/svg/DiamondList/bid-stone.svg';
import cartSvg from 'assets/svg/DiamondList/cart.svg';
import certiSvg from 'assets/svg/DiamondList/certi.svg';
import compareSvg from 'assets/svg/DiamondList/compare.svg';
import downloadSvg from 'assets/svg/DiamondList/download.svg';
import ModifySvg from 'assets/svg/DiamondList/modifySearch.svg';
import myBidSvg from 'assets/svg/DiamondList/my-bid.svg';
import printListSvg from 'assets/svg/DiamondList/print-list.svg';
import shareSvg from 'assets/svg/DiamondList/share.svg';
import updatestoneSvg from 'assets/svg/DiamondList/updatestone.svg';
import watchSvg from 'assets/svg/DiamondList/watch.svg';
import confirmSvg from 'assets/svg/DiamondSearch/confirm.svg';
import moreSvg from 'assets/svg/DiamondSearch/more.svg';
import deleteSvg from 'assets/svg/delete.svg';

const restrictedSts = ['U'];

const restrictedStatus = ['H', 'M', 'U'];

const DiamondListingAction = (props) => {
  const { children } = props;
  const { toggle, trackType, update, viewcerti } = props;
  const { clearAll, downloadZip, fetch, setCount, shareXray, verifyCerti, checkedData } = props;
  const { currentType, deleteReminder, downloadzip, editOffer, exportexcel, inTrackDiamonds, myBid } = props;
  const { ActionFalse, addBidStoneToWishList, addBidStoneViewRequest, bidConfig, bidStone } = props;
  const { onAddBidStoneToWishListSuccess, onAddBidStoneViewRequestSuccess, permKey, seachFilterTag, sharexray } = props;
  const {
    nocart,
    noCompare,
    noCompareNow,
    noconfirm,
    nodownload,
    noenquiry,
    // nofinalcalc,
    nonote,
    // nooffice,
    nopaginate,
    noprint,
    // noquote,
    // noreminder,
    modify,
    onModify,
    noshare,
    nowatch,
    noshowselected,
  } = props;

  const pathname = usePathname();
  const query = useQueryParams();

  const [selectedRows] = useSelectedRows(currentType);
  const [showCompare, setShowCompare] = useBoolean();
  const checked = React.useMemo(() => selectedRows ?? checkedData ?? [], [selectedRows, checkedData]);

  const [visible, setVisible] = useState('');
  const [moreSearch, moreSearchOption] = useState(false);
  const [more, setMore] = useState(false);
  const [reminder, setReminder] = useState(false);
  const [disclaimer, setDisclaimer] = useState(false);
  const [showGuest, setShowGuest] = useState(false);

  const permissions = React.useMemo(() => Storage.get('userPermissions'), []);

  const p = React.useCallback((module) => permissions?.[module]?.insert, [permissions]);

  const isGuest = Storage.get('guest');
  const [isMobile] = useIsMobile();

  // const clearSelectedRows = React.useCallback(() => {
  //   RowSelectService.resetSelectedRows(currentType);
  // }, [currentType]);
  const moreSearchDrawer = () => moreSearchOption(true);

  const onClose = React.useCallback(() => {
    setVisible('');
    setReminder('');
    moreSearchOption(false);
    void setCount?.(!deleteReminder.count);
  }, [deleteReminder.count, setCount]);

  const handleShowSelectedStones = React.useCallback(async () => {
    if (isEmpty(checked)) return OpenNotification({ type: 'error', title: 'Please select stone(s) to share.' });
    const vStnIds = checked.map((stone) => ({ vStnId: [stone.vStnId] }));
    const [, res] = await callApi({
      ...GET_DIAMOND_DATA,
      request: { filters: [{ or: vStnIds }] },
    });

    if (res?.code === 'OK') {
      const id = res?.data?.[0]?.filter?.id;
      if (isEmpty(id)) return;
      window.open(`/diamond-list/${id}${objectToQueryString({ selected: true })}`);
    }
  }, [checked]);

  const actions = React.useMemo(() => {
    return [
      bidStone &&
        p('BID') && {
          name: <IntlMessage id="app.bidStone" />,
          key: 'bid',
          img: bidStoneSvg,
          onClick: () => {
            if (isGuest) return setShowGuest(true);
            isEmpty(checked) ? notify.error({ message: 'Please select stone(s) to bid.' }) : setVisible('bid');
          },
        },
      myBid &&
        p('BID') && {
          name: <IntlMessage id="app.myBid" />,
          key: 'mybid',
          img: myBidSvg,
          onClick: () => {
            if (isGuest) return setShowGuest(true);
            history.push('/my-bid');
          },
        },
      addBidStoneToWishList && (
        <AddBidStoneToWishList list={currentType} bidConfig={bidConfig} onSuccess={onAddBidStoneToWishListSuccess} />
      ),
      addBidStoneViewRequest && (
        <AddBidStoneViewRequest list={currentType} bidConfig={bidConfig} onSuccess={onAddBidStoneViewRequestSuccess} />
      ),
      props.delete && {
        name: <IntlMessage id="app.removeStone" />,
        img: deleteSvg,
        key: 'delete',
        onClick: () => {
          if (isGuest) return setShowGuest(true);

          if (isEmpty(checked)) {
            notify.error({ message: 'Please select stone(s) to delete.' });
            return;
          }

          if (pathname === PAGES.MYBID) {
            handleBidDelete(
              checked.map((row) => row.trackId),
              (flag) => {
                void fetch?.();
                void clearAll?.();
                if (flag) void setCount?.(!deleteReminder.count);
              },
            );
          } else if (pathname === PAGES.NOTE) {
            handleNoteDelete(
              checked.map((row) => row.trackId),
              (flag) => {
                void clearAll?.();

                if (flag) {
                  void fetch?.();
                  void setCount?.(!deleteReminder.count);
                  StatsService.setDashboardStats();
                }
              },
            );
          } else if (pathname === PAGES.OFFICE) {
            const schedule = [];

            checked.map((ck) => {
              schedule.push({
                diamonds: [ck.id],
                id: ck.slottId,
              });
              return true;
            });

            handleDeleteOffice({ schedule: schedule }, () => {
              void clearAll?.();
              void fetch?.();
              OpenNotification({ type: 'success', title: 'Your stone(s) is removed from office successfully.' });
            });
          } else if (pathname === PAGES.CONFIRM) {
            const orders = checked.map((ck) => ({
              diamond: ck.diamondId,
              id: ck.orderId,
            }));

            handleConfirmCancel(orders, (flag) => {
              void clearAll?.();

              if (flag) {
                void fetch?.();
                void setCount?.(!deleteReminder.count);
              }
            });
          } else {
            handleTrackDelete(
              trackType,
              checked.map((row) => row.id),
              inTrackDiamonds,
              (flag) => {
                void clearAll?.();

                if (flag) {
                  void fetch?.();
                  void setCount?.(!deleteReminder.count);
                  StatsService.setDashboardStats();
                }
              },
            );
          }
        },
      },
      update && {
        name: <IntlMessage id="app.updateStone" />,
        img: updatestoneSvg,
        key: 'update',
        onClick: () => {
          if (isGuest) return setShowGuest(true);

          if (pathname === PAGES.REMINDER) {
            isEmpty(checked)
              ? OpenNotification({ type: 'error', title: 'Please select stone(s) to update reminder.' })
              : setReminder('update');
          } else if (pathname === PAGES.MYBID) {
            isEmpty(checked)
              ? OpenNotification({ type: 'error', title: 'Please select stone(s) to update bid.' })
              : setVisible('bid');
          } else if (pathname === PAGES.NOTE) {
            setVisible('note');
          }
        },
      },
      permKey === 'offer' && {
        name: 'Reject',
        key: 'rejectoffer',
        onClick: () => {
          isEmpty(checked)
            ? OpenNotification({ type: 'error', title: 'Please select stone(stones) to reject quote.' })
            : DiamondTrackStatusUpdate(
                checked.map((x) => x.trackId),
                { offerStatus: 3 },
                () => {
                  void clearAll?.();
                },
              );
        },
      },
      isMobile &&
        modify && {
          name: <IntlMessage id="app.modify" />,
          key: 'modify',
          onClick: onModify,
          img: ModifySvg,
        },

      !isMobile &&
        !noconfirm &&
        p('CONFIRM_STONE') && {
          name: <IntlMessage id="app.confirmStone" />,
          key: 'confirm',
          onClick: () => {
            if (isGuest) return setShowGuest(true);

            if (!isArray(checked) || isEmpty(checked))
              return OpenNotification({ type: 'error', title: 'Please select stone(s) to confirm.' });

            if (checked.length > 100)
              return OpenNotification({ type: 'error', title: 'You can select only 100 stones at time to confirm.' });

            if (!checked.filter(({ sSts }) => !['H', 'M', 'U'].includes(sSts)).length)
              return OpenNotification({
                type: 'error',
                title: `Sorry! Unable to perform action at this time. The selected diamond(s) ${checked
                  .filter((x) => restrictedStatus.includes(x.wSts))
                  .map((x) => x.vStnId)
                  .join(', ')} is in hold/memo.`,
              });

            // if (checked.some((rec) => restrictedStatus.includes(rec?.wSts)))
            // return OpenNotification({ //   type: 'error',
            //   title: `Sorry! Unable to perform action at this time. The selected diamond(s) ${checked
            //     .filter((x) => restrictedStatus.includes(x.wSts))
            //     .map((x) => x.vStnId)
            //     .join(', ')} is in hold/memo.`,
            // });

            // if (checked.length > 1) return OpenNotification({ type: 'error', title: 'Please select only one stone.' });

            setVisible('confirm');
            setDisclaimer(false);
          },
          img: confirmSvg,
        },
      !nocart &&
        p('CART') && {
          name: <IntlMessage id="app.Cart" />,
          key: 'cart',
          onClick: () => {
            if (isGuest) return setShowGuest(true);

            isEmpty(checked)
              ? OpenNotification({ type: 'error', title: 'Please select stone(s) to add in cart.' })
              : checked.length > 100
              ? OpenNotification({ type: 'error', title: 'You can select only 100 stones at a time in cart.' })
              : checked.some((a) => restrictedSts.includes(a.wSts))
              ? OpenNotification({
                  type: 'error',
                  title: `Sorry! Unable to perform action at this time. The selected diamond(s) ${checked
                    .filter((x) => restrictedSts.includes(x.wSts))
                    .map((x) => x.vStnId)
                    .join(', ')} is in upcoming.`,
                })
              : handleInsertTrack(TRACK_TYPES.CART, checked, (status) => {
                  void clearAll?.();
                  if (status) void setCount?.(!deleteReminder.count);
                });
          },
          img: cartSvg,
        },
      !nowatch &&
        p('WATCHLIST') && {
          name: <IntlMessage id="app.Watch" />,
          key: 'watch',
          onClick: () => {
            if (isGuest) return setShowGuest(true);

            isEmpty(checked)
              ? OpenNotification({ type: 'error', title: 'Please select stone(s) to add in watch.' })
              : checked.length > 100
              ? OpenNotification({ type: 'error', title: 'You can select only 100 stones at a time in watchlist.' })
              : setVisible('watchlist');
          },
          img: watchSvg,
        },
      // noreminder
      //   ? null
      //   : {
      //       name: <IntlMessages id="app.reminder" />,
      //       key: "reminder",
      //       onClick: () => {
      //         if (!isGuest) {
      //           isEmpty(checked)
      //             ? OpenNotification({ //                 type: "error",
      //                 title: "Please select stone(s) to add in reminder.",
      //               })
      //             : setVisible("insert");
      //         } else {
      //           setShowGuest(true);
      //         }
      //       },
      //     },
      // !noquote && {
      //   name: <IntlMessage id="app.Offer" />,
      //   key: 'quote',
      //   onClick: () => {
      //     if (isGuest) return setShowGuest(true);
      //     isEmpty(checked)
      //       ? OpenNotification({ type: 'error', title: 'Please select stone(s) to apply quote.' })
      //       : !checked.filter((x) => !['H', 'M', 'U'].includes(x.sSts))?.length
      //       ? OpenNotification({
      //           type: 'error',
      //           title: `Sorry! Unable to perform action at this time. The selected diamond(s) ${checked
      //             .filter((x) => restrictedStatus.includes(x.wSts))
      //             .map((x) => x.vStnId)
      //             .join(', ')} is in hold/memo.`,
      //         })
      //       : setVisible('quote');
      //   },
      // },
      // nofinalcalc
      //   ? null
      //   : {
      //       name: <IntlMessages id="app.FinalCalculation" />,
      //       key: "calc",
      //       img: require("assets/svg/DiamondList/cal.svg"),
      //       onClick: () => {
      //         if (!isGuest) {
      //           if (isEmpty(checked)) {
      //             OpenNotification({ //               type: "error",
      //               title: "Please select stone(s) for final calculation.",
      //             });
      //           } else {
      //             let fm = checked.filter((el) => el.isFm === "ELIG");
      //             if (fm.length === checked.length || fm.length === 0) setVisible("finalCalc");
      //             else
      //               OpenNotification({ //                 type: "error",
      //                 title: "Please select FM stones separately.",
      //               });
      //           }
      //         } else {
      //           setShowGuest(true);
      //         }
      //       },
      //     },
      // !nooffice && {
      //   name: <IntlMessage id="app.appointment" />,
      //   key: 'office',
      //   onClick: () => {
      //     if (isGuest) return setShowGuest(true);
      //     isEmpty(checked)
      //       ? OpenNotification({ type: 'error', title: 'Please select stone(s) for office.' })
      //       : checked.some((a) => restrictedSts.includes(a.wSts))
      //       ? OpenNotification({
      //           type: 'error',
      //           title: `Sorry! Unable to perform action at this time. The selected diamond(s) ${checked
      //             .filter((x) => restrictedSts.includes(x.wSts))
      //             .map((x) => x.vStnId)
      //             .join(', ')} is in upcoming.`,
      //         })
      //       : setVisible('office');
      //   },
      // },
      !noenquiry &&
        p('ENQUIRY') && {
          name: <IntlMessage id="app.AddEnquiry" />,
          key: 'enquiry',
          onClick: () => {
            if (isGuest) return setShowGuest(true);

            isEmpty(checked)
              ? OpenNotification({ type: 'error', title: 'Please select stone(s) for enquiry.' })
              : checked.length > 100
              ? OpenNotification({ type: 'error', title: 'You can select only 100 stones at a time in enquiry.' })
              : setVisible('enquiry');
          },
        },

      !exportexcel
        ? null
        : {
            name: <IntlMessage id="app.ExportExcel" />,
            onClick: () => {
              if (!isGuest) {
                if (checked.length < 1) {
                  return notify.error({ message: 'Please, select stone(s) to Export Excel.' });
                }

                if (checked.find((chk) => chk.wSts === 'B') && pathname === 'account') {
                  OpenNotification({
                    type: 'error',
                    title: 'You can not add Bid stone(s) for any action. Please uncheck/remove bid stone(s).',
                  });
                } else if (pathname === PAGES.CONFIRM || (pathname === PAGES.PURCHASE && checked.length > 0)) {
                  handleDownloadExcel(
                    {},
                    checked.map((x) => x.id),
                    () => {},
                  );
                } else if (pathname === PAGES.MY_MEMO && checked.length > 0) {
                  handleDownloadExcel(
                    {},
                    checked.map((x) => x.id),
                    () => {},
                    {
                      excelType: 3,
                      filter: {
                        blockid: checked.map((chk) => chk.blockId),
                      },
                    },
                  );
                } else if (pathname === PAGES.PREDEFINEMATCHPAIR && checked.length > 0) {
                  handleDownloadExcel(
                    {},
                    checked.map((x) => x.id),
                    () => {},
                    {
                      isPairExcel: true,
                    },
                  );
                } else if (checked.length > 0) {
                  handleDownloadExcel(
                    {},
                    checked.map((x) => x.id),
                    () => {},
                  );
                } else {
                  return OpenNotification({
                    type: 'error',
                    title: 'No Stones Found',
                  });
                }
              } else {
                setShowGuest(true);
              }
            },
          },

      !nonote &&
        p('NOTES') && {
          name: <IntlMessage id="app.comment" />,
          key: 'note',
          onClick: () => {
            if (!isGuest) {
              isEmpty(checked)
                ? OpenNotification({ type: 'error', title: 'Please select stone(s) to add note.' })
                : checked.length > 100
                ? OpenNotification({ type: 'error', title: 'You can select only 100 stones at a time in notes.' })
                : // : checked.some((a) => restrictedStatus.includes(a.wSts))
                  // ? OpenNotification({
                  //     type: 'error',
                  //     title: `Sorry! Unable to perform action at this time. The selected diamond(s) ${checked
                  //       .filter((x) => restrictedStatus.includes(x.wSts))
                  //       .map((x) => x.vStnId)
                  //       .join(', ')} is in hold/memo.`,
                  //   })
                  setVisible('note');
            } else {
              setShowGuest(true);
            }
          },
        },

      !noshowselected &&
        !query?.selected && {
          name: <IntlMessage id="app.ShowSelected" />,
          key: 'showselected',
          // img: shareSvg,
          onClick: () => {
            if (isGuest) return setShowGuest(true);

            isEmpty(checked)
              ? OpenNotification({ type: 'error', title: 'Please select stone(s) to share.' })
              : handleShowSelectedStones();
          },
        },
      noCompare || !p('COMPARE')
        ? null
        : noCompareNow
        ? {
            name: (
              <span>
                <IntlMessage id="app.CompareStoneNow" />
                {/* {' (' + checked?.length + ')'} */}
              </span>
            ),
            key: 'compare',
            img: compareSvg,
            onClick: () => {
              if (isGuest) return setShowGuest(true);
              setShowCompare.true();
              toggle();
            },
          }
        : {
            name: <IntlMessage id="app.CompareStone" />,
            key: 'compare',
            img: compareSvg,
            onClick: async () => {
              if (isMobile) {
                onClose();
              }

              if (isGuest) {
                setShowGuest(true);
                return;
              } else {
                if (!checked) {
                  return;
                } else if (checked.length < 1) {
                  notify.error({ message: 'Please, select stone(s) to compare.' });
                } else if (checked.length < 2) {
                  notify.error({ message: 'Please, select minimum two stones to compare.' });
                } else if (checked.length > 15) {
                  notify.error({ message: 'Maximum 15 stones can be compared.' });
                } else {
                  setShowCompare.true();
                }
              }
            },
          },

      !noshare && {
        name: <IntlMessage id="app.Share" />,
        key: 'share',
        img: shareSvg,
        onClick: () => {
          if (isGuest) return setShowGuest(true);

          isEmpty(checked)
            ? OpenNotification({ type: 'error', title: 'Please select stone(s) to share.' })
            : setVisible('share');
        },
      },
      !nodownload && {
        name: <IntlMessage id="app.Download" />,
        key: 'download',
        img: downloadSvg,
        onClick: () => {
          if (isGuest) return setShowGuest(true);

          if (isEmpty(checked)) {
            OpenNotification({ type: 'error', title: 'Please select stone(s) for download options.' });
          } else {
            setVisible('download');
          }
        },
      },
      !noprint && {
        name: <IntlMessage id="app.PrintList" />,
        key: 'print',
        img: printListSvg,
        onClick: () => {
          if (isGuest) return setShowGuest(true);

          if (isEmpty(checked)) {
            OpenNotification({ type: 'error', title: 'Please select stone(s) to print.' });
          } else {
            if (pathname === PAGES.CONFIRM || pathname === PAGES.PURCHASE) {
              handlePrint(
                seachFilterTag ? seachFilterTag.searchData : {},
                checked.map((chk) => chk.id),
                false,
                { orderDiamond: true },
              );
              void clearAll?.();
            } else if (pathname === PAGES.UPCOMING) {
              handlePrint(
                seachFilterTag ? seachFilterTag.searchData : {},
                checked.map((chk) => chk.id),
                true,
              );
              void clearAll?.();
            } else if (pathname === PAGES.MY_MEMO) {
              handlePrint(
                seachFilterTag ? seachFilterTag.searchData : {},
                checked.map((chk) => chk.id),
                false,
                {
                  excelType: 3,
                  filter: {
                    blockid: checked.map((chk) => chk.blockId),
                  },
                },
              );
              void clearAll?.();
            } else {
              handlePrint(
                seachFilterTag ? seachFilterTag.searchData : {},
                checked.map((chk) => chk.id),
              );
              void clearAll?.();
            }
          }
        },
      },
      sharexray && {
        name: <>Share X-Ray</>,
        key: 'xray',
        onClick: () => {
          if (isGuest) return setShowGuest(true);
          void shareXray?.();
        },
        img: certiSvg,
      },
      viewcerti && {
        name: <>Verify Certificate</>,
        key: 'certi',
        onClick: () => {
          if (isGuest) return setShowGuest(true);
          void verifyCerti?.();
          onClose();
        },
      },
      downloadzip && {
        name: <>Download Zip</>,
        key: 'zip',
        onClick: () => {
          if (isGuest) return setShowGuest(true);
          void downloadZip?.();
          onClose();
        },
      },
      isMobile &&
        p('CONFIRM_STONE') &&
        !noconfirm && {
          name: <IntlMessage id="app.confirmStone" />,
          key: 'confirm',
          onClick: () => {
            if (isGuest) return setShowGuest(true);

            if (!isArray(checked) || isEmpty(checked))
              return OpenNotification({ type: 'error', title: 'Please select stone(s) to confirm.' });

            if (checked.length > 100)
              return OpenNotification({ type: 'error', title: 'You can select only 100 stones at time to confirm.' });

            if (!checked.filter(({ sSts }) => !['H', 'M', 'U'].includes(sSts)).length)
              return OpenNotification({
                type: 'error',
                title: `Sorry! Unable to perform action at this time. The selected diamond(s) ${checked
                  .filter((x) => restrictedStatus.includes(x.wSts))
                  .map((x) => x.vStnId)
                  .join(', ')} is in hold/memo.`,
              });

            // if (checked.some((rec) => restrictedStatus.includes(rec?.wSts)))
            // return OpenNotification({ //   type: 'error',
            //   title: `Sorry! Unable to perform action at this time. The selected diamond(s) ${checked
            //     .filter((x) => restrictedStatus.includes(x.wSts))
            //     .map((x) => x.vStnId)
            //     .join(', ')} is in hold/memo.`,
            // });

            // if (checked.length > 1) return OpenNotification({ type: 'error', title: 'Please select only one stone.' });

            setVisible('confirm');
            setDisclaimer(false);
          },
          img: confirmSvg,
        },
    ]
      .filter(isNotEmpty)
      .filter(Boolean);
  }, [
    bidStone,
    p,
    myBid,
    addBidStoneToWishList,
    currentType,
    bidConfig,
    onAddBidStoneToWishListSuccess,
    addBidStoneViewRequest,
    onAddBidStoneViewRequestSuccess,
    props.delete,
    update,
    permKey,
    isMobile,
    modify,
    onModify,
    noconfirm,
    nocart,
    nowatch,
    noenquiry,
    exportexcel,
    nonote,
    noshowselected,
    query?.selected,
    noCompare,
    noCompareNow,
    noshare,
    nodownload,
    noprint,
    sharexray,
    viewcerti,
    downloadzip,
    isGuest,
    checked,
    pathname,
    fetch,
    clearAll,
    setCount,
    deleteReminder.count,
    trackType,
    inTrackDiamonds,
    handleShowSelectedStones,
    setShowCompare,
    toggle,
    onClose,
    seachFilterTag,
    shareXray,
    verifyCerti,
    downloadZip,
  ]);

  const ipad = window.innerWidth <= 991;
  return (
    <>
      <div className="mobileSearchBottom">
        {actions.slice(0, actions.length > 4 ? 2 : 4).map((x) => {
          if (React.isValidElement(x)) return x;
          return (
            <div className="mobileSearchActionItem" key={x.key}>
              <a onClick={x.onClick}>
                <img alt="" src={x.img} />
                <span>{x.key === 'calc' ? <IntlMessage id="app.FinalCalc" /> : x.name}</span>
              </a>
            </div>
          );
        })}
        {actions.length > 2 && (
          <div className="mobileSearchActionItem">
            <a onClick={moreSearchDrawer}>
              <img alt="" src={moreSvg} />
              <span>
                <IntlMessage id="app.More" />
              </span>
            </a>
          </div>
        )}
      </div>
      <Drawer onClose={onClose} visible={moreSearch} wrapClassName="mobileSidebar mobileBottomSidebar" destroyOnClose>
        {moreSearch && (
          <div className="MoreSearchOptionItem">
            <ul>
              {actions.slice(2, actions.length).map((x) => {
                if (React.isValidElement(x)) return x;
                return (
                  <li onClick={x.onClick} key={x.key}>
                    <a>{x.name}</a>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </Drawer>
      <div
        className={classNames([
          'botoomStickyBar diamondListingAction',
          ActionFalse === false && ' justify-content-center',
        ])}
      >
        <div className="d-flex listingAction">
          {actions.slice(0, ipad ? 3 : actions.length > 7 ? actions.length - 6 : actions.length).map((x) => {
            if (React.isValidElement(x)) return x;
            return (
              <a key={x.key} className="bottomStickyButton" onClick={x.onClick}>
                <span>{x.name}</span>
              </a>
            );
          })}
          {children}
          {((ipad && actions.length > 3) || actions.length > 7) && (
            <Dropdown
              isOpen={more}
              onClick={() => {
                setMore(!more);
              }}
              toggle={() => {
                setMore(!more);
              }}
            >
              <DropdownToggle className="bottomStickyButton" caret>
                <span>
                  <IntlMessage id="app.More" />
                </span>
              </DropdownToggle>
              <DropdownMenu className="diamondListBottomDropdown">
                <ul>
                  {actions.slice(actions.length - 6, actions.length).map((x, i) => {
                    if (React.isValidElement(x)) return x;
                    return (
                      <li key={i + 'li'} onClick={x.onClick}>
                        <a>
                          <div className="dropdownBottomItem">
                            <span>{x.name}</span>
                          </div>
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </DropdownMenu>
            </Dropdown>
          )}
        </div>
        {!nopaginate && <Pagination {...props} />}
      </div>
      <Drawer
        onClose={onClose}
        visible={visible === 'confirm'}
        wrapClassName="stone-detail-sidebar xl-size"
        destroyOnClose
      >
        {visible === 'confirm' && <ConfirmStonePopup {...props} checked={checked} onClose={onClose} />}
      </Drawer>
      <Drawer
        onClose={() => {
          onClose();
          if (editOffer) void fetch?.();
        }}
        visible={visible === 'quote' || editOffer}
        wrapClassName="stone-detail-sidebar offerPopup xl-size"
        destroyOnClose
      >
        {visible === 'quote' ||
          (editOffer && (
            <QuotePopup
              quoteDay={pathname === PAGES.QUOTEDAY ? true : false}
              {...props}
              checked={checked}
              onClose={() => {
                onClose();
                if (editOffer) void fetch?.();
              }}
            />
          ))}
      </Drawer>
      <Drawer onClose={onClose} visible={visible === 'bid'} wrapClassName="stone-detail-sidebar xl-size" destroyOnClose>
        {visible === 'bid' && <BidPopup {...props} checked={checked} onClose={onClose} />}
      </Drawer>
      <Drawer
        onClose={onClose}
        visible={visible === 'note'}
        wrapClassName="stone-detail-sidebar xl-size"
        destroyOnClose
      >
        {visible === 'note' && <NotePopup {...props} checked={checked} onClose={onClose} />}
      </Drawer>
      <Drawer
        onClose={onClose}
        visible={visible === 'office'}
        wrapClassName="stone-detail-sidebar OfficePopup"
        destroyOnClose
      >
        {visible === 'office' && (
          <OfficeSidebar
            bid={pathname === PAGES.BID}
            quoteDay={pathname === PAGES.QUOTEDAY}
            {...props}
            checked={checked}
            onClose={onClose}
          />
        )}
      </Drawer>
      <Drawer
        onClose={onClose}
        visible={visible === 'finalCalc'}
        wrapClassName="stone-detail-sidebar xl-size"
        destroyOnClose
      >
        {visible === 'finalCalc' && <FinalCalculations {...props} checked={checked} onClose={onClose} />}
      </Drawer>
      <Drawer
        onClose={onClose}
        visible={visible === 'watchlist'}
        wrapClassName="stone-detail-sidebar xl-size"
        destroyOnClose
      >
        {visible === 'watchlist' && <WatchlistPopup {...props} checked={checked} onClose={onClose} />}
      </Drawer>
      {visible === 'enquiry' && <EnquiryPopup visible={true} checked={checked} handleCancel={onClose} />}
      <CommonModal
        modalSize="ListingModal"
        title={<IntlMessage id="app.ShareStone" />}
        handleCancel={onClose}
        visible={visible === 'share' && !isMobile}
        wrapClassName=""
      >
        {visible === 'share' && !isMobile && (
          <ShareDropdown
            order={pathname === PAGES.CONFIRM}
            {...props}
            checked={checked}
            onClose={onClose}
            listingPage
          />
        )}
      </CommonModal>
      <Drawer
        onClose={onClose}
        visible={visible === 'share' && isMobile}
        wrapClassName="stone-detail-sidebar xl-size"
        destroyOnClose
      >
        {visible === 'share' && isMobile && (
          <ShareDropdown
            order={pathname === PAGES.CONFIRM}
            {...props}
            checked={checked}
            onClose={onClose}
            listingPage
          />
        )}
      </Drawer>
      <CommonModal
        modalSize="ListingModal"
        title={<IntlMessage id="app.Download" />}
        handleCancel={onClose}
        visible={visible === 'download' && !isMobile}
      >
        {visible === 'download' && !isMobile && (
          <DownloadDropdown
            {...props}
            checked={checked}
            orderDiamond={window.location.pathname === '/account/confirm-list' || Storage.get('confirm-compare')}
            onClose={onClose}
          />
        )}
      </CommonModal>
      <Drawer
        onClose={onClose}
        visible={visible === 'download' && isMobile}
        wrapClassName="stone-detail-sidebar xl-size"
        destroyOnClose
      >
        {visible === 'download' && isMobile && (
          <DownloadDropdown
            {...props}
            checked={checked}
            orderDiamond={window.location.pathname === '/account/confirm-list' || Storage.get('confirm-compare')}
            onClose={onClose}
          />
        )}
      </Drawer>
      <Drawer onClose={onClose} visible={reminder === 'insert'} wrapClassName="stone-detail-sidebar" destroyOnClose>
        {reminder === 'insert' && (
          <Reminder
            handleCancel={() => {
              setReminder(false);
              void clearAll?.();
            }}
            handleSetReminder={(e) =>
              handleInsertTrack(
                TRACK_TYPES.REMINDER,
                checked,
                () => {
                  void clearAll?.();
                  setReminder(false);
                },
                e,
              )
            }
            checked={checked}
          />
        )}
      </Drawer>
      <Drawer
        onClose={onClose}
        visible={reminder === 'update'}
        wrapClassName="stone-detail-sidebar xl-size"
        destroyOnClose
      >
        {reminder === 'update' && (
          <UpdateReminder
            handleCancel={() => {
              setReminder(false);
              void clearAll?.();
            }}
            date={checked && checked[0] ? checked[0].reminderDate : null}
            handleUpdateReminder={(e) =>
              handleUpsertTrack(
                TRACK_TYPES.REMINDER,
                checked,
                inTrackDiamonds,
                () => {
                  void clearAll?.();
                  void fetch?.();
                  setReminder(false);
                },
                e,
              )
            }
            checked={checked}
          />
        )}
      </Drawer>
      <CommonModal
        handleCancel={() => setDisclaimer(false)}
        handleOk={() => {
          setVisible(disclaimer);
          setDisclaimer(false);
        }}
        visible={isArray(checked) && !isEmpty(checked) && disclaimer}
        modalSize="xs-size"
        footerShow
        title={<IntlMessage id="app.Disclaimer" />}
        submitTitle={<IntlMessage id="app.IAgree" />}
        cancelTitle={<IntlMessage id="app.Quit" />}
      >
        {isArray(checked) &&
        !isEmpty(checked) &&
        checked.map((x) => (x.locNm ? x.locNm.toUpperCase() : '')).filter((el) => el !== 'INDIA').length ? (
          <p>
            <IntlMessage id="app.PacketNo" />:{checked.map((x) => x.vStnId).join(', ')},
            <IntlMessage id="app.isLocatedIn" />
            {checked.map((x) => (x.locNm ? x.locNm.toUpperCase() : '')).join('/')}
            <IntlMessage id="app.notAvail" />
            <br />
            <p className="mt-10">
              <IntlMessage id="app.pandemicNotice" />
            </p>
          </p>
        ) : (
          <p>
            <IntlMessage id="app.PacketNo" />:{checked.map((x) => x.vStnId).join(', ')},{' '}
            <IntlMessage id="app.deliverIndia" />
          </p>
        )}
      </CommonModal>
      {showGuest && (
        <GuestUserPopup
          visible={showGuest}
          register={() => {
            Storage.deleteAll();
            window.open('/signup', '_self');
          }}
          handleCancel={() => {
            setShowGuest(false);
          }}
        />
      )}

      {showCompare && <Compare parentType={currentType} toggle={setShowCompare.toggle} />}
    </>
  );
};

export default connect(
  (state) => ({
    common: state.common,
    deleteReminder: state.deleteReminder,
  }),
  { setCount },
)(DiamondListingAction);
