import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Select } from 'antd';
import ipapi from 'ipapi.co';
import _ from 'lodash';

import languageData from 'constants/languageData';

import { switchLanguage } from 'redux/actions/Common';

const { Option } = Select;

class LangDropdown extends Component {
  state = {
    country_code: '',
    langDefaultVal: (this.props.common.locale && this.props.common.locale.name) || languageData[0].name,
  };

  componentDidMount() {
    const self = this;
    const callback = function (res) {
      if (res && res.country_code) {
        const findCountry = _.find(languageData, { country_code: res.country_code });
        const setLanguage = {
          country_code: res.country_code,
        };
        if (findCountry && findCountry.languageId) {
          setLanguage.langDefaultVal = findCountry.name;
          self.props.switchLanguage(findCountry.languageId);
        }
        self.setState(setLanguage);
      }
    };

    ipapi.location(callback);
  }

  render() {
    return (
      <Select
        className="lang-steps dropdownLag"
        dropdownClassName="dropUibox"
        defaultValue={this.state.langDefaultVal}
        style={{ width: 100 }}
        onChange={this.props.switchLanguage}
      >
        {languageData.map((language) => (
          <Option key={language.languageId} value={language.languageId}>
            {language.name}
          </Option>
        ))}
      </Select>
    );
  }
}

const mapStateToProps = (props) => {
  return { common: props.common };
};
export default connect(mapStateToProps, { switchLanguage })(LangDropdown);
