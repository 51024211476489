import React from 'react';

import { MOBILE_MAX_WIDTH, TABLET_MAX_WIDTH } from 'util/constants';

export function useIsMobile() {
  const [width, setWidth] = React.useState(window.innerWidth);

  const setSize = () => setWidth(window.innerWidth);

  React.useEffect(() => {
    setSize();
    window.addEventListener('resize', setSize);
    return () => window.removeEventListener('resize', setSize);
  }, []);

  const output = React.useMemo(() => {
    const isMobile = width <= MOBILE_MAX_WIDTH;
    const isTablet = width <= TABLET_MAX_WIDTH;
    return [isMobile, isTablet];
  }, [width]);

  React.useDebugValue(output);
  return output;
}
