import React from 'react';
import './checkBox.less';

const CheckBox = (props) => {
  const { label, ...rest } = props;
  return (
    <div className={`customCheckBox d-flex flex-wrap ${rest?.className}`}>
      <input type="checkbox" {...rest} />
      <span className="customCheckBoxLebel"></span>
      {label && <label>{label}</label>}
    </div>
  );
};

export default CheckBox;
