export const COMPARE_LIST = [
  [
    'Diamond Details',
    [
      { label: 'Stone No', field: 'vStnId' },
      { label: 'Certificate No', field: 'rptNo' },
    ],
  ],
  [
    'Diamond Grading',
    [
      { label: 'Shape', field: 'shpNm' },
      { label: 'Carat', field: 'crt' },
      { label: 'Color', field: 'colNm' },
      { label: 'Clarity', field: 'clrNm' },
      { label: 'Cut', field: 'cutNm' },
      { label: 'Polish', field: 'polNm' },
      { label: 'Symmetry', field: 'symNm' },
      { label: 'Lab', field: 'lbNm' },
      { label: 'Fluorescence', field: 'fluNm' },
      { label: 'Shade', field: 'shdNm' },
    ],
  ],
  [
    'Pricing',
    [
      { label: 'Rap ($)', field: 'rap' },
      { label: 'Discount%', field: 'back' },
      { label: 'Price/Carat ($)', field: 'ctPr' },
      { label: 'Amount ($)', field: 'amt' },
    ],
  ],
  [
    'Measurements',
    [
      // { label: 'Rap ($)', field: 'rap' },
      // { label: 'Discount%', field: 'back' },
      // { label: 'Price/Carat ($)', field: 'ctPr' },
      // { label: 'Amount ($)', field: 'amt' },
      { label: 'Eye Clean', field: 'eClnNm' },
      { label: 'Depth %', field: 'depPer' },
      { label: 'Table %', field: 'tblPer' },
      { label: 'Length', field: 'length' },
      { label: 'Width', field: 'width' },
      { label: 'Depth', field: 'height' },
      { label: 'Crown Angle', field: 'cAng' },
      { label: 'Crown Height', field: 'cHgt' },
      { label: 'Pav Angle', field: 'pAng' },
      { label: 'Pav Height', field: 'pHgt' },
      { label: 'Girdle', field: 'girdleStr' },
      { label: 'Culet', field: 'cultNm' },
      { label: 'H&A', field: 'hANm' },
    ],
  ],
];
