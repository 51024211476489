import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import SpeechRecognition from 'react-speech-recognition';

import { Input } from 'antd';
import { isEmpty, split, last, concat } from 'lodash';

import { LISTINGPAGES } from 'components/DiamondList/DiamondListFunctions';

import UtilService from 'services/util';

import IntlMessages from 'util/IntlMessages';
import { notify } from 'util/notify';

import { LOCAL_STORAGE_VAR } from 'constants/Common';
import { SEARCH_DIAMOND_LIST } from 'constants/apiConstant';

function HeaderSearch(props) {
  const { resetTranscript, browserSupportsSpeechRecognition, startListening, stopListening } = props;

  const history = useHistory();
  const masterData = JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-master`));
  const [searchData, setSearchData] = useState([]);
  const [searchInput, setSearchInput] = useState({});
  const [recordOn, setRecordOn] = useState(false);

  const handleSearchChange = (e) => {
    let value = e.target.value;
    let searched = [];
    const splitted = value ? split(value, ' ') : [];

    if (!isEmpty(splitted)) value = last(splitted);

    if (value) {
      const others = [];
      const savedSearch = [];
      Object.keys(masterData).map((m) => {
        masterData[m].map((dt) => {
          if (dt.name.toLowerCase().includes(value)) {
            others.push({ label: dt.name, type: 'OTHER' });
          }
        });
      });

      props.searchList.map((sl) => {
        if (sl.name.toLowerCase().includes(value)) {
          savedSearch.push({ label: sl.name, type: 'SAVEDSEARCH', id: sl.id });
        }
      });

      searched = concat(others.slice(0, 5), savedSearch.slice(0, 5));
    } else {
      searched = [];
    }

    setSearchInput({ str: e.target.value, type: 'OTHER' });
    setSearchData(searched);
  };

  const handleSearch = () => {
    if (searchInput.type === 'OTHER') {
      const obj = {
        search: searchInput.str.toUpperCase(),
        isReturnCountOnly: true,
        isNotReturnTotal: true,
        isDirectSearch: true,
        limit: 100,
        page: 1,
      };
      const objData = {
        ...SEARCH_DIAMOND_LIST,
        request: obj,
      };
      UtilService.callApi(objData, (err, data) => {
        if (data?.code === 'E_BAD_REQUEST') {
          return notify.error({ message: data?.message });
        }
        if (data && data.code === 'OK') {
          const count = data?.data?.[0]?.count;
          if (!count) {
            notify.error({ message: 'No Result Found!' });
            return;
          }

          history.push(`/${LISTINGPAGES.LIST}/${data.data[0].filter.id}`);
          setSearchInput({});
          props.onClick();
        }
      });
    }
    if (searchInput.type === 'SAVEDSEARCH') {
      history.push(`/${LISTINGPAGES.LIST}/${searchInput.id}`);
      setSearchInput({});
      props.onClick();
    }
  };

  const catchFunc = (func, reset) => {
    setSearchInput({ str: props.transcript, type: 'OTHER' });
    props.abortListening();
    func();
    // reset();
  };

  const start = (func) => {
    setRecordOn(true);
    setSearchInput({ str: '', type: 'OTHER' });
    func();
  };

  const stop = (func, reset) => {
    setRecordOn(false);
    catchFunc(func, reset);
    if (!isEmpty(searchInput)) handleSearch();
  };

  useEffect(() => {
    if (props.transcript !== '') {
      setSearchInput({ str: props.transcript, type: 'OTHER' });
    }
  }, [props.transcript]);

  return (
    <div className={`searchWrapper ${props.className}`}>
      <div className="searchWrapperInner">
        <div className="searchBox d-flex align-items-center">
          <span className="searchIcon">
            <img src={require('assets/svg/Header/search-white.svg')} />
          </span>
          <IntlMessages id="app.Search">
            {(placeholder) => (
              <Input.Search
                value={searchInput.str}
                placeholder={placeholder}
                onChange={handleSearchChange}
                suffix={false}
                onPressEnter={handleSearch}
              />
            )}
          </IntlMessages>
          {!!browserSupportsSpeechRecognition && (
            <>
              {!recordOn ? (
                <span
                  className="searchIcon"
                  onClick={() => {
                    start(startListening);
                  }}
                >
                  <img src={require('assets/svg/Header/voice.svg')} className="voiceSearchImg" />
                </span>
              ) : (
                <span
                  className="searchIcon"
                  onClick={() => {
                    props.abortListening();
                    stop(stopListening, resetTranscript);
                  }}
                >
                  <img src={require('assets/svg/Header/mute.svg')} className="voiceSearchImg" />
                </span>
              )}
            </>
          )}

          <span
            className="searchIconClose"
            onClick={() => {
              setSearchInput({});
              setSearchData([]);
              setRecordOn(false);
              props.onClick();
            }}
          >
            <img src={require('assets/svg/Header/close-white.svg')} alt="" />
          </span>
        </div>
        <div className="searchDropdown">
          <h2>
            <IntlMessages id="app.SearchRecent" />
          </h2>
          <ul>
            {searchData.map((item) => (
              <li
                key={item.label}
                onClick={() => {
                  setSearchInput({ str: item.label, type: item.type, id: item.id });
                  handleSearch();
                }}
                style={{ cursor: 'pointer' }}
              >
                {item.label}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default SpeechRecognition({
  autoStart: false,
  continuous: false,
})(HeaderSearch);
