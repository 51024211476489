import React, { memo, useCallback } from 'react';

import { Tooltip } from 'antd';
import { each, find, flatten, groupBy, includes, last, map, split, uniq } from 'lodash';
import { DateTime } from 'luxon';

import { prepareStoneForBargain } from 'components/common/DiamondListing/QuotePopup';

import { LoaderService } from 'services/LoaderService';
import { StatsService } from 'services/StatsService';
import { StoneService } from 'services/StoneService';
import { Storage } from 'services/Storage';
import UtilService from 'services/util';

import { IntlMessage } from 'util/IntlMessages';
import { callApi } from 'util/call-api';
import { usePathname } from 'util/hooks';
import { notify } from 'util/notify';
import {
  classNames,
  clone,
  formatDate,
  formatDateTime,
  formatDecimal,
  formatNumber,
  hasKey,
  isArray,
  isEmpty,
  isNotEmpty,
  isString,
  logWarn,
  parseDecimal,
  unique,
} from 'util/utils';

import {
  BASE_DOWNLOAD_URL,
  BASE_URL,
  trackTypeObj,
  LOCAL_STORAGE_VAR,
  DIAMOND_WEB_STATUS,
  DIAMONDCONFIRM,
  OFFICE_STATUS,
  OTHER_COUNTRY,
} from 'constants/Common';
import {
  SEARCH_DIAMOND_LIST,
  FETCH_CONFIRM_STONE,
  FETCH_DIAMOND_TRACK,
  CREATE_TRACK,
  UPDATE_TRACK,
  PRINT_PDF,
  EXPORT_EXCEL,
  CONFIRM_STONE,
  DELETE_TRACK,
  CREATE_BID,
  SEND_EMAIL_XRAY,
  CREATE_MATCH_PAIR,
  BID_PAGINATE,
  BID_DELETE,
  UPDATE_BID,
  DOWNLOAD_ZIP,
  DOWNLOAD_FILE,
  NOTE_UPSERT,
  FETCH_NOTES,
  NOTE_DELETE,
  PROJECT_SETTING,
  FETCH_OFFICE_DIAMOND,
  DELETE_OFFICE_DIAMOND,
  CONFIRM_CANCEL,
  DIAMOND_TRACK_STATUS_UPDATE,
  COUNTER_OFFER,
  GET_BLOCK_DIAMONDS,
} from 'constants/apiConstant';
import { PAGES, PAGE_TITLE } from 'constants/paths.const';

import * as TableConfig from './table-config';

import OpenNotification from '../common/CommonButton/OpenNotification';

import diamondListCloseBlackSvg from 'assets/svg/DiamondList/close-black.svg';
import downArrowDisSvg from 'assets/svg/down-arrow-dis.svg';
import downArrowSvg from 'assets/svg/down-arrow.svg';
import layoutSvg from 'assets/svg/layout.svg';
import matchPairSvg from 'assets/svg/match-pair.svg';
import pdfSvg from 'assets/svg/pdf.svg';
import upArrowDisSvg from 'assets/svg/up-arrow-dis.svg';
import upArrowSvg from 'assets/svg/up-arrow.svg';

const FILTER_COLUMNS = TableConfig.FILTER_COLUMNS;
const floatkeys = TableConfig.FLOAT_COLUMNS;
const roundkeys = TableConfig.ROUND_COLUMNS;
const DISPLAY_TOTAL = TableConfig.TOTAL_COLUMNS;

export const prepareStoneTrackResponse = (err, res) => {
  let rows = [];
  let count = 0;
  let sum = {};
  const filterArray = {};
  let seachFilterTag = {};
  let inTrackDiamonds = [];

  if (res && res.code === 'OK') {
    seachFilterTag = res.data?.filter;

    rows = res.data?.list?.map?.((record) => {
      const { diamond, ...track } = record;
      return { ...diamond, track };
    });
    count = res.data?.count;
    sum = res.data?.total[0] ?? {};
    inTrackDiamonds = res.data.inTrackDiamonds;
  }
  return {
    data: rows,
    seachFilterTag,
    sum,
    count,
    filterArray,
    selectedFilterArray: {},
    inTrackDiamonds,
  };
};

export const prepareStonePaginateResponse = (err, res, type) => {
  let rows = [];
  let count = 0;
  let sum = {};
  let seachFilterTag = {};
  const filterArray = {};
  let inTrackDiamonds = [];

  if (res && res.code === 'OK') {
    seachFilterTag = res.data[0].filter;
    rows = res.data[0].diamonds;

    if (type === DIAMOND_WEB_STATUS.UPCOMING) {
      const grp = groupBy(rows, 'inDt');
      rows = [];

      Object.keys(grp).map((x) => {
        grp[x][0].totalDiamonds = grp[x].length;
        rows = [...rows, ...grp[x]];
      });
    }
    sum = res.data[0].total[0] || {};
    count = res.data[0].count;

    FILTER_COLUMNS.map((x) => {
      filterArray[x] = uniq(rows.map((r) => r[x]));
    });
    inTrackDiamonds = res.data.inTrackDiamonds;
  }
  return {
    data: rows,
    seachFilterTag,
    sum,
    count,
    filterArray,
    selectedFilterArray: {},
    inTrackDiamonds,
  };
};

const LOCATION = {
  india: 'IND',
  beligium: 'BEL',
  newyork: 'NY',
  hongkong: 'HK',
  dubai: 'DXB',
};
const NOTUPCOMING = ['amt', 'ctPr', 'back'];

export const MEMO_BLOCK_CODE = 2;
export const MEMO_STATUS = 2;

export const LISTINGPAGES = PAGES;

export const TITLE = PAGE_TITLE;

export function getPath() {
  return window.location.pathname?.split?.('/')?.pop?.();
}

const ColumnFilter = memo((props) => {
  const {
    column: { filterValue = [], setFilter, preFilteredRows, id, isSorted },
  } = props;
  const availableOptions = React.useMemo(() => {
    const options = new Set();

    preFilteredRows.forEach((row) => {
      if (!filterValue.includes(row.values[id])) {
        options.add(row.values[id]);
      }
    });
    return [...options.values()];
  }, [id, preFilteredRows, filterValue]);

  const toggleFilter = useCallback(
    (id) => {
      const selectedFilters = [...filterValue];

      if (selectedFilters.includes(id)) {
        selectedFilters.splice(
          selectedFilters.findIndex((x) => x == id),
          1,
        );
      } else {
        selectedFilters.push(id);
      }
      setFilter(selectedFilters);
    },
    [setFilter, filterValue],
  );

  return (
    <div className="llfilterMenuDropdown" style={isSorted ? { borderTop: '1px solid #e9ebf0' } : {}}>
      {filterValue && filterValue.length > 0 && (
        <ul className="llfilterSelect">
          <div className="llSortingTitle">Selected Filter</div>
          {filterValue.map((x) => {
            return (
              <li key={`columnFilter_${id}${x}`}>
                {x}
                <div className="closeFilter" onClick={() => toggleFilter(x)}>
                  <img src={diamondListCloseBlackSvg} alt="icon" />
                </div>
              </li>
            );
          })}
        </ul>
      )}
      {availableOptions.length ? (
        <ul>
          <div className="llSortingTitle">Select Filter</div>
          {availableOptions.map((x) => {
            return (
              <li key={`columnFilter_${id}${x}`} onClick={() => toggleFilter(x)}>
                {x}
              </li>
            );
          })}
        </ul>
      ) : null}
    </div>
  );
});

// Define a custom filter filter function!
function filterArrayIncludes(rows, id, filterValue = []) {
  if (filterValue.length === 0) return rows;
  return rows.filter((row) => filterValue.includes(row.values[id]));
}
filterArrayIncludes.autoRemove = (val) => !val || !val.length;

export function isMobile() {
  return window.innerWidth <= 767;
}

export function getColumn() {
  const columns = [{ Header: 'Details', accessor: 'Details', id: 'Details' }];
  const master = Storage.get('master', false);
  const storedColumnList = Storage.get('column', false);

  if (!isEmpty(storedColumnList)) {
    const columnList = storedColumnList;

    columnList.forEach((dataa) => {
      const data = {};

      Object.keys(dataa).forEach((x) => (data[x] = dataa[x]));

      if (data.isActive && data.field) {
        data.field = data.field.trim();
        data.title = data.title ? data.title.trim() : data.title;
        data.key = data.key ? data.key.trim() : data.key;

        if (find(columns, { id: data.field })) return;
        if (data.field === 'reminderDate' && getPath() !== LISTINGPAGES.REMINDER) return false;

        const remarksList = [LISTINGPAGES.ENQUIRY, LISTINGPAGES.NOTE];

        if (data.field === 'remarks' && !remarksList.includes(getPath())) return false;
        if (data.field === 'remarks' && remarksList.includes(getPath()))
          data.title = getPath() === LISTINGPAGES.NOTE ? 'Notes' : LISTINGPAGES.ENQUIRY ? 'Remarks' : 'Enquiry';

        if (data.field === 'status' && getPath() !== LISTINGPAGES.OFFICE) return false;
        if (data.field === 'status' && getPath() === LISTINGPAGES.OFFICE) data.title = 'Status';

        const createdAtList = [LISTINGPAGES.CART, LISTINGPAGES.WATCHLIST, LISTINGPAGES.NOTE];

        if (data.field === 'createdAt' && !createdAtList.includes(getPath())) return false;

        if (data.field === 'createdAt' && createdAtList.includes(getPath())) data.title = 'Date';

        if (NOTUPCOMING.includes(data.field) && getPath() === LISTINGPAGES.UPCOMING) return false;
        const canFilter = FILTER_COLUMNS.includes(data.field);
        if (data.field === 'back' && getPath() === LISTINGPAGES.WATCHLIST) data.title = 'Disc%';

        columns.push({
          ...data,
          Header: data.title,
          accessor: data.field,
          id: data.field,
          desc: data.desc,
          link: data.link,
          sort: data.sort,
          Cell({ row, cell }) {
            const content = React.useMemo(() => {
              const fullName = find(master?.[data?.key], { code: cell?.value })?.description ?? undefined;

              if (data.field === 'back' && getPath() === LISTINGPAGES.WATCHLIST) {
                return row.original.back !== row.original.prvBack && row.original.prvBack ? (
                  <div
                    className={`newDiscount ${
                      row.original.back < 0
                        ? row.original.back > row.original.prvBack
                          ? `red`
                          : `green`
                        : row.original.back < row.original.prvBack
                        ? `green`
                        : `red`
                    }`}
                  >
                    <span>{formatDecimal(row.original.back)}</span> | <del>{formatDecimal(row.original.prvBack)} </del>
                  </div>
                ) : (
                  <div style={{ textAlign: 'center' }}>
                    <span>{formatDecimal(row.original.back)}</span>
                  </div>
                );
              }

              if (data.field === 'ratio' && row.original.shpNm === 'ROUND') return '-';

              if (data.field === 'vStnId') {
                return cell.value;
                // if (!isEmpty(row.original.layoutNo) && `${row.original.layoutNo}` !== '0') {
                //   return (
                //     <div style={{ position: 'relative' }}>
                //       {cell.value}{' '}
                //       <Tooltip title="Layout">
                //         <img style={{ width: '20px', height: '20px', marginLeft: '5px' }} src={layoutSvg} alt="" />
                //       </Tooltip>
                //     </div>
                //   );
                // }

                // if (!isEmpty(row.original?.pairStkNo)) {
                //   return (
                //     <div style={{ position: 'relative' }}>
                //       {cell.value}{' '}
                //       <Tooltip title="Match pair">
                //         <img style={{ width: '20px', height: '20px', marginLeft: '5px' }} src={matchPairSvg} alt="" />
                //       </Tooltip>
                //     </div>
                //   );
                // }
              }

              if (data.field === 'countryNm')
                return OTHER_COUNTRY.includes(row?.original?.countryNm) ? 'Other' : row?.original?.countryNm;

              if (data.field === 'ratio' && row.original.shpNm === 'ROUND') return '-';

              // if (data.field === 'shpNm') return isEmpty(cell.value) ? '-' : cell.value;
              if (data.field === 'colNm') {
                return (
                  <div className="normalTableDropdown">
                    <span className="tableDropdownTitle">
                      {/* {row.original.isFcCol ? row.original.fcColNm : row.original.colNm} */}
                      {/* <Tooltip
                        className="tool"
                        title={[row.original.fcColNm, row.original.intenNm, row.original.ovrtnNm]
                          .filter(isNotEmpty)
                          .join(' | ')}
                      > */}
                      {row.original.isFcCol ? row.original.fcColDesc : row.original.colNm}
                      {/* </Tooltip> */}
                    </span>
                  </div>
                );
              }

              if (data.field === 'dna') return 'DNA';

              if (data.isFlag) {
                return cell.value
                  ? typeof data.sLabel === 'string'
                    ? data.sLabel
                    : 'Yes'
                  : typeof data.nLabel === 'string'
                  ? data.nLabel
                  : '-';
              }
              if (cell.value === true) return 'Y';

              if (isEmpty(cell.value)) return '-';

              if (fullName) {
                return (
                  <Tooltip key={cell.row.id + cell.column.id} title={fullName}>
                    {cell.value}
                  </Tooltip>
                );
              }

              if (data.field === 'rap') return `$${formatDecimal(cell.value)}`;
              if (data.field === 'amt') return `$${formatDecimal(cell.value)}`;
              if (data.field === 'ctPr') return `$${formatDecimal(cell.value)}`;
              if (data.field === 'back') return `${formatDecimal(cell.value)}%`;
              // if (TableConfig.CURRENCY_COLUMNS.includes(data.field)) {
              //   return formatCurrency(cell.value, {
              //     fractionLength: TableConfig.FLOAT_COLUMNS.includes(data.field)
              //       ? FRACTION_LENGTH
              //       : TableConfig.ROUND_COLUMNS.includes(data.field)
              //       ? 0
              //       : undefined,
              //   });
              // }
              if (TableConfig.FLOAT_COLUMNS.includes(data.field)) return formatDecimal(cell.value);
              if (TableConfig.ROUND_COLUMNS.includes(data.field)) return formatNumber(Math.round(cell.value));

              // moment(row.original.reminderDate).format('ll');
              if (data.field === 'reminderDate')
                return DateTime.fromIso(row.original.reminderDate).toLocaleString(DateTime.DATE_FULL);

              // if (row.original.isFcCol && data.field === 'colNm') {
              //   return (
              //     <div className="normalTableDropdown">
              //       <span className="tableDropdownTitle">{row.original.fcColNm ? row.original.fcColNm : `-`}</span>
              //     </div>
              //   );
              // }
              if (data.field === 'locNm')
                return (
                  LOCATION[['IND-RC', 'ST'].includes(cell.value) ? 'india' : cell.value.trim().toLowerCase()] ||
                  cell.value
                );

              if (data.field === 'lbNm' && cell.value === 'NONE') return <p>NONE</p>;

              if (getPath() === LISTINGPAGES.OFFICE && data.field === 'status') {
                return (
                  <div className={`activeStatus ${cell.value === 'REJECTED' && `red`}`}>
                    <span>{cell.value}</span>
                  </div>
                );
              }

              return cell.value;
            }, [cell.column, cell.row, cell.value, row.original]);

            return (
              <div
                className={classNames([
                  data.field !== 'dna' &&
                    (floatkeys.includes(data.field) || roundkeys.includes(data.field)) &&
                    'numberValue',
                  (isEmpty(content) || ['-'].includes(content)) && 'text-center',
                ])}
              >
                {content}
              </div>
            );
          },
          Filter: canFilter ? ColumnFilter : <></>,
          filter: filterArrayIncludes,
        });

        if (data.field === 'back' && getPath() === LISTINGPAGES.WATCHLIST) {
          // Columns.push({
          //   Header: 'Today Disc%',
          //   accessor: 'back',
          //   id: 'back',
          //   Cell({ cell }) {
          //     const value = cell.value ? parseFloat(cell.value).toFixed(2) : '-';
          //     return <div className={classNames([['-'].includes(value) && 'text-center'])}>{value}</div>;
          //   },
          // });
          // _columns.push({
          //   Header: 'Watchlist Disc%',
          //   accessor: 'trackDiscount',
          //   id: 'trackDiscount',
          //   Cell({ cell }) {
          //     const value = cell.value ? parseFloat(cell.value).toFixed(2) : '-';
          //     return <div className={classNames([['-'].includes(value) && 'text-center'])}>{value}</div>;
          //   },
          // });
          columns.push({
            Header: 'Expected Disc%',
            accessor: 'newDiscount',
            id: 'newDiscount',
            Cell({ cell }) {
              const value = cell.value ? parseFloat(cell.value).toFixed(2) : '-';
              return <div className={classNames([['-'].includes(value) && 'text-center'])}>{value}</div>;
            },
          });

          // columns.push({
          //   accessor: '**',
          //   id: '**',
          //   Header: (
          //     <>
          //       <img width="12px" height="12px" src={upArrowSvg} alt="Up Arrow" />
          //       /
          //       <img width="12px" height="12px" src={downArrowSvg} alt="Down Arrow" />
          //     </>
          //   ),

          //   Cell: ({ row }) => {
          //     let discUpDown = '-';

          //     if (row.original.back < 0 && row.original.prvBack) {
          //       if (row.original.back > row.original.prvBack) {
          //         discUpDown = <img width="12px" height="12px" src={upArrowDisSvg} alt="Up Arrow" />;
          //       } else {
          //         discUpDown = <img width="12px" height="12px" src={downArrowDisSvg} alt="Down Arrow" />;
          //       }
          //     } else if (row.original.back > 0 && row.original.prvBack) {
          //       if (row.original.back < row.original.prvBack) {
          //         discUpDown = <img width="12px" height="12px" src={downArrowSvg} alt="Down Arrow" />;
          //       } else {
          //         discUpDown = <img width="12px" height="12px" src={upArrowSvg} alt="Up Arrow" />;
          //       }
          //     }
          //     return discUpDown;
          //   },
          // });
        }

        if (data.field === 'status' && getPath() === LISTINGPAGES.OFFICE) {
          columns.push({
            Header: 'Notes',
            accessor: 'purpose',
            id: '__purpose',
          });
        }

        if (data.field === 'fluNm' && getPath() === LISTINGPAGES.CONFIRM) {
          columns.push({
            Header: 'Remarks',
            accessor: 'comment',
            id: 'comment',
          });
        }

        if (data.field === 'vStnId' && getPath() === LISTINGPAGES.PURCHASE) {
          // columns.push({
          //   Header: 'Invoice',
          //   accessor: 'invoice',
          //   id: '___invoice',
          //   Cell({ row }) {
          //     return hasKey(row.original, 'invoice') ? (
          //       <img
          //         alt=""
          //         onClick={(e) => {
          //           e.stopPropagation();
          //           const obj = {
          //             path: `${BASE_URL}${row.original.invoice.filePath}`,
          //             fileName: `invoice_${row.original.memoNo}`,
          //             ext: '.pdf',
          //           };
          //           downloadFile(obj);
          //         }}
          //         src={pdfSvg}
          //       />
          //     ) : (
          //       ' - '
          //     );
          //   },
          // });
        }
      }
    });
  }

  if (getPath() === PAGES.NOTE) {
    const remarksColumnsIndex = columns.findIndex((col) => col.field === 'remarks');
    const remarksColumn = columns[remarksColumnsIndex];
    columns.splice(remarksColumnsIndex, 1);

    const createdAtColumnsIndex = columns.findIndex((col) => col.field === 'createdAt');
    const createdAtColumn = columns[createdAtColumnsIndex];
    columns.splice(createdAtColumnsIndex, 1);

    const fluNmColumnIndex = columns.findIndex((col) => col.field === 'fluNm');
    columns.splice(fluNmColumnIndex + 1, 0, remarksColumn);
    columns.splice(fluNmColumnIndex + 2, 0, createdAtColumn);
  }

  if (getPath() === PAGES.ENQUIRY) {
    const remarksColumnsIndex = columns.findIndex((col) => col.field === 'remarks');
    const remarksColumn = columns[remarksColumnsIndex];
    columns.splice(remarksColumnsIndex, 1);
    const fluNmColumnIndex = columns.findIndex((col) => col.field === 'fluNm');
    columns.splice(fluNmColumnIndex + 1, 0, remarksColumn);
  }

  if (localStorage.getItem(`${LOCAL_STORAGE_VAR}-guest`)) {
    return columns.filter((el) => !['back', 'amt', 'ctPr'].includes(el.id));
  }

  return columns.filter(isNotEmpty);
}

export function handleAlter(array, item, key) {
  let checked = clone(array) || [];
  if (find(checked, { [key]: item[key] })) checked = checked.filter((el) => el[key] !== item[key]);
  else checked.push(item);
  return checked;
}

export const getParams = (stone) =>
  [stone.colNm, stone.clrNm, stone.cutNm, stone.polNm, stone.symNm, stone.fluNm].filter(isNotEmpty);

export function getStoneInfo(props_row) {
  const params = [
    props_row.vStnId || props_row.stoneId,
    props_row.shpNm,
    parseFloat(props_row.crt).toFixed(2),
    props_row.isFcCol ? props_row.fcColNm : props_row.colNm,
    props_row.clrNm,
    props_row.cutNm,
    props_row.polNm,
    props_row.symNm,
    props_row.fluNm,
  ].filter((el) => {
    return el && el;
  });
  return params;
}

export function getStoneTitle(list) {
  const stonePropList = list.map((stone) => ({
    shp: stone.shpNm ?? 'OTHER',
    crt: parseDecimal(stone.crt, 2),
    col: stone.isFcCol ? stone.fcColNm : stone.colNm,
    clr: stone.clrNm,
    cut: stone.cutNm,
    pol: stone.polNm,
    sym: stone.symNm,
  }));

  const firstStoneProp = stonePropList[0];
  const display = Object.keys(firstStoneProp)
    .map((key) => {
      const isPropSame = stonePropList.every((stone_) => stone_[key] === firstStoneProp[key]);
      return isPropSame ? firstStoneProp[key] : undefined;
    })
    .filter(isNotEmpty);
  return !isEmpty(display) ? display.join(' ') : '-';
}

export async function compareStones(list) {
  if (!isArray(list)) return;
  if (list.length < 1) return notify.error({ message: 'Please, select stone(s) to compare.' });
  if (list.length < 2) return notify.error({ message: 'Please, select minimum two stones to compare.' });
  if (list.length > 15) return notify.error({ message: 'Maximum 15 stones can be compared.' });

  const data = list.map((stone) => stone.diamondId ?? stone.id ?? stone.vStnId);

  Storage.set('compare-diamond', unique(data));
  Storage.set('prev-location', `${window.location.pathname}${window.location.search}`);

  window.location.pathname === '/account/confirm-list'
    ? Storage.set('confirm-compare', true)
    : Storage.delete('confirm-compare');
  await new Promise((r) => setTimeout(r, 100));
  window.open('/compare', 'dn-compare-stone');
}

// fetchDiamondList
export function fetchDiamondList(state, filters, cb) {
  const myParam = `${split(window.location.search, '?')[2]}`;
  const obj = SEARCH_DIAMOND_LIST;

  obj.request = {
    filters,
    isAppendMasters: true,
    page: state.page,
    limit: state.limit,
    sort: state.sort,
    isSkipSave: true,
    isHideSuggestedDiamond: myParam === 'collection=true',
  };
  return UtilService.callApi(obj, (err, data) => {
    let rows = [];
    let count = 0;
    let sum = {};
    let seachFilterTag = {};
    let inTrackDiamonds = [];
    const inBlockDiamonds = data.data[0].inBlockDiamonds || [];
    const filterArray = {};
    let diamondNotFound;

    if (data && data.code === 'OK') {
      seachFilterTag = data.data[0].filter;
      rows = data.data[0].diamonds;
      sum = data.data[0].total[0];
      count = data.data[0].count;
      diamondNotFound = data.data[0]?.diamondNotFound;

      FILTER_COLUMNS.forEach((x) => {
        filterArray[x] = uniq(rows.map((r) => r[x]));
      });
      inTrackDiamonds = data.data[0].inTrackDiamonds;

      if (!isEmpty(inBlockDiamonds)) {
        rows.forEach((r) => {
          if (includes(map(flatten(map(inBlockDiamonds, 'diamonds')), 'id'), r.id)) {
            r.isBlockedStone = true;
          }
        });
      }
    }

    void cb?.({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
      inBlockDiamonds,
      diamondNotFound,
    });
  });
}

export const fetchBlockDiamonds = (state, blockType, status, cb) => {
  const obj = {
    ...GET_BLOCK_DIAMONDS,
    request: {
      sort: state?.sort,
      blockType,
      status,
    },
  };

  UtilService.callApi(obj, (err, res) => {
    let rows = [];
    let count = 0;
    let sum = {};
    let seachFilterTag = {};
    let inTrackDiamonds = [];
    let inBlockDiamonds = [];
    const filterArray = {};

    if (res?.code === 'OK') {
      count = res.data.count;
      sum = res.data.total ? res.data.total[0] : {};
      seachFilterTag = res.data.filter ? res.data.filter : {};
      inTrackDiamonds = res.data.inTrackDiamonds ? res.data.inTrackDiamonds : [];
      inBlockDiamonds = res.data.inBlockDiamonds ? res.data.inBlockDiamonds : [];

      rows =
        res.data?.list?.map?.((detail) => ({ _original: detail, ...detail, ...detail.diamond, ids: detail.id })) ?? [];
    }

    void cb?.({
      res,
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
      inBlockDiamonds,
    });
  });
};

export function fetchStoneOfDay(state, filters, cb) {
  const obj = {
    ...SEARCH_DIAMOND_LIST,
  };

  obj.request = {
    filters,
    isHideSuggestedDiamond: true,
    page: state.page,
    limit: state.limit,
    sort: state.sort,
    isAppendMasters: true,
  };
  return UtilService.callApi(obj, (err, data) => {
    let rows = [];
    let count = 0;
    let sum = {};
    const seachFilterTag = {};
    let inTrackDiamonds = [];
    const filterArray = {};

    if (data && data.code === 'OK') {
      sum = data.data[0].total[0];
      rows = data.data[0].diamonds;
      count = data.data[0].count;

      FILTER_COLUMNS.map((x) => {
        filterArray[x] = uniq(rows.map((r) => r[x]));
      });
      inTrackDiamonds = data.data[0].inTrackDiamonds;
    }

    void cb?.({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
}

export function handleExclusiveSearchListId(cb) {
  const searchAPI = SEARCH_DIAMOND_LIST;
  const searchAPIObj = {
    filters: [
      {
        or: [
          { crt: { '>=': 5.0, '<=': 5.99 } },
          { crt: { '>=': 6.0, '<=': 9.99 } },
          { crt: { '>=': 10.0, '<=': 19.99 } },
          { crt: { '>=': 20.0, '<=': 100 } },
        ],
      },
    ],
    isNotReturnTotal: true,
    isReturnCountOnly: true,
  };

  const objData = { ...searchAPI, request: searchAPIObj };
  return UtilService.callApi(objData, (err, data) => {
    if (data && data.code === 'OK') {
      void cb?.(data && data.code === 'OK' ? data.data[0].filter.searchData : null);
    } else void cb?.(null);
  });
}

export function fetchOffer(state, cb) {
  const obj = {
    method: FETCH_DIAMOND_TRACK.method,
    url: FETCH_DIAMOND_TRACK.url,
    request: {
      page: state.page,
      limit: state.limit,
      trackType: trackTypeObj.QUOTE,
      sort: state.sort,
    },
  };
  return UtilService.callApi(obj, (err, data) => {
    let rows = [];
    let count = 0;
    let sum = {};
    const seachFilterTag = {};
    const filterArray = {};
    let inTrackDiamonds = [];

    if (data && data.code === 'OK') {
      rows = data.data.list.map((x) => ({
        ...x,
        ...x.diamond,
        otherRemarks: x.otherRemarks,
        bargainTrack: x.bargainTrack,
        remarks: x.remarks,
        newDiscount: x.newDiscount,
        newAmount: x.newAmount,
        newPricePerCarat: x.newPricePerCarat,
        offerStatus: x.offerStatus,
        updatedAt: x.updatedAt,
        trackId: x.id,
        offerValidDate: x.offerValidDate,
        grpBy: x.updatedAt.split('T')[0],
      }));
      const grp = groupBy(rows, 'grpBy');
      rows = [];

      Object.keys(grp).forEach((key) => {
        grp[key][0].isOfferHeader = true;
        rows = [...rows, ...grp[key]];
      });
      sum = data.data.total[0];
      count = data.data.count;

      FILTER_COLUMNS.forEach((x) => {
        filterArray[x] = uniq(rows.map((r) => r[x]));
      });
      inTrackDiamonds = data.data.inTrackDiamonds;
    }

    void cb?.({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
}

export function fetchBid(state, configId = undefined, subTypeCode = undefined, status, cb, historyTab) {
  const obj = BID_PAGINATE;

  obj.request = {
    page: state.page,
    limit: state.limit,
    sort: state.sort,
    status,
    from: state.date.from ? state.date.from.toISOString() : null,
    to: state.date.to ? state.date.to.toISOString() : null,
  };

  if (!historyTab) {
    obj.request = {
      ...obj.request,
      bidConfigurationId: configId,
      subTypeCode,
    };
  }
  return UtilService.callApi(obj, (err, data) => {
    let rows = [];
    let count = 0;
    let sum = {};
    const seachFilterTag = {};
    let inTrackDiamonds = [];
    const filterArray = {};

    if (data && data.code === 'OK') {
      rows = data.data.list.map((x) => ({
        ...x.diamond,
        trackId: x.id,
        bidAmount: x.bidAmount,
        bidDiscount: x.bidDiscount,
        bidPricePerCarat: x.bidPricePerCarat,
        bidType: x.bidType,
        status: x.status,
        updatedAt: x.updatedAt,
        grpBy: x.updatedAt.split('T')[0],
        isLowerBid: x.isLowerBid,
      }));

      const grp = groupBy(rows, 'grpBy');
      rows = [];

      Object.keys(grp).map((grpNo) => {
        const val = grp[grpNo];
        val[0].isBidHeader = true;
        val[0].groupingTitle = formatDate(val[0].updatedAt);
        rows = [...rows, ...val];
      });
      sum = data.data.total[0];
      count = data.data.count;
      inTrackDiamonds = data.data.inTrackDiamonds;
    }

    void cb?.({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
}

export function fetchMatchPair(state, diamondSearchId, cb) {
  const config = {
    ...CREATE_MATCH_PAIR,
    request: {
      filter: { diamondSearchId },
      page: state.page,
      limit: state.limit,
      sort: state.sort,
      isPredefinedPair: true,
    },
  };

  return UtilService.callApi(config, (err, res) => {
    let rows = [];
    let count = 0;
    let sum = {};
    const seachFilterTag = {};
    let inTrackDiamonds = [];
    const filterArray = {};

    if (res && res.code === 'OK') {
      const list = StoneService.formatStoneList(res.data.list);
      const groupMap = groupBy(
        list.map((record) => ({ ...record, _groupKey_: record.pairStkNo })),
        'pairStkNo',
      );

      Object.keys(groupMap).forEach((grpNo) => {
        const records = groupMap[grpNo];

        records[0].isHeader = true;
        records[0].groupCheckBox = true;

        records.forEach((record) => {
          record._groupKey_ = record.pairStkNo;
          record.groupTitle = getStoneTitle(records);
        });

        records[0].isMatchHeader = records[0].isHeader;
        records[0].groupingTitle = records[0].groupTitle;
        records[0].totalDiamonds = records.length;
        records[records.length - 1].groupData = {};
        records[records.length - 1].isMatchFooter = true;

        DISPLAY_TOTAL.forEach((i) => {
          records[records.length - 1].groupData[i] = [];

          records.map((x) => {
            if (x[i]) records[records.length - 1].groupData[i].push(x[i]);
          });
        });
        rows = [...rows, ...records];
      });
      sum = res.data.total[0];
      count = res.data.count;
      inTrackDiamonds = res.data.inTrackDiamonds;
    }

    void cb?.({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
}

export function fetchPreDefineMatchPair(state, diamondSearchId, cb) {
  const obj = CREATE_MATCH_PAIR;

  obj.request = {
    filter: { diamondSearchId },
    page: state.page,
    // limit: state.limit,
    sort: state.sort,
    // layoutNo: "",
    // isPredefinedPair: true,
  };
  return UtilService.callApi(obj, (err, res) => {
    let rows = [];
    let count = 0;
    let sum = {};
    const seachFilterTag = {};
    let inTrackDiamonds = [];
    const filterArray = {};

    if (res && res.code === 'OK') {
      if (!isEmpty(res.data.list)) {
        const list = StoneService.formatStoneList(res.data.list);
        const groupMap = groupBy(
          list.map((record) => ({ ...record, _groupKey_: record.groupNo })),
          'groupNo',
        );

        Object.keys(groupMap).map((grpNo) => {
          const records = groupMap[grpNo];

          records[0].isHeader = true;
          records[0].groupCheckBox = true;
          records[0].groupTitle = getStoneTitle(records);

          records[0].isMatchHeader = records[0].isHeader;
          records[0].groupingTitle = records[0].groupTitle;
          records[0].totalDiamonds = records.length;

          records[records.length - 1].groupData = {};
          records[records.length - 1].isMatchFooter = true;

          DISPLAY_TOTAL.map((i) => {
            records[records.length - 1].groupData[i] = [];

            records.map((x) => {
              if (x[i]) records[records.length - 1].groupData[i].push(x[i]);
              return true;
            });
            return true;
          });

          rows = [...rows, ...records];
        });
        sum = res.data.total[0];
        inTrackDiamonds = res.data.inTrackDiamonds;
      }
      count = res.data.count;
    }

    void cb?.({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
}

export function fetchConfirmStone(state, purchase, cb) {
  const obj = {
    ...FETCH_CONFIRM_STONE,
    request: { page: state.page, limit: state.limit, sort: state.sort },
  };

  obj.request.filter = {
    status: purchase ? DIAMONDCONFIRM.STATUS.ACCEPTED : DIAMONDCONFIRM.STATUS.PENDING,
  };

  UtilService.callApi(obj, (err, res) => {
    const sum = {};
    let rows = [];
    let count = 0;
    let seachFilterTag = {};
    let inTrackDiamonds = [];
    const filterArray = {};

    if (res && res.code === 'OK') {
      rows = (() => {
        const stoneWiseList = res.data.list
          .map(({ memoDetails, ...record }) =>
            memoDetails.map((memoDetail) => ({
              _original: { ...record },
              ...record,
              ...memoDetail,
              _groupKey_: record?.memoNo,
              selectionKey: memoDetail?.id,
              totalDiamonds: memoDetails?.length ?? 0,
            })),
          )
          .flat();

        const groups = groupBy(stoneWiseList, '_groupKey_');
        const grouppedList = Object.entries(groups).map(([key, records]) =>
          records.map((record, index) => {
            const isHeader = index === 0;
            const groupTitle = `Order No.: ${key}`;
            return { ...record, isHeader, groupTitle };
          }),
        );

        const output = grouppedList.flat();
        return output;
      })();

      count = res.data.count;
      seachFilterTag = res.data.filter;
      inTrackDiamonds = res.data.inTrackDiamonds;

      FILTER_COLUMNS.forEach((x) => {
        filterArray[x] = uniq(rows.map((r) => r[x]));
      });
    }

    cb({
      list: res.data.list ?? [],
      data: rows.map(prepareStoneForBargain),
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
}

export function fetchCart(state, cb) {
  const obj = FETCH_DIAMOND_TRACK;

  obj.request = {
    page: state.page,
    limit: state.limit,
    trackType: trackTypeObj.CART,
    sort: state.sort,
  };
  return UtilService.callApi(obj, (err, data) => {
    let rows = [];
    let count = 0;
    let sum = {};
    const seachFilterTag = {};
    const filterArray = {};
    let inTrackDiamonds = [];

    if (data && data.code === 'OK') {
      rows = data.data.list.map((x) => ({
        ...x.diamond,
        createdAt: x.createdAt ? formatDateTime(x.createdAt) : '-',
      }));
      sum = data.data.total[0];
      count = data.data.count;

      FILTER_COLUMNS.map((x) => {
        filterArray[x] = uniq(rows.map((r) => r[x]));
      });
      inTrackDiamonds = data.data.inTrackDiamonds;
    }

    void cb?.({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
}

export function fetchOfficeList(state, cb) {
  const obj = FETCH_OFFICE_DIAMOND;

  obj.request = {
    page: state.page,
    limit: state.limit,
    sort: [{ date: 'ASC' }],
    //type: 1,
  };
  return UtilService.callApi(obj, (err, data) => {
    let rows = [];
    let count = 0;
    let sum = {};
    const seachFilterTag = {};
    const filterArray = {};
    let inTrackDiamonds = [];

    if (data && data.code === 'OK') {
      const grp = data.data.list.filter((x) => x.diamonds && x.diamonds.length);

      each(grp, (x) => {
        const val = x.diamonds;

        val[0] = {
          ...val[0],
          isOfficeHeader: true,
          cabinSlot: x.cabinSlot,
          date: x.date,
          meetingType: x.meetingType,
        };

        each(val, (all) => {
          all.slottId = x.id;
          all.status = (x.status && OFFICE_STATUS[x.status]) || 'Pending';
          all.purpose = x.purpose;
        });
        rows = [...rows, ...val];
      });

      count = data.data.count;
      sum = data.data.total;

      FILTER_COLUMNS.map((x) => {
        filterArray[x] = uniq(rows.map((r) => r[x]));
      });
      inTrackDiamonds = data.data.inTrackDiamonds;
    }

    void cb?.({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
}

export function fetchWatch(state, cb) {
  const obj = FETCH_DIAMOND_TRACK;

  obj.request = {
    page: state.page,
    limit: state.limit,
    trackType: trackTypeObj.WATCHLIST,
    sort: state.sort,
  };
  return UtilService.callApi(obj, (err, data) => {
    let rows = [];
    let count = 0;
    let sum = {};
    const seachFilterTag = {};
    const filterArray = {};
    let inTrackDiamonds = [];

    if (data && data.code === 'OK') {
      rows = data.data.list.map((x) => ({
        ...x.diamond,
        newDiscount: x.newDiscount,
        trackDiscount: x.trackDiscount,
        createdAt: x.createdAt ? formatDateTime(x.createdAt) : '-',
      }));
      sum = data.data.total[0];
      count = data.data.count;

      FILTER_COLUMNS.map((x) => {
        filterArray[x] = uniq(rows.map((r) => r[x]));
      });
      inTrackDiamonds = data.data.inTrackDiamonds;
    }

    void cb?.({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
}

export function fetchNotes(state, cb) {
  const obj = FETCH_NOTES;

  obj.request = {
    page: state.page,
    limit: state.limit,
    isAppendDiamond: 1,
    sort: state.sort,
  };
  return UtilService.callApi(obj, (err, data) => {
    let rows = [];
    let count = 0;
    let sum = {};
    const seachFilterTag = {};
    const filterArray = {};
    let inTrackDiamonds = [];

    if (data && data.code === 'OK') {
      rows = data.data.list.map((x) => ({
        ...x.diamond,
        remarks: x.remarks,
        trackId: x.id,
        createdAt: x.createdAt ? formatDateTime(x.createdAt) : '-',
      }));
      sum = data.data.total[0];
      count = data.data.count;

      FILTER_COLUMNS.map((x) => {
        filterArray[x] = uniq(rows.map((r) => r[x]));
      });
      inTrackDiamonds = data.data.inTrackDiamonds;
    }

    void cb?.({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
}

export function fetchEnquiries(state, cb) {
  const obj = FETCH_DIAMOND_TRACK;

  obj.request = {
    page: state.page,
    limit: state.limit,
    isAppendDiamond: 1,
    sort: state.sort,
    trackType: trackTypeObj.ENQUIRY,
  };

  return UtilService.callApi(obj, (err, data) => {
    let rows = [];
    let count = 0;
    let sum = {};
    const seachFilterTag = {};
    const filterArray = {};
    let inTrackDiamonds = [];

    if (data && data.code === 'OK') {
      rows = data.data.list.map((x) => ({
        remarks: x.status == 2 ? x.closedRemarks : x.remarks,
        ...x.diamond,
        trackId: x.id,
        createdAt: x.createdAt ? formatDateTime(x.createdAt) : '-',
        enqStatus: x.status,
      }));
      sum = data.data.total[0];
      count = data.data.count;

      FILTER_COLUMNS.map((x) => {
        filterArray[x] = uniq(rows.map((r) => r[x]));
      });
      const grp = groupBy(rows, 'remarks');
      rows = [];

      Object.keys(grp).forEach((x) => {
        grp[x][0].isEnquiryRemarks = true;
        rows = [...rows, ...grp[x]];
      });

      inTrackDiamonds = data.data.inTrackDiamonds;
    }

    void cb?.({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
}

export function fetchReminder(state, cb) {
  const obj = FETCH_DIAMOND_TRACK;

  obj.request = {
    page: state.page,
    limit: state.limit,
    trackType: trackTypeObj.REMINDER,
    sort: state.sort,
  };
  return UtilService.callApi(obj, (err, data) => {
    let rows = [];
    let count = 0;
    let sum = {};
    const seachFilterTag = {};
    const filterArray = {};
    let inTrackDiamonds = [];

    if (data && data.code === 'OK') {
      rows = data.data.list.map((x) => ({
        ...x.diamond,
        reminderDate: x.reminderDate,
      }));
      sum = data.data.total[0];
      count = data.data.count;

      FILTER_COLUMNS.map((x) => {
        filterArray[x] = uniq(rows.map((r) => r[x]));
      });
      inTrackDiamonds = data.data.inTrackDiamonds;
    }

    void cb?.({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
}

//fetchNewArrivalDiamondPaginate
export function fetchNewArrivalDiamondPaginate(state, filters, type, cb) {
  const obj = SEARCH_DIAMOND_LIST;

  obj.request = {
    filters: filters,
    isAppendMasters: true,
    page: state.page,
    limit: state.limit,
    sort: state.sort,
    viewType: type.viewType,
  };
  return UtilService.callApi(obj, (err, data) => {
    let rows = [];
    let count = 0;
    let sum = {};
    let seachFilterTag = {};
    let inTrackDiamonds = [];
    const filterArray = {};

    if (data && data.code === 'OK') {
      seachFilterTag = data.data[0].filter;
      rows = data.data[0].diamonds;
      sum = data.data[0].total[0];
      count = data.data[0].count;

      FILTER_COLUMNS.map((x) => {
        filterArray[x] = uniq(rows.map((r) => r[x]));
      });
      inTrackDiamonds = data.data[0].inTrackDiamonds;
    }

    void cb?.({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
}

export function fetchDiamondPaginate(state, type, cb, filtr = {}) {
  const obj = SEARCH_DIAMOND_LIST;
  obj.request = { filters: [filtr], viewType: type.viewType, ...state };

  if (type === DIAMOND_WEB_STATUS.UPCOMING) {
    obj.request.sort = [{ inDt: 'ASC' }, ...state.sort];
    obj.request.isUpcoming = true;
  }
  callApi(obj, (err, res) => void cb?.(prepareStonePaginateResponse(err, res, type)));
}

export const handleInsertTrack = (trackType, selected, cb, date, remarks) => {
  const diamonds = [];

  selected.map((s) =>
    diamonds.push({
      diamond: s.id,
      trackPricePerCarat: s.ctPr,
      trackAmount: s.amt,
      newDiscount: trackType === trackTypeObj.WATCHLIST ? s.expBack : null,
      trackDiscount: trackType === trackTypeObj.WATCHLIST ? s.back : null,
    }),
  );
  const obj = {
    reminderDate: date,
    trackType,
    diamonds,
    remarks,
  };
  const objData = {
    ...CREATE_TRACK,
    request: obj,
  };
  return UtilService.callApi(objData, (err, data) => {
    const flag =
      trackType !== trackTypeObj.REMINDER
        ? true
        : data &&
          data.data &&
          data.data.created &&
          data.data.created.length === 0 &&
          data.data.exists &&
          data.data.exists.length !== 0
        ? false
        : true;

    if (data && data.code === 'OK' && flag) {
      void cb?.(true);
      OpenNotification({ type: 'success', title: data.message });
    } else {
      OpenNotification({ type: 'error', title: data ? data.data.message : err && err.data ? err.data.message : '' });
      void cb?.(false);
    }
    StatsService.setDashboardStats();
  });
};

export function handleNoteUpsert(selected, cb) {
  const obj = NOTE_UPSERT;

  obj.request = {
    diamonds: selected.map((d) => ({ diamond: d.id, remarks: d.note.trim() })),
  };
  return UtilService.callApi(obj, (err, data) => {
    if (data && data.code === 'OK') {
      OpenNotification({ type: 'success', title: data.message });
      void cb?.(true);
    } else {
      OpenNotification({ type: 'error', title: data ? data.data.message : err && err.data ? err.data.message : '' });
      void cb?.(false);
    }
  });
}

export const handleUpsertTrack = (trackType, selected, inTrackDiamonds, cb, date) => {
  const ids = selected.map((x) => x.id);
  const id = [];
  const diamonds = find(inTrackDiamonds, { _id: trackType }) ? find(inTrackDiamonds, { _id: trackType }).diamonds : [];

  if (diamonds) {
    each(ids, (diamondId) => {
      const trackId = find(diamonds, { id: diamondId });
      if (trackId) id.push(find(diamonds, { id: diamondId }).trackId);
    });
  }
  const obj = {
    reminderDate: date,
    trackType,
    id,
  };
  const objData = {
    ...UPDATE_TRACK,
    request: obj,
  };
  return UtilService.callApi(objData, (err, data) => {
    if (data && data.code === 'OK') {
      void cb?.(true);
      return OpenNotification({ type: 'success', title: data.message });
    } else {
      OpenNotification({ type: 'error', title: data ? data.message : err && err.data ? err.data.message : '' });
      void cb?.(false);
    }
  });
};

export const handleMemoPrint = (filter, orderDiamond, cb) => {
  let obj = {
    orderDiamond,
  };

  if (isEmpty(orderDiamond)) {
    obj = { filter };
  }
  const objData = {
    ...PRINT_PDF,
    request: obj,
  };
  return UtilService.callApi(objData, async (err, data) => {
    if (err) throw err;

    if (data && data.code === 'OK') {
      const server = BASE_DOWNLOAD_URL;
      const file_path = server + data.data;
      window.open(file_path, 'Download');
    }
  });
};

// export const handlePrint = (filter, id, cb) => {
//   let obj = {
//     id,
//   };
//   if (isEmpty(id)) {
//     obj = { filter };
//   }
//   let objData = {
//     ...PRINT_PDF,
//     request: obj,
//   };
// return UtilService.callApi(objData, async function (err, data) {
//     if (err) throw err;
//     if (data && data.code === "OK") {
//       let server = BASE_DOWNLOAD_URL;
//       let file_path = server + data.data;
//       window.open(file_path, "Download");
//     }
//   });
// };

export const handlePrint = (filter, id, sort = false, extraRequest = {}) => {
  let obj = {
    id,
    ...extraRequest,
  };

  if (isEmpty(id)) {
    obj = { filter };
  }

  if (sort) {
    obj.sort = [{ inDt: 'ASC' }];
  }
  const objData = {
    ...PRINT_PDF,
    request: obj,
  };
  return UtilService.callApi(objData, async (err, data) => {
    if (err) throw err;

    if (data && data.code === 'OK') {
      const server = BASE_DOWNLOAD_URL;
      const file_path = server + data.data;
      window.open(file_path, 'Download');
    }
  });
};

export const handleDownloadExcel = (filter = {}, id = [], cb, extraRequest = {}) => {
  const request = isEmpty(filter) ? { id, ...extraRequest } : { filter };
  if (getPath() === LISTINGPAGES.PREDEFINEMATCHPAIR) request.isPairExcel = true;

  if (getPath() === LISTINGPAGES.CONFIRM || getPath() === LISTINGPAGES.PURCHASE) {
    request.orderDiamond = true;
  }

  if (getPath() === LISTINGPAGES.SEARCH_LAYOUT) {
    request.isPairExcel = true;
    request.isLayoutExcel = true;
  }
  const objData = {
    ...EXPORT_EXCEL,
    request,
    showLoader: true,
  };

  return UtilService.callApi(objData, async (err, data) => {
    if (err) throw err;

    if (data && data.code === 'OK') {
      const server = BASE_DOWNLOAD_URL;
      const file_path = server + data.data.data;
      window.open(file_path, 'Download');
      void cb?.(true);
    }
  });
};

export function handleConfirmStone(
  ids,
  comment,
  date,
  company,
  dayTermsId,
  cb,
  billingAddress,
  shippingAddress,
  isFromCounterOffer,
) {
  const obj = {
    ...CONFIRM_STONE,
    request: {
      diamonds: ids,
      comment,
      company,
      dayTermsId,
      invoiceDate: date,
      billingAddress,
      shippingAddress,
      isFromCounterOffer,
    },
  };
  return UtilService.callApi(obj, (err, data) => {
    if (data && data.code === 'OK') {
      OpenNotification({ type: 'success', title: data.message });
      void cb?.(true);
    } else {
      OpenNotification({ type: 'error', title: data.data.message });
      void cb?.(false);
    }

    StatsService.setDashboardStats();
  });
}

export function handleNoteDelete(id, cb) {
  const obj = NOTE_DELETE;
  obj.request = { id };
  return UtilService.callApi(obj, (err, data) => {
    if (data && data.code === 'OK') {
      OpenNotification({ type: 'success', title: data.message });
      void cb?.(true);
    } else {
      OpenNotification({ type: 'error', title: data ? data.message : err.data.message });
      void cb?.(null);
    }
  });
}

//diamond-confirm/cancel
export function handleConfirmCancel(orders, cb) {
  const obj = {
    ...CONFIRM_CANCEL,
    request: {
      orders,
    },
  };
  return UtilService.callApi(obj, (err, data) => {
    if (data && data.code === 'OK') {
      OpenNotification({ type: 'success', title: data.message });
      void cb?.(true);
    } else {
      OpenNotification({ type: 'error', title: data ? data.message : err && err.data ? err.data.message : '' });
      void cb?.(false);
    }
    StatsService.setDashboardStats();
  });
}

export function handleTrackDelete(trackType, ids, inTrackDiamonds, cb) {
  const id = [];
  const diamonds = find(inTrackDiamonds, { _id: trackType }) ? find(inTrackDiamonds, { _id: trackType }).diamonds : [];

  if (diamonds) {
    each(ids, (diamondId) => {
      const trackId = find(diamonds, { id: diamondId });
      if (trackId) id.push(find(diamonds, { id: diamondId }).trackId);
    });
  }
  const obj = { ...DELETE_TRACK, request: { trackType, id } };
  return UtilService.callApi(obj, (err, data) => {
    if (data && data.code === 'OK') {
      OpenNotification({ type: 'success', title: data.message });
      void cb?.(true);
    } else {
      OpenNotification({ type: 'error', title: data ? data.message : err && err.data ? err.data.message : '' });
      void cb?.(false);
    }
    StatsService.setDashboardStats();
  });
}

export function handleBidDelete(id, cb) {
  const obj = BID_DELETE;
  obj.request = { id };
  return UtilService.callApi(obj, (err, data) => {
    if (data && data.code === 'OK') {
      OpenNotification({ type: 'success', title: data.message });
      void cb?.(true);
    } else {
      OpenNotification({ type: 'error', title: data ? data.message : err && err.data ? err.data.message : '' });
      void cb?.(false);
    }
    StatsService.setDashboardStats();
  });
}

export const handleSendEmail = (apiObj, cb) => {
  const obj = {
    ...EXPORT_EXCEL,
    request: apiObj,
  };

  return UtilService.callApi(obj, (err, data) => {
    if (data && data.code === 'OK') {
      void cb?.(true);
      OpenNotification({ type: 'success', title: data.message });
    } else {
      void cb?.(false);
      OpenNotification({ type: 'error', title: data.data.message });
    }
  });
};

export function handleBidUpsert(rows, id, code, result, cb) {
  const obj = getPath() === LISTINGPAGES.MYBID ? UPDATE_BID : CREATE_BID;

  obj.request =
    getPath() === LISTINGPAGES.MYBID
      ? {
          id,
          result_type: parseFloat(result),
          diamonds: rows.map((x) => ({
            id: x.trackId,
            bidPricePerCarat: x.bidPricePerCarat,
            bidDiscount: x.isFcCol ? 0 : x.bidDiscount,
            bidAmount: x.bidAmount,
            trackPricePerCarat: x.ctPr,
            trackAmount: x.amt,
            trackDiscount: x.back,
            deviceType: 1,
            status: x.status,
          })),
        }
      : {
          //bidType: type,
          bidConfigurationId: id,
          subTypeCode: code,
          //bidConfigurationType: 1,
          // bidTermsDiscount: termObj,
          result_type: parseFloat(result),
          diamonds: rows.map((x) => ({
            diamond: x.id,
            bidPricePerCarat: x.bidPricePerCarat,
            bidAmount: x.bidAmount,
            bidDiscount: x.isFcCol ? 0 : x.bidDiscount,
            trackPricePerCarat: x.ctPr,
            trackAmount: x.amt,
            trackDiscount: x.back,
            vnd: x.vnd,
            vStnId: x.vStnId,
          })),
        };
  return UtilService.callApi(obj, (err, data) => {
    if (data && data.code === 'OK') {
      OpenNotification({ type: 'success', title: data.message });
      void cb?.(true);
    } else {
      OpenNotification({ type: 'error', title: err && err.data ? err.data.message : data.message });
      void cb?.(false);
    }
    StatsService.setDashboardStats();
  });
}

function offerObject(item, isUpdate = false) {
  // const offerValidDate = !isUpdate
  //   ? DateTime.local()
  //       .plus({ hour: item.hours ?? 2 })
  //       .toUTC()
  //       .toISO()
  //   : item.offerValidDate;

  const offerValidDate = DateTime.local()
    .plus({ hour: item.hours ?? 2 })
    .toUTC()
    .toISO();

  return {
    vStnId: item.vStnId,
    diamond: item.id,
    offerValidDate,
    validityHours: item.hours,

    newAmount: item.calcAmount,
    newPricePerCarat: item.calcPricePerCarat,
    newDiscount: item.calcDiscount,

    trackAmount: item.calcAmount,
    trackPricePerCarat: item.calcPricePerCarat,
    trackDiscount: item.calcDiscount,
  };
}

export function handleSaveQuote(selectedStones, remarks, company, cb, update) {
  const obj = update ? COUNTER_OFFER : CREATE_TRACK;

  obj.request = update
    ? {
        tracks: selectedStones.map((stone) => ({
          ...offerObject(stone, true),
          id: stone.trackId,
          company,
          remarks,
        })),
      }
    : {
        trackType: trackTypeObj.QUOTE,
        diamonds: selectedStones.map(offerObject),
        company,
        remarks,
      };

  return UtilService.callApi(obj, (err, res) => {
    StatsService.setDashboardStats();

    if (res && res.code === 'OK') {
      if (update) {
        OpenNotification({ type: 'success', title: res.message });
        return void cb?.(true);
      } else {
        OpenNotification({ type: res?.data?.created?.length < 1 ? 'error' : 'success', title: res.message });
        return void cb?.(res?.data?.created?.length > 0);
      }
    } else {
      OpenNotification({ type: 'error', title: err?.data?.message ?? res?.message });
      return void cb?.(false);
    }
  });
}

export const handleSendXRayEmail = (apiObj, path, cb) => {
  apiObj.message = `${apiObj.message} <a href=${path}> View File</a>`;
  const obj = {
    ...SEND_EMAIL_XRAY,
    request: apiObj,
  };
  return UtilService.callApi(obj, (err, data) => {
    if (data && data.code === 'OK') {
      void cb?.(true);
      OpenNotification({ type: 'success', title: data.message });
    } else {
      void cb?.(false);
      OpenNotification({ type: 'error', title: data.message });
    }
    StatsService.setDashboardStats();
  });
};

export function allSortObject(columns) {
  const sort = {};

  each(columns, (col) => {
    if (col.id !== 'Details') sort[col.id] = col.Header;
    if (col.sort) sort[col.sort] = col.Header;
  });
  return sort;
}

export function getProjectSetting(type, cb) {
  const obj = PROJECT_SETTING;

  obj.request = {
    filter: {
      type,
    },
  };
  return UtilService.callApi(obj, (err, data) => {
    if (data && data.code === 'OK') void cb?.(data.data.data);
    else void cb?.([]);
  });
}

export const downloadZip = (dt, cb) => {
  const obj = {
    ...DOWNLOAD_ZIP,
    request: { ...dt },
  };
  LoaderService.startLoading();
  return UtilService.callApi(obj, (err, data) => {
    if (data && data.code === 'OK') {
      const file_path = BASE_URL + '/' + data.data;
      const xhr = new XMLHttpRequest();
      xhr.open('GET', file_path, true);
      xhr.responseType = 'blob';

      xhr.onload = function () {
        if (xhr.statusText === 'OK' || xhr.status === 200) {
          const urlCreator = window.URL || window.webkitURL;
          const imageUrl = urlCreator.createObjectURL(this.response);
          const tag = document.createElement('a');
          tag.href = imageUrl;
          tag.download = last(split(data.data || data.data[0], '/'));
          document.body.appendChild(tag);
          tag.click();
          document.body.removeChild(tag);
        } else {
          OpenNotification({ type: 'error', title: 'Resource Not Found.' });
          void cb?.(true);
        }
      };

      setTimeout(() => {
        xhr.send();
        LoaderService.stopLoading();
        void cb?.(true);
      }, 5000);
    } else {
      OpenNotification({ type: 'error', title: data?.data?.message });
      LoaderService.stopLoading();
    }
  });
};

export const downloadFile = async (apiObj) => {
  LoaderService.startLoading();

  try {
    const obj = {
      method: DOWNLOAD_FILE.method,
      url: DOWNLOAD_FILE.url,
      request: apiObj,
    };
    const [err, res] = await UtilService.callApi(obj);

    if (res?.code !== 'OK') return OpenNotification({ type: 'error', title: err.data.message || res.message });

    await new Promise((r) => setTimeout(r, 1000));
    const server = BASE_DOWNLOAD_URL;
    const file_path = server + res?.data?.path;

    await new Promise((resolve, reject) => {
      try {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', file_path, true);
        xhr.responseType = 'blob';

        xhr.onload = function () {
          if (xhr.statusText === 'OK' || xhr.status === 200) {
            const URL = window.URL || window.webkitURL;
            const imageUrl = URL.createObjectURL(this.response);
            const tag = document.createElement('a');
            tag.href = imageUrl;
            tag.download = `${apiObj.fileName}${apiObj.ext}`;
            document.body.appendChild(tag);
            tag.click();
            document.body.removeChild(tag);
            resolve();
          } else {
            OpenNotification({ type: 'error', title: 'Resource Not Found.' });
            reject('Resource Not Found');
          }
        };
        xhr.send();
      } catch (error) {
        reject(error);
      }
    });
    LoaderService.stopLoading();

    return [err, res];
  } catch (error) {
    logWarn(error);
  }
  LoaderService.stopLoading();
};

export const downloadVideo = (apiObj) => {
  LoaderService.startLoading();

  try {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', apiObj.path, true);
    xhr.responseType = 'blob';

    xhr.onload = function () {
      if (xhr.statusText === 'OK' || xhr.status === 200) {
        const urlCreator = window.URL || window.webkitURL;
        const imageUrl = urlCreator.createObjectURL(this.response);
        const tag = document.createElement('a');
        tag.href = imageUrl;
        tag.target = '_blank';
        tag.download = apiObj.fileName + apiObj.ext;
        document.body.appendChild(tag);
        tag.click();
        document.body.removeChild(tag);
      } else {
        OpenNotification({ type: 'error', title: 'Resource Not Found.' });
      }
    };
    xhr.send();
  } catch (error) {
    logWarn(error);
  }
  LoaderService.stopLoading();
};

export const handleDeleteOffice = (filter, cb) => {
  const groupedData = groupBy(filter.schedule, 'id');
  const formatedData = Object.entries(groupedData);
  const temp = [];

  each(formatedData, (data) => {
    temp.push({
      id: data[0],
      diamonds: data[1].map((x) => x.diamonds).flat(),
    });
  });
  const schedule = {
    schedule: temp,
  };

  const objData = {
    ...DELETE_OFFICE_DIAMOND,
    request: schedule,
  };
  return UtilService.callApi(objData, (err, res) => {
    if (err) throw err;

    if (res && res.code === 'OK') {
      void cb?.(true);
    }
    StatsService.setDashboardStats();
  });
};

export const myAccountExcelTrack = (trackType) => {
  const obj = {
    ...FETCH_DIAMOND_TRACK,
    request: {
      isExcel: true,
      page: 1,
      limit: 100000,
      trackType,
      sort: {},
    },
  };
  return UtilService.callApi(obj, (err, data) => {
    if (data && data.code === 'OK') {
      const server = BASE_DOWNLOAD_URL;
      const file_path = server + data.data.data;
      window.open(file_path, 'Download');
    } else {
      OpenNotification({ type: 'error', title: err.data.message || data.message });
    }
    //void cb?.(true);
  });
};

export const myAccountExcelMemo = (filter) => {
  const obj = {
    ...FETCH_CONFIRM_STONE,
    request: {
      isExcel: true,
      page: 1,
      limit: 100000,
      filter,
      sort: [{ memoNo: 'DESC' }],
    },
  };
  return UtilService.callApi(obj, (err, data) => {
    if (data && data.code === 'OK') {
      const server = BASE_DOWNLOAD_URL;
      const file_path = server + data.data.data;
      window.open(file_path, 'Download');
    } else {
      OpenNotification({ type: 'error', title: err.data.message || data.message });
    }
    //void cb?.(true);
  });
};

export const myAccountExcelOffice = () => {
  const obj = {
    ...FETCH_OFFICE_DIAMOND,
    request: {
      isExcel: true,
      page: 1,
      limit: 100000,
      sort: [{ date: 'ASC' }],
      filter: { date: { '>=': DateTime.local().startOf('day').toUTC().toISO() } },
    },
  };
  return UtilService.callApi(obj, (err, data) => {
    if (data && data.code === 'OK') {
      const server = BASE_DOWNLOAD_URL;
      const file_path = server + data.data.data;
      window.open(file_path, 'Download');
    } else {
      OpenNotification({ type: 'error', title: err.data.message || data.message });
    }
    //void cb?.(true);
  });
};

export function myAccountNotesExcel() {
  const obj = {
    ...FETCH_NOTES,
    request: {
      isExcel: true,
      page: 1,
      isAppendDiamond: 1,
      limit: 100000,
      sort: [],
    },
  };
  return UtilService.callApi(obj, (err, data) => {
    if (data && data.code === 'OK') {
      const server = BASE_DOWNLOAD_URL;
      const file_path = server + data.data.data;
      window.open(file_path, 'Download');
    } else {
      OpenNotification({ type: 'error', title: err.data.message || data.message });
    }
    //void cb?.(true);
  });
}

export function myBidExcel() {
  const obj = {
    ...BID_PAGINATE,
    request: {
      isExcel: true,
      page: 1,
      status: [1],
      bidType: [1],
      limit: 100000,
    },
  };
  return UtilService.callApi(obj, (err, data) => {
    if (data && data.code === 'OK') {
      const server = BASE_DOWNLOAD_URL;
      const file_path = server + data.data.data;
      window.open(file_path, 'Download');
    } else {
      OpenNotification({ type: 'error', title: err.data.message || data.message });
    }
    //void cb?.(true);
  });
}

export function mySuggestedExcel() {
  const obj = {
    ...EXPORT_EXCEL,
    request: {
      moduleType: 'suggestedStock',
    },
  };
  return UtilService.callApi(obj, (err, data) => {
    if (data && data.code === 'OK') {
      const server = BASE_DOWNLOAD_URL;
      const file_path = server + data.data.data;
      window.open(file_path, 'Download');
    } else {
      OpenNotification({ type: 'error', title: data.data.message });
    }
    //void cb?.(true);
  });
}

export function myExclusiveExcel() {
  const obj = {
    ...EXPORT_EXCEL,
    request: {
      page: 1,
      limit: 250,
      sort: [],
      filter: {
        or: [
          { crt: { '>=': '5.00', '<=': '5.99' } },
          { crt: { '>=': '6.00', '<=': '9.99' } },
          { crt: { '>=': '10.00', '<=': '19.99' } },
          { crt: { '>=': '20.000', '<=': '100' } },
        ],
      },
    },
  };
  return UtilService.callApi(obj, (err, data) => {
    if (data && data.code === 'OK') {
      const server = BASE_DOWNLOAD_URL;
      const file_path = server + data.data.data;
      window.open(file_path, 'Download');
    } else {
      OpenNotification({ type: 'error', title: data.data.message });
    }
    //void cb?.(true);
  });
}

export const DiamondTrackStatusUpdate = (id, offerStatus, cb) => {
  const objData = {
    ...DIAMOND_TRACK_STATUS_UPDATE,
    request: { id, ...offerStatus },
  };
  return UtilService.callApi(objData, async (err, data) => {
    if (data && data.code === 'OK') {
      OpenNotification({ type: 'error', title: data.data.message || data.message });
      void cb?.(true);
    } else {
      OpenNotification({ type: 'error', title: err.data.message || data.message });
      void cb?.(false);
    }
    StatsService.setDashboardStats();
  });
};

export function shareExcel(checked, cb, extraRequest = {}) {
  const obj = {
    ...EXPORT_EXCEL,
    request: {
      id: checked,
      ...extraRequest,
    },
  };

  return UtilService.callApi(obj, (err, data) => {
    if (data && data.code === 'OK') {
      void cb?.(data.data.data);
    } else {
      OpenNotification({ type: 'error', title: data.data.message });
    }
  });
}
