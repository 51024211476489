import { DiamondActions } from 'redux/reducers/diamond';
import { store } from 'redux/store';

export class RowSelectService {
  static selectRows(type, rows, replace, uniqueBy) {
    return store.dispatch(DiamondActions.selectRows({ payload: rows, currentType: type, replace, uniqueBy }));
  }

  static unSelectRows(type, rows, replace) {
    return store.dispatch(DiamondActions.unSelectRows({ payload: rows, currentType: type, replace }));
  }

  static resetSelectedRows(type) {
    return store.dispatch(DiamondActions.resetSelectedRows({ currentType: type }));
  }
}
