import React from "react";
import "./salesPerson.less";
const SalesPerson = (props) => {
  return (
    <>
      {props && props.seller && (
        <div className="salesPeronInnerDetail">
          {props.seller && (props.seller.firstName || props.seller.lastName) && (
            <div className="salesPersonInnerItem">
              <div className="salespersonInnerDetail">
                {`${props.seller.firstName && props.seller.firstName} ${
                  props.seller.lastName && props.seller.lastName
                }`}
              </div>
            </div>
          )}
          {props.seller && props.seller.email && (
            <div className="salesPersonInnerItem">
              <div className="salesPersonIcon">
                <img
                  src={require("../../../assets/svg/email.svg")}
                  alt="icon"
                />
              </div>
              <div className="salespersonInnerDetail">
                <a href={`mailto:${props.seller.email}`}>
                  {props.seller.email}
                </a>
              </div>
            </div>
          )}

          {props.seller && props.seller.mobile && (
            <div className="salesPersonInnerItem">
              <div className="salesPersonIcon">
                <img
                  src={require("../../../assets/svg/phonecall.svg")}
                  alt="icon"
                />
              </div>
              <div className="salespersonInnerDetail">
                <a href={`tel://${props.seller.mobile}`}>
                  {props.seller.mobile}
                </a>
              </div>
            </div>
          )}
          <div className="salesPersonInnerItem">
            {props.seller && props.seller.whatsapp ? (
              <div
                onClick={() => {
                  window.open(
                    `https://wa.me/${props.seller.whatsapp}?text=I need help with `,
                    "_blank"
                  );
                }}
                className="salesPersonIcon mr-5"
              >
                <img
                  src={require("../../../assets/svg/Dashboard/whatsapp.svg")}
                  alt="icon"
                />
              </div>
            ) : null}
            {props.seller && props.seller.skype ? (
              <div
                onClick={() => {
                  window.open("https://web.skype.com/share?", "_blank");
                }}
                className="salesPersonIcon mr-5"
              >
                <img
                  src={require("../../../assets/svg/Dashboard/skype.svg")}
                  alt="icon"
                />
              </div>
            ) : null}
            {/* {props.seller && props.seller.mobile ? (
              <div
                onClick={() => {
                  window.open(`sms://${props.seller.mobile}`, "_blank");
                }}
                className="salesPersonIcon "
              >
                <img
                  src={require("../../../assets/svg/Dashboard/chat.svg")}
                  alt="icon"
                />
              </div>
            ) : null} */}
          </div>
        </div>
      )}
    </>
  );
};
export default SalesPerson;
