import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import { generateRoutePath } from 'util/utils';

export function usePathname() {
  const match = useRouteMatch();
  const pathname = React.useMemo(() => generateRoutePath(match.path)?.split('/')?.pop(), [match.path]);
  React.useDebugValue(pathname);
  return pathname;
}
