import { Storage } from 'services/Storage';

import { callApi } from 'util/call-api';
import { isEmpty, objectToQueryString, pruneEmpty } from 'util/utils';

import { CREATE_ANALYTICS, GET_SERVER_TIME, BID_CONFIG } from 'constants/apiConstant';

export { callApi } from 'util/call-api';

export const track = (data) => {
  if (isEmpty(Storage.get('token'))) return;
  const [page, section, action] = data.split('/');
  return callApi({ ...CREATE_ANALYTICS, request: { page, section, action } });
};

export const getCurrentTime = () => {
  return new Promise((resolve, reject) => {
    callApi({ ...GET_SERVER_TIME, url: `${GET_SERVER_TIME.url}?${Math.random()}` }, (err, res) => {
      if (err) return reject(err);
      if (res?.code === 'OK') return resolve(res.data);
      reject(err);
    });
  });
};

export const getBidConfig = (type = 1) => {
  return new Promise((resolve, reject) => {
    const request = {
      ...BID_CONFIG,
      url: `${BID_CONFIG.url}${objectToQueryString(pruneEmpty({ type }))}&t=${Math.random()} `,
    };
    callApi(request, (err, res) => {
      if (res && res.code === 'OK') return resolve(res.data);
      reject(err);
    });
  });
};

export default {
  callApi,
  getLocalStorageItem: (key) => Storage.get(key),
  setLocalStorageItem: (value, key) => Storage.set(key, value),
};
