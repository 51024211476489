import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Tooltip } from 'antd';
import { get } from 'lodash';
import { DateTime } from 'luxon';

import MobileViewList from 'components/DiamondList/MobileViewList';
import Table from 'components/DiamondList/TableBack';
import DiamondListingAction from 'components/common/DiamondListing/DiamondListingAction';
import { prepareStoneForBargain } from 'components/common/DiamondListing/QuotePopup';

import UtilService from 'services/util';

import { useCompositeState, useIsMobile, usePathname } from 'util/hooks';
import { classNames, formatDateTime, formatNumber, formatDecimal } from 'util/utils';
import { isArray, isEmpty, isNumber } from 'util/utils';

import { TRACK_TYPES, LOCAL_STORAGE_VAR } from 'constants/Common';

import { getGridHeight, emptyData, getState } from '../DiamondList';
import { getStoneInfo, fetchOffer, handleTrackDelete, TITLE } from '../DiamondList/DiamondListFunctions'; // prettier-ignore

import deleteSvg from 'assets/svg/Account/delettable.svg';
import editSvg from 'assets/svg/Account/edit.svg';

export const OFFERSTATUS = { 1: 'Pending', 2: 'Accepted', 3: 'Rejected', 4: 'Expired' };

const Offer = (props) => {
  const { location, history, onSumChange } = props;

  const [state, setState] = useCompositeState(getState(props));
  const [, forceUpdate] = React.useReducer((x) => x + 1, 0);
  const [isMobile] = useIsMobile();
  const pathname = usePathname();
  const currentType = pathname;

  const dispatch = useDispatch();

  const USER_PERMIT = UtilService.getLocalStorageItem(`userPermissions`);

  React.useEffect(() => {
    if (!isMobile) onSumChange && onSumChange(state.sum, currentType.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.sum, isMobile]);

  React.useEffect(() => {
    forceUpdate();
  }, [props.common.strip]);

  React.useEffect(() => {
    const listener = history.listen((route) => {
      if (props.location.pathname !== route.pathname) {
        dispatch({ type: 'RESET_SELECTED_ROWS' });
        setState(getState(props));
      }

      if (props.location.search !== route.search) {
        setState(getState(props));
      }
    });

    return () => listener();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setResponse = (res) => {
    if (res) {
      const diamond = res.data || [];
      let defaultChecked = [];

      if (localStorage.getItem(`${LOCAL_STORAGE_VAR}-more`)) {
        defaultChecked = Storage.get('compare-diamond') ?? [];

        if (defaultChecked && defaultChecked.length > 0) {
          diamond.forEach((d) => {
            if (defaultChecked.includes(d.id)) d['isDefaultChecked'] = true;
          });
        }
        localStorage.removeItem(`${LOCAL_STORAGE_VAR}-more`);
      }

      setState({
        ...res,
        data: diamond.map((stone) => prepareStoneForBargain(stone, { noModify: true })),
        defaultChecked,
        loading: false,
      });
    } else {
      setState({ loading: false, defaultChecked: [] });
    }
  };

  const fetch = React.useCallback(() => {
    setState({ editOffer: null, loading: true, data: [] });
    fetchOffer(state, setResponse);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.page, state.limit, state.sum]);

  React.useEffect(() => {
    fetch();
    window.addEventListener('resize', forceUpdate);
    return () => window.removeEventListener('resize', forceUpdate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  React.useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.page, state.limit]);

  const clearAllSelectedRows = React.useCallback(() => {
    dispatch({ type: 'RESET_SELECTED_ROWS' });
  }, [dispatch]);

  // let getLoggedInUser = UtilService.getLocalStorageItem(`user`);

  const fetchcolumn = React.useMemo(() => {
    const columns = [
      {
        Header: 'No',
        accessor: 'no',
        Cell({ row }) {
          return <span>{row.index + 1}</span>;
        },
      },
      {
        Header: 'Stone Info',
        accessor: 'stoneinfo',
        Cell({ row }) {
          return <span>{getStoneInfo(row.original.original).join(' ')}</span>;
        },
      },
      {
        Header: 'Disc % / Price/Ct',
        accessor: 'dispricCt',
        Cell({ row }) {
          return (
            <span>
              <span>
                {!row.original.original.isFcCol && isNumber(Number(row.original.original.back))
                  ? formatDecimal(row.original.original.back)
                  : '-'}
              </span>
              <span>&nbsp;/&nbsp;</span>
              <span>
                {isNumber(Number(row.original.original.ctPr)) ? formatDecimal(row.original.original.ctPr) : '-'}
              </span>
            </span>
          );
        },
      },
      {
        Header: 'Final % / Price/Ct',
        accessor: 'quotepricect',
        Cell({ row }) {
          if (isEmpty(row.original.currentValidTrack)) return '-';

          return (
            <span>
              <strong>
                {!row.original.original.isFcCol && isNumber(Number(row.original.currentValidTrack?.trackDiscount))
                  ? formatDecimal(row.original.currentValidTrack?.trackDiscount)
                  : '-'}
              </strong>
              <span>&nbsp;/&nbsp;</span>
              <span>
                {isNumber(Number(row.original.currentValidTrack?.trackPricePerCarat))
                  ? formatDecimal(row.original.currentValidTrack?.trackPricePerCarat)
                  : '-'}
              </span>
            </span>
          );
        },
      },
      {
        Header: 'My Offers',
        accessor: 'bargain',
        Cell({ row }) {
          const trackList =
            isArray(row.original.bargainTrack) && !isEmpty(row.original.bargainTrack)
              ? [row.original.defaultTrack, ...row.original.bargainTrack]
              : [row.original.defaultTrack];

          return (
            <div>
              {trackList.map((track, index) => {
                const client = [4, 10].includes(track?.userType);
                const admin = [1, 2, 8].includes(track?.userType);

                return (
                  <Tooltip key={index} title={formatDateTime(track?.updatedAt)}>
                    <span className={classNames(['offerPrice', admin && 'admin', client && 'client'])}>
                      {row.original.isFcCol
                        ? `${formatDecimal(track?.trackPricePerCarat ?? 0)}`
                        : `${formatDecimal(track?.trackDiscount ?? 0)}% | ${formatDecimal(
                            track?.trackPricePerCarat ?? 0,
                          )}`}
                    </span>
                    {trackList.length > index + 1 && (
                      <span className="offerPrice">
                        <br />
                      </span>
                    )}
                  </Tooltip>
                );
              })}
            </div>
          );
        },
      },
      {
        Header: 'Comment',
        accessor: 'remarks',
        Cell: ({ cell }) => cell.value || '-',
      },
      {
        Header: 'Active',
        accessor: 'active',
        Cell({ row }) {
          return (
            <div className="d-flex j-content-center">
              <div
                className={`activeStatus ${
                  (row.original.original.offerStatus === 3 || row.original.original.offerStatus === 4) && `red`
                }`}
              >
                <span>{OFFERSTATUS[row.original.original.offerStatus]}</span>
              </div>
            </div>
          );
        },
      },
      {
        Header: 'Action',
        accessor: 'Action',
        Cell({ row }) {
          const { offerStatus, offerValidDate, confirmedStone } = { ...row.original, ...row.original.original };

          const diff = React.useMemo(() => {
            const endDate = DateTime.fromISO(offerValidDate);
            const currDate = DateTime.local();
            return endDate.isValid ? endDate.diff(currDate).toMillis() : undefined;
          }, [offerValidDate]);

          if (offerStatus !== 1 || confirmedStone) return null;

          return (
            <div className="actionButton">
              {diff > 0 && (
                <div className="actionAction" onClick={() => setState({ editOffer: [row.original.original] })}>
                  <img src={editSvg} alt="" />
                </div>
              )}
              <div
                className="actionAction"
                onClick={() => {
                  handleTrackDelete(TRACK_TYPES.QUOTE, [row.original.original.id], state.inTrackDiamonds, () => {
                    clearAllSelectedRows();
                    fetch();
                  });
                }}
              >
                <img src={deleteSvg} alt="" />
              </div>
            </div>
          );
        },
      },
    ].map((c) => ({ ...c, id: c.accessor }));
    return columns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const onPaginationChange = React.useCallback(
    (page, limit) => {
      setState({ page, limit });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.sum],
  );

  const getTitle = () => {
    return TITLE[pathname] + ' (' + formatNumber(state.count) + ')';
  };

  const getAction = () => {
    return (
      <DiamondListingAction
        permKey="offer"
        trackType={TRACK_TYPES.QUOTE}
        {...state}
        {...props}
        currentType={currentType.current}
        delete={get(USER_PERMIT, 'QUOTE.delete', false)}
        nofinalcalc={false}
        noCompare={!get(USER_PERMIT, 'COMPARE.view', false)}
        noconfirm={!get(USER_PERMIT, 'CONFIRM_STONE.view', false)}
        nocart={!get(USER_PERMIT, 'CART.view', false)}
        nowatch={!get(USER_PERMIT, 'WATCHLIST.view', false)}
        nooffice={!get(USER_PERMIT, 'OFFICE.view', false)}
        nonote={!get(USER_PERMIT, 'NOTES.view', false)}
        noshare={
          !get(USER_PERMIT, 'SHARE_VIA_MAIL.view', false) ||
          !get(USER_PERMIT, 'SHARE_VIA_WHATSAPP.view', false) ||
          !get(USER_PERMIT, 'SHARE_VIA_SKYPE.view', false)
        }
        nodownload={!get(USER_PERMIT, 'DOWNLOAD.view', false)}
        noprint={!get(USER_PERMIT, 'PRINT.view', false)}
        noenquiry={!get(USER_PERMIT, 'ENQUIRY.view', false)}
        noquote
        onPaginationChange={onPaginationChange}
        clearAll={clearAllSelectedRows}
        fetch={fetch}
      />
    );
  };

  const gridHeight = getGridHeight(
    [
      'headerTopMenu',
      'accountTopBlock',
      'accountTabBox',
      'filterTopMainBlock',
      'diamondListingAction',
      'covidStrip',
      'HeaderStripBlock',
    ],
    10,
  );

  return isMobile ? (
    <MobileViewList listView={true} {...props} {...state} currentType={currentType} parent={{ getTitle, getAction }} />
  ) : (
    <div className="searchResultListWrapper profilePage">
      <div className="searchInnerResult">
        <div className="searchResultTable profilePageTable" style={{ height: gridHeight, overflow: 'auto' }}>
          <Table
            data={state.data}
            columns={fetchcolumn}
            loading={state.loading}
            currentType={currentType.current}
            defaultChecked={state.defaultChecked}
            FilterOption={false}
            canSort={false}
            nostatus
          />
          {emptyData(state.loading, state.data.length)}
        </div>
        {getAction()}
      </div>
    </div>
  );
};

export default connect((props) => {
  return props;
}, {})(withRouter(Offer));
