import React from 'react';
import Magnifier from 'react-magnifier';

import { MediaService } from 'services/MediaService';

import { useBoolean } from 'util/hooks';

function MagnifierImage(props) {
  const { image, src, zoomFactor = 1, clickToOpen, type, children, ...restProps } = props;

  const ref = React.useRef();
  const url = React.useMemo(() => src ?? image, [src, image]);
  const [available, setAvailable] = React.useState(false);
  const [error, setError] = useBoolean(false);

  const handleClick = React.useCallback(() => void (clickToOpen && window.open(url, '_blank')), [clickToOpen, url]);

  const getAvailable = React.useCallback(async () => {
    const [error] = await MediaService.getHeaders(url);
    setAvailable(!error);
  }, [setAvailable, url]);

  React.useEffect(() => {
    getAvailable();
  }, [getAvailable]);

  React.useEffect(() => {
    const image = ref?.current?.img;
    if (!image) return;
    image.addEventListener('load', setError.false);
    image.addEventListener('error', setError.true);
  });

  return !available && error ? (
    children ?? <div className="no-msg">No {type === 'CERT' ? 'Certificate' : 'image'} available</div>
  ) : (
    <Magnifier ref={ref} src={url} zoomFactor={zoomFactor} onClick={handleClick} {...restProps} />
  );
}

export default React.memo(MagnifierImage);
