import React, { useState } from 'react';
import { Dropdown, Select } from 'antd';
import { FLOAT_COLUMNS } from '../../DiamondList/TableBack';
import { formatNumber } from './SelectStone';
import { sum, map, includes } from 'lodash';

const { Option } = Select;

const CALCULATE = {
  sum: 'Sum',
  avg: 'Average',
  range: 'Range',
};
const DISPLAY_TITLE = {
  ctPr: 'Price/Carat',
  amt: 'Amount',
  rap: 'Rap Price',
  crt: 'Carat',
};

const NO_DISPLAY_DOLLAR = ['crt', 'back'];

const DiamondTotalCard = (props) => {
  const [visible, handleClick] = useState(false);
  const [dropdown, handleDropdown] = useState(false);
  const [calculate, handleCalculate] = useState('sum');
  let avgCtPr = sum(props.row.groupData?.amt) / sum(props.row.groupData?.crt);
  let avgRap =
    sum(map(props.row.groupData?.['rap'], (m, i) => m * props.row.groupData?.['crt'][i])) /
    sum(props.row.groupData?.crt);
  let result =
    (!includes(NO_DISPLAY_DOLLAR, props.col) ? '$' : '') +
    formatNumber(
      parseFloat(
        props.col === 'ctPr'
          ? avgCtPr
          : props.col === 'rap'
          ? avgRap
          : props.col === 'back'
          ? (1 - avgCtPr / avgRap) * -100
          : sum(props.row.groupData?.[props.col]),
      ).toFixed(FLOAT_COLUMNS.includes(props.col) ? 2 : 0),
    );

  return (
    // <Dropdown
    //   trigger={["click"]}
    //   onVisibleChange={(e) => {
    //     if (dropdown) e = true;
    //     handleClick(e);
    //   }}
    //   visible={visible}
    //   placement="bottomCenter"
    //   overlayClassName="amountCalalateOverClass"
    //   overlay={
    //     <div className="amountCalculateDropdown">
    //       <div className="calculatorTitle">CALCULATE</div>
    //       <Select
    //         value={calculate}
    //         className="calculatorSelect"
    //         open={dropdown}
    //         onDropdownVisibleChange={(e) => handleDropdown(e)}
    //         onChange={(e) => handleCalculate(e)}
    //       >
    //         {Object.keys(CALCULATE).map((x) => (
    //           <Option value={x}>{CALCULATE[x]}</Option>
    //         ))}
    //       </Select>
    //       <button>Calculate</button>
    //       <div className="calTotalAmount">
    //         Total {DISPLAY_TITLE[props.col]}: {result}
    //       </div>
    //     </div>
    //   }
    // >
    <span style={{ fontSize: '13px' }} className="selectAmount">
      {result}
    </span>
    // </Dropdown>
  );
};

export default DiamondTotalCard;
