import { Action } from 'services/Action';
import { Storage } from 'services/Storage';

import { isArray, isEmpty, isNotEmpty, isObject } from 'util/utils';

export class MasterService {
  static identifier = 'Master';
  static data = [];
  static tree = [];

  static get updateTreeEvent() {
    return new Action('@master/update-tree');
  }

  static get updateListEvent() {
    return new Action('@master/update-list');
  }

  static setData() {
    const master = Storage.get('master', false) ?? {};

    this.data = Object.fromEntries(
      Object.entries(master).map(([master, list]) => [
        master,
        list.map((item) => ({ ...item, _id: isArray(item?.id) ? item?.id?.[0] : item?.id })),
      ]),
    );
    this.updateListEvent.emit(this.data);
  }

  static getData() {
    if (!isObject(this.data) || isEmpty(this.data)) this.setData();
    return this.data;
  }

  static getTree() {
    if (!isObject(this.tree) || isEmpty(this.tree)) this.setTree();
    return this.tree;
  }

  static setTree() {
    this.tree = Object.fromEntries(
      Object.entries(this.getData()).map(([key, list]) => {
        const map = { byId: {}, byCode: {} };

        list.forEach((item) => {
          const idList = isArray(item.id) ? item.id : [item.id];
          const codeList = isArray(item.code) ? item.code : [item.code];
          idList.forEach((id) => void (map.byId[id] = item));
          codeList.filter(isNotEmpty).forEach((code) => void (map.byCode[code] = item));
        });

        return [key, map];
      }),
    );
    this.updateTreeEvent.emit(this.tree);
  }

  static getList(key) {
    return this.getData()?.[key];
  }

  static getMapById(key) {
    return this.getTree()?.[key]?.byId;
  }

  static getMapByCode(key) {
    return this.getTree()?.[key]?.byCode;
  }

  static initialize() {
    MasterService.setTree();
    void window.__DN?.MasterListeners?.forEach?.((fn) => fn?.());
    const MasterListeners = [Storage?.listen?.('master', () => MasterService.setTree())];
    window.__DN.MasterListeners = MasterListeners;
  }
}
