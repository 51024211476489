import React from 'react';

import noImageSvg from '../../../assets/svg/noimage.svg';
import noImageSvgWhite from '../../../assets/svg/noimage-white.svg';
import './image.less';
import { useBoolean } from 'util/hooks';

const Image = React.memo(function Image({ children, bgDark, ...props }) {
  const [hasError, setError] = useBoolean(false);

  React.useEffect(() => {
    setError.false();
  }, [props.src, setError]);

  return !hasError ? (
    <img onError={setError.true} {...props} alt="" />
  ) : children ? (
    children
  ) : (
    <img {...props} src={bgDark ? noImageSvgWhite : noImageSvg} alt="" />
  );
});

export default Image;
