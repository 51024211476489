import React from 'react';
import { useSelector } from 'react-redux';

import { Drawer, Dropdown, Tag } from 'antd';
import { isArray } from 'lodash';

import { LISTINGPAGES } from 'components/DiamondList/DiamondListFunctions';

import IntlMessages from 'util/IntlMessages';
import { usePathname } from 'util/hooks';
import { notify } from 'util/notify';
import { catchError, isEmpty } from 'util/utils';

import FilterTopOption from './FilterOption';
import FilterTop from './FilterTop';

import { isMobile } from '../../DiamondList/DiamondListFunctions';

import CopyPacketIcon from 'assets/svg/DiamondList/copy-packet.svg';
import eyeCloseSvg from 'assets/svg/DiamondList/eye-close.svg';
import eyeSvg from 'assets/svg/DiamondList/eye.svg';
import filterSvg from 'assets/svg/DiamondList/filter.svg';
import mobilesortingSvg from 'assets/svg/DiamondList/mobilesorting.svg';

const ListingTop = (props) => {
  const pathname = usePathname();

  const selectedRows = useSelector((state) => state.diamond.selectedRows[props.currentType] ?? []);

  const [filterOpen, setFilterOpen] = React.useState(false);

  const openFilter = () => setFilterOpen(true);
  const closeFilter = () => setFilterOpen(false);

  const copyPacketNo = React.useCallback(
    async () =>
      isEmpty(selectedRows)
        ? notify.error({ message: 'Please select stone(s) to copy Stock No.' })
        : catchError(
            async () => {
              const copyText = selectedRows.map((Id) => Id.vStnId);
              await navigator.clipboard.writeText(copyText.toString());
              notify.destroy();
              notify.success({ message: 'Stock No Copied!' });
            },
            () => {
              notify.destroy();
              notify.error({ message: 'Failed to copy Stock No!' });
            },
          ),
    [selectedRows],
  );

  const gridIcon = () => {
    return (
      <>
        {props.listGridIcon && (
          <div className="gridListIcon">
            <img src={props.image} alt="gridIcon" onClick={props.onClick} />
          </div>
        )}
      </>
    );
  };

  const copyIcon = () => {
    return (
      <>
        {props.copyIcon && (
          <div className="gridListIcon">
            <img src={CopyPacketIcon} alt="gridIcon" onClick={copyPacketNo} />
          </div>
        )}
      </>
    );
  };
  const filterTopValidation = () => {
    return props.filterTop >= 2 ? true : false;
  };

  return (
    <>
      {isMobile() ? (
        <div className="d-flex align-items-center mobileRightAction">
          {gridIcon()}
          {selectedRows.length > 0 ? copyIcon() : null}
          {pathname === LISTINGPAGES.LIST && filterTopValidation() && (
            <div className="filterOption" onClick={openFilter}>
              <span className="filterStatus">{props.filterTop}</span>
              <img src={filterSvg} alt="filter" />
            </div>
          )}
          {props.Cart && (
            <Dropdown
              overlayClassName="mobileSortingDropdown"
              overlay={
                <div className="mobileSortingDropdownList">
                  <ul>
                    <li>Diamond Wise Grouping</li>
                    <li>Date Wise Grouping</li>
                  </ul>
                </div>
              }
              trigger={['click']}
            >
              <div className="filterOption mobileSortingIcon" onClick={(e) => e.preventDefault()}>
                <img src={mobilesortingSvg} alt="filter" />
              </div>
            </Dropdown>
          )}
        </div>
      ) : (
        <div className="d-flex j-space-between filterTopMainBlock align-items-center">
          {/* {props.filterTop && (
            <div className="searchInnerFilterMain">
              <FilterTop filterTitle="1 Filter" image={eyeCloseSvg} />
              <FilterTop filterTitle="2 Filter" image={eyeSvg} />
              <FilterTop filterTitle="3 Filter" image={eyeSvg} />
            </div>
          )}
          {props.matchpairfilterTop && (
            <div className="searchInnerFilterMain">
              <FilterTop filterTitle="ROUND 0.50-0.69 DEF IFVVS1" image={eyeCloseSvg} />
              <FilterTop filterTitle="Pear 0.50-0.69 GHI IFVVS1" image={eyeSvg} />
              <FilterTop filterTitle="ROUND 0.50-0.69 EF IFVVS2" image={eyeSvg} />
            </div>
          )} */}
          {props.Cart && (
            <div className="d-flex">
              <FilterTopOption SearchReultOption dateFilter={false} />
            </div>
          )}
          {props.modify && (
            <button className="commonButton smallButton mr-10" onClick={props.onModify}>
              <IntlMessages id="app.modify" />
            </button>
          )}
          {gridIcon()}
          {selectedRows.length > 0 ? copyIcon() : null}
        </div>
      )}
      {filterOpen && (
        <Drawer
          onClose={closeFilter}
          visible={filterOpen}
          wrapClassName="mobileFilterSideBar result-drawer"
          destroyOnClose
        >
          {/* <MobileFilterSidebar /> */}{' '}
          <div className="searchInnerFilterMain">
            <>
              {isArray(props.ids) && props.ids?.length > 1 && (
                <div className="dn-list-tags m-list-result">
                  <div className="dn-list-tags-title">Results: </div>
                  {props.ids.map((_searchId, index) => {
                    let _index = props.searchIdListConst.findIndex((id) => id === _searchId);
                    return (
                      <Tag
                        key={`${_searchId}_${index}_${_index}`}
                        onClick={() => {
                          props.setSearchId(_searchId);
                          closeFilter();
                        }}
                        onClose={() => {
                          props.removeSearchId(_searchId);
                          closeFilter();
                        }}
                        color={_searchId === props.searchId ? '#0a0a0a' : undefined}
                        closable
                      >
                        Result{++_index}
                      </Tag>
                    );
                  })}
                </div>
              )}
            </>
          </div>
        </Drawer>
      )}
    </>
  );
};
export default ListingTop;
