import React from 'react';

import { usePathname } from 'util/hooks';

export function withPathname(WrappedComponent) {
  return function WithPathname(props) {
    const pathname = usePathname();
    return <WrappedComponent {...props} pathname={pathname} />;
  };
}
withPathname.displayName = 'withCurrentType';
