import React from 'react';

import moment from 'moment';

import Loader from 'components/common/Loader';
import NoDataShow from 'components/common/NoDataShow';

import { usePathname } from 'util/hooks';

import { EXCEEDING_LIMIT } from 'constants/Common';
import { PAGES } from 'constants/paths.const';

import { ListingCards } from '../common/DiamondListing/MobileComfirmStone';
import Heading from '../common/Heading';
import MobileGroupCard from '../common/MobileDiamondList/MobileGroupCard';

export function emptyData(loading, length) {
  if (loading) return <NoDataShow message={length === 0 || loading ? <Loader loading={true} fixed /> : undefined} />;
  else return <></>;
}

const getGroupedData = (arr, key) => {
  const data = [];
  let iter = -1;
  arr.forEach((x) => {
    if (x[key]) iter += 1;
    if (!data[iter]) data[iter] = [];
    data[iter].push(x);
  });
  return data;
};

const MobileViewList = (props) => {
  const parent = props.parent;

  const pathname = usePathname();

  const groupData =
    pathname === PAGES.QUOTE
      ? props.data.map((dt) => [dt])
      : pathname === PAGES.OFFICE
      ? getGroupedData(props.data, 'isOfficeHeader')
      : pathname === PAGES.MATCHPAIR
      ? getGroupedData(props.data, 'isMatchHeader')
      : pathname === PAGES.UPCOMING
      ? getGroupedData(props.data, 'isUpcomingHeader')
      : null;

  const title = (item) =>
    pathname === PAGES.QUOTE
      ? moment(item.updatedAt).format('DD/MM/YYYY')
      : pathname === PAGES.OFFICE
      ? moment(item.date).format('DD/MM/YYYY') +
        ' - [' +
        moment(item.cabinSlot[0].start).format('hh:mm A') +
        ' - ' +
        moment(item.cabinSlot[0].end).format('hh:mm A') +
        ']'
      : pathname === PAGES.MATCHPAIR
      ? item.groupingTitle
      : pathname === PAGES.CONFIRM
      ? item.memoNo
      : pathname === PAGES.UPCOMING
      ? moment(item.inDt).format('DD/MM/YYYY') + ' 3 PM IST'
      : null;

  return (
    <div className="searchResultListWrapper">
      <div className="selectStonHeader">
        <div className="listingTopBlock width-100">
          <div className="d-flex justify-content-between align-items-center width-100">
            <Heading title={parent.getTitle()} />
            {parent.getListTop ? parent.getListTop() : null}
          </div>
          {parent.getSelection ? parent.getSelection() : null}
        </div>
      </div>
      <div className="searchInnerResult">
        {props.isExceedingLimit ? (
          <div className="empty-data">
            <h3>
              <span>{`Please modify your search, maximum ${EXCEEDING_LIMIT} stones can be displayed`}</span>
            </h3>
          </div>
        ) : props.listView ? (
          <div className="diamondListMobile">
            {groupData ? (
              groupData.map((lItem, index) => {
                return (
                  <MobileGroupCard key={title?.id ?? index} title={title(lItem[0])}>
                    <ListingCards quotePage={pathname === PAGES.QUOTE} data={lItem} currentType={props.currentType} />
                  </MobileGroupCard>
                );
              })
            ) : (
              <ListingCards data={props.data} note={pathname === PAGES.NOTE} currentType={props.currentType} />
            )}
            {emptyData(props.loading, props.data.length)}
          </div>
        ) : (
          <div className="search-result-wrapper">{parent.getGridView()}</div>
        )}
        {parent.getAction()}
      </div>
    </div>
  );
};

export default React.memo(MobileViewList);
