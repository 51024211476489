import React, { useState, useEffect, useReducer } from 'react';

import { Checkbox } from 'antd';
import _, { keys, pickBy } from 'lodash';

import { getLoginUser } from 'services/Commonfunction';

import IntlMessages from 'util/IntlMessages';

import { DOWNLOAD_OPTIONS } from 'constants/Common';

import { downloadZip, getPath, LISTINGPAGES } from '../../DiamondList/DiamondListFunctions';
import OpenNotification from '../CommonButton/OpenNotification';
import Heading from '../Heading';

const MobileDownloadOption = (props) => {
  const [totalChecked, setTotalChecked] = useState([]);
  const [images, setImages] = useState(false);
  const [visible1, setVisible1] = useState(true);
  const [visible2, setVisible2] = useState(true);
  const [visible3, setVisible3] = useState(true);
  const [visible4, setVisible4] = useState(true);
  const [excels, setExcels] = useState(false);
  const [videos, setVideos] = useState(false);
  const [certificates, setCertificates] = useState(false);
  const [roughs, setRoughs] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const loginUser = getLoginUser();

  const isDna = React.useMemo(() => window.location.pathname?.split('/')?.[1] === 'dna', []);

  const ShareOption = (props) => {
    return (
      <li>
        <div className="dropdownBottomItem">
          <div className="dropdownIconCheckBox">
            <Checkbox onChange={props.onChange} checked={props.checked}></Checkbox>
          </div>
          <span className="shareOptionLabel">{props.title}</span>
        </div>
      </li>
    );
  };

  const handleDownloadClick = () => {
    const trueAny = Object.values(totalChecked).some((x) => x === true);
    let totalTrue = 0;
    Object.values(totalChecked).forEach((x) => x === true && totalTrue++);

    if (trueAny) {
      let obj = {};
      const singleTrue = keys(pickBy(totalChecked));

      if (singleTrue.length === 1 && singleTrue[0] === 'isExcel') {
        obj = { zip: false };
      } else if (totalTrue >= 2 || props.checked.length >= 2) {
        obj = { zip: true };
      } else {
        obj = { zip: false };
      }

      obj = {
        ...obj,
        filter: {
          vStnId: _.map(props.checked, 'vStnId'),
        },
        ...totalChecked,
        ...(props.orderDiamond && { orderDiamond: true }),
        companyName: loginUser?.account?.companyName ?? '',
      };

      if (getPath() === LISTINGPAGES.SEARCH_LAYOUT || getPath() === LISTINGPAGES.PREDEFINEMATCHPAIR) {
        obj.isPairExcel = true;

        if (getPath() === LISTINGPAGES.SEARCH_LAYOUT) {
          obj.isLayoutExcel = true;
        }
      }

      downloadZip(obj, () => {
        props.clearAll && props.clearAll();
        props.onClose();

        OpenNotification({
          type: 'success',
          title: 'Wait for sometime, your download is in process. File(s) will be downloaded soon.',
        });
      });
    } else {
      OpenNotification({
        type: 'error',
        title: 'Please select atleast 1 option to download.',
      });
    }
  };

  useEffect(() => {
    if (DOWNLOAD_OPTIONS?.Images) {
      const mappedImagesKeys = _.map(DOWNLOAD_OPTIONS?.Images.subLevel, 'key');

      Object.keys(totalChecked).forEach((v) => {
        mappedImagesKeys.includes(v) && (totalChecked[v] = images);
      });
      forceUpdate();
    }
  }, [images]);

  useEffect(() => {
    if (DOWNLOAD_OPTIONS?.Videos) {
      const mappedVideosKeys = _.map(DOWNLOAD_OPTIONS?.Videos.subLevel, 'key');

      Object.keys(totalChecked).forEach((v) => {
        mappedVideosKeys.includes(v) && (totalChecked[v] = videos);
      });
      forceUpdate();
    }
  }, [videos]);

  useEffect(() => {
    if (DOWNLOAD_OPTIONS?.Roughs) {
      const mappedCertificatesKeys = _.map(DOWNLOAD_OPTIONS?.Certificates.subLevel, 'key');

      Object.keys(totalChecked).forEach((v) => {
        mappedCertificatesKeys.includes(v) && (totalChecked[v] = certificates);
      });
      forceUpdate();
    }
  }, [certificates]);

  useEffect(() => {
    if (DOWNLOAD_OPTIONS?.Roughs) {
      const mappedRoughsKeys = _.map(DOWNLOAD_OPTIONS?.Roughs.subLevel, 'key');

      Object.keys(totalChecked).forEach((v) => {
        mappedRoughsKeys.includes(v) && (totalChecked[v] = roughs);
      });
      forceUpdate();
    }
  }, [roughs]);

  useEffect(() => {
    if (DOWNLOAD_OPTIONS?.Excels) {
      const mappedExcelssKeys = _.map(DOWNLOAD_OPTIONS?.Excels.subLevel, 'key');

      Object.keys(totalChecked).forEach((v) => {
        mappedExcelssKeys.includes(v) && (totalChecked[v] = excels);
      });
      forceUpdate();
    }
  }, [excels]);

  const getAllKeys = () => {
    const keys = _.uniq(
      _.flattenDeep(
        _.map(DOWNLOAD_OPTIONS, (val, key) => {
          let tmp = [];

          if (val && val.key) {
            tmp.push(val.key);
          }

          if (val && val.subLevel && val.subLevel.length) {
            tmp = _.concat(tmp, _.map(val.subLevel, 'key'));
          }
          return tmp;
        }),
      ),
    );
    return keys;
  };

  useEffect(() => {
    const definedKeys = getAllKeys();

    definedKeys.forEach((key) => {
      setTotalChecked((totalChecked) => ({
        ...totalChecked,
        [key]: false,
      }));
    });
  }, []);

  useEffect(() => {
    const definedKeys = getAllKeys();

    definedKeys.forEach((key) => {
      setTotalChecked((totalChecked) => ({
        ...totalChecked,
        [key]: selectAll,
      }));
    });
    setImages(selectAll);
    setVideos(selectAll);
    setCertificates(selectAll);
    setExcels(selectAll);
  }, [selectAll]);

  const hASelectionHandling = () => {
    if (totalChecked && totalChecked.hAFile) {
      totalChecked.idealWhiteImage = true;
      totalChecked.arrBlack = true;
      totalChecked.heartImage = true;
    } else {
      totalChecked.idealWhiteImage = false;
      totalChecked.hAFile = false;
      totalChecked.arrBlack = false;
      totalChecked.heartImage = false;
    }
  };

  useEffect(() => {
    hASelectionHandling();
  }, [totalChecked.hAFile]);

  return (
    <div>
      <Heading title="Download Stone" className="p-0" />
      <div className="">
        <div className="shareStoneMobileItem">
          <div className="shareStoneMobileHead">
            <span className="shareDropdownMobileHead d-flex">
              <div className="dropdownIconCheckBox">
                <Checkbox
                  checked={images}
                  onChange={() => {
                    setImages(!images);
                    hASelectionHandling();
                  }}
                ></Checkbox>
              </div>
              {DOWNLOAD_OPTIONS?.Images.label}
            </span>
            <span
              onClick={() => {
                setVisible1(!visible1);
              }}
            >
              {visible1 ? (
                <img src={require('../../../assets/svg/Search/down-arrow.svg')} alt="" />
              ) : (
                <img src={require('../../../assets/svg/Search/up-arrow.svg')} alt="" />
              )}
            </span>
          </div>
          {visible1 && (
            <div className="mobileShareOption">
              {DOWNLOAD_OPTIONS?.Images?.subLevel?.map((record) => (
                <ShareOption
                  key={record.key}
                  title={record.label}
                  activeClass={totalChecked[record.key] ? 'active' : ''}
                  checked={totalChecked[record.key]}
                  onChange={(e) => {
                    setTotalChecked({
                      ...totalChecked,
                      [record.key]: e.target.checked,
                    });
                  }}
                />
              ))}
            </div>
          )}
        </div>
        <div className="shareStoneMobileItem">
          <div className="shareStoneMobileHead">
            <span className="shareDropdownMobileHead d-flex">
              <div className="dropdownIconCheckBox">
                <Checkbox
                  checked={videos}
                  onChange={() => {
                    setVideos(!videos);
                  }}
                ></Checkbox>
              </div>
              {DOWNLOAD_OPTIONS?.Videos.label}
            </span>
            <span
              onClick={() => {
                setVisible2(!visible2);
              }}
            >
              {visible2 ? (
                <img src={require('../../../assets/svg/Search/down-arrow.svg')} alt="" />
              ) : (
                <img src={require('../../../assets/svg/Search/up-arrow.svg')} alt="" />
              )}
            </span>
          </div>
          {visible2 && (
            <div className="mobileShareOption">
              {DOWNLOAD_OPTIONS?.Videos?.subLevel?.map((record) => (
                <ShareOption
                  key={record.key}
                  title={record.label}
                  activeClass={totalChecked[record.key] ? 'active' : ''}
                  checked={totalChecked[record.key]}
                  onChange={(e) => {
                    setTotalChecked({
                      ...totalChecked,
                      [record.key]: e.target.checked,
                    });
                  }}
                />
              ))}
            </div>
          )}
        </div>
        <div className="shareStoneMobileItem">
          <div className="shareStoneMobileHead">
            <span className="shareDropdownMobileHead d-flex">
              <div className="dropdownIconCheckBox">
                {/* <Checkbox checked={certificates} onChange={() => setCertificates(!certificates)}></Checkbox> */}
              </div>
              Certificates
            </span>
            <span
              onClick={() => {
                setVisible3(!visible3);
              }}
            >
              {visible3 ? (
                <img src={require('../../../assets/svg/Search/down-arrow.svg')} alt="" />
              ) : (
                <img src={require('../../../assets/svg/Search/up-arrow.svg')} alt="" />
              )}
            </span>
          </div>
          {visible3 && (
            <div className="mobileShareOption">
              <ShareOption
                title={DOWNLOAD_OPTIONS.Certificates.label}
                activeClass={totalChecked[DOWNLOAD_OPTIONS.Certificates.key] ? 'active' : ''}
                checked={totalChecked[DOWNLOAD_OPTIONS.Certificates.key]}
                onChange={(e) => {
                  setTotalChecked({
                    ...totalChecked,
                    [DOWNLOAD_OPTIONS.Certificates.key]: e.target.checked,
                  });
                }}
              />
            </div>
          )}
        </div>
        {!isDna && (
          <div className="shareStoneMobileItem">
            <div className="shareStoneMobileHead">
              <span className="shareDropdownMobileHead d-flex">
                <div className="dropdownIconCheckBox">{/* <Checkbox></Checkbox> */}</div>
                Excel
              </span>
              <span
                onClick={() => {
                  setVisible4(!visible4);
                }}
              >
                {visible4 ? (
                  <img src={require('../../../assets/svg/Search/down-arrow.svg')} alt="" />
                ) : (
                  <img src={require('../../../assets/svg/Search/up-arrow.svg')} alt="" />
                )}
              </span>
            </div>
            {visible4 && (
              <div className="mobileShareOption">
                <ShareOption
                  title={DOWNLOAD_OPTIONS.Excels.label}
                  activeClass={totalChecked[DOWNLOAD_OPTIONS.Excels.key] ? 'active' : ''}
                  checked={totalChecked[DOWNLOAD_OPTIONS.Excels.key]}
                  onChange={(e) => {
                    setTotalChecked({
                      ...totalChecked,
                      [DOWNLOAD_OPTIONS.Excels.key]: e.target.checked,
                    });
                  }}
                />
              </div>
            )}
          </div>
        )}
      </div>
      <div className="sideBarPopupButton">
        <a onClick={() => handleDownloadClick()} className="commonButton">
          Download
        </a>
        <a onClick={() => props.onClose()} className="commonButton">
          Cancel
        </a>
      </div>
    </div>
  );
};
export default MobileDownloadOption;
