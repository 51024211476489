import React, { useState, useEffect } from 'react';
import PopupStoneTable from '../common/DiamondListing/PopupStoneTable';
import { handleConfirmStone, handleSaveQuote } from '../../components/DiamondList/DiamondListFunctions';
import IntlMessages from '../../util/IntlMessages';
import { Checkbox, Drawer, InputNumber } from 'antd';
import OpenNotification from '../common/CommonButton/OpenNotification';
import DatePicker from '../common/DatePicker';
import moment from 'moment';

const OfferOrder = (props) => {
  const [remark, setRemark] = useState('');
  const [check, setCheck] = useState(false);
  const [expire, setExpire] = useState(null);
  const [visible, setVisible] = useState(false);
  const [timeError, setTimeError] = useState(false);
  const [data, setData] = useState([]);
  //   useEffect(() => {
  //     if (props.checked && props.checked.length) {
  //       setData([...props.checked]);
  //     }
  //   }, [props.checked]);
  const dateHandleChange = (e, val) => {
    let da = null;
    da = parseInt(e);
    if (Number(e) < 1 || Number(e) > 24) {
      setTimeError(true);
    } else {
      setTimeError(false);
    }
    setExpire(da);
  };

  const disabledDate = (current) => {
    return current && current < moment().subtract(1, 'days');
  };
  const getValue = (quote, finalQuote, dis) => {
    let temp = [];
    if (props.checked && props.checked.length) {
      props.checked.map((t) => {
        temp.push({
          quote: quote,
          finalquote: finalQuote,
          offerDis: dis,
          ...t,
        });
      });
      setData([...temp]);
    }
  };
  return (
    <div className="diamondDetailRightPopup mt-10" style={props.style}>
      <h2 className="popupInnerTitle">Apply Offer</h2>
      <PopupStoneTable
        getValue={(q, f, d) => {
          getValue(q, f, d);
        }}
        offer
        tableData={props.checked}
      />

      <div className="confirmListOption mt-10">
        {/* <label>:</label> */}
        {/* <DatePicker
                    showShip
                    showShipFn={() => {
                        setVisible(true)
                    }}
                    expiryDate={expire}
                    handleChange={dateHandleChange}
                    single disabledDate={disabledDate} label='Offer Valid Till :' /> */}
        <div className="width-50">
          <label>Offer Valid Till (Hours):</label>
          <InputNumber
            placeholder={'Enter Hours'}
            style={{ margin: 0, display: 'block', marginTop: '5px', width: '95%' }}
            type="number"
            value={expire}
            onChange={(e) => dateHandleChange(e)}
          />
          {timeError && (
            <span className="err_span">Enter Offer Time (Hours) * Min Offer Time: 1 | Max Offer Time: 24</span>
          )}
        </div>
        <div className="commonTextArea from-group">
          <label>Comment</label>
          <textarea onChange={(e) => setRemark(e.target.value)}></textarea>
        </div>
      </div>
      <div className="sideBarPopupButton">
        <a
          className="commonButton"
          disabled={timeError}
          onClick={() => {
            if (!expire) {
              OpenNotification({
                type: 'error',
                title: 'Please add offer valid till',
              });
            } else {
              let expireDate = null;
              let quoteHr = expire;
              if (expire) {
                expireDate = moment().add(expire, 'hours').toISOString();
              }

              handleSaveQuote(
                data,
                expireDate,
                remark,

                () => {
                  props.onClose();
                  props.clearAll();
                },
                props.editOffer ? true : false,
                quoteHr,
              );
            }
          }}
        >
          <IntlMessages id="app.Offer" />
        </a>
        <a className="commonButton" onClick={props.onCancel}>
          Cancel Stone
        </a>
      </div>
      <Drawer
        style={{ zIndex: 100000009 }}
        title={false}
        onClose={() => {
          setVisible(false);
        }}
        visible={visible}
        wrapClassName="stone-detail-sidebar"
      ></Drawer>
    </div>
  );
};
export default OfferOrder;
