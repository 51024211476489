import React from 'react';

import loadableFn from '@loadable/component';

import { get, isString } from 'util/utils';

import { withErrorBoundary } from 'libraries/ReactErrorBoundary';

import { compose } from './hocs';

export function loadable(func, options) {
  return compose(withErrorBoundary())(
    loadableFn(func, {
      fallback: !window.location.pathname.includes('/dna') ? <div className="loading-indicator" /> : null,
      ...(isString(options) ? { resolveComponent: (c) => get(c, options) } : { ...options }),
    }),
  );
}
