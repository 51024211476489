import React from 'react';

import AntSelect from 'antd/es/select';
import Tooltip from 'antd/es/tooltip';

import { useBoolean } from 'util/hooks';
import { classNames, isEmpty } from 'util/utils';

import IconHidePasswd from 'assets/svg/ChangePassword/hidden.svg';
import IconShowPasswd from 'assets/svg/ChangePassword/show.svg';
import IconShowInfo from 'assets/svg/info.svg';

import 'components/common/InputBlock/inputBlock.less';

export const Label = React.memo((props) => {
  const { children, info, required, ...rest } = props;

  return (
    <label {...rest}>
      {children}
      {Boolean(required) && <span style={{ marginLeft: '2px', color: props.value ? 'green' : 'red' }}>*</span>}
      {!isEmpty(info) && (
        <Tooltip overlayClassName="Tool" placement="topLeft" title={info}>
          <span>
            <img
              style={{ cursor: 'pointer', marginLeft: '6px', marginBottom: '5px', width: '16px', height: '16px' }}
              src={IconShowInfo}
            />
          </span>
        </Tooltip>
      )}
    </label>
  );
});
Label.displayName = 'Label';

export const Input = React.memo((props) => {
  const { children, className, ...rest } = props;

  return (
    <div className={classNames(['input-wrapper', className])}>
      <input {...rest}>{children}</input>
    </div>
  );
});
Input.displayName = 'Input';

export const Select = AntSelect;

export const Error = React.memo((props) => {
  const { children } = props;
  if (isEmpty(children)) return <span>&nbsp;</span>;
  return <span STYLE="color: red;">{children}</span>;
});
Error.displayName = 'Error';

export const Field = React.memo((props) => {
  const { children, className, hasErrors, ...rest } = props;

  return (
    <div className={classNames(['form-group', 'inputBlock-uniq', className, hasErrors && 'has-errors'])} {...rest}>
      {children}
      <br />
    </div>
  );
});
Field.displayName = 'Field';

export const InputPassword = React.memo((props) => {
  const { children, className, type = 'password', ...rest } = props;

  const [isVisible, setIsVisible] = useBoolean();

  return (
    <div className={classNames(['input-wrapper', className])}>
      <input type={isVisible ? 'text' : type} {...rest}>
        {children}
      </input>
      <img
        className="show-hide-password"
        src={isVisible ? IconHidePasswd : IconShowPasswd}
        alt={`${isVisible ? 'Show' : 'Hide'} Password`}
        onClick={setIsVisible.toggle}
      />
    </div>
  );
});
InputPassword.displayName = 'InputPassword';
