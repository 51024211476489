import React from 'react';

import { ListAction } from 'components/common/DiamondListing/ListAction';

import { StoneService } from 'services/StoneService';

import { notify } from 'util/notify';
import { isEmpty } from 'util/utils';

import { store } from 'redux/store';

export const AddBidStoneToWishList = (props) => {
  const { list, bidConfig, onSuccess, onError, ...rest } = props;

  const handleClick = React.useCallback(async () => {
    const stones = store.getState()?.diamond?.selectedRows?.[list];
    if (isEmpty(stones)) {
      notify.info({ message: 'Please select stone(s) to add to wish list.' });
      return;
    }

    const [err, res] = await StoneService.addStoneToTrack(StoneService.TRACK_TYPE_FILTERS.BID_WISH_LIST.trackType, {
      stones,
      bidConfig,
    });

    void (!err ? onSuccess?.(res) : onError?.(res));
  }, [bidConfig, list, onError, onSuccess]);

  return (
    <ListAction title="Add selected stones to wish list" onClick={handleClick} {...rest}>
      <span>Wish List</span>
    </ListAction>
  );
};
