export const PAGES = {
  LIST: 'diamond-list',
  UPCOMING: 'upcoming',
  DASHBOARD: 'dashboard',
  SEARCH: 'diamond-search',
  SEARCH_MATCH_PAIR: 'search-match-pair',
  FANCY_SEARCH: 'fancy-search',
  CART: 'cart',
  COLLECTION: 'collection',

  WATCHLIST: 'watchlist',
  REMINDER: 'reminder',
  CONFIRM: 'confirm-list',
  BID: 'new-arrival-bid-it',
  QUOTE: 'quote-list',
  STONE_OF_DAY: 'feature-stone',
  NEWARR: 'new-arrivals',
  MATCHPAIR: 'match-pair',
  MYBID: 'my-bid',
  EXCLUSIVE: 'SL-exclusive',
  DEMO: 'demo',
  NOTE: 'notes',
  OFFICE: 'appointment-list',
  DETAIL: 'diamond-detail',
  DETAILS: 'diamond-details',
  QUOTEDAY: 'quote-day',
  SEARCH_LAYOUT: 'layout',
  PURCHASE: 'my-purchase',
  PREDEFINEMATCHPAIR: 'predefine-match-pair',
  NEW_ARRIVAL: 'new-arrival',
  ENQUIRY: 'enquiry',
  DIAMOND_LAYOUT: 'diamond-layout',
  MY_MEMO: 'memo-list',
  // OFFER_LIST: 'offer-list',
};

export const PAGE_TITLE = {
  [PAGES.LIST]: 'Diamond List',
  [PAGES.UPCOMING]: 'Upcoming',
  [PAGES.BID]: 'A.B.S.',
  [PAGES.STONE_OF_DAY]: 'Feature Stone',
  [PAGES.NEWARR]: 'New Arrivals',
  [PAGES.MATCHPAIR]: 'Match Pair',
  [PAGES.MYBID]: 'My Bid',
  [PAGES.EXCLUSIVE]: 'Exclusive Diamonds',
  [PAGES.CART]: 'My Cart',
  [PAGES.COLLECTION]: 'My Collection',

  [PAGES.WATCHLIST]: 'My Watchlist',
  [PAGES.REMINDER]: 'My Reminder',
  [PAGES.CONFIRM]: 'My Confirm List',
  [PAGES.QUOTE]: 'My Quote List',
  [PAGES.NOTE]: 'My Notes',
  [PAGES.ENQUIRY]: 'My Enquiry',
  [PAGES.OFFICE]: 'My Office List',
  [PAGES.QUOTEDAY]: 'Quote Day',
  [PAGES.SEARCH_LAYOUT]: 'Seach Layout',
  [PAGES.PREDEFINEMATCHPAIR]: 'Match Pair',
  [PAGES.NEW_ARRIVAL]: 'New Arrival',
  [PAGES.PURCHASE]: 'My Invoices History',
  [PAGES.MY_MEMO]: 'My Memo',
  // [PAGES.OFFER_LIST]: 'Offer List',
};

export const PAGE_TITLE_DESC = {
  [PAGES.BID]: 'SL Bid System',
};
