import React from 'react';
import { useSelector } from 'react-redux';

import { GridHeightContainer } from 'components/GridHeightContainer/GridHeightContainer';
import TopHeader from 'components/Header';
import MobileHeader from 'components/MobileHeader/index';
import SidebarHeader from 'components/SidebarHeader';

import { useIsMobile } from 'util/hooks';
import { isHTMLElement, logWarn } from 'util/utils';

const App = (props) => {
  const { children } = props;
  const [isMobile] = useIsMobile();

  const settings = useSelector((state) => state.settings);
  const areaRef = React.useRef();
  const { smallSidebar } = settings;

  React.useEffect(() => {
    try {
      const el = areaRef.current;

      if (isHTMLElement(el)) {
        el.style.marginLeft = `${smallSidebar ? 60 : 80}px`;
      }
    } catch (error) {
      logWarn(error);
    }
  }, [smallSidebar]);

  const topHeader = React.useMemo(() => {
    return isMobile ? null : <TopHeader />;
  }, [isMobile]);

  const subHeader = React.useMemo(() => {
    return isMobile ? <MobileHeader /> : <SidebarHeader />;
  }, [isMobile]);

  return (
    <React.Fragment key="App">
      {topHeader}
      <GridHeightContainer
        className="d-flex flex-wrap pageWrapperBlock dashbord-bg-color-white"
        subtractFrom="#root > .HeaderSticky"
        adjustment={-1}
      >
        {subHeader}
        <GridHeightContainer
          ref={areaRef}
          className="content-area pageHeight without-footer"
          subtractFrom="#root > .HeaderSticky,#root .pageWrapperBlock .covidStrip,#root .pageWrapperBlock .headerTopMenu"
          adjustment={-4}
        >
          {children}
        </GridHeightContainer>
      </GridHeightContainer>
    </React.Fragment>
  );
};

export default App;
