import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { filterList } from 'components/DiamondList/table-utils';

import { DiamondActions } from 'redux/reducers/diamond';

export const useGroupSelect = (rows = [], currentType) => {
  const dispatch = useDispatch();

  const selectedRowIds = useSelector((state) => state?.diamond?.selectedRowIds?.[currentType] ?? []);

  const groupRowIds = React.useMemo(() => rows.map((row) => row?.selectionKey ?? row?.id), [rows]);
  const isGroupChecked = React.useMemo(() => {
    return Boolean(groupRowIds.every((id) => selectedRowIds.includes(id)));
  }, [groupRowIds, selectedRowIds]);

  const isIndeterminate = React.useMemo(() => {
    if (isGroupChecked) return false;
    return Boolean(groupRowIds.some((id) => selectedRowIds.includes(id)));
  }, [isGroupChecked, groupRowIds, selectedRowIds]);

  const toggleGroupSelection = React.useCallback(() => {
    isGroupChecked
      ? dispatch(DiamondActions.unSelectRows({ payload: filterList(rows), currentType }))
      : dispatch(DiamondActions.selectRows({ payload: filterList(rows), currentType }));
  }, [isGroupChecked, dispatch, rows, currentType]);

  return [isGroupChecked, isIndeterminate, toggleGroupSelection];
};
