import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useRowSelect = (currentType) => {
  const dispatch = useDispatch();
  const selectedRowIds = useSelector((state) => state.diamond.selectedRowIds[currentType] || []);

  const toggleRowSelection = React.useCallback(
    (row, setValue = true) => {
      if (!row.id) return;
      dispatch({ type: setValue ? 'SELECT_ROWS' : 'UNSELECT_ROWS', payload: row, currentType });
    },
    [currentType, dispatch],
  );

  const clearAllRows = () => dispatch({ type: 'RESET_SELECTED_ROWS' });

  const toggleAllRowSelection = React.useCallback(
    (displayedRows) => {
      const displayedRowIds = displayedRows.map((r) => r.id);

      const areAllChecked = displayedRowIds.every((id) => selectedRowIds.includes(id));
      const isAnyChecked = displayedRowIds.some((id) => selectedRowIds.includes(id));
      const areAllUnChecked = displayedRowIds.every((id) => !selectedRowIds.includes(id));

      if (areAllChecked) {
        displayedRows.forEach((r) => toggleRowSelection(r, false));
      } else if (isAnyChecked) {
        displayedRows.forEach((r) => !selectedRowIds.includes(r.id) && toggleRowSelection(r, true));
      } else if (areAllUnChecked) {
        displayedRows.forEach((r) => toggleRowSelection(r, true));
      }
    },
    [selectedRowIds, toggleRowSelection],
  );

  const isHeaderChecked = (displayedRows) =>
    Boolean(displayedRows?.length > 0 && displayedRows.map((r) => r.id).every((id) => selectedRowIds.includes(id)));

  const isRowChecked = (rowId) => selectedRowIds.includes(rowId);

  return {
    isRowChecked,
    isHeaderChecked,
    toggleRowSelection,
    toggleAllRowSelection,
    clearAllRows,
  };
};
export default useRowSelect;
