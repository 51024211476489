import React from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import { Modal, Tag } from 'antd';
import { invert, find, findLastIndex, groupBy } from 'lodash';

import DiamondGridView from 'components/DiamondList/DiamondGrid';
import Table from 'components/DiamondList/TableBack';
import ViewedStonesTags from 'components/DiamondList/ViewedStonesTags';
import GridHeightContainer from 'components/GridHeightContainer/GridHeightContainer';
import CheckBox from 'components/common/CheckBox';
import DiamondListingAction from 'components/common/DiamondListing/DiamondListingAction';
import ListingTop from 'components/common/DiamondListing/ListingTop';
import SelectStone, { calculate, formatNumber } from 'components/common/DiamondListing/SelectStone';
import { ListHeading } from 'components/common/Heading/ListHeading';
import Loader from 'components/common/Loader';
import NoDataShow from 'components/common/NoDataShow';

import { RowSelectService } from 'services/RowSelectService';
import { Storage } from 'services/Storage';

import { useAutoRef, useBoolean, useIsMobile, useLoading, usePathname, useRowSelect } from 'util/hooks';
import { useCompositeState } from 'util/hooks';
import {
  classNames,
  deepEquals,
  formatDate,
  formatDecimal,
  isArray,
  isEmpty,
  isNotEmpty,
  isNumber,
  unique,
} from 'util/utils';
import { capitalize } from 'util/utils';

import { PAGES } from 'constants/paths.const';

import {
  getColumn,
  fetchDiamondList,
  fetchDiamondPaginate,
  fetchNewArrivalDiamondPaginate,
  fetchCart,
  fetchWatch,
  TITLE,
  fetchReminder,
  fetchConfirmStone,
  fetchStoneOfDay,
  fetchMatchPair,
  fetchPreDefineMatchPair,
  handleExclusiveSearchListId,
  fetchNotes,
  fetchOfficeList,
  fetchEnquiries,
  fetchBlockDiamonds,
  MEMO_BLOCK_CODE,
  MEMO_STATUS,
} from './DiamondListFunctions';
import MasterTags from './MasterTags';
import MobileViewList from './MobileViewList';
import MyPurchaseMobile from './MyPurchaseMobile';
import { PURCHASE_COLUMNS } from './_data';

import {
  trackTypeObj,
  DIAMOND_WEB_STATUS,
  DIAMOND_DISPLAY_TYPE,
  LOCAL_STORAGE_VAR,
  ENABLE_SEARCH_TAGS,
  TABLE_PAGE_LIMIT,
  EXCEEDING_LIMIT,
} from '../../constants/Common';
import { SEARCH_PAGES, handleLayoutSearch } from '../../containers/APIFunctions';

import gridSvg from 'assets/svg/DiamondList/grid.svg';
import listSvg from 'assets/svg/DiamondList/list.svg';

const DiamondList = (props) => {
  const { onSumChange } = props;

  const onSumChangeRef = useAutoRef(onSumChange);

  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const pathname = usePathname();
  const [loading, startLoading, stopLoading] = useLoading(false, true);

  const currentType = pathname;

  const [state, setState, resetState] = useCompositeState(() => ({
    listview: true,
    data: [],
    page: 1,
    limit: TABLE_PAGE_LIMIT,
    sum: {},
    columns: [],
    count: 0,
    searchId: null,
    inTrackDiamonds: [],
    defaultChecked: [],
    inBlockDiamonds: [],
  }));
  const [listView, setListView] = useBoolean(true);
  const [isMobile] = useIsMobile();
  const isExceedingLimit =
    [PAGES.LIST, PAGES.COLLECTION, PAGES.PREDEFINEMATCHPAIR].includes(pathname) && isNumber(state.count)
      ? state.count > EXCEEDING_LIMIT
      : false;
  const { isHeaderChecked } = useRowSelect(currentType);

  const list = React.useMemo(() => (isExceedingLimit ? [] : state.data), [isExceedingLimit, state.data]);

  const isaccount = React.useMemo(() => {
    return location.pathname.split('/')[1] === 'account';
  }, [location.pathname]);

  const confirmPage = [PAGES.CONFIRM, PAGES.PURCHASE].includes(pathname) ? [{ createdAt: 'DESC' }] : [];

  const [sort, setSort] = React.useState(() => (isaccount ? confirmPage : [{ back: 'ASC' }]));

  React.useEffect(() => {
    if ([PAGES.CONFIRM, PAGES.PURCHASE].includes(pathname)) {
      const sortConfirm = isEmpty(sort) ? [{ createdAt: 'DESC' }] : sort;
      const d =
        Object.keys(sortConfirm[0]).includes('createdAt') && sortConfirm?.length > 1
          ? sortConfirm.shift()
          : sortConfirm;
      setSort(sortConfirm);
    }
  }, [pathname, sort]);

  const searchIdListConst = React.useMemo(() => {
    return match.params?.id?.split?.(',');
  }, [match.params?.id]);

  const [searchIdList, setSearchIdList] = React.useState(() => match.params?.id?.split?.(','));
  const [searchId, setSearchId] = React.useState(() => searchIdListConst?.[0]);

  React.useEffect(() => {
    const newSearchIdList = match.params?.id?.split?.(',');
    setSearchIdList(newSearchIdList);
    setSearchId(newSearchIdList?.[0]);
  }, [match.params?.id]);

  const removeSearchId = React.useCallback((_searchId) => {
    setSearchIdList((list) => {
      if (list?.length < 2) return list;
      const index = list.findIndex((id) => id === _searchId);
      const newList = [...list];
      newList.splice(index, 1);

      setTimeout(() => {
        setSearchId((searchId) => (searchId === _searchId ? newList?.[index] ?? newList?.[index - 1] : searchId));
      }, 0);
      return newList;
    });
  }, []);

  const USER_PERMIT = React.useMemo(() => Storage.get('userPermissions'), []);

  React.useEffect(() => {
    const onSumChange = onSumChangeRef.current;
    if (isaccount && !isMobile) void onSumChange?.(state.sum, currentType);
  }, [currentType, isMobile, isaccount, onSumChangeRef, state.sum]);

  React.useEffect(() => {
    RowSelectService.resetSelectedRows(currentType);
    resetState();
  }, [currentType, resetState]);

  React.useEffect(() => {
    if (!isArray(searchIdList) || isEmpty(searchIdList)) {
      if (pathname === PAGES.LIST) history.push('/' + PAGES.DASHBOARD);
      return;
    }
  }, [history, pathname, searchIdList]);

  React.useEffect(() => {
    if (isArray(state.diamondNotFound) && isNotEmpty(state.diamondNotFound)) {
      Modal.info({
        title: 'Stones Not Found',
        content: (
          <div>
            <p>
              <b>Following Stone Id(s) are not found in Search</b>
            </p>
            <p>{state.diamondNotFound.join(', ')}</p>
          </div>
        ),
        onOk() {},
      });
    }
  }, [state.diamondNotFound]);

  const setResponse = React.useCallback(
    (response) => {
      let columns = [];

      if (pathname === PAGES.PURCHASE) {
        columns = PURCHASE_COLUMNS;

        const jangadGroupList = {};

        response.list.forEach(({ memoDetails, ...memo }) => {
          memoDetails.forEach((memoDetail) => {
            jangadGroupList[memoDetail.jangadNo] = jangadGroupList[memoDetail.jangadNo] ?? {};

            jangadGroupList[memoDetail.jangadNo][memo.memoNo] = (() => {
              const details = memoDetails.filter((detail) => detail.jangadNo === memoDetail.jangadNo);
              const diamonds = details.map((detail) => detail.diamondId);
              const jangadList = details.map((detail) => detail.jangadNo);
              return { ...memo, memoDetails: details, diamonds, jangadList };
            })();
          });
        });

        const data = Object.entries(jangadGroupList).map(([jangadNo, orderGroup]) => {
          const records = Object.values(orderGroup)
            .map((order) => {
              const records = order.memoDetails.map((record) => ({
                ...record,
                // groupTitle: `Order No.: ${record.memoNo}`,
              }));

              // records[0].isHeader = true;
              return records;
            })
            .flat();
          const summation = calculate(records);
          return {
            id: jangadNo,
            _groupKey_: jangadNo,
            piece: summation.total_pieces,
            orderNo: unique(Object.keys(orderGroup)).join(', '),
            carat: formatDecimal(summation.total_carat, 3),
            rap: formatDecimal(summation.final_rapaport),
            discount: formatDecimal(summation.final_discount),
            ctpr: formatDecimal(summation.final_price),
            crt: formatDecimal(summation.total_carat),
            amount: formatDecimal(summation.final_value),
            invoiceDate: formatDate(records?.[0]?.createdAt),
            memoNo: jangadNo,
            remarks: records?.[0]?.remarks,

            subRows: records,
            noDetail: true,
          };
        });
        stopLoading();
        return setState({ ...response, data, columns });
      }

      if (pathname === PAGES.MY_MEMO) {
        const list = response?.res?.data?.list ?? [];
        const grouppedList = groupBy(list, 'jangadNo');

        const data = Object.entries(grouppedList)
          .map(([jangadNo, memoList]) => {
            return memoList.map((memo, index) => {
              return {
                ...memo,
                ...memo.diamond,
                blockId: memo.id,
                back: memo.blockDiscount,
                ctPr: memo.blockPricePerCarat,
                amt: memo.blockAmount,
                _groupKey_: jangadNo,
                groupTitle: `Memo No.: ${jangadNo}`,
                isHeader: !index,
              };
            });
          })
          .flat();

        setState({ ...response, data, columns: getColumn() });
        stopLoading();
        return;
      }

      columns = getColumn();

      const col = [
        {
          Header: 'Status',
          accessor: 'enqStatus',
          id: 'enqStatus',
          width: 230,
          Cell({ row }) {
            return (
              <span className={row.original.enqStatus === 1 ? 'openEnquiry' : 'closeEnquiry'}>
                {row.original.enqStatus === 1 ? 'OPEN' : 'CLOSED'}{' '}
              </span>
            );
          },
        },
      ];

      if (response) {
        const diamond = pathname === PAGES.SEARCH_LAYOUT ? response.list : response.data || [];
        let defaultChecked = [];

        if (localStorage.getItem(`${LOCAL_STORAGE_VAR}-more`)) {
          defaultChecked = Storage.get('compare-diamond') ?? [];

          if (defaultChecked && defaultChecked.length > 0) {
            diamond.forEach((d) => {
              if (
                defaultChecked.includes(Storage.get('prev-location') === '/account/confirm-list' ? d.diamondId : d.id)
              ) {
                d['isDefaultChecked'] = true;
              }
            });
          }
          localStorage.removeItem(`${LOCAL_STORAGE_VAR}-more`);
        }

        setState((state) => ({
          ...state,
          ...response,
          data: diamond,
          defaultChecked,
          columns: pathname === PAGES.ENQUIRY ? [...col, ...columns] : columns,
        }));
      } else {
        setState({ defaultChecked: [] });
      }

      stopLoading();
    },
    [pathname, setState, stopLoading],
  );

  const fetch = React.useCallback(() => {
    startLoading();
    setState({ data: [] });

    const payload = {
      date: state.date,
      page: state.page,
      limit: state.limit,
      sort: !isEmpty(sort) ? sort : [],
    };

    if ([PAGES.LIST, PAGES.COLLECTION].includes(pathname)) {
      fetchDiamondList(payload, [{ diamondSearchId: searchId }], setResponse, true);

      return;
    }

    if (pathname === PAGES.UPCOMING) {
      searchId
        ? fetchDiamondList(payload, [{ diamondSearchId: searchId }], setResponse)
        : fetchDiamondPaginate(payload, DIAMOND_WEB_STATUS.UPCOMING, setResponse);
      return;
    }

    if (pathname === PAGES.CART) {
      fetchCart(payload, setResponse);
      return;
    }

    if (pathname === PAGES.WATCHLIST) {
      fetchWatch(payload, setResponse);
      return;
    }

    if (pathname === PAGES.REMINDER) {
      fetchReminder(payload, setResponse);
      return;
    }

    if (pathname === PAGES.CONFIRM) {
      fetchConfirmStone(payload, false, setResponse);
      return;
    }

    if (pathname === PAGES.STONE_OF_DAY) {
      fetchStoneOfDay(payload, [{ sectionType: 11 }], setResponse);
      return;
    }

    if (pathname === PAGES.NEWARR) {
      fetchDiamondPaginate(payload, { viewType: DIAMOND_DISPLAY_TYPE.NEW }, setResponse);
      return;
    }

    if (pathname === PAGES.MATCHPAIR) {
      fetchMatchPair(payload, searchId, setResponse);
      return;
    }

    if (pathname === PAGES.PREDEFINEMATCHPAIR) {
      fetchPreDefineMatchPair(payload, searchId, setResponse);
      return;
    }

    if (pathname === PAGES.SEARCH_LAYOUT) {
      handleLayoutSearch({ layoutNo: { in: [Storage.get('layout')] } }, setResponse);
      return;
    }

    if (pathname === PAGES.EXCLUSIVE) {
      handleExclusiveSearchListId((filter) => {
        filter ? fetchDiamondList(payload, [filter], setResponse) : stopLoading();
      });
      return;
    }

    if (pathname === PAGES.NOTE) {
      fetchNotes(payload, setResponse);
      return;
    }

    if (pathname === PAGES.MY_MEMO) {
      fetchBlockDiamonds(payload, MEMO_BLOCK_CODE, MEMO_STATUS, setResponse);
    }

    if (pathname === PAGES.OFFICE) {
      fetchOfficeList(payload, setResponse);
      return;
    }

    if (pathname === PAGES.QUOTEDAY) {
      fetchDiamondPaginate(payload, { viewType: DIAMOND_DISPLAY_TYPE.NEW }, setResponse);
      return;
    }

    if (pathname === PAGES.NEW_ARRIVAL) {
      searchId
        ? fetchNewArrivalDiamondPaginate(payload, [{ diamondSearchId: searchId }], { viewType: 2 }, setResponse)
        : fetchDiamondPaginate(payload, { viewType: 2 }, setResponse);
      return;
    }

    if (pathname === PAGES.PURCHASE) {
      fetchConfirmStone(payload, true, setResponse);
      return;
    }

    if (pathname === PAGES.ENQUIRY) {
      fetchEnquiries(payload, setResponse);
      return;
    }

    stopLoading();
  }, [startLoading, setState, state.date, state.page, state.limit, sort, pathname, stopLoading, searchId, setResponse]);

  React.useEffect(() => {
    fetch();
  }, [fetch]);

  React.useEffect(() => {
    if (location.state?.viewList) setListView.true();
  }, [location.state?.viewList, setListView]);

  const clearAllSelectedRows = React.useCallback(() => RowSelectService.resetSelectedRows(currentType), [currentType]);

  const modifySearch = React.useCallback(() => {
    Modal.confirm({
      title: 'This page is asking you to confirm that you want to leave',
      content: 'Information you’ve entered may not be saved.',
      autoFocusButton: 'cancel',
      onOk: () => {
        const redirect =
          pathname === PAGES.PREDEFINEMATCHPAIR
            ? SEARCH_PAGES.MATCH_PAIR
            : pathname === PAGES.SEARCH_LAYOUT
            ? `${SEARCH_PAGES.SEARCH_LAYOUT}${location.search}`
            : SEARCH_PAGES.SEARCH;

        history.push(`${redirect}${!isEmpty(searchId) && pathname !== PAGES.SEARCH_LAYOUT ? '/' + searchId : ''}`, {
          searchId,
        });
      },
      onCancel: () => {},
    });
  }, [history, location.search, pathname, searchId]);

  const selection = React.useMemo(() => {
    if (isaccount && !isMobile) return null;

    if (currentType !== 'my-purchase') {
      return (
        <SelectStone
          sum={state.sum}
          profilListing={isaccount ? 'profileStonSelect' : 'diamondListStone'}
          currentType={currentType}
        />
      );
    }
  }, [currentType, isMobile, isaccount, state.sum]);

  const getSelection = React.useCallback(() => selection, [selection]);

  const listTop = React.useMemo(() => {
    return (
      <ListingTop
        modify={
          pathname === PAGES.LIST ||
          pathname === PAGES.UPCOMING ||
          pathname === PAGES.PREDEFINEMATCHPAIR ||
          (!isEmpty(searchIdList) && pathname === PAGES.NEW_ARRIVAL) ||
          pathname === PAGES.SEARCH_LAYOUT
        }
        onModify={() => {
          if (!isArray(searchIdListConst)) return;
          if (searchIdListConst.length > 1) return modifySearch();

          const redirect =
            pathname === PAGES.PREDEFINEMATCHPAIR
              ? SEARCH_PAGES.MATCH_PAIR
              : pathname === PAGES.SEARCH_LAYOUT
              ? `${SEARCH_PAGES.SEARCH_LAYOUT}${location.search}`
              : SEARCH_PAGES.SEARCH;

          history.push(`${redirect}${!isEmpty(searchId) && pathname !== PAGES.SEARCH_LAYOUT ? '/' + searchId : ''}`, {
            searchId,
          });
        }}
        listGridIcon={location.pathname.split('/')[1] !== 'account'}
        copyIcon={location.pathname.split('/')[1] !== 'account'}
        Cart={pathname === PAGES.CART || pathname === PAGES.WATCHLIST}
        onClick={setListView.toggle}
        image={listView ? gridSvg : listSvg}
        filterTop={location.pathname.split('/')[1] === 'account' ? null : true && searchIdList?.length}
        ids={searchIdList}
        searchIdListConst={searchIdListConst}
        setSearchId={setSearchId}
        removeSearchId={removeSearchId}
        searchId={searchId}
        currentType={currentType}
      />
    );
  }, [
    currentType,
    history,
    listView,
    location.pathname,
    location.search,
    modifySearch,
    pathname,
    removeSearchId,
    searchId,
    searchIdList,
    searchIdListConst,
    setListView.toggle,
  ]);

  const getListTop = () => listTop;

  const onPaginationChange = React.useCallback(
    (page, limit) => {
      setState({ page, limit });
      RowSelectService.resetSelectedRows(currentType);
    },
    [currentType, setState],
  );

  const actions = React.useMemo(() => {
    return (
      <DiamondListingAction
        trackType={trackTypeObj[invert(PAGES)[pathname]]}
        {...state}
        {...props}
        modify={
          pathname === PAGES.LIST ||
          pathname === PAGES.UPCOMING ||
          pathname === PAGES.PREDEFINEMATCHPAIR ||
          (!isEmpty(searchIdList) && pathname === PAGES.NEW_ARRIVAL) ||
          pathname === PAGES.SEARCH_LAYOUT
        }
        onModify={() => {
          if (!isArray(searchIdListConst)) return;
          if (searchIdListConst.length > 1) return modifySearch();

          const redirect =
            pathname === PAGES.PREDEFINEMATCHPAIR
              ? SEARCH_PAGES.MATCH_PAIR
              : pathname === PAGES.SEARCH_LAYOUT
              ? `${SEARCH_PAGES.SEARCH_LAYOUT}${location.search}`
              : SEARCH_PAGES.SEARCH;

          history.push(`${redirect}${!isEmpty(searchId) && pathname !== PAGES.SEARCH_LAYOUT ? '/' + searchId : ''}`, {
            searchId,
          });
        }}
        currentType={currentType}
        exportexcel
        noCompare={!USER_PERMIT?.COMPARE?.view || pathname === PAGES.QUOTEDAY}
        nowatch={
          !USER_PERMIT?.WATCHLIST?.view ||
          pathname === PAGES.MY_MEMO ||
          pathname === PAGES.WATCHLIST ||
          pathname === PAGES.CONFIRM ||
          pathname === PAGES.PURCHASE ||
          pathname === PAGES.QUOTEDAY
        }
        nocart={
          !USER_PERMIT?.CART?.view ||
          pathname === PAGES.MY_MEMO ||
          pathname === PAGES.CART ||
          pathname === PAGES.PURCHASE ||
          pathname === PAGES.CONFIRM ||
          pathname === PAGES.UPCOMING
        }
        noreminder={
          !USER_PERMIT?.REMINDER?.view ||
          pathname === PAGES.REMINDER ||
          pathname === PAGES.CONFIRM ||
          pathname === PAGES.PURCHASE ||
          pathname === PAGES.UPCOMING ||
          pathname === PAGES.QUOTEDAY
        }
        delete={
          (USER_PERMIT?.CART?.delete && pathname === PAGES.CART) ||
          (USER_PERMIT?.WATCHLIST?.delete && pathname === PAGES.WATCHLIST) ||
          (USER_PERMIT?.REMINDER?.delete && pathname === PAGES.REMINDER) ||
          (USER_PERMIT?.NOTES?.delete && pathname === PAGES.NOTE) ||
          (USER_PERMIT?.OFFICE?.delete && pathname === PAGES.OFFICE)
        }
        noconfirm={
          !USER_PERMIT?.CONFIRM_STONE?.view ||
          pathname === PAGES.CONFIRM ||
          pathname === PAGES.MY_MEMO ||
          pathname === PAGES.PURCHASE ||
          pathname === PAGES.UPCOMING ||
          pathname === PAGES.QUOTEDAY
        }
        nooffice={
          !USER_PERMIT?.OFFICE?.view ||
          pathname === PAGES.OFFICE ||
          pathname === PAGES.CONFIRM ||
          pathname === PAGES.PURCHASE ||
          pathname === PAGES.UPCOMING
        }
        nonote={
          !USER_PERMIT?.NOTES?.view ||
          pathname === PAGES.MY_MEMO ||
          pathname === PAGES.NOTE ||
          pathname === PAGES.CONFIRM ||
          pathname === PAGES.PURCHASE ||
          pathname === PAGES.UPCOMING ||
          pathname === PAGES.QUOTEDAY
        }
        noquote={
          !USER_PERMIT?.QUOTE?.view ||
          pathname === PAGES.QUOTE ||
          pathname === PAGES.CONFIRM ||
          pathname === PAGES.PURCHASE ||
          pathname === PAGES.UPCOMING
        }
        noenquiry={
          !USER_PERMIT?.ENQUIRY?.view ||
          pathname === PAGES.MY_MEMO ||
          pathname === PAGES.ENQUIRY ||
          pathname === PAGES.CONFIRM ||
          pathname === PAGES.PURCHASE ||
          pathname === PAGES.UPCOMING
        }
        noshowselected={
          pathname === PAGES.ENQUIRY ||
          pathname === PAGES.CONFIRM ||
          pathname === PAGES.PURCHASE ||
          pathname === PAGES.UPCOMING ||
          location.pathname.split('/')[1] === 'account'
        }
        // update={
        //   (USER_PERMIT?.REMINDER?.update && pathname === PAGES.REMINDER) ||
        //   (USER_PERMIT?.NOTES?.update && pathname === PAGES.NOTE)
        // }
        nofinalcalc={pathname === PAGES.UPCOMING}
        noshare={
          !USER_PERMIT?.SHARE_VIA_MAIL?.view ||
          !USER_PERMIT?.SHARE_VIA_WHATSAPP?.view ||
          !USER_PERMIT?.SHARE_VIA_SKYPE?.view
        }
        nodownload={!USER_PERMIT?.DOWNLOAD?.view}
        noprint={!USER_PERMIT?.PRINT?.view}
        onPaginationChange={onPaginationChange}
        clearAll={clearAllSelectedRows}
        fetch={fetch}
        nopaginate={pathname === PAGES.PREDEFINEMATCHPAIR ? true : location.state?.fromLayout}
      />
    );
  }, [
    USER_PERMIT,
    clearAllSelectedRows,
    currentType,
    fetch,
    location.pathname,
    location.state?.fromLayout,
    onPaginationChange,
    pathname,
    props,
    state,
  ]);

  const getAction = React.useCallback(() => actions, [actions]);

  const gridView = React.useMemo(
    () => (
      <>
        <DiamondGridView
          data={list}
          loading={loading}
          currentType={currentType}
          isMatchPair={location.pathname.startsWith('/predefine-match-pair')}
        />
      </>
    ),
    [list, loading, currentType, location.pathname],
  );

  const getGridView = React.useCallback(() => gridView, [gridView]);

  const title = React.useMemo(() => {
    if (location.state?.moreData?.name)
      return `${capitalize(location.state?.moreData?.name)} ${state.count ? `(${state.count})` : ''}`;

    const limitedPaths = [
      'upcoming',
      'new-arrival',
      'SL-exclusive',
      // 'predefine-match-pair',
      'match-pair',
      'diamond-layout',
    ];

    const hasExact = !isEmpty(find(list, { isExactSearch: true }));

    const count = hasExact
      ? findLastIndex(list, (o) => o.isExactSearch == true) + 1
      : limitedPaths.includes(pathname) && state.count > 250
      ? 250
      : state.count;

    return TITLE[pathname] + ' (' + formatNumber(count) + ')';
  }, [location.state?.moreData?.name, pathname, state.count, list]);

  const getTitle = () => title;

  const gridHeight = isaccount
    ? getGridHeight(
        [
          'headerTopMenu',
          'accountTopBlock',
          'accountTabBox',
          'filterTopMainBlock',
          'diamondListingAction',
          'covidStrip',
          'HeaderStripBlock',
          'smallHeaderWrapper',
        ],
        22,
      )
    : getGridHeight(
        ['diamondListingAction', 'headerTopMenu', 'selectStonHeader', 'covidStrip', 'HeaderStripBlock'],
        12,
      );

  const defaultTable = React.useMemo(() => {
    return (
      <Table
        data={list}
        loading={loading}
        handleSort={setSort}
        sort={sort}
        currentType={currentType}
        defaultChecked={state.defaultChecked}
        columns={state.columns}
        collapsible={pathname === PAGES.PURCHASE}
      />
    );
  }, [list, loading, sort, currentType, state.defaultChecked, state.columns, pathname]);

  if (isMobile) {
    if (pathname === PAGES.PURCHASE) {
      return (
        <MyPurchaseMobile
          data={list}
          currentType={currentType}
          parent={{ getTitle, getListTop, getSelection, getGridView, getAction }}
        />
      );
    }

    return (
      <MobileViewList
        {...{ ...props, ...state, data: list }}
        isExceedingLimit={isExceedingLimit}
        listView={listView}
        currentType={currentType}
        parent={{ getTitle, getListTop, getSelection, getGridView, getAction }}
      />
    );
  }

  if (isaccount) {
    return (
      <div className="searchResultListWrapper profilePage">
        <div className="searchInnerResult">
          <div className="position-relative">{selection}</div>
          <div className="searchResultTable profilePageTable" style={{ height: gridHeight, overflow: 'auto' }}>
            <Table
              data={list}
              loading={loading}
              sort={sort}
              handleSort={setSort}
              columns={state.columns}
              currentType={currentType}
              defaultChecked={state.defaultChecked}
              noCheckBox={pathname === PAGES.PURCHASE}
              showGroupCheckBox={pathname === PAGES.PURCHASE}
              nocheck={pathname === PAGES.PURCHASE}
            />
          </div>
          {actions}
        </div>
      </div>
    );
  }

  return (
    <div className="searchResultListWrapper">
      <div className="dn-list-top">
        <div className="dn-list-header">
          <ListHeading>{title}</ListHeading>
          {!listView && (
            <div className="stone-grid-checkbox">
              <CheckBox
                className="gridCheckBox"
                checked={isHeaderChecked(state.data)}
                onChange={() =>
                  !isHeaderChecked(state.data)
                    ? RowSelectService.selectRows(currentType, state.data)
                    : RowSelectService.unSelectRows(currentType, state.data)
                }
                label={'Select All'}
              />
            </div>
          )}
          {isArray(searchIdList) && searchIdList?.length > 1 && (
            <div className="dn-list-tags" style={{ marginLeft: '1rem', marginRight: 'auto' }}>
              <div className="dn-list-tags-title">Results: </div>
              {searchIdList.map((_searchId, index) => {
                let _index = searchIdListConst.findIndex((id) => id === _searchId);
                return (
                  <Tag
                    key={`${_searchId}_${index}_${_index}`}
                    onClick={() => setSearchId(_searchId)}
                    onClose={() => removeSearchId(_searchId)}
                    color={_searchId === searchId ? '#0a0a0a' : undefined}
                    closable
                  >
                    Result{++_index}
                  </Tag>
                );
              })}
            </div>
          )}
          <ViewedStonesTags />
        </div>
        <div className="selectStonHeader">
          <div className="d-flex justify-content-between align-items-center width-100 listingTopBlock">
            <div className="d-flex align-items-center  flex-wrap max-w-90">
              {/* <div className="d-flex item-centre">
                    <Heading title={title} />
                  </div> */}
              {/* <ListHint>
                    <ListHintItem icon={LayoutIcon}>Layout</ListHintItem>
                    <ListHintItem icon={MatchPairIcon}>Match Pair</ListHintItem>
                  </ListHint> */}
              {ENABLE_SEARCH_TAGS && pathname === PAGES.LIST && (
                <div className="d-flex width-100 listTag flex-wrap">
                  {state.seachFilterTag && <MasterTags filterData={state.seachFilterTag.displayData} />}
                </div>
              )}
            </div>
            {selection}
            {listTop}
          </div>
        </div>
      </div>
      <div className="searchInnerResult">
        <GridHeightContainer
          className={classNames([listView ? 'searchResultTable' : 'search-result-wrapper'])}
          subtractFrom="#root .HeaderSticky,.searchResultListWrapper .dn-list-top,.searchResultListWrapper .diamondListingAction"
        >
          {isExceedingLimit ? (
            <div className="empty-data">
              <h3>
                <span>{`Please modify your search, maximum ${EXCEEDING_LIMIT} stones can be displayed`}</span>
              </h3>
            </div>
          ) : listView ? (
            defaultTable
          ) : (
            gridView
          )}
        </GridHeightContainer>
      </div>
      {actions}
    </div>
  );
};

export default DiamondList;

function getElementByClass(name) {
  if (document.getElementsByClassName(name) && document.getElementsByClassName(name)[0])
    return document.getElementsByClassName(name)[0];
  else return null;
}

export function getGridHeight(allClasses, initial) {
  const allContainers = {};

  allClasses.map((classname) => {
    allContainers[classname] = getElementByClass(classname) ? getElementByClass(classname) : 0;
    return true;
  });
  let totalHeight = window.innerHeight - initial;

  Object.keys(allContainers).map((key) => {
    if (allContainers[key] && allContainers[key].offsetHeight) {
      totalHeight = totalHeight - allContainers[key].offsetHeight;
    }
    return true;
  });
  return totalHeight;
}

export function emptyData(loading, length) {
  if (loading) return <NoDataShow message={length === 0 || loading ? <Loader loading={true} fixed /> : undefined} />;
  else return <></>;
}

export function getState(obj) {
  return {
    listview: true,
    data: [],
    page: 1,
    limit: TABLE_PAGE_LIMIT,
    sum: {},
    columns: [],
    count: 0,
    searchId: null,
    inTrackDiamonds: [],
    defaultChecked: [],
    inBlockDiamonds: [],
    ...obj,
  };
}

export const isArrayEqual = deepEquals;
