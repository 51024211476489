import React from 'react';

import { Field, Label, Input, Error } from 'components/Input/Input';

import { isEmpty } from 'util/utils';

import './inputBlock.less';

export const InputBlock = React.memo((props) => {
  const { error, label: title, hasError: he, ...rest } = props;

  const hasError = !isEmpty(error);

  return (
    <Field hasErrors={hasError}>
      <Label>{title}</Label>
      <Input {...rest} />
      <Error>{error}</Error>
    </Field>
  );
});
InputBlock.displayName = 'InputBlock';

export default InputBlock;
