import forOwn from 'lodash/forOwn';

import { catchError } from './catchError';
import { clone } from './clone';
import { isArray } from './isArray';
import { isEmpty } from './isEmpty';
import { isObject } from './isObject';
import { isString } from './isString';

/**
 * Prune refs with empty values
 * @author Sagar Panchal <panchal.sagar@outlook.com>
 * @param {*} input value
 * @param {*} options options
 * @returns {boolean}
 */
export function pruneEmpty(input, options) {
  options = { clone: true, ...options };
  input = options.clone ? clone(input) : input;

  const prune = (current) => {
    current = catchError(() => {
      if (isEmpty(current)) return;
      if (isString(current)) current = current?.trim?.();
      if (isArray(current)) current = current.filter((value) => !isEmpty(prune(value), options));

      forOwn(current, (value, key) => {
        if (isEmpty(value, options) || ((isObject(value) || isArray(value)) && isEmpty(prune(value), options)))
          delete current[key];
      });
      return current;
    });

    current = !isEmpty(current, options) ? current : undefined;
    return current;
  };
  return prune(input);
}
