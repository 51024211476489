import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';

import _, { capitalize } from 'lodash';

import HeaderStrip from 'components/common/Header/HeaderStrip';
import LangDropdown from 'components/common/Header/HeaderStrip/LangDropdown';

import { AuthService } from 'services/Auth';
import { Storage } from 'services/Storage';

import { IntlMessage } from 'util/IntlMessages';

import { GET_EXCLUSIVE_COLLECTION } from 'constants/apiConstant';

import UtilService from '../../services/util';
import getData from '../common/svgSidebar';

import 'react-tippy/dist/tippy.css';
import downarrow from 'assets/svg/MobileHeader/down-arrow.svg';
import uparrow from 'assets/svg/MobileHeader/up-arrow.svg';

class MobileSidebar extends Component {
  state = {
    visible: false,
    visible1: false,
    visible2: false,
    visible3: false,
    list: [],
  };

  submenu = () => {
    this.setState(
      {
        visible: !this.state.visible,
      },
      () => {
        if (this.state.list.length === 0 && this.state.visible) this.fetchExclusiveCollection();
      },
    );
  };

  submenu1 = () => {
    this.setState({
      visible1: !this.state.visible1,
    });
  };

  fetchExclusiveCollection = async () => {
    const [, res] = await UtilService.callApi({
      ...GET_EXCLUSIVE_COLLECTION,
      showLoader: false,
      request: { page: 1, limit: 10 },
    });

    this.setState({
      list: res?.data?.list ?? [],
    });
  };

  handleRedirect = (item) => () => {
    this.props.onClose();

    this.props.history.push(`diamond-list/${item.id}`, {
      moreData: { ...item },
    });
  };

  submenu2 = () => {
    this.setState({
      visible2: !this.state.visible2,
    });
  };

  submenu3 = () => {
    this.setState({
      visible3: !this.state.visible3,
    });
  };

  render() {
    const crnt_url = window.location.pathname;
    const permissionData = Storage.get('userPermissions');
    const sidebarSvgFiles = getData(permissionData);
    const cuIndex = _.findIndex(sidebarSvgFiles, { url: crnt_url });
    return (
      <div className="headerMenuBox">
        <div className="headerMeniInner">
          <HeaderStrip
          // className={btnClass || btnClass1 || btnClass2}
          // onClick={this.HeaderStripCick}
          />
          <ul id="links" className="">
            <li onClick={this.props.onClose}>
              <Link className="menuItem" to="/diamond-search">
                <IntlMessage id="app.Search" />
              </Link>
            </li>
            <li onClick={this.props.onClose}>
              <Link className="menuItem" to="/search-match-pair">
                <IntlMessage id="app.searchMatchPair" />
              </Link>
            </li>
            {/* <li onClick={this.props.onClose}>
              <Link className="menuItem" to="/search-layout">
                Search Layout
              </Link>
            </li> */}
            {/* <li onClick={this.props.onClose}>
              <Link className="menuItem" to="/new-arrival-bid-it">
                Bid It
              </Link>
            </li> */}
            {/* <li onClick={this.props.onClose}>
              <Link className="menuItem" to="/SL-exclusive">
                SL Diamonds
              </Link>
            </li> */}

            <li className={'d-flex align-items-center'}>
              <a onClick={this.submenu.bind(this)} className="menuItem active">
                <IntlMessage id="app.more" />
                {this.state.visible ? (
                  <img
                    onClick={this.submenu.bind(this)}
                    src={downarrow}
                    // src={require('assets/svg/MobileHeader/down-arrow.svg')}
                    alt="downarrow-mobile"
                  />
                ) : (
                  <img onClick={this.submenu.bind(this)} src={uparrow} alt="" />
                  // <img onClick={this.submenu.bind(this)} src={require('assets/svg/MobileHeader/up-arrow.svg')} alt="" />
                )}
              </a>
              <div className={`mobileSubMenu ${this.state.visible && 'active'}`}>
                <ul>
                  <li onClick={this.props.onClose}>
                    {permissionData?.FEATURE_STONE?.view && (
                      <Link to="/feature-stone">
                        <IntlMessage id="app.featuredStoneDashboard" />
                      </Link>
                    )}
                  </li>
                  {/* {this.state.list.length &&
                    this.state.list?.map((item, key) => (
                      <li key={key}>
                        <a onClick={this.handleRedirect(item)}>{capitalize(item.name)}</a>
                      </li>
                    ))} */}
                </ul>
              </div>
            </li>
            {/* <li onClick={this.props.onClose}>
              <Link className="menuItem" to="/upcoming">
                <IntlMessage id="app.Upcoming" />
              </Link>
            </li> */}
            <li onClick={this.props.onClose}>
              <Link className="menuItem" to="/account">
                <IntlMessage id="app.myAccount" />
              </Link>
            </li>
            <li onClick={AuthService.signOut}>
              <a>
                <IntlMessage id="app.Logout" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default withRouter(MobileSidebar);
