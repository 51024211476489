import { routerReducer as routing } from 'react-router-redux';
import { combineReducers } from 'redux';

import auth from 'redux/reducers/Auth';
import diamond from 'redux/reducers/diamond';
import loader from 'redux/reducers/loader';
import settings from 'redux/reducers/settings';
import stats from 'redux/reducers/stats';

import common from './Common';
import deleteReminder from './deleteReminder';
import setTimer from './timer';
import viewedStones from './viewed-stones';

const oldReducers = { routing, common, deleteReminder, setTimer };

const reducers = combineReducers({
  ...oldReducers,
  auth,
  diamond,
  loader,
  viewedStones,
  settings,
  stats,
});

export default reducers;
