import React from 'react';
import { useSelector } from 'react-redux';
import { useTable } from 'react-table';

import { Checkbox, Drawer } from 'antd';
import { groupBy, findLastIndex } from 'lodash';

import { handleMatchPair } from 'containers/APIFunctions';

import DiamondDetail from 'components/DiamondDetail';
import DiamondListingHead from 'components/DiamondList/ListTableHeaderBack';
// import TableCollapse from 'components/DiamondList/TableCollapse';
import { TableGroupCollapse } from 'components/DiamondList/TableGroupCollapse';
import RowGroupHeader from 'components/common/DiamondListing/RowGroupHeader';
import Status from 'components/common/DiamondListing/Status';
import TableGrouping from 'components/common/DiamondListing/TableGrouping';
import Image from 'components/common/Image';
import NoDataShow from 'components/common/NoDataShow';

import { RowSelectService } from 'services/RowSelectService';

import { history } from 'util/history';
import { useAutoRef, useBoolean, useFilters, useIsIntersecting, usePathname, useSortBy } from 'util/hooks';
import { notify } from 'util/notify';
import {
  catchError,
  classNames,
  deepEquals,
  hasKey,
  isArray,
  isBoolean,
  isEmpty,
  isNotEmpty,
  isNumber,
  pruneEmpty,
} from 'util/utils';

import { IS_TABLE_COLLAPSABLE, getFileUrl, ENABLE_DNA_DRAWER } from 'constants/Common';
import { PAGES } from 'constants/paths.const';

import * as TableConfig from './table-config';
import * as TableUtils from './table-utils';

export {
  SORTABLE_COLUMNS,
  TABLE_PAGE_LIMIT,
  FILTER_COLUMNS,
  FLOAT_COLUMNS,
  ROUND_COLUMNS,
  TOTAL_COLUMNS,
} from './table-config';

const getStyles = (props, item, type) => [
  props,
  {
    style: {
      textAlign: item.cellClass ? item.cellClass.replace('text-', '') : 'center',
      width: (item.width || '100') + 'px',
      fontWeight: type === 'cell' && ['stoneId', 'vStnId'].includes(item.id) ? '600' : '',
      color: type === 'cell' && (item.link || ['lbNm', 'rptNo', 'dna', 'vStnId'].includes(item.id)) ? '#008cba' : '',
    },
  },
];
const headerProps = (props, { column }) => getStyles(props, column, 'header');
const cellProps = (props, { cell }) => getStyles(props, cell.column, 'cell');

// TODO: DEBUG
const makeSticky = (table, options = {}) => {
  const stickyIndex = options.index ?? 5;
  const rows = table?.querySelectorAll?.('tr');
  const cellSlice = Array.prototype.slice.call(rows[0].children, 0, stickyIndex);
  const widthList = Array.prototype.map.call(cellSlice, (cell) => cell?.getBoundingClientRect()?.width);
  const leftOffsetList = widthList.map((_, index) => {
    let output = options.offset ?? -0;
    for (let i = 0; i < index; i++) output = output + widthList[i];
    return output;
  });

  table.style['border-collapse'] = 'separate';
  table.style['border-spacing'] = 0;

  Array.prototype.forEach.call(rows, (row) => {
    const cellList = Array.prototype.slice.call(row.children, 0, stickyIndex);

    Array.prototype.forEach.call(cellList, (cell, index) => {
      if (cell.classList.contains('groupingTableAmount', 'activebg')) return;

      cell['data-orig-z-index'] = cell['data-orig-z-index'] ?? cell.style['z-index'] ?? 0;
      const zIndex = isNumber(Number(cell['data-orig-z-index'])) ? Number(cell['data-orig-z-index']) : 0;

      cell.style['position'] = 'sticky';
      cell.style['left'] = `${leftOffsetList[index]}px`;
      cell.style['z-index'] = cell.tagName === 'TD' ? zIndex + 1 : zIndex + 101;
      cell.style['background'] = cell.tagName === 'TD' ? '#fff' : '';
      if (cell.classList.contains('tableGropupingBox')) cell.style.removeProperty('z-index');
      // cell.classList.add('cell-sticky');

      if (index + 1 === cellList?.length) cell.classList.add('cell-shadow-left');
    });
  });
};

const ActionHeader = React.memo((props) => {
  const { areAllChecked, displayedRows, disabled, nostatus, children } = props;
  const { currentType, noCheckBox } = props;

  const propsRef = useAutoRef(props);

  const selectedIdList = useSelector((state) => state.diamond.selectedRowIds?.[currentType] ?? []);

  const [isSelected, isIndeterminate] = React.useMemo(() => {
    if (isEmpty(displayedRows)) return [false, false];
    if (isEmpty(selectedIdList)) return [false, false];

    const currentIdList = displayedRows.map((row) => row?.selectionKey ?? row?.id);
    const isSelected = currentIdList.every((currentId) => selectedIdList.includes(currentId));
    const isIndeterminate = !isSelected && currentIdList.some((currentId) => selectedIdList.includes(currentId));

    return [isSelected, isIndeterminate];
  }, [displayedRows, selectedIdList]);

  const toggleSelection = React.useCallback(() => {
    isSelected
      ? RowSelectService.unSelectRows(currentType, displayedRows)
      : RowSelectService.selectRows(currentType, displayedRows);
  }, [currentType, displayedRows, isSelected]);

  React.useEffect(() => {
    if (areAllChecked) {
      if (!isEmpty(displayedRows)) RowSelectService.selectRows(currentType, displayedRows, true);
    }
  }, [areAllChecked, currentType, displayedRows, propsRef]);

  return (
    <div className="selectActionIcon">
      {!noCheckBox && (
        <div className={classNames(['selectActionIconWrapper'])}>
          <Checkbox
            key="selection_header"
            checked={isSelected}
            indeterminate={isIndeterminate}
            onChange={toggleSelection}
            className={classNames([disabled && 'disabled'])}
            disabled={disabled}
          />
        </div>
      )}
      {!nostatus && (
        <div className="showSatusBlock">
          <span>All</span>
        </div>
      )}
      {children}
    </div>
  );
});
ActionHeader.displayName = 'ActionHeader';

const ActionCell = React.memo((props) => {
  const { isHeader, row = {}, disabled, nostatus, children } = props;
  const { currentType, noCheckBox, areAllChecked } = props;

  const divRef = React.useRef(props);
  const propsRef = useAutoRef(props);

  const isIntersecting = useIsIntersecting(divRef);
  const isIntersectingRef = useAutoRef(isIntersecting);

  const isSelected = useSelector(
    (state) => Boolean(state.diamond.status?.[currentType]?.[row.selectionKey ?? row.id]),
    (left, right) => (isIntersectingRef.current ? left === right : true),
  );

  const showCheckbox = !(noCheckBox || row?.hideCheckBox);

  const toggleSelection = React.useCallback(() => {
    if (row?.disableSelection) {
      if (row?.onCheckError) notify.error({ message: row?.onCheckError });
      return;
    }
    isSelected ? RowSelectService.unSelectRows(currentType, [row]) : RowSelectService.selectRows(currentType, [row]);
  }, [currentType, isSelected, row]);

  React.useEffect(() => {
    const { row } = propsRef.current;
    if (row?.disableSelection) return;
    if (areAllChecked && !isEmpty(row) && row?.isDefaultChecked) RowSelectService.selectRows(currentType, [row]);
  }, [areAllChecked, currentType, propsRef]);

  return (
    <div ref={divRef} className="selectActionIcon">
      {showCheckbox && (
        <div className={classNames(['selectActionIconWrapper', row.altCert && 'doubleRow'])}>
          <Checkbox
            disabled={disabled || row?.disableCheckbox}
            key={`selection_${isHeader ? 'header' : row.id}`}
            checked={isSelected}
            // indeterminate={!isIntersecting}
            onChange={toggleSelection}
            className={classNames([(disabled || row?.disableSelection || row?.disableSelection) && 'disabled'])}
          />
        </div>
      )}
      {!nostatus && <Status row={row} />}
      {children}
    </div>
  );
});
ActionCell.displayName = 'ActionCell';

const Resource = React.memo((props) => {
  const { row } = props;
  const { vStnId, vStnIds } = row ?? {};

  const pathname = usePathname();

  const imageSource = getFileUrl(
    pathname === PAGES.PREDEFINEMATCHPAIR ? 'JPG_IMG' : 'JPG_ALT',
    vStnId ?? vStnIds ?? '',
  );

  return (
    <div className="d-flex width-max-content j-content-center tableSmallImage">
      <Image src={imageSource} alt="" />
    </div>
  );
});
Resource.displayName = 'Resource';

const CellAltCert = React.memo((props) => {
  const { row, cell } = props;

  return (
    <div>
      <span style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
        <span
          style={{ width: '100%' }}
          onClick={(e) => {
            void e?.persist?.();

            if (cell.column.id === 'lbNm' && row.original.lbNm && row.original.rptNo) {
              void e?.stopPropagation?.();
              TableUtils.viewStoneCertFile(row.original);
            }
          }}
        >
          {cell.render('Cell')}
        </span>
        {row.original.altCert.map((alt, index) => {
          return (
            <span
              key={index}
              onClick={(e) => {
                void e?.persist?.();

                if (cell.column.id === 'lbNm' && alt.lbNm && alt.rptNo) {
                  void e?.stopPropagation?.();
                  TableUtils.viewStoneCertFile(alt);
                }
              }}
              style={{ width: '100%' }}
              className={
                TableConfig.FLOAT_COLUMNS.includes(cell.column.id) || TableConfig.ROUND_COLUMNS.includes(cell.column.id)
                  ? 'numberValue'
                  : null
              }
            >
              {cell.column.id === 'dna' ? <div /> : TableUtils.formatValues(alt[cell.column.id], cell.column.id)}
            </span>
          );
        })}
      </span>
    </div>
  );
});
CellAltCert.displayName = 'CellAltCert';

const Cell = React.memo((props) => {
  const { cell, row, overrideColumns, grouppedRows } = props;
  const { onClick, expandedList = [] } = props;

  const tdRef = React.useRef();

  const OverrideCell = overrideColumns?.[cell?.column?.id];

  const rowSpan = React.useMemo(() => {
    if (window.location?.pathname.includes('predefine-match-pair') && cell.column.id === 'Details') {
      const { id, pairStkNo } = { ...row?.original };
      const rows = grouppedRows?.[pairStkNo];

      if (rows?.length === 2) {
        const expList = rows.filter((row) => row?.id !== rows?.[rows.length - 1]?.id && expandedList.includes(row?.id));
        if (!isEmpty(expList)) return 1;
        if (rows?.[0]?.id === id) return 2;
        return 0;
      }
    }
    return 1;
  }, [cell.column.id, expandedList, grouppedRows, row?.original]);

  const content = React.useMemo(() => {
    if (!isEmpty(OverrideCell)) {
      return <OverrideCell row={row} cell={cell} />;
    }

    if (cell.column.id === 'Details') {
      return <Resource row={row.original} />;
    }

    if (row.original.altCert && !TableConfig.ALT_COLUMNS.includes(cell.column.id)) {
      return <CellAltCert row={row} cell={cell} />;
    }

    return <div>{cell.render('Cell')}</div>;
  }, [OverrideCell, cell, row]);

  if (rowSpan === 0) return null;

  return (
    <td ref={tdRef} {...cell.getCellProps(cellProps)} rowSpan={rowSpan} onClick={(e) => onClick(e, cell)}>
      {content}
    </td>
  );
});
Cell.displayName = 'Cell';

const Row = React.memo((props) => {
  const { row, currentType, grouppedRows, overrideColumns } = props;

  const [drawerVisible, setDrawerVisible] = useBoolean();
  const [isGroupExpanded, setGroupExpanded] = useBoolean();

  const trRef = React.useRef();

  const isIntersecting = useIsIntersecting(trRef);
  const isIntersectingRef = useAutoRef(isIntersecting);

  const isSelected = useSelector(
    (state) => Boolean(state.diamond.status?.[currentType]?.[row.original.selectionKey ?? row.original.id]),
    (left, right) => (isIntersectingRef.current ? left === right : true),
  );

  const pathname = usePathname();

  const handleClick = React.useCallback(
    async (e, cell) => {
      const record = pruneEmpty(row.original);

      if (pathname === PAGES.DIAMOND_LAYOUT) {
        return handleMatchPair(
          { layoutNo: { in: [record.layoutNo] } },
          (id) => id && history.push(`/${PAGES.SEARCH_LAYOUT}?` + id),
        );
      }

      if (cell.column.id === 'selection') return;

      if (cell.column.link && cell.value) {
        let field = cell.column.link.slice(cell.column.link.indexOf('$') + 1, cell.column.link.length);
        field = field.slice(0, field.indexOf('$'));
        const link = cell.column.link.replace('$' + field + '$', record[field]);
        return window.open(link);
      }

      if (['dna'].includes(cell.column.id)) {
        pathname === PAGES.CONFIRM || pathname === PAGES.PURCHASE
          ? window.open(`/dna/${record.diamondId}`)
          : window.open(`/dna/${record.id}`);
        return;
      }

      if (['ftc'].includes(cell.column.id) && cell.value) {
        return window.open(`/ftc/${record.id}`);
      }

      if (['lbNm', 'rptNo'].includes(cell.column.id) && !record.altCert && record.lbNm && record.rptNo) {
        return TableUtils.viewStoneCertFile(record);
      }

      if (!TableConfig.NOCHECK_COLUMNS.includes(cell.column.id)) {
        ENABLE_DNA_DRAWER && !hasKey(record, 'subRows') ? setDrawerVisible.true() : setGroupExpanded.toggle();
        return;
      }
    },
    [pathname, row.original, setGroupExpanded, setDrawerVisible],
  );

  return (
    <React.Fragment>
      <tr
        {...row.getRowProps({
          'table-row': `${currentType}${row.original.id ?? row.original.accessor}`,
          className: classNames([isSelected && 'selectTr']),
        })}
        ref={trRef}
      >
        {row.cells.map((cell) => (
          <Cell
            key={cell.column.id + '_cell'}
            grouppedRows={grouppedRows}
            overrideColumns={overrideColumns}
            onClick={handleClick}
            row={row}
            cell={cell}
          />
        ))}
      </tr>
      {IS_TABLE_COLLAPSABLE && row?.original?.subRows && (
        <TableGroupCollapse
          visible={isGroupExpanded}
          data={row.original.subRows}
          colSpan={row.cells.length}
          currentType={currentType}
        >
          {({ data, columns }) => <Table currentType={currentType} data={data} columns={columns} />}
        </TableGroupCollapse>
      )}
      <Drawer
        visible={drawerVisible}
        onClose={setDrawerVisible.false}
        wrapClassName="diamondDetailPopup"
        destroyOnClose
      >
        {drawerVisible && (
          <DiamondDetail markAsSeen diamondPopup data={row.original} onClose={setDrawerVisible.false} />
        )}
      </Drawer>
    </React.Fragment>
  );
});
Row.displayName = 'Row';

function Table(props) {
  const {
    overrideColumns = {},
    handleSort,
    groupKey = '_groupKey_',
    //statuses
    nocheck = false,
    nostatus = false,
    noCheckBox = false,
    showGroupCheckBox = false,
    loading = false,
    nodots = false,
    noGrp,
    noStoneFormat,
    currentType,
    areAllChecked = false,
    FilterOption = true,
    canSort = true,
    sort,
  } = props;

  const pathname = usePathname();
  const tableRef = React.useRef();

  const selectionColumn = React.useMemo(() => {
    return {
      id: 'selection',
      Header({ rows }) {
        const hasSimilar = rows.some((row) => Boolean(row.original.isExactSearch));

        const _rows = React.useMemo(() => {
          return rows
            .filter((row) => (hasSimilar ? Boolean(row.original.isExactSearch) : true))
            .map((row) => row.original);
        }, [hasSimilar, rows]);

        return (
          <ActionHeader
            displayedRows={_rows}
            nostatus={nostatus}
            disabled={pathname === PAGES.OFFER_LIST}
            noCheckBox={noCheckBox}
            currentType={currentType}
            areAllChecked={areAllChecked}
          />
        );
      },
      Cell({ row }) {
        return (
          <ActionCell
            nostatus={nostatus}
            row={row.original}
            noCheckBox={noCheckBox}
            currentType={currentType}
            areAllChecked={areAllChecked}
          />
        );
      },
    };
  }, [areAllChecked, currentType, noCheckBox, nostatus, pathname]);

  const data = React.useMemo(() => {
    if (!isArray(props.data)) return [];
    return props?.data.map((record) => {
      if (!noStoneFormat) record = TableUtils.updateRecord(record);
      return { ...record };
    });
  }, [props.data, noStoneFormat]);

  const columns = React.useMemo(() => {
    const _columns = isArray(props.columns) ? [...props.columns] : TableUtils.getColumns();

    const detailsIndex = _columns.findIndex((col) => col?.id === 'Details');
    if (detailsIndex > -1) _columns.splice(detailsIndex, 1);

    if (pathname !== PAGES.PREDEFINEMATCHPAIR) _columns.map((col) => ({ ...col, canSort: !!col?.sort }));

    return nocheck ? _columns : [selectionColumn, ..._columns];
  }, [props.columns, pathname, nocheck, selectionColumn]);

  const translatedSort = React.useMemo(() => {
    return (
      sort
        ?.map?.((item) => {
          return Object.entries(item).map(([id, desc]) => {
            const title = (() => {
              const col = isArray(columns) && columns.find((col) => [col?.accessor, col?.id].includes(id));
              const output = col?.Header ?? col?.title ?? id;
              return output;
            })();
            desc = `${desc}`.toUpperCase() === 'DESC';
            return { id, desc, title };
          })?.[0];
        })
        ?.filter?.(isNotEmpty) ?? []
    );
  }, [columns, sort]);

  const tableParams = React.useMemo(() => {
    return [
      {
        data,
        columns,
        initialState: { sortBy: translatedSort },
        manualSortBy: true,
        isMultiSortEvent: () => true,
        disableSortBy: !canSort,
      },
      useFilters,
      useSortBy,
    ];
  }, [canSort, columns, data, translatedSort]);

  const reactTable = useTable(...tableParams);
  const { getTableProps, getTableBodyProps, prepareRow } = reactTable;
  const { headerGroups, rows, setSortBy, toggleSortBy } = reactTable;
  const { sortBy, filters } = reactTable.state;

  const sortRef = useAutoRef(sort);
  const sortByRef = useAutoRef(sortBy);
  const handleSortRef = useAutoRef(handleSort);

  React.useEffect(() => {
    const sortBy = sortByRef.current;
    if (!deepEquals(sortBy, translatedSort)) setSortBy(translatedSort);
  }, [setSortBy, sortByRef, translatedSort]);

  React.useEffect(() => {
    const sort = sortRef.current;
    const handleSort = handleSortRef.current;
    const newSort = sortBy.map(({ id, desc }) => ({ [id]: desc ? 'DESC' : 'ASC' }));
    if (!isArray(sort)) return void handleSort?.(newSort);
    if (!deepEquals(sort, newSort)) return void handleSort?.(newSort);
  }, [handleSortRef, sortRef, sortBy]);

  const grouppedRows = React.useMemo(() => {
    return groupBy(
      rows.map((row) => row.original),
      groupKey,
    );
  }, [groupKey, rows]);

  const freezeColumns = React.useCallback(() => {
    void catchError(() => {
      const index = columns.findIndex((col) => col.id === 'clrNm');
      if (index > -1) makeSticky(tableRef.current, { offset: 1, index: index + 1 });
    });
  }, [columns]);

  React.useLayoutEffect(() => {
    freezeColumns();
  });

  React.useEffect(() => {
    if (!isEmpty(currentType)) {
      if (areAllChecked && !isEmpty(rows)) {
        const list = rows.map((row) => (prepareRow(row), { ...row.original }));
        RowSelectService.selectRows(currentType, list, true);
      } else {
        RowSelectService.selectRows(currentType);
      }
    } else {
      RowSelectService.resetSelectedRows(currentType);
    }
  }, [areAllChecked, currentType, prepareRow, rows]);

  if (!loading && isEmpty(data)) return <NoDataShow />;

  return (
    <table ref={tableRef} {...getTableProps()} className="dn-table">
      <thead>
        {headerGroups.map((headerGroup, index) => {
          return (
            <tr key={index} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                return (
                  <th
                    key={column?.id}
                    {...column.getHeaderProps(headerProps)}
                    style={{ width: (column.width || 100) + 'px' }}
                  >
                    {column.id === 'selection'
                      ? column.render('Header')
                      : column.id !== 'action' && (
                          <DiamondListingHead
                            toggleSortBy={toggleSortBy}
                            setSortBy={setSortBy}
                            sortBy={sortBy}
                            column={column}
                            filters={filters}
                            nodots={nodots}
                            FilterOption={FilterOption}
                          />
                        )}
                  </th>
                );
              })}
            </tr>
          );
        })}
      </thead>
      <tbody {...getTableBodyProps()}>
        {!loading &&
          isArray(rows) &&
          rows.map((row) => {
            prepareRow(row);

            const { isHeader } = row.original;

            const groupId = !noGrp && row.original?.[groupKey];
            const groupRows = !noGrp && grouppedRows?.[groupId];
            const groupTitle = row.original.groupTitle;
            const showCheckBox = showGroupCheckBox || row.original.groupCheckBox;

            const lastExactSearchIndex = findLastIndex(data, (record) => Boolean(record.isExactSearch));
            const searchResultEnd = lastExactSearchIndex === row.index && data.length - 1 !== row.index;
            const similarStoneCount = data?.length - (lastExactSearchIndex + 1);

            return (
              <>
                {!noGrp &&
                  (isBoolean(isHeader) ? (
                    isHeader && (
                      <RowGroupHeader
                        row={row.original}
                        currentType={currentType}
                        groupRows={groupRows}
                        showCheckBox={showCheckBox}
                        colSpan={columns?.length}
                      >
                        {groupTitle}
                      </RowGroupHeader>
                    )
                  ) : row.original.isUpcomingHeader || row.original.isOfferHeader ? (
                    <TableGrouping
                      row={row.original}
                      currentType={currentType}
                      groupRows={groupRows}
                      showCheckBox={showGroupCheckBox}
                    />
                  ) : row.original.isOfficeHeader ? (
                    <TableGrouping row={row.original} />
                  ) : null)}
                <Row
                  row={row}
                  currentType={currentType}
                  grouppedRows={grouppedRows}
                  overrideColumns={overrideColumns}
                />
                {!noGrp && !!row.original.isMatchFooter && (
                  <TableGrouping
                    displayTotal={TableConfig.TOTAL_COLUMNS.filter((el) => columns.find((c) => c.id === el))}
                    multipleCheckBox
                    row={row.original}
                    columns={columns}
                  />
                )}
                {Boolean(searchResultEnd && similarStoneCount) && (
                  <tr className="title-row">
                    <td colSpan={columns?.length} className="position-static">
                      <div className="label">
                        <div className="text">Similar Stones ({similarStoneCount})</div>
                      </div>
                    </td>
                  </tr>
                )}
              </>
            );
          })}
      </tbody>
    </table>
  );
}

export default React.memo(Table);
