import React, { Component } from 'react';
import './pageNotFound.less';

class PageNotFound extends Component {
  render() {
    return (
      <div className="page_not">
        <div className="page_wrap">
          <figure>
            <img src={require('../../assets/img/no-diamond.png')} alt="" />
          </figure>
          <div className="page_not_content">
            <h1>404</h1>
            <h3>Oops, Page not found!</h3>
            <p>We can not seems to find the page you are looking for.</p>
            <a href="/diamond-search">
              <button className="commonButton">Go Back</button>
            </a>
          </div>
        </div>
      </div>
      // <div className="thankYouWrapper">
      //   <div className="thankYouInnerBox">
      //     <div className="thankYouMainBox">
      //       <div className="thankYouImgBlock">

      //       </div>
      //       <div className="thankYouPageContent">
      //         <h2>Oops, Page not found!</h2>

      //         <a href="/dashboard">
      //           <button className="commonButton">Go Back</button>
      //         </a>
      //       </div>
      //     </div>
      //   </div>
      // </div>
    );
  }
}

export default PageNotFound;
