import React from 'react';
import { useSelector } from 'react-redux';

import each from 'lodash/each';
import map from 'lodash/map';
import sum from 'lodash/sum';

import { RowSelectService } from 'services/RowSelectService';

import IntlMessages from 'util/IntlMessages';
import { usePathname, useIsMobile } from 'util/hooks';
import { formatCurrency, formatDecimal, isArray, isEmpty, isNotEmpty, parseDecimal } from 'util/utils';

import { PAGES } from 'constants/paths.const';

export function formatNumber(num) {
  return num ? num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : 0;
}

export function calculate(dataList) {
  const sum_total = {
    total_pieces: 0,
    total_carat: 0,
    total_avg_rap: 0,
    total_rap_avg: 0,
    final_discount: 0,
    final_price: 0,
    final_value: 0,
    total_rapaport: 0,
    avg_discount: 0,
    final_net_value: 0,
    final_rate: 0,
    final_term_discount: 0,
    avg_term_discount: 0,
    fancy_price_per_carat: 0,
  };
  const total = {
    fancy_total: { ...sum_total },
    non_fancy_total: { ...sum_total },
  };
  total.fancy_total.isFancy = 1;
  total.non_fancy_total.isFancy = 0;
  each(dataList, (lst) => {
    const use_total_key = lst.isFancy ? 'fancy_total' : 'non_fancy_total';
    total[use_total_key].total_pieces += 1;
    total[use_total_key].total_carat += lst.crt && parseFloat(lst.crt) ? parseFloat(lst.crt) : 0;
    lst.rap_avg = lst.rap ? parseFloat(lst.rap) * parseFloat(lst.crt || 0) : 0;
    total[use_total_key].total_avg_rap += lst.rap_avg;
    total[use_total_key].total_rap_avg += lst.rap_avg;
    total[use_total_key].total_rapaport += lst.rap ? parseFloat(lst.rap) : 0;
    total[use_total_key].final_value += lst.amt || 0;
    total[use_total_key].final_net_value += lst.calcAmount || 0;
    total[use_total_key].fancy_price_per_carat += lst.newPricePerCarat || 0;
  });
  total.fancy_total.final_price =
    total.fancy_total.final_value && total.fancy_total.total_carat
      ? total.fancy_total.final_value / total.fancy_total.total_carat
      : 0;
  total.non_fancy_total.final_price =
    total.non_fancy_total.final_value && total.non_fancy_total.total_carat
      ? total.non_fancy_total.final_value / total.non_fancy_total.total_carat
      : 0;

  total.fancy_total.final_rate =
    total.fancy_total.final_net_value && total.fancy_total.total_carat
      ? total.fancy_total.final_net_value / total.fancy_total.total_carat
      : 0;
  total.non_fancy_total.final_rate =
    total.non_fancy_total.final_net_value && total.non_fancy_total.total_carat
      ? total.non_fancy_total.final_net_value / total.non_fancy_total.total_carat
      : 0;
  total.fancy_total.total_avg_rap = total.fancy_total.total_avg_rap
    ? total.fancy_total.total_avg_rap / total.fancy_total.total_carat
    : 0;
  total.non_fancy_total.total_avg_rap = total.non_fancy_total.total_avg_rap
    ? total.non_fancy_total.total_avg_rap / total.non_fancy_total.total_carat
    : 0;
  total.fancy_total.final_discount =
    total.fancy_total.final_price && total.fancy_total.total_avg_rap
      ? (1 - total.fancy_total.final_price / total.fancy_total.total_avg_rap) * -100
      : 0;
  total.non_fancy_total.final_discount =
    total.non_fancy_total.final_price && total.non_fancy_total.total_avg_rap
      ? (1 - total.non_fancy_total.final_price / total.non_fancy_total.total_avg_rap) * -100
      : 0;
  total.fancy_total.final_term_discount =
    total.fancy_total.final_rate && total.fancy_total.total_avg_rap
      ? (1 - total.fancy_total.final_rate / total.fancy_total.total_avg_rap) * -100
      : 0;
  total.non_fancy_total.final_term_discount =
    total.non_fancy_total.final_rate && total.non_fancy_total.total_avg_rap
      ? (1 - total.non_fancy_total.final_rate / total.non_fancy_total.total_avg_rap) * -100
      : 0;
  const allTotal = {
    total_pieces: total.fancy_total.total_pieces + total.non_fancy_total.total_pieces,
    total_carat: total.fancy_total.total_carat + total.non_fancy_total.total_carat,
    final_rapaport: total.fancy_total.total_avg_rap + total.non_fancy_total.total_avg_rap,
    final_rap_avg: total.fancy_total.total_rap_avg + total.non_fancy_total.total_rap_avg,
    final_discount: total.fancy_total.final_discount
      ? total.fancy_total.final_discount
      : total.non_fancy_total.final_discount,
    final_value: total.fancy_total.final_value + total.non_fancy_total.final_value,
    final_price:
      total.fancy_total.total_carat + total.non_fancy_total.total_carat
        ? (total.fancy_total.final_value + total.non_fancy_total.final_value) /
          (total.fancy_total.total_carat + total.non_fancy_total.total_carat)
        : 0,
    fancy_price_per_carat: total.fancy_price_per_carat
      ? (total.fancy_total.fancy_price_per_carat + total.non_fancy_total.fancy_price_per_carat) /
        (total.fancy_total.total_carat + total.non_fancy_total.total_carat)
      : 0,
    total_rapaport: total.fancy_total.total_rapaport + total.non_fancy_total.total_rapaport,
    final_net_value: total.fancy_total.final_net_value + total.non_fancy_total.final_net_value,
    final_rate:
      total.fancy_total.total_carat + total.non_fancy_total.total_carat
        ? (total.fancy_total.final_net_value + total.non_fancy_total.final_net_value) /
          (total.fancy_total.total_carat + total.non_fancy_total.total_carat)
        : 0,
    final_term_discount: total.fancy_total.final_term_discount
      ? total.fancy_total.final_term_discount
      : total.non_fancy_total.final_term_discount,
  };
  allTotal.avg_discount = allTotal.final_rapaport ? allTotal.final_price / allTotal.final_rapaport : 0;
  allTotal.avg_term_discount = allTotal.final_rapaport ? allTotal.final_rate / allTotal.final_rapaport : 0;
  allTotal.fancy_price_per_carat =
    map(dataList, 'newPricePerCarat').length && dataList.length
      ? sum(map(dataList, 'newPricePerCarat')) / dataList.length
      : 0;
  return allTotal;
}

const SelectStone = (props) => {
  const { currentType, rows, sum = [] } = props;

  const pathname = usePathname();
  const [isMobile] = useIsMobile();

  const selectedRows = useSelector(
    (state) => state.diamond.selectedRows[currentType] ?? state.diamond.selectedRows[`${currentType}_sub`] ?? [],
  );

  const checked = isArray(rows) && !isEmpty(rows) ? rows : selectedRows;

  const summation = React.useMemo(() => calculate(checked), [checked]);

  const clearAll = () => RowSelectService.resetSelectedRows(currentType);

  const objects = React.useMemo(() => {
    return [
      {
        label: <IntlMessages id="app.Pieces" />,
        check: summation.total_pieces,
        sum: sum.count ?? 0,
      },
      {
        label: <IntlMessages id="app.Carats" />,
        check: formatDecimal(summation.total_carat),
        sum: formatDecimal(sum.totalCarat) ?? 0,
      },
      ...(pathname !== PAGES.UPCOMING
        ? [
            {
              label: <IntlMessages id="app.RapAmount" />,
              check: formatCurrency(summation.final_rap_avg),
              sum: formatDecimal(sum.rapPrice) ?? 0,
            },
            {
              label: <span>Rap$/Ct:</span>,
              check: formatCurrency(summation.final_rapaport),
              sum: formatDecimal(sum.rapPrice) ?? 0,
            },
            {
              label: <span>Avg. Dis:</span>,
              check: `${formatDecimal(summation.final_discount)}%`,
              sum: formatDecimal(sum.avgDiscount) ?? 0,
            },
            {
              label: <span>$/Ct:</span>,
              check: formatCurrency(summation.final_price),
              sum: formatDecimal(sum.totalPricePerCarat) ?? 0,
            },
            {
              label: <IntlMessages id="app.Amount" />,
              check: formatCurrency(summation.final_value),
              sum: formatDecimal(sum.totalAmount) ?? 0,
            },
          ]
        : []),
    ].filter(isNotEmpty);
  }, [sum, summation]);

  if (isMobile) {
    return (
      <div className="mobileStoneSelect">
        {objects.map((item) => {
          return (
            <div className="mobileSelectStoneItem" key={item.label}>
              {checked.length ? <span style={{ fontWeight: '500' }}>{formatNumber(item.check)}</span> : null}
              {/* <span style={{ fontWeight: '600', color: '#000' }}>{formatNumber(isNaN(item.sum) ? 0 : item.sum)}</span> */}
              <span>{item.label}</span>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    checked.length > 0 && (
      <div className={`selectStoneValueBlock ${props.profilListing}`} style={props.blockStyle || {}}>
        <div className="diamond-list-select d-flex">
          {objects.map((item, i) => {
            return (
              <div className="selectStoneValueItem" key={item.label + i}>
                <span className="selectStoneLabel">{item.label} </span>
                <span className="selectStopnValue">
                  <span className="redColor">{formatNumber(item.check)}</span>
                  {/* /{formatNumber(item.sum)} */}
                </span>
              </div>
            );
          })}
        </div>
        <div className="searchStoneClose ml-ipad-10" onClick={clearAll} style={props.clearStyle || {}}>
          <IntlMessages id="app.ClearAll" />
        </div>
      </div>
    )
  );
};

export default React.memo(SelectStone);
