import React from 'react';

import { trackTypeObj } from '../../../constants/Common';
import IntlMessages from '../../../util/IntlMessages';
import { handleInsertTrack } from '../../DiamondList/DiamondListFunctions';
import OpenNotification from '../CommonButton/OpenNotification';
import CommonModal from '../CommonModal';
import TextArea from '../TextArea';

const EnquiryPopup = ({ ...props }) => {
  const [comment, _setRemark] = React.useState('');
  const [error, setError] = React.useState(false);

  const setRemark = React.useCallback((e) => {
    _setRemark(e.target.value);
  }, []);

  React.useEffect(() => {
    setError(false);
    _setRemark('');
  }, [props.visible]);

  const onSubmit = React.useCallback(() => {
    if (!comment.length) {
      return OpenNotification({ type: 'error', title: 'Please insert remark' });
    }

    handleInsertTrack(
      trackTypeObj.ENQUIRY,
      props.checked,
      (status) => {
        // props.clearAll();
        if (status) props.handleCancel();
      },
      undefined,
      comment,
    );
  }, [comment]);

  return (
    <>
      <CommonModal
        visible={true}
        handleOk={onSubmit}
        handleCancel={props.handleCancel}
        title={<IntlMessages id="app.AddEnquiry" />}
        footerShow
        submitTitle={<IntlMessages id="app.PlaceEnquiry" />}
        cancelTitle={<IntlMessages id="app.Cancel" />}
      >
        <div className="searchPopupCommon">
          <div className="searchPopupTwoValue">
            <IntlMessages id="app.remark">
              {(placeholder) => (
                <TextArea
                  label={`${placeholder} *`}
                  onChange={setRemark}
                  value={comment}
                  placeholder={placeholder}
                  error={error && 'Please insert remark'}
                />
              )}
            </IntlMessages>
          </div>
        </div>
      </CommonModal>
    </>
  );
};

export default EnquiryPopup;
