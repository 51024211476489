import UtilService from './util';
import { diamondParameters, LOCAL_STORAGE_VAR } from '../constants/Common';

export const getLoginUser = () => UtilService.getLocalStorageItem(`user`);

const Common = {
  DiamondTrackType(req, api, cb) {
    let obj = {
      method: 'post',
      url: api,
      request: req,
    };

    UtilService.callApi(obj, function (err, data) {
      if (data && data.code === 'OK') {
        cb(null, data);
      } else {
        cb(err, null);
      }
    });
  },
};
export default Common;

export const setDateFormat = (date) => {
  let selectedDate = new Date(date);
  if (selectedDate) {
    let newYear = selectedDate.getFullYear();
    let newMonth = selectedDate.getMonth();
    let newDate = selectedDate.getDate();
    newMonth = newMonth >= 9 ? newMonth + 1 : `0${newMonth + 1}`;
    newDate = newDate > 9 ? newDate : `0${newDate}`;
    let newSetFormat = `${newDate}/${newMonth}/${newYear}`;
    return newSetFormat;
  }
};

export const setTimeFormat = (date) => {
  let selectedDate = new Date(date);

  if (selectedDate) {
    let Hours = selectedDate.getHours();
    let Minutes = selectedDate.getMinutes();
    var ampm = Hours >= 12 ? 'PM' : 'AM';
    Hours = Hours % 12;
    Hours = Hours ? (Hours < 10 ? '0' + Hours : Hours) : 12; // the hour '0' should be '12'
    Minutes = Minutes < 10 ? '0' + Minutes : Minutes;

    return `${Hours}:${Minutes} ${ampm}`;
  }
};

export const checkForMaster = (key) => {
  let keyMaster = '';
  if (diamondParameters && diamondParameters.length !== 0) {
    diamondParameters.map((mas) => {
      if (mas.key === key) {
        keyMaster = mas.master;
      }
    });
  }
  return keyMaster;
};

export const handleMobileNumber = (evt) => {
  if (evt.which < 48 || evt.which > 57) {
    evt.preventDefault();
  }
};
