import React from "react";

const PopupStoneTable = (props) => {
  return (
    <div className="summaryListWrapper">
      <div className="smallTableScroll">
        <table>
          <tr className="summaryListTop">
            <th>Shape</th>
            <th>Carat</th>
            <th>Color</th>
            <th>Clarity</th>
            <th>Cut</th>
            <th>Pol</th>
            <th>SYM</th>
            <th>Flo</th>
            <th>Rap</th>
            <th>Discount%</th>
            <th>Pr/Ct</th>
            <th>Amount</th>
          </tr>
          {props.tableData.map((t) => (
            <tr className="summaryListScroll">
              <td>{t.shpNm}</td>
              <td className="numberValue">{t.crt}</td>
              <td>{t.colNm}</td>
              <td>{t.clrNm}</td>
              <td>{t.cutNm}</td>
              <td>{t.polNm}</td>
              <td>{t.symNm}</td>
              <td>{t.fluNm}</td>

              <td className="numberValue">3700.00</td>
              <td className="numberValue">{t.cDis?parseFloat(t.cDis).toFixed(2):0}</td>
              <td className="numberValue">{parseFloat(t.ctPr).toFixed(2)}</td>
              <td className="numberValue">{parseFloat(t.amt).toFixed(2)}</td>
            </tr>
          ))}
        </table>
      </div>
    </div>
  );
};
export default PopupStoneTable;
